import React, { useEffect, useState } from "react";
import "../reminder.css";
import { Button, Card, DatePicker, message, Popconfirm, Row, Statistic, Table, Tag, Tooltip } from "antd";
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined,
  HistoryOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { hideLoading, showLoading, useLoadingDispatch } from "../../../context/loadingContext";
import { Autocomplete, Grid, Pagination, TextField } from "@mui/material";
import { vehicleDueOptions, HelperReminderTasks } from "../../../Components/common/constants";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import DriverTaskHistoryModal from "../../../Components/Modals/DriverTaskHistoryModal";
import Message from "../../../Components/common/Message";
import AuthService from "../../../Components/auth/AuthService";
import HelperTaskHistoryModal from "../../../Components/Modals/HelperTaskHistoryModal";
import { ActionDialog } from "../../../Components/actionDialog/ActionDialog";


const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
  cards: {
    "& .ant-card .ant-card-body": {
      padding: "8px",
    },
    "& .ant-statistic .ant-statistic-content": {
      // fontSize: "12px",
      fontWeight: "700",
      display: 'flex',
      justifyContent: 'end'
    },
    "& .ant-statistic .ant-statistic-title": {
      fontSize: "13px",
      color: 'darkslategrey'
    },
  }
}));


const HelperReminders = () => {
  const classes = useStyles();
  const navigate = useNavigate()
  var layoutDispatch = useLoadingDispatch();
  const currentDate = new Date();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(lastDayOfMonth);
  const [allHelpers, setAllHelpers] = React.useState([]);
  const [allReminders, setAllReminders] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [pageChange, setPageChange] = React.useState(false);
  const [limit] = React.useState(50);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(null);

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [selectedHelper, setSelectedHelper] = useState("");
  const [selectedTask, setSelectedTask] = useState("");
  const [selectedDueStatus, setSelectedDueStatus] = useState("");
  const [selectedRecord, setSelectedRecord] = useState("");

  const [isHistoryModalOpen, setIsHistoryModalOpen] = React.useState(false);
  const [historyRecord, setHistoryRecord] = React.useState([]);
  const [counts, setCounts] = React.useState({
    dueSoon: '',
    overDue: '',
    total: ''
  });

  const [actionDialogProps, setActionDialogProps] = useState({
    message: "",
    type: "",
    isOpen: false,
  });

  const ShowActionDialog = (
    message,
    type,
    isOpen,
    OnOkCallback,
    OnCancellCallback
  ) => {
    setActionDialogProps((prevState) => ({
      ...prevState,
      message: message,
      type: type,
      isOpen: isOpen,
      onOk: OnOkCallback,
      onCancel: OnCancellCallback,
    }));
  };

  const infoItems = [
    { label: "Total Overdue Reminders", count: counts?.overDue || 0, color: "red", status: 1 },
    { label: "Total Due Soon Reminders", count: counts?.dueSoon || 0, color: "orange", status: 2 },
    // { label: "Total", count: counts?.total || 0, color: "grey" },
  ];

  const reminderColumns = [
    {
      title: 'Helper',
      render: (record) => {
        return record?.helper_id
          ? allHelpers.filter((obj) => obj.id == record.helper_id)[0]?.first_name
          : ""
      },
    },
    {
      title: 'Next Due',
      render: (record )=> {
        return (
          <>
          <span>{moment(record.due_date, "YYYY-MM-DD").format("YYYY-MM-DD")}</span>
        </>
        )
       
      }

    },
    {
      align: "center",
      title: "Status",
      render: ({ due_date, type }) => {
        const serviceObject = HelperReminderTasks.find((obj) => obj.id === type);
        const info = getStatusInfo(moment(due_date, "YYYY-MM-DD"), serviceObject?.thresholdDays);
        return (
          <Tag color={info?.color} icon={info?.icon} style={{ marginLeft: '4px' }}>
            {info?.text}
          </Tag>
        );
      },
    },
    {
      align: "center",
      title: "Tasks",
      render: (record) => {
          const info = HelperReminderTasks.find((obj) => obj.id === record?.type);
        return (
          <>
          <span>{info?.title || ""}</span><br/>
          <span style={{ fontSize: '10px', color: 'darkgrey'}}>{info?.message}</span>
          </>
        );
      },
    },
    {
      align: "center",
      title: "Last Completed",
      render: (record) => {
        return (
          <>
          <span>{record.last_completed ? moment(record.last_completed, "YYYY-MM-DD").format("YYYY-MM-DD") : null}</span>
          </>
          
        );
      },
    },
    
    {
      title: "Action",
      key: "action",
      align: "right",
      dataIndex: "",
      render(record) {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            {
              !record?.last_completed && (
                <Tooltip title="Delete">
              {/* <Popconfirm
                  title="Delete Record"
                  description="Are you sure you want to delete this record?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => handleDelete(record.id)}
                  placement="topLeft"
                  okButtonProps={{ style: { height: "40px", width: '80px', fontSize: '16px' } }}
                  cancelButtonProps={{ style: { height: "40px", width: '80px', fontSize: '16px' } }}
                > */}
            <DeleteFilled
              onClick={() => handleDelete(record.id)}
              style={{ fontSize: "25px", cursor: "pointer", color: "#cd1437", marginRight: "5px", }}
            />
            {/* </Popconfirm> */}
            </Tooltip>
              )
            }
            {
              record?.last_completed && (
                <Tooltip title="History">
            <HistoryOutlined
              style={{
                fontSize: "25px",
                cursor: "pointer",
                marginRight: "5px",
                // color: "green",
              }}
              onClick={()=> {
                getHistory(record)
              }}
            />
            </Tooltip>
              )
            }
            
            <Tooltip title="Resolve">
            <CheckCircleFilled
              style={{
                fontSize: "25px",
                cursor: "pointer",
                marginRight: "5px",
                color: "green",
              }}
              onClick={()=> {
                navigate(`/helper_reminder/resolve/${record?.id}`)
              }}
            />
            </Tooltip>
            <Tooltip title="Edit">
            <EditFilled
              style={{
                fontSize: "25px",
                cursor: "pointer",
                marginRight: "5px",
                color: "#006cb8",
              }}
              onClick={()=> {
                 navigate(`/helper_reminder/${record?.id}`)
              }}
            />
            </Tooltip>
            
            
          </div>
        );
      },
    },
  ];

  const getHistory = async (data) => {
    const DataToSend = {
      driver_id: data?.driver_id,
      type: data?.type
    }
    showLoading(layoutDispatch);
    try {
      const res = await Auth.fetch("/reminder/helper-reminders-history", {
        method: "POST",
        body: JSON.stringify(DataToSend),
      })

      if(res?.ack) {
      console.log('res', res);
      if(res?.reminders.length > 0) {
        setHistoryRecord(res?.reminders)
        setIsHistoryModalOpen(true)
      } else  {
        message?.info('No related history found')
      }
      } else  {
        message.error(res?.message)
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoading(layoutDispatch)
    }
  }

  const getIntervals = (thresholdDays) => {
    const years = thresholdDays / 365;
    const months = thresholdDays / 30;
    const weeks = thresholdDays / 7;
  
    if (Number.isInteger(years)) {
      return `every ${years} year${years > 1 ? 's' : ''}`;
    } else if (Number.isInteger(months)) {
      return `every ${months} month${months > 1 ? 's' : ''}`;
    } else if (Number.isInteger(weeks)) {
      return `every ${weeks} week${weeks > 1 ? 's' : ''}`;
    } else {
      return `every ${thresholdDays} day${thresholdDays > 1 ? 's' : ''}`;
    }
  };

  const getStatusInfo = (date, thresholdDays) => {
    const daysDifference = date.diff(moment(), "days");
  
    if (daysDifference < 0) {
      return {
        color: "#ff002a",
        text: "Overdue",
        icon: <CloseCircleOutlined />,
      };
    } else if (daysDifference == thresholdDays || (daysDifference < thresholdDays && daysDifference > 0 ) || daysDifference === 0 ) {
      return {
        color: "#ec942c",
        text: "Due Soon",
        icon: <ExclamationCircleOutlined />,
      };
    } else if (daysDifference > thresholdDays) {
      return {
        color: "#339900",
        text: "Upcoming",
        icon: <CheckCircleOutlined />,
      };
    }
  };

  const handleDelete = (recordID) => {
    ShowActionDialog(
      "Are you sure to want to delete this record?",
      "confirm",
      true,
      async function () {
        console.log("Are you sure to delete this record");
        showLoading(layoutDispatch)
    const params = {
      method: "DELETE",
      body: JSON.stringify({
        id: recordID,
      }),
    };
    try {
      const res = await Auth.fetch('/reminder/reminder-helper', params)
      if(res?.ack) {
        message.success(res.message)
        search()
      } else  {
        message.error(res.message)
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoading(layoutDispatch)
    }
      
      }
    );
  };

  // const handleDelete = async (recordID) => {
  //   showLoading(layoutDispatch)
  //   const params = {
  //     method: "DELETE",
  //     body: JSON.stringify({
  //       id: recordID,
  //     }),
  //   };
  //   try {
  //     const res = await Auth.fetch('/reminder/reminder-helper', params)
  //     if(res?.ack) {
  //       message.success(res.message)
  //       search()
  //     } else  {
  //       message.error(res.message)
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     hideLoading(layoutDispatch)
  //   }
  // }


  const getAllHelpers = async () => {
    showLoading(layoutDispatch);
    try {
      const response = await Auth.fetch(`/helper/driver-helpers`);
      if (response) {
        hideLoading(layoutDispatch);
        if (response?.ack) {
          setAllHelpers(response.driver_helpers);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleChange = (event, value) => {
    setPage(value);
    setPageChange(true)
  };


  const search = (download) => {
    let search = {};
    if (startDate) search.start_date = moment(startDate).format("YYYY-MM-DD");
    if (endDate) search.end_date = moment(endDate).format("YYYY-MM-DD");
    if (selectedHelper) search.helper_id = selectedHelper.id;
    if (selectedTask) search.type = selectedTask.id;
    if (selectedDueStatus) search.status = selectedDueStatus.id;
    if (page && pageChange) search.page = page;

    showLoading(layoutDispatch);
    Auth.fetch("/reminder/reminders-helper", {
      method: "POST",
      body: JSON.stringify(search),
    }).then((res) => {
      hideLoading(layoutDispatch);
      if (res?.ack) {
        console.log('res', res);
        setAllReminders(res?.reminders)
        setCounts((prev)=>({
          ...prev,
          dueSoon: res?.due_soon_count,
          overDue: res?.over_due_count, 
          // total: res?.total_count
        }))
        setTotalPages(Math.ceil(res?.total_count / limit));
  setTotalCount(res.total_count);

      } else {
        setAllReminders([])
        message.error(res?.message)
      }
    });
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => search(), 1000);
    return () => clearTimeout(timeOutId);
  }, [
    startDate,
    endDate,
    selectedTask,
    selectedHelper,
    selectedDueStatus,
    page
  ]);

  useEffect(()=> {
    getAllHelpers()
  },[])

  useEffect(() => {
    message.config({
      top: window.innerHeight - 100, 
      duration: 3, 
    });
  }, [])

  return (
    <div>
      <div className="page-header">
        <h2>Helper Reminders</h2>
        <Button onClick={()=> navigate('/helper_reminder/new')} type="primary" icon={<PlusOutlined />}>Add Helper Reminder</Button>
      </div>
      <div className={classes?.cards}>
      <Row gutter={[16,16]}>
        {
          infoItems.map((item)=>(
          <Card 
          onClick={() => {
            if (item.count > 0) { // Check if item.count is greater than 0
              setStartDate(null);
              setEndDate(null);
              setSelectedHelper('');
              setSelectedTask('');
              
              const info = vehicleDueOptions.filter((itemCheck) => itemCheck.id == item?.status);
              console.log('info', info);
              
              setSelectedDueStatus(info[0]);
            }
          }} 
          bordered 
          style={{ background: '#f0f0f0', margin: '5px', cursor: item.count > 0 ? 'pointer' : 'not-allowed' }}>
            <div >
              <Statistic
                title={item?.label}
                value={item.count}
                valueStyle={{
                  color: item?.color,
                }}
              />
            </div>
          </Card>
          ))
        }
      
      </Row>
      </div>
      <div>
      <Grid container rowSpacing={2} columnSpacing={1.5}>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
          <label htmlFor="pcnDate">Start Due Date:</label>
          <DatePicker
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={startDate ? dayjs(startDate) : null}
               onChange={(_, dateStr) => {
                setStartDate(dateStr);
                setPageChange(false)
                setPage(1)
              }}
              placeholder="Select Date" 
               />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
          <label htmlFor="pcnDate">End Due Date:</label>
          <DatePicker
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={endDate ? dayjs(endDate) : null}
               onChange={(_, dateStr) => {
                setEndDate(dateStr);
                setPageChange(false)
                setPage(1)
              }}
              placeholder="Select Date" 
               />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
          <label htmlFor="route">Helper</label>
          <Autocomplete
            id="helper"
            size="small"
            options={allHelpers}
            getOptionLabel={(option) => option.first_name ? option.first_name : ''}
            value={selectedHelper}
            onChange={(e, values) => {
              setSelectedHelper(values);
              setPageChange(false)
              setPage(1)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Helper"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
          <label htmlFor="Status">Tasks</label>
          <Autocomplete
            id="servicetask"
            size="small"
            options={HelperReminderTasks}
            getOptionLabel={(option) => option.title ? option.title : ''}
            value={selectedTask}
            onChange={(e, values) => {
              setSelectedTask(values);
              setPageChange(false)
              setPage(1)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Tasks"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
          <label htmlFor="Status">Status</label>
          <Autocomplete
            id="dueStatus"
            size="small"
            options={vehicleDueOptions}
            getOptionLabel={(option) => option.title ? option.title : ''}
            value={selectedDueStatus}
            onChange={(e, values) => {
              setSelectedDueStatus(values);
              setPageChange(false)
              setPage(1)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Status"
              />
            )}
          />
        </Grid>
      </Grid>

      <div className={classes.antTable}>
        <Table
          columns={reminderColumns}
          style={{ overflowX: "auto", marginTop: '10px' }}
          pagination={false}
          dataSource={allReminders}
          // footer={() => `List of Reminders - Total Records: ${allReminders?.length === 0 ? "0" : allReminders?.length}`}
          footer={() => {
            const startRecord = (page - 1) * limit + 1;
            const endRecord = Math.min(page * limit, allReminders.length ? totalCount : 0); 
            return `Showing ${startRecord}-${endRecord} of ${allReminders.length ? totalCount : "0"} Records`;
          }}
        />
      </div>

      <div style={{ float: "right", margin: "10px" }}>
        <Pagination
          page={page}
          count={totalPages}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </div>
    </div>

            
    <ActionDialog
        message={actionDialogProps.message}
        type={actionDialogProps.type}
        isOpen={actionDialogProps.isOpen}
        onClose={actionDialogProps.onClose}
        onSubmit={() => {
          actionDialogProps.onOk();
        }}
        onCancel={() =>
          setActionDialogProps((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
      />
      <Message type={type} msg={loadMessage} />
      <HelperTaskHistoryModal isModalOpen={isHistoryModalOpen} setIsModalOpen={setIsHistoryModalOpen} history={historyRecord} setHistory={setHistoryRecord} />
    </div>
  );
};

export default HelperReminders;
