import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import RouteIcon from "@mui/icons-material/Route";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Card, Col, Row, Skeleton, Statistic as Stats, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  formatCurrency,
  formatWeight,
  getOrderById,
} from "../../Components/common/CommonFun";
import _ from "lodash";
import { dashboardColors } from "../../Components/common/constants";
import { updatePreference } from "./Service/Preference";
import { useSelector } from "react-redux";

const Statistic = ({ total, loading, totalVehicle, vehicleLoading }) => {
  const { chartItems, statItems: statItemsFromStore } = useSelector(
    (state) => state.UIState?.preferences
  );

  const initialStatsData = [
    {
      id: "ordersValue",
      title: "Revenue",
      value: formatCurrency(total?.revenue || 0),
      color: dashboardColors.Revenue,
      order: 1,
    },
    {
      id: "orders",
      title: "Orders",
      value: total?.orders || 0,
      icon: <ShoppingCartIcon className="icon" />,
      color: dashboardColors.Orders,
      order: 2,
    },
    {
      id: "routes",
      title: "Routes",
      value: total?.routes || 0,
      icon: <RouteIcon className="icon" />,
      color: dashboardColors.Routes,
      order: 3,
    },
    {
      id: "availableVehicles",
      title: "Available Vehicles",
      value: totalVehicle || 0,
      icon: <LocalShippingIcon className="icon" />,
      color: dashboardColors.Vehicles,
      order: 4,
    },
    {
      id: "weight",
      title: "Weight",
      value: formatWeight(total?.weight || 0),
      icon: <FitnessCenterIcon className="icon" />,
      color: dashboardColors.Weight,
      order: 5,
    },
  ];

  const [statsData, setStatsData] = useState(initialStatsData);

  const updateStatsData = () => {
    const updatedData = statsData.map((stat) => {
      if (stat.id === "ordersValue") {
        return {
          ...stat,
          value: formatCurrency(total?.revenue || 0),
          order:
            getOrderById({ data: statItemsFromStore, id: "ordersValue" }) || 1,
        };
      }
      if (stat.id === "orders") {
        return {
          ...stat,
          value: total?.orders || 0,
          order: getOrderById({ data: statItemsFromStore, id: "orders" }) || 2,
        };
      }
      if (stat.id === "routes") {
        return {
          ...stat,
          value: total?.routes || 0,
          order: getOrderById({ data: statItemsFromStore, id: "routes" }) || 3,
        };
      }
      if (stat.id === "weight") {
        return {
          ...stat,
          value: formatWeight(total?.weight || 0),
          order: getOrderById({ data: statItemsFromStore, id: "weight" }) || 4,
        };
      }
      if (stat.id === "availableVehicles") {
        return {
          ...stat,
          value: totalVehicle || 0,
          order:
            getOrderById({
              data: statItemsFromStore,
              id: "availableVehicles",
            }) || 5,
        };
      }
      return stat;
    });
    const sortedData = _.sortBy(updatedData, "order");
    setStatsData(sortedData);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const reorderedItems = Array.from(statsData);
    const [moved] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, moved);

    reorderedItems.forEach((item, index) => {
      item.order = index + 1;
    });

    const body = {
      statItems: reorderedItems.map(({ id, order }) => ({ id, order })),
      chartItems,
    };

    updatePreference(body);
    setStatsData(reorderedItems);
  };

  useEffect(() => {
    updateStatsData();
  }, [total, totalVehicle, statItemsFromStore]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="stats" direction="horizontal">
        {(provided) => (
          <Row
            gutter={[16, 16]}
            style={{ textAlign: "center" }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {statsData.map((stat, index) => (
              <Draggable key={stat.id} draggableId={stat.id} index={index}>
                {(provided) => (
                  <Col
                    xs={24}
                    sm={24}
                    md={
                      ["Weight", "Available Vehicles"].includes(stat.title)
                        ? 12
                        : 8
                    }
                    lg={
                      ["Weight", "Available Vehicles"].includes(stat.title)
                        ? 12
                        : 8
                    }
                    xl={
                      ["Weight", "Available Vehicles"].includes(stat.title)
                        ? 6
                        : 4
                    }
                    xxl={
                      ["Weight", "Available Vehicles"].includes(stat.title)
                        ? 6
                        : 4
                    }
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Card>
                      {(loading && stat.id !== "availableVehicles") ||
                      (vehicleLoading && stat.id === "availableVehicles") ? (
                        <Skeleton
                          active
                          title={false}
                          paragraph={{ rows: 2 }}
                        />
                      ) : (
                        <Tooltip
                          title={stat.value}
                          style={{ background: "red !important" }}
                        >
                          <Stats
                            title={stat.title}
                            value={stat.value}
                            prefix={
                              <span style={{ color: stat.color }}>
                                {stat?.icon}
                              </span>
                            }
                            valueStyle={{
                              color: stat.color,
                            }}
                          />
                        </Tooltip>
                      )}
                    </Card>
                  </Col>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Row>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Statistic;
