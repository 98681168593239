import * as React from "react";

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import AuthService from "../../Components/auth/AuthService";
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from "@mui/system";
import Message from "../../Components/common/Message";
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { FormButton } from "../../Components/UiElements/UiElements";
import { useLoadingDispatch, showLoading, hideLoading } from '../../context/loadingContext';
import moment from "moment";
import DatePicker from "react-date-picker";

const Auth = new AuthService();

export default function Product(props) {

  const navigate = useNavigate();
  const params = useParams();
  var layoutDispatch = useLoadingDispatch();

  const [id, setId] = React.useState(params.id ? params.id : 0);

  const [name, setName] = React.useState("");
  const [code, setCode] = React.useState("");
  const [casesInPallets, setCasesInPallets] = React.useState("");


  const [binLocations, setBinLocations] = React.useState([]);
  const [pickLocations, setPickLocations] = React.useState([]);
  const [locations, setLocations] = React.useState([]);
  const [nameError, setNameError] = React.useState("");
  const [codeError, setCodeError] = React.useState("");
  const [pickLocationError, setPickLocationError] = React.useState("");
  const [binLocationError, setBinLocationError] = React.useState("");

  const [locationsError, setLocationsError] = React.useState("");

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [messageKey, setMessageKey] = React.useState(false);

  React.useEffect(() => {
    Auth.fetch(`/location/locations`)
      .then((res1) => {
        if (res1.ack) {
          const locations1 = res1.locations;
          setLocations(locations1);
          if (id > 0) {
            showLoading(layoutDispatch);
            Auth.fetch(`/product/product?id=${id}`)
              .then((res) => {
                hideLoading(layoutDispatch);
                if (res.ack) {
                  setName(res.product.name);
                  setCode(res.product.code);
                  setCasesInPallets(res.product.cases_in_pallets);

                  const pick_locations = [];
                  const pick_locations1 = res.picking_locations;
                  if (pick_locations1.length > 0) {
                    pick_locations1.map((loc) => {
                      let d = locations1.find((obj) => obj.id == loc.location_id);
                      if (d.id) {
                        pick_locations.push(d);
                      }
                    });
                    setPickLocations(pick_locations);
                  }

                  const bin_locations = [];
                  const bin_locations1 = res.binning_locations;
                  if (bin_locations1.length > 0) {
                    bin_locations1.map((loc) => {
                      let d = locations1.find((obj) => obj.id == loc.location_id);
                      if (d.id) {
                        bin_locations.push(d);
                      }
                    });
                    setBinLocations(bin_locations);
                  }

                  // if (res.product.pick_location_id) {
                  //   const pickLocation1 = locations1.find(obj => obj.id == res.product.pick_location_id);
                  //   setPickLocation(pickLocation1);
                  // }

                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onClickCancel = () => {
    navigate("/products");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (code === "") {
      setCodeError("Required");
      return;
    } else {
      setCodeError("");
    }

    if (name === "") {
      setNameError("Required");
      return;
    } else {
      setNameError("");
    }
    if (code === "") {
      setCodeError("Required");
      return;
    } else {
      setCodeError("");
    }
    if (locations === "") {
      setLocationsError("Required");
      return;
    } else {
      setLocationsError("");
    }

    if (id > 0) {
      showLoading(layoutDispatch);
      Auth.fetch("/product/product", {
        method: "PUT",
        body: JSON.stringify({
          bin_locations: binLocations,
          pick_locations: pickLocations,
          id: id
        }),
      })
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/products");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLoading(true);
      showLoading(layoutDispatch);
      Auth.fetch("/product/product", {
        method: "POST",
        body: JSON.stringify({
          bin_locations: binLocations,
          pick_locations: pickLocations
        }),
      })
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/products");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };


  const onChangeBinLocation = (event, values) => {
    console.log(values);

    if (values.length > 0) {
      let check = pickLocations.filter(
        (s) => s.id == values[values.length - 1].id
      );
      if (check.length > 0) {
        setType("error");
        setMessageKey(!messageKey);
        setLoadMessage("Already selected as picking location");
      } else setBinLocations(values);
    }
    else setBinLocations(values);
  }
  const onChangePickLocation = (event, values) => {
    if (values.length > 0) {
      let check = binLocations.filter(
        (s) => s.id == values[values.length - 1].id
      );
      if (check.length > 0) {
        setType("error");
        setMessageKey(!messageKey);
        setLoadMessage("Already selected as binning location");
      } else setPickLocations(values);
    }
    else setPickLocations(values);
  }

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "100%" },
      }}
      noValidate
      autoComplete="off"
    >
      <h1> {id > 0 ? "Update" : "Add"} Product</h1>

      <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>


        <Grid item xs={10} sm={4}>
          <TextField
            value={code}
            disabled={true}
            label="Code"
            id="code"
          />
          <br />
          <TextField
            value={name}
            disabled={true}
            label="Name"
            id="name"
            error={nameError === "" ? false : true}
            helperText={nameError}
          />
          <br />
          <TextField
            value={casesInPallets}
            label="Cases In Pallets"
            id="casesInPallets"
            disabled={true}
          />
          <br />

          <Autocomplete
            multiple
            id="bin-locations"
            options={locations}
            getOptionLabel={(option) => option.title}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterSelectedOptions
            value={binLocations}
            onChange={onChangeBinLocation}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Binning Locations"
                placeholder="Binning Locations"
              />
            )}
          />

          <br />

          <Autocomplete
            multiple
            id="pick-locations"
            options={locations}
            getOptionLabel={(option) => option.title}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterSelectedOptions
            value={pickLocations}
            onChange={onChangePickLocation}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Picking Locations"
                placeholder="Picking Locations"
              />
            )}
          />


          <br />

        </Grid>
      </Grid>
      <Box style={{ float: "right", marginTop: "40px" }}>
        <FormButton
          type="close"
          style={{ marginRight: "10px" }}
          onClick={onClickCancel}
        >
          Cancel
        </FormButton>
        <FormButton type="save" onClick={handleSubmit}>
          Save
        </FormButton>
        {/* <Button
          variant="outlined"
          style={{ marginRight: "10px" }}
          onClick={onClickCancel}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Save
        </Button> */}
      </Box>

      <Message type={type} msg={loadMessage} />
    </Box>
  );
}
