import * as React from "react";

import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AuthService from "../../Components/auth/AuthService";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/system";
import Message from "../../Components/common/Message";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import {
  availableDays,
  vehicleTypes,
  driverTypes,
  driverIssuesActions,
  maintenanceIssues,
  driverIssuesTypes,
  HelperReminderTasks,
} from "../../Components/common/constants";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { FormButton } from "../../Components/UiElements/UiElements";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
import CloseIcon from '@mui/icons-material/Close';

import Paper from "@mui/material/Paper";
// import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import qs from "query-string";
import { v4 as uuidv4 } from "uuid";
import config from "./../../Components/config";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Col, Row, Table, Tooltip, Button as AntButton, Tag, message, Popconfirm} from "antd";
import { CheckCircleFilled, CheckCircleOutlined, CloseCircleOutlined, DeleteFilled, EditFilled, ExclamationCircleOutlined, HistoryOutlined, PlusOutlined } from "@ant-design/icons";
import HelperReminderHistoryModal from "../../Components/Modals/HelperReminderHistoryModal";
import AddHelperReminderModal from "../../Components/Modals/AddHelperReminderModal";
import EditHelperReminderModal from "../../Components/Modals/EditHelperReminderModal";
import ResolveHelperReminder from "../Reminders/HelperReminders/ResolveHelperReminder";
import CompleteHelperReminderModal from "../../Components/Modals/CompleteHelperReminderModal";


const useStyles = makeStyles((theme) => ({
 
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
}));

const Auth = new AuthService();

export default function Helper(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  var layoutDispatch = useLoadingDispatch();

  const [id, setId] = React.useState(params.id ? params.id : 0);

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");

  const [selectedDays, setSelectedDays] = React.useState([]);
  const [selectedOffDays, setSelectedOffDays] = React.useState([]);
  const [vehicleType, setVehicleType] = React.useState({ id: "", title: "" });

  const [firstNameError, setFirstNameError] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");


  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [messageKey, setMessageKey] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [allReminders, setAllReminders] = React.useState([]);
  const [allHelpers, setAllHelpers] = React.useState([]);
  const [historyRecord, setHistoryRecord] = React.useState([]);
  const [selectedRecord, setSelectedRecord] = React.useState("");



  const [reminderPage, setReminderPage] = React.useState(1);
  const [reminderPageChange, setReminderPageChange] = React.useState(false);
  const [limit] = React.useState(50);
  const [totalPagesReminder, setTotalPagesReminder] = React.useState(0);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = React.useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = React.useState(false);


  const getHelperData = async () => {
    showLoading(layoutDispatch);
    try {
      const res = await Auth.post(`/helper/driver-helpers`)
      if (res.ack) {
        setAllHelpers(res.driver_helpers);
      }
    } catch (err) {
      console.log(err);
    } finally {
      hideLoading(layoutDispatch);
    }
  } 

  const getHistory = async (data) => {
    const DataToSend = {
      helper_id: data?.helper_id,
      type: data?.type
    }
    showLoading(layoutDispatch);
    try {
      const res = await Auth.fetch("/reminder/helper-reminders-history", {
        method: "POST",
        body: JSON.stringify(DataToSend),
      })

      if(res?.ack) {
      console.log('res', res);
      if(res?.reminders.length > 0) {
        setHistoryRecord(res?.reminders)
        setIsHistoryModalOpen(true)
      } else  {
        message?.info('No related history found')
      }
      } else  {
        message.error(res?.message)
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoading(layoutDispatch)
    }
  }

  const getData = async () => {
    showLoading(layoutDispatch);
    try {
      const res = await Auth.fetch(`/helper/driver-helper?id=${id}`)
      if (res.ack) {
        setFirstName(res.driver_helper.first_name);
        setLastName(res.driver_helper.last_name);
        setPhone(res.driver_helper.phone);
        setEmail(res.driver_helper.email);

        const selected_days1 = res.seletected_days;
        const selected_off_days1 = res.seletected_off_days;
        const selected_days = [];
        const selected_off_days = [];

        if (selected_days1.length > 0) {
          selected_days1.map((day) => {
            let d = availableDays.find((obj) => obj.id == day.day);
            if (d.id) {
              selected_days.push(d);
            }
          });
          setSelectedDays(selected_days);
        }

        if (selected_off_days1.length > 0) {
          selected_off_days1.map((day) => {
            let d = availableDays.find((obj) => obj.id == day.day);
            if (d.id) {
              selected_off_days.push(d);
            }
          });
          setSelectedOffDays(selected_off_days);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      hideLoading(layoutDispatch)
    }
  }


  React.useEffect(() => {
    if (id > 0) {
      // showLoading(layoutDispatch);
      // Auth.fetch(`/helper/driver-helper?id=${id}`)
      //   .then((res) => {
      //     hideLoading(layoutDispatch);
      //     if (res.ack) {
      //       setFirstName(res.driver_helper.first_name);
      //       setLastName(res.driver_helper.last_name);

      //       const selected_days1 = res.seletected_days;
      //       const selected_off_days1 = res.seletected_off_days;
      //       const selected_days = [];
      //       const selected_off_days = [];

      //       if (selected_days1.length > 0) {
      //         selected_days1.map((day) => {
      //           let d = availableDays.find((obj) => obj.id == day.day);
      //           if (d.id) {
      //             selected_days.push(d);
      //           }
      //         });
      //         setSelectedDays(selected_days);
      //       }

      //       if (selected_off_days1.length > 0) {
      //         selected_off_days1.map((day) => {
      //           let d = availableDays.find((obj) => obj.id == day.day);
      //           if (d.id) {
      //             selected_off_days.push(d);
      //           }
      //         });
      //         setSelectedOffDays(selected_off_days);
      //       }
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      getData()
      getHelperData()
    }
  }, []);

  const onClickCancel = () => {
    navigate("/helpers");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (firstName === "") {
      setFirstNameError("Required");
      return;
    } else {
      setFirstNameError("");
    }

    if (lastName === "") {
      setLastNameError("Required");
      return;
    } else {
      setLastNameError("");
    }

    if (phone === "") {
      setPhoneError("Required");
      return;
    } else {
      setPhoneError("");
    }
    if (email === "") {
      setEmailError("Required");
      return;
    } else {
      setEmailError("");
    }

    if (id > 0) {
      showLoading(layoutDispatch);
      Auth.fetch("/helper/driver-helper", {
        method: "PUT",
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          phone: phone,
          email: email,
          vehicle_type: vehicleType,
          selected_days: selectedDays,
          selected_off_days: selectedOffDays,
          id: id,
        }),
      })
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/helpers");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // setLoading(true);
      showLoading(layoutDispatch);
      Auth.fetch("/helper/driver-helper", {
        method: "POST",
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          phone: phone,
          email: email,
          selected_days: selectedDays,
          selected_off_days: selectedOffDays,
        }),
      })
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/helpers");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChangeDays = (event, values) => {
    console.log(selectedOffDays);
    let check = selectedOffDays.filter(
      (s) => s.id == values[values.length - 1].id
    );
    if (check.length > 0) {
      setType("error");
      setMessageKey(!messageKey);
      setLoadMessage("Already selected as off day");
      // alert('Already selected as off day');
    } else setSelectedDays(values);
  };

  const onChangeOffDays = (event, values) => {
    console.log(selectedDays);
    let check = selectedDays.filter(
      (s) => s.id == values[values.length - 1].id
    );
    if (check.length > 0) {
      setType("error");
      setMessageKey(!messageKey);
      setLoadMessage("Already selected as available day");
      // alert('Already selected as available day');
    } else setSelectedOffDays(values);
  };

  const getStatusInfo = (date, thresholdDays) => {
    const daysDifference = date.diff(moment(), "days");
  
    if (daysDifference < 0) {
      return {
        color: "#ff002a",
        text: "Overdue",
        icon: <CloseCircleOutlined />,
      };
    } else if (daysDifference == thresholdDays || (daysDifference < thresholdDays && daysDifference > 0 ) || daysDifference === 0 ) {
      return {
        color: "#ec942c",
        text: "Due Soon",
        icon: <ExclamationCircleOutlined />,
      };
    } else if (daysDifference > thresholdDays) {
      return {
        color: "#339900",
        text: "Upcoming",
        icon: <CheckCircleOutlined />,
      };
    }
  }

  const reminderColumns = [
    
    {
      title: 'Next Due',
      render: (record )=> {
        return (
          <>
          <span>{moment(record.due_date, "YYYY-MM-DD").format("YYYY-MM-DD")}</span>
        </>
        )
       
      }

    },
    {
      align: "center",
      title: "Status",
      render: ({ due_date, type }) => {
        const serviceObject = HelperReminderTasks.find((obj) => obj.id === type);
        const info = getStatusInfo(moment(due_date, "YYYY-MM-DD"), serviceObject?.thresholdDays);
        return (
          <Tag color={info?.color} icon={info?.icon} style={{ marginLeft: '4px' }}>
            {info?.text}
          </Tag>
        );
      },
    },
    {
      align: "center",
      title: "Task",
      render: (record) => {
          const info = HelperReminderTasks.find((obj) => obj.id === record?.type);
        return (
          <>
          <span>{info?.title || ""}</span><br/>
          <span style={{ fontSize: '10px', color: 'darkgrey'}}>{info?.message}</span>
          </>
        );
      },
    },
    {
      align: "center",
      title: "Last Completed",
      render: (record) => {
        return (
          <>
          <span>{record.last_completed ? moment(record.last_completed, "YYYY-MM-DD").format("YYYY-MM-DD") : null}</span>
          </>
          
        );
      },
    },
    {
      title: "Action",
      key: "action",
      align: "right",
      dataIndex: "",
      render(record) {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Tooltip title="Resolve">
            <CheckCircleFilled
              style={{
                fontSize: "25px",
                cursor: "pointer",
                marginRight: "5px",
                color: "green",
              }}
              onClick={()=> {
                setSelectedRecord(record)
                setIsCompleteModalOpen(true)
              }}
            />
            </Tooltip>
            {
              record?.last_completed && (
                <Tooltip title="History">
            <HistoryOutlined
              style={{
                fontSize: "25px",
                cursor: "pointer",
                marginRight: "5px",
                // color: "green",
              }}
              onClick={()=> {
                getHistory(record)
              }}
            />
            </Tooltip>
              )
            }
            
            <Tooltip title="Edit">
            <EditFilled
              style={{
                fontSize: "25px",
                cursor: "pointer",
                marginRight: "5px",
                color: "#006cb8",
              }}
              onClick={()=> {
                setSelectedRecord(record)
                setIsEditModalOpen(true)
              }}
            />
            </Tooltip>
            {
              !record?.last_completed && (
                <Tooltip title="Delete">
            <Popconfirm
                  title="Delete Record"
                  description="Are you sure you want to delete this record?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => handleDelete(record.id)}
                  placement="topLeft"
                  okButtonProps={{ style: { height: "40px", width: '80px', fontSize: '16px' } }}
                  cancelButtonProps={{ style: { height: "40px", width: '80px', fontSize: '16px' } }}
                >
            <DeleteFilled
              style={{ fontSize: "25px", cursor: "pointer", color: "#cd1437" }}
            />
            </Popconfirm>
            </Tooltip>
              )
            }
            
            
          </div>
        );
      },
    },
  ];

  const handleDelete = async (recordID) => {
    showLoading(layoutDispatch)
    const params = {
      method: "DELETE",
      body: JSON.stringify({
        id: recordID,
      }),
    };
    try {
      const res = await Auth.fetch('/reminder/reminder-helper', params)
      if(res?.ack) {
        message.success(res.message)
        getData()
      } else  {
        message.error(res.message)
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoading(layoutDispatch)
    }
  }

  const getReminder = async () => {
    const dataToSend = {
      helper_id: Number(id),
    }
    if (reminderPage && reminderPageChange) dataToSend.page = reminderPage;
    showLoading(layoutDispatch);
    try {
      const reminderRes = await Auth.fetch("/reminder/reminders-helper", {
        method: "POST",
        body: JSON.stringify(dataToSend),
      })

      if(reminderRes?.ack) {
      setAllReminders(reminderRes?.reminders);
      setTotalPagesReminder(Math.ceil(reminderRes?.total_count / limit));
      } else {
      setAllReminders([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoading(layoutDispatch)
    }
  }

  const handleReminderPageChange = (event, value) => {
    setReminderPage(value);
    setReminderPageChange(true)
  };

  React.useEffect(() => {
    getReminder()
  }, [reminderPage]);

  React.useEffect(() => {
    message.config({
      top: window.innerHeight - 100, 
      duration: 3, 
    });
  }, [])

  return (
    <>
    <div>
    <div className="accident-box" style={{ marginTop: '15px' }}>
          <span className="accident-box-heading">Helper Details</span>
          <Row style={{marginTop: '15px'}} gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <TextField
              value={firstName}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
              fullWidth
              label="First Name"
              id="firstName"
              variant="outlined"
              size="small"
              error={firstNameError === "" ? false : true}
              helperText={firstNameError}
            />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <TextField
              value={lastName}
              onChange={(event) => {
                setLastName(event.target.value);
              }}
              fullWidth
              label="Last Name"
              id="lastName"
              variant="outlined"
              size="small"
              error={lastNameError === "" ? false : true}
              helperText={lastNameError}
            />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <TextField
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              fullWidth
              label="Email"
              id="email"
              variant="outlined"
              size="small"
              error={emailError === "" ? false : true}
              helperText={emailError}
            />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <TextField
              value={phone}
              onChange={(event) => {
                setPhone(event.target.value);
              }}
              fullWidth
              label="Phone Number"
              id="phone"
              variant="outlined"
              size="small"
              error={phoneError === "" ? false : true}
              helperText={phoneError}
            />
            </Col>
          </Row>   
          <div className="accident-box" style={{ marginTop: '15px' }}>
          <span className="accident-box-heading">Work Days Details</span>
          <Row style={{marginTop: '15px'}} gutter={[16,16]}>
          {availableDays.length && (
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Autocomplete
                multiple
                id="available-days"
                options={availableDays}
                getOptionLabel={(option) => option.title}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterSelectedOptions
                value={selectedDays}
                onChange={onChangeDays}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label="Available Days"
                    placeholder="Available Days"
                  />
                )}
              />
            </Col>
            )}
          </Row>
          <Row style={{marginTop: '15px'}} gutter={[16,16]}>
          {availableDays.length && (
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Autocomplete
                multiple
                id="off-days"
                options={availableDays}
                getOptionLabel={(option) => option.title}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterSelectedOptions
                
                value={selectedOffDays}
                onChange={onChangeOffDays}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label="Off Days"
                    placeholder="Off Days"
                  />
                )}
              />
            </Col>
            )}
          </Row>
        </div> 
        <div style={{ display: "flex", justifyContent: 'end', marginTop: '15px'}}>
        <FormButton type="close" onClick={onClickCancel}>
          Cancel
        </FormButton>
        <FormButton type="save" onClick={handleSubmit}>
          Save
        </FormButton>
        </div>
        {
          id > 0 && (
            <>
            
                <div className="accident-box" style={{ marginTop: '30px' }}>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center'}}>
                  <span className="accident-box-heading">
                    Helper Reminders
                  </span>
                <AntButton onClick={()=> {
                  setIsModalOpen(true)
                  
                }} type="primary" icon={<PlusOutlined />}>Add Helper Reminder</AntButton>
                  </div>

               <Table dataSource={allReminders} columns={reminderColumns} style={{ marginTop: "4px"}} pagination={false} />
               <div style={{ display: 'flex', justifyContent: "end" , marginTop: "10px" }}>
                <Pagination
                  page={reminderPage}
                  count={totalPagesReminder}
                  variant="outlined"
                  color="primary"
                  onChange={handleReminderPageChange}
                />
              </div>
               </div>
            </>
          )
        }
        </div>
    </div>
      <Message key={messageKey} type={type} msg={loadMessage} />
      <HelperReminderHistoryModal isModalOpen={isHistoryModalOpen} setIsModalOpen={setIsHistoryModalOpen} allHelpers={allHelpers} history={historyRecord} setHistory={setHistoryRecord} />
      <AddHelperReminderModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} allHelpers={allHelpers} searchFunction={getReminder} newRecord={id} />
      <EditHelperReminderModal isModalOpen={isEditModalOpen} setIsModalOpen={setIsEditModalOpen} allHelpers={allHelpers} selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord} searchFunction={getReminder} />
      <CompleteHelperReminderModal isModalOpen={isCompleteModalOpen} setIsModalOpen={setIsCompleteModalOpen} selectedRecord={selectedRecord} searchFunction={getReminder} allHelpers={allHelpers} />
      
    </>
  );
}
