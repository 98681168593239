import { Navigate, useLocation } from "react-router-dom";
import AuthService from "./Components/auth/AuthService";
import React from "react";
import {
  modulesPermissions,
  userRolesTypes,
} from "./Components/common/constants";
import { useSelector } from "react-redux";

const Auth = new AuthService();

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = Auth.loggedIn();
  const { type, roles } = useSelector((state) => state.AppState?.userTypes);
  const location = useLocation();

  if (type === 2) {
    if (isAuthenticated) {
      const matchedRoles = roles
        .map((role) => {
          return userRolesTypes.find(
            (userRole) => userRole.id === role.role_id
          );
        })
        .filter(Boolean);

      const allowedRoutes = matchedRoles.flatMap((matchedRole) => {
        return modulesPermissions
          .filter((module) => matchedRole.modules.includes(module.title))
          .flatMap((module) => module.routes);
      });
      const isAllowed = allowedRoutes.some((route) =>
        route === location.pathname || location.pathname.startsWith(route)
      );
      if (!isAllowed) {
        return <Navigate to="/dashboard" />;
      }
      return children;
    } else {
      return <Navigate to="/" />;
    }
  } else {
    return isAuthenticated ? children : <Navigate to="/" />;
  }
};

export default ProtectedRoute;
