import React, { useEffect, useState } from "react";
import { Button, Divider, Menu, Modal, Table, Tabs } from "antd";
import AuthService from "../auth/AuthService";
import config, { env, tms_uploads_cdn } from "../config";
import { BgColorsOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";

const Auth = new AuthService();

const FileDetailsModal = ({ isModalOpen, setIsModalOpen, fileDetails, allVehicle, reportType, extractOriginalFileName, classes }) => {

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = async () => {
    setIsModalOpen(false);
  };

  const fuelFileCol = [
    // {
    //   title: 'File Name',
    //   dataIndex: 'file_name',
    //   key: 'file_name',
    //   render: (text) => <>
    //   <a
    //   href={`${tms_uploads_cdn}/uat/imports/${reportType === 1 ? "fuels" : "service"}/${text}`}
    //   target="_blank"
    //   >{extractOriginalFileName(text)}</a>
    //   </> 
    // },
    {
        title: 'Vehicle Name',
        render: (record) => {
            console.log('record', record);
            return record?.vehicle_id
              ? allVehicle?.find(obj => obj.id.toString() === record?.vehicle_id.toString())?.registration_number
              : '';
          },
      },
    {
      title: 'Date',
      // dataIndex: 'fuel_date',
      // key: 'fuel_date',
      render: (record) => <>
      {moment(record?.fuel_date).format('YYYY-MM-DD')}
      </> 
    },
    {
        title: 'Fuel Type',
        dataIndex: 'fuel_type',
        key: 'fuel_type',
        render: (text) => <>
        {text === 2 ? 'Petrol' : 'Diesel' }
        </> 
    },
    {
        title: 'Volume',
        dataIndex: 'volume',
        key: 'volume',        
    },
    {
        title: 'Unit Price',
        dataIndex: 'unit_price',
        key: 'unit_price',        
    },
    {
        title: 'Total Price',
        render: (record) => {
            const totalPrice = record?.volume * record.unit_price
            return  <>{`£ ${totalPrice.toFixed(2)}`  }</>;
          },
    },
    {
      title: 'Odometer',
      dataIndex: 'odometer',
      key: 'odometer',        
  },
    {
        title: 'Vendor',
        dataIndex: 'vendor',
        key: 'vendor',        
    },
    {
      title: 'Fuel Tank',
      dataIndex: 'fuel_tank',
      key: 'fuel_tank',        
  },
  ]
  const serviceFileCol = [
    
    {
        title: 'Vehicle Name',
        render: (record) => {
            console.log('record', record);
            return record?.vehicle_id
              ? allVehicle?.find(obj => obj.id.toString() === record?.vehicle_id.toString())?.registration_number
              : '';
          },
      },
    {
      title: 'Date',
      // dataIndex: 'service_date',
      // key: 'service_date',
      render: (record) => <>
      {moment(record?.service_date).format('YYYY-MM-DD')}
      </> 
    },
    {
        title: 'Vendor',
        dataIndex: 'vendor',
        key: 'vendor',        
    },
    {
      title: 'Service Type',
      render: (record) => {
        return record?.service_type == 1
          ? "Valet"
          : "Valet"
      },
    },
  ]

  
  return (
    <>
      <Modal
        width={800}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        title={"Details"}
        footer={null}
      >
        <>
        <Divider />

        <span> 
        <a
        href={`${tms_uploads_cdn}/${env}/imports/${reportType === 1 ? "fuels" : "service"}/${fileDetails[0]?.file_name}`}
        target="_blank"
        >{extractOriginalFileName(fileDetails[0]?.file_name)}</a>
        </span>

        <div className={classes.antTable}>
          <Table
          columns={reportType === 1 ? fuelFileCol : serviceFileCol}
          style={{ overflowX: "auto", marginTop: '10px' }}
          pagination={false}
          bordered
          dataSource={fileDetails}
        //   footer={() => `List of Files Record `}
        />
      </div>

        </>
          
      </Modal>
    </>
  );
};

export default FileDetailsModal;
