import * as React from "react";

import {
  Button,
  Grid,
  TextField,
  Autocomplete,
  Box,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import config from "./../../Components/config";
import qs from "query-string";

import AuthService from "../../Components/auth/AuthService";
import Message from "../../Components/common/Message";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { equipmentModels, equipmentMakes, equipmentOwnership, equipmentStatus, equipmentTypes } from "../../Components/common/constants";
import { FormButton } from "../../Components/UiElements/UiElements";
import { useLoadingDispatch, showLoading, hideLoading } from '../../context/loadingContext';
import moment from "moment";
import DatePicker from "react-date-picker";

const Auth = new AuthService();

export default function Equipment(props) {

  const navigate = useNavigate();
  const params = useParams();
  var layoutDispatch = useLoadingDispatch();

  const [id, setId] = React.useState(params.id ? params.id : 0);

  const [title, setTitle] = React.useState("");
  const [serial, setSerial] = React.useState("");
  const [make, setMake] = React.useState({ id: "", title: "" });
  const [model, setModel] = React.useState({ id: "", title: "" });
  const [ownership, setOwnership] = React.useState({ id: "", title: "" });
  const [status, setStatus] = React.useState({ id: "", title: "" });
  const [dueDate, setDueDate] = React.useState("");

  const [equipmentType, setEquipmentType] = React.useState({ id: "", title: "" });
  const [titleError, setTitleError] = React.useState("");
  const [serialError, setSerialError] = React.useState("");

  const [makeError, setMakeError] = React.useState("");
  const [ownershipError, setOwnershipError] = React.useState("");
  const [statusError, setStatusError] = React.useState("");
  const [modelError, setModelError] = React.useState("");
  const [equipmentTypeError, setEquipmentTypeError] = React.useState("");

  const [bandError, setBandError] = React.useState("");

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [eqipmentFiles, setEquipmentFiles] = React.useState([]);


  React.useEffect(() => {

    if (id > 0) {
      showLoading(layoutDispatch);
      Auth.fetch(`/equipment/equipment?id=${id}`)
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack) {
            setTitle(res.equipment.title);
            setSerial(res.equipment.serial);
            const equipmentType1 = equipmentTypes.find(obj => obj.id == res.equipment.type);
            setEquipmentType(equipmentType1);

            const make1 = equipmentMakes.find(obj => obj.id == res.equipment.make);
            setMake(make1);

            const model1 = equipmentModels.find(obj => obj.id == res.equipment.model);
            setModel(model1);

            const ownership1 = equipmentOwnership.find(obj => obj.id == res.equipment.ownership);
            setOwnership(ownership1);

            const status1 = equipmentStatus.find(obj => obj.id == res.equipment.status);
            setStatus(status1);
            setEquipmentFiles(res.equipment.files != null ? JSON.parse(res.equipment.files): [])

            setDueDate(res.equipment.due_date);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

  }, []);

  const onClickCancel = () => {
    navigate("/equipments");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (title === "") {
      setTitleError("Required");
      return;
    } else {
      setTitleError("");
    }

    if (serial === "") {
      setSerialError("Required");
      return;
    } else {
      setSerialError("");
    }

    if (equipmentType == undefined || equipmentType.id == undefined || equipmentType.id == "") {
      setEquipmentTypeError("Required");
      return;
    } else {
      setEquipmentTypeError("");
    }

    if (make == undefined || make.id == undefined || make.id == "") {
      setMakeError("Required");
      return;
    } else {
      setMakeError("");
    }


    if (model == undefined || model.id == undefined || model.id == "") {
      setModelError("Required");
      return;
    } else {
      setModelError("");
    }

    if (serial === "") {
      setSerialError("Required");
      return;
    } else {
      setSerialError("");
    }

    if (ownership == undefined || ownership.id == undefined || ownership.id == "") {
      setOwnershipError("Required");
      return;
    } else {
      setOwnershipError("");
    }

    // if (status == undefined || status.id == undefined || status.id == "") {
    //   setStatusError("Required");
    //   return;
    // } else {
    //   setStatusError("");
    // }


    if (id > 0) {
      showLoading(layoutDispatch);
      Auth.fetch("/equipment/equipment", {
        method: "PUT",
        body: JSON.stringify({
          title: title,
          serial: serial,
          type: equipmentType,
          make: make,
          model: model,
          ownership: ownership,
          files: eqipmentFiles,
          // status: status,// status to be updated from maintenance
          due_date: moment(dueDate).format("YYYY-MM-DD"),
          id: id
        }),
      })
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack === true) {
            const uploadPromises = files.map(async (f, idx) => {
              try {
                const uploadUrl = await getUploadUrl(f, id);
                await uploadFileToS3(uploadUrl, f.file, f.file.type);
              } catch (error) {
                console.error("Error uploading file:", error);
              }
            });

            Promise.all(uploadPromises);
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/equipments");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLoading(true);
      showLoading(layoutDispatch);
      Auth.fetch("/equipment/equipment", {
        method: "POST",
        body: JSON.stringify({
          title: title,
          serial: serial,
          type: equipmentType,
          make: make,
          model: model,
          ownership: ownership,
          files: eqipmentFiles,
          // status: status, // status to be updated from maintenance
          due_date: moment(dueDate).format("YYYY-MM-DD"),
        }),
      })
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack === true) {
            const uploadPromises = files.map(async (f, idx) => {
              try {
                const uploadUrl = await getUploadUrl(f, res.record_id);
                await uploadFileToS3(uploadUrl, f.file, f.file.type);
              } catch (error) {
                console.error("Error uploading file:", error);
              }
            });

            Promise.all(uploadPromises);
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/equipments");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChangeEquipmentType = (event, values) => {
    console.log(values)
    setEquipmentType(values)
  }

  const onChangeMake = (event, values) => {
    console.log(values)
    setMake(values)
  }
  const onChangeModel = (event, values) => {
    console.log(values)
    setModel(values)
  }
  
  const onChangeOwnership = (event, values) => {
    console.log(values)
    setOwnership(values)
  }
  const onChangeStatus = (event, values) => {
    console.log(values)
    setStatus(values)
  }

  const handleSubmitImage = (e) => {
    e.preventDefault();
    let acceptedFiles = e.target.files;

    for (let file of acceptedFiles) {
      const randomString = Math.random().toString(36).substring(2, 10);
      const newFileName = `${file.name.split(".")[0]}-${randomString}.${file.name.split(".")[1]
        }`;
      setLoadMessage("");
      setFiles((prevFiles) => [
        ...prevFiles,
        {
          name: newFileName,
          display_name: newFileName,
          file: file,
          ContentType: file.type,
        },
      ]);
      setEquipmentFiles((prevFiles) => [...prevFiles, newFileName]);
    }
  };

  const getUploadUrl = async (file, recordId) => {
    const params = {
      bucket: config.tms_uploads_bucket,
      ContentType: file.file.type,
      Key: config.env + "/equipments/" + recordId + "/" + file.name,
    };
    const response = await fetch(
      `${config.domain}/generate-put-url?${qs.stringify(params)}`,
      {
        headers: {
          "Content-Type": params.ContentType,
        },
      }
    );
    return response.json();
  };

  const uploadFileToS3 = async (uploadUrl, file, contentType) => {
    const response = await fetch(uploadUrl.putURL, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": contentType,
      },
    });
    if (response.status !== 200) {
      throw new Error("File upload failed.");
    }
  };

  const handleRemoveImage = (name, index) => {
      setEquipmentFiles((state) => state.filter((item) => item !== name));
      setFiles((state) => state.filter((item) => item.name !== name));
  };


  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "100%" },
      }}
      noValidate
      autoComplete="off"
    >
      <h1> {id > 0 ? "Update" : "Add"} Equipment</h1>

      <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>


        <Grid item xs={10} sm={4}>
          <TextField
            value={title}
            onChange={(event) => {
              setTitle(event.target.value);
            }}
            label="Tag No."
            id="title"
            error={titleError === "" ? false : true}
            helperText={titleError}
          />
          <br />
          <TextField
            value={serial}
            onChange={(event) => {
              setSerial(event.target.value);
            }}
            label="Serial No."
            id="serial"
            error={serialError === "" ? false : true}
            helperText={serialError}
          />
          <br />
          <Autocomplete
            id="tags-standard"
            options={equipmentTypes}
            getOptionLabel={(option) => option.title}
            value={equipmentType}
            onChange={onChangeEquipmentType}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Equipment Type"
                placeholder="Equipment Type"
                error={equipmentTypeError === "" ? false : true}
                helperText={equipmentTypeError}
              />
            )}
          />
          <br />
          <Autocomplete
            id="tags-standard"
            options={equipmentMakes}
            getOptionLabel={(option) => option.title}
            value={make}
            onChange={onChangeMake}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Make"
                placeholder="Make"
                error={makeError === "" ? false : true}
                helperText={makeError}
              />
            )}
          />
          <br />
          <Autocomplete
            id="tags-standard"
            options={equipmentModels}
            getOptionLabel={(option) => option.title}
            value={model}
            onChange={onChangeModel}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Model"
                placeholder="Model"
                error={modelError === "" ? false : true}
                helperText={modelError}
              />
            )}
          />
          <br />
          <Autocomplete
            id="tags-standard"
            options={equipmentOwnership}
            getOptionLabel={(option) => option.title}
            value={ownership}
            onChange={onChangeOwnership}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Ownership"
                placeholder="Ownership"
                error={ownershipError === "" ? false : true}
                helperText={ownershipError}
              />
            )}
          />
          
          <br />
          {/* <Autocomplete
            id="tags-standard"
            options={equipmentStatus}
            getOptionLabel={(option) => option.title}
            value={status}
            onChange={onChangeStatus}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Status"
                placeholder="Status"
                error={statusError === "" ? false : true}
                helperText={statusError}
              />
            )}
          />
          <br /> */}
          LOLER Certification Due Date	
          <br/>
          <DatePicker style={{width: "100%"}} label={"Due Date"} value={dueDate} format={"dd-MM-yyyy"} onChange={(date) => {
            setDueDate(date)
          }} />
          <br />
          <Button sx={{ marginTop: '10px', background: "#1677FF", ":hover": {background: "#4096ff" } }} variant="contained" component="label">
              Add Files
              <input
                type="file"
                hidden
                onChange={(e) => {
                  handleSubmitImage(e);
                }}
              />
            </Button>

            {eqipmentFiles?.length > 0 && <p>Files:</p>}
            <ul>
              {eqipmentFiles && eqipmentFiles.map((file, idx) => {
                const lastHyphenIndex = file.lastIndexOf('-')
                const dotBeforeExtensionIndex = file.lastIndexOf('.');
                const newFileName = file.slice(0, lastHyphenIndex) + file.slice(dotBeforeExtensionIndex);
                return (
                  <li key={idx}>
                    {
                        <IconButton
                          aria-label="delete"
                          color="primary"
                          onClick={() => {
                            handleRemoveImage(file, idx);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                    }
                    {
                      id > 0 ? (<a
                        href={`${config.tms_uploads_cdn}/${config.env}/equipments/${id}/${file}`}
                        target="_blank"
                      >
                        {newFileName}
                      </a>) : (<span
                      >
                        {newFileName}
                      </span>)
                    }
                  </li>
                );
              })}
            </ul>
        </Grid>
      </Grid>
      <Box style={{ float: "right", marginTop: "40px" }}>
        <FormButton
          type="close"
          style={{ marginRight: "10px" }}
          onClick={onClickCancel}
        >
          Cancel
        </FormButton>
        <FormButton type="save" onClick={handleSubmit}>
          Save
        </FormButton>
        {/* <Button
          variant="outlined"
          style={{ marginRight: "10px" }}
          onClick={onClickCancel}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Save
        </Button> */}
      </Box>

      <Message type={type} msg={loadMessage} />
    </Box>
  );
}
