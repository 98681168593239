import React, { useEffect, useLayoutEffect, useState } from "react";
import { Autocomplete, Box, Grid, IconButton, Pagination, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AuthService from "../../Components/auth/AuthService";
import { useNavigate } from "react-router-dom";
import {
  hideLoading,
  showLoading,
  useLoadingDispatch,
} from "../../context/loadingContext";
import { Button, Table, DatePicker as AntDate, Result, Modal, Descriptions } from "antd/es";
import { DeleteFilled, EditFilled, EyeFilled, PlusCircleFilled } from "@ant-design/icons";
import DatePicker from "react-date-picker";
import moment from "moment";
import { AuthReqTypes, orderStatuses } from "../../Components/common/constants";
import Message from "../../Components/common/Message";
import config, { netsuite_url } from "../../Components/config";
import dayjs from "dayjs";
import { keyboard } from "@testing-library/user-event/dist/keyboard";
const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  tblTopDiv: {},
  tblsearch: {
    display: "flex",
    float: "left",
  },
  addIcon: {
    float: "right",
  },
  dateField: {
    width: "100%",
    marginRight: "10px",
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "thin solid #b6babf",
      borderRadius: '5px',
    }
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
  antAddIcon: {
    "& .anticon-plus-circle:hover": {
      color: "#4096ff !important"
    }
  },
}));

const Auth = new AuthService();

export default function PendingOrders() {

  const classes = useStyles();
  const navigate = useNavigate();
  var layoutDispatch = useLoadingDispatch();
  const currentDate = new Date();
  const [page, setPage] = React.useState(1);
  const [pageChange, setPageChange] = React.useState(false);
  const [limit] = React.useState(50);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(null);

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [allOrders, setAllOrders] = React.useState([]);
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [selectedStatus, setSelectedStatus] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentAuthorizer, setPaymentAuthorizer] = React.useState([]);


  
  const [filesOpen, setFilesOpen] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState("");



  const orderColumns = [
    // {
    //   title: 'Invoice',
    //   dataIndex: "invoice_no",
    //   render: (text, record) => {
    //     return <span><a href={`${config.netsuite_url}/app/accounting/transactions/custinvc.nl?id=${record.inv_internal_id}`} target="_blank">{record.invoice_no}</a></span>;
    //   },
    // },
    {
      title: 'Date',
      dataIndex: "delivery_date",
      key: "delivery_date",
    },
    {
      title: 'Route',
      dataIndex: "route",
      keyboard: "route",
    },
    {
      title: 'Driver',
      dataIndex: "driver",
      key: "driver",
    },
    {
      title: 'Vehicle',
      dataIndex: "vehicle",
      key: "vehicle",
    },
    {
      title: 'Customer',
      render: (text, record) => {
        return <span><a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${record.netsuite_customer_id}`} target="_blank">{record.netsuite_customer_code}</a></span>;
    },
    },
    {
      title: 'Sales Order No.',
      render: (text, record) => {
            return <span><a href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${record.internal_id}`} target="_blank">{record.sale_order_number}</a></span>;
      },
    },
    
    
    
   
    {
      title: "Status",
      render(record) {
        let statusText;
        let backgroundColor;
        let status;
        
          status = record.is_submitted

        switch (status) {
          case 0:
            statusText = "Not Submitted";
            backgroundColor = "#faad14";
            break;
          case 1:
            statusText = "Submitted";
            backgroundColor = "#80cb80";
            break;
          default:
            statusText = "";
            backgroundColor = "";
            break;
        }

        return {
          props: {
            style: {
              background: backgroundColor,
            },
          },
          children: <>{statusText}</>,
        };
      },
    },
    {
      title: 'Action',
      align: 'center',
      render(record) {
        return (
          <>
          {record?.is_submitted === 0 ? (
            <Button type="primary" onClick={()=> retryPost(record)}>Post to Netsuite</Button>
          ):(
            <span><a href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${record.inv_internal_id}`} target="_blank">{record.invoice_no}</a></span>
          )}
          </>
        );
      },
    },
    {
      title: "View",
      align: "center",
      width: 75,
      render(record) {
        return (
          <EyeFilled
            style={{ cursor: "pointer", fontSize: "25px" }}
            onClick={() => {
              handleFilesOpen(record);
            }}
          />
        );
      },
    },
  ];

  const retryPost = async (data) => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
    const payload = {
      internal_id: data?.internal_id,
      
    };
    showLoading(layoutDispatch)
    try {
      const response = await Auth.fetch("/route/retry-order-invoice", {
        method: "POST",
        body: JSON.stringify(payload)
        });
        if(response?.ack) {
          setType('success')
          setLoadMessage(response?.message + str)
          window.location.reload()
        } else {
          setType('error')
          setLoadMessage(response?.message + str)
          window.location.reload()
        }
    } catch(err) {
      console.log(err);
      setType('error')
      setLoadMessage('Something went wrong, try Again' + str)
    } finally {
      hideLoading(layoutDispatch)
    }
  }

  const handleFilesOpen = (row) => {
    console.log("row", row);
    if (row) {
      setSelectedRecord(row);
    }
    setFilesOpen(true);
  };
  const handleFilesClose = () => setFilesOpen(false);

  const search = () => {
    let search = {};
    if (startDate) search.date = moment(startDate).format("YYYY-MM-DD");
    // if (endDate) search.end_date = moment(endDate).format("YYYY-MM-DD");
    if (selectedStatus) search.status = selectedStatus.id;
    if (page && pageChange) search.page = page;

    // showLoading(layoutDispatch);
    setIsLoading(true)
    Auth.fetch("/route/load-pending-orders", {
      method: "POST",
      body: JSON.stringify(search),
    }).then((res) => {
    //   hideLoading(layoutDispatch);
    setIsLoading(false)
      if (res?.ack) {
        setTotalPages(Math.ceil(res.orders_count / limit));
  setTotalCount(res.orders_count);

        const newData = transformArray(res?.orders)
        console.log('newData', newData);
        setAllOrders(newData);
        
      } else {
        setAllOrders([]);
        setType('error')
        setLoadMessage(res?.message)
      }
    });
  };

  const transformArray = (data) => {
    const grouped = {};

    data.forEach(item => {
        const key = `${item.internal_id}-${item.route_status_id}`;

        if (!grouped[key]) {
            grouped[key] = {
                route_status_id: item.route_status_id,
                internal_id: item.internal_id,
                sale_order_number: item.sale_order_number,
                delivery_date: item.delivery_date,
                netsuite_customer_code: item.netsuite_customer_code,
                netsuite_customer_id: item.netsuite_customer_id,
                is_submitted: item.is_submitted,
                inv_invoice_id: item.inv_invoice_id,
                invoice_no: item.invoice_no,
                delivery_date: item.delivery_date,
                driver: item.driver,
                route: item.route,
                vehicle: item.vehicle,
                items: []
            };
        }

        grouped[key].items.push({
            quantity: item.quantity,
            item_code: item.item_code,
            item_name: item.item_name,
            delivered_quantity: item.delivered_quantity,
        });
    });

    return Object.values(grouped);
}


  const handleChange = (event, value) => {
    setPage(value);
    setPageChange(true)
  };

  useEffect(() => {
    const searchTimeout = setTimeout(() => search(), 1000);

    return () => {
        clearTimeout(searchTimeout);
    };
}, [startDate, selectedStatus, page]);


  const [screenWidth, setScreenWidth] = React.useState("");

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  React.useEffect(() => {
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const updateFilters = (newValues) => {
    const existingFiltersString = localStorage.getItem('PendingOrderFilters');
    const existingFilters = existingFiltersString ? JSON.parse(existingFiltersString) : {};
    Object.assign(existingFilters, newValues);
    existingFilters.timestamp = new Date().getTime();
    const updatedFiltersString = JSON.stringify(existingFilters);
    localStorage.setItem('PendingOrderFilters', updatedFiltersString);
  };

  const getFiltersOrDefault = () => {
    const existingFiltersString = localStorage.getItem('PendingOrderFilters');
    const existingFilters = existingFiltersString ? JSON.parse(existingFiltersString) : {};
    const currentTime = new Date().getTime();
    const expirationTime = 30 * 60 * 1000;
    if (existingFilters.timestamp && currentTime - existingFilters.timestamp < expirationTime) {

      if (!existingFilters.status) {
        existingFilters.status = '';
      }
      return existingFilters;
    } else {
      localStorage.removeItem('PendingOrderFilters');
      return {};
    }
  };

  useEffect(() => {
    const initialState = getFiltersOrDefault();
    if (!initialState?.status) {
      setSelectedStatus({ id: 0, title: "Not Submitted" });
    } else {
      setSelectedStatus(initialState?.status);
    }
    if (!initialState?.startDate) {
      setStartDate(currentDate);
      initialState.startDate = currentDate

    } else {
      setStartDate(initialState.startDate);
    }

    initialState.timestamp = new Date().getTime();
    updateFilters(initialState)
  }, []);

  const itemColumns = [
    {
      title: "S No.",
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (text, record, index) => index + 1,
      width: 60,
    },
    {
      title: "Code",
      dataIndex: "item_code",
      key: "item_code",
      width: 70,
    },
    {
      title: "Name",
      dataIndex: "item_name",
      key: "item_name",
      width: 250,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 75,
      align: 'center',
    },
    {
      title: "Delivered Quantity",
      dataIndex: "delivered_quantity",
      key: "delivered_quantity",
      width: 150,
      align: 'center',
    },
  ];

  return (
    <div>
      <h2>Pending Orders</h2>
      <Grid container rowSpacing={2} columnSpacing={1.5}>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="pcnDate">Route Date:</label>
          
          <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={startDate ? dayjs(startDate) : null}
               onChange={(_, dateStr) => {
                setStartDate(dateStr);
                updateFilters({ startDate: dateStr })
                setPageChange(false)
              setPage(1)
              }}
              placeholder="Select Start Date" 
               />
        </Grid>
        
        <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
          <label htmlFor="Status">Status</label>
          <Autocomplete
            size="small"
            id="Status-type"
            options={orderStatuses}
            getOptionLabel={(option) => option.title ? option.title : ''}
            value={selectedStatus}
            onChange={(e, values) => {
              setSelectedStatus(values);
              updateFilters({ status: values })
              setPageChange(false)
              setPage(1)

            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Status"
              />
            )}
          />
        </Grid>
      </Grid>

      <div className={classes.antTable}>
        <Table
          columns={orderColumns}
          style={{ overflowX: "auto" }}
          pagination={false}
          bordered
          dataSource={allOrders}
          loading={isLoading}
          // footer={() => `List of Orders - Total Records: ${allOrders?.length === 0 ? "0" : allOrders?.length}`}
          footer={() => {
            const startRecord = (page - 1) * limit + 1;
            const endRecord = Math.min(page * limit, allOrders.length ? totalCount : 0); 
            return `Showing ${startRecord}-${endRecord} of ${allOrders.length ? totalCount : "0"} Records`;
          }}
        />
      </div>

      <div style={{ float: "right", margin: "10px" }}>
        <Pagination
          page={page}
          count={totalPages}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </div>
      <Modal
        open={filesOpen}
        onCancel={handleFilesClose}
        okButtonProps={{ style: { display: "none" } }}
        title="Order Details"
        cancelText="Close"
        width={700}
        style={{
          top: 20,
        }}
        
      >
        <>
  {selectedRecord &&
  (
    selectedRecord?.items && selectedRecord.items.length > 0
  ) ? (
    <>
    <Descriptions style={{ marginBottom: "5px" }} bordered size={"small"}>
            <Descriptions.Item
              label={`Order No.`}
              labelStyle={{ width: "200px" }}
            >
              <span>
              {selectedRecord?.invoice_no != null && selectedRecord?.invoice_no != "" ? (
                      <a 
                          href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${selectedRecord?.inv_internal_id}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                      >
                          {selectedRecord?.invoice_no}
                      </a>
                  ) : (
                      <a 
                          href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${selectedRecord?.internal_id}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                      >
                          {selectedRecord?.sale_order_no}
                      </a>
                  )}
            </span>
            </Descriptions.Item>
          </Descriptions>
    <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Route"
            labelStyle={{ width: "200px" }}
          >
            {selectedRecord?.route || ""}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions style={{ marginBottom: "5px" }} bordered size="small">
          <Descriptions.Item
            label="Driver"
            labelStyle={{ width: "200px" }}
          >
            {selectedRecord?.driver || ""}
          </Descriptions.Item>
        </Descriptions>
          <Descriptions style={{ marginBottom: "5px" }} bordered size={"small"}>
            <Descriptions.Item
              label={`Customer`}
              labelStyle={{ width: "200px" }}
            >
              <span>
              <a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${selectedRecord?.netsuite_customer_id}`} target="_blank">{selectedRecord?.netsuite_customer_code}</a>
              </span><br/>
            </Descriptions.Item>
          </Descriptions>
    
      <h2>Items</h2>
          <Table
          columns={itemColumns}
          dataSource={selectedRecord?.items?.filter(
            (item) => item.item_code !== "Delivery Charge"
          )}
          pagination={false}
          style={{ overflowX: "auto", marginTop: "10px", marginBottom: "20px" }}
          scroll={{
            y: 450,
          }}
        />
    </>
  ) : (
    <Result status="404" subTitle="Sorry, no data data to show." />
  )}
</>
</Modal>

          <Message type={type} msg={loadMessage} />
    </div>
  );
}
