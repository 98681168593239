import React, { useEffect, useState } from "react";
import { Button, Col, Input, Modal, Row, Select } from "antd";
import Message from "../common/Message";
import AuthService from "../auth/AuthService";
import config from "../config";
import { DiscountStatuses } from "../common/constants";

const Auth = new AuthService();

const DiscountModal = ({
  isModalOpen,
  setIsModalOpen,
  selectedRecord,
  setLoadMessage,
  setType,
  search
}) => {
//   const [loadMessage, setLoadMessage] = React.useState("");
//   const [type, setType] = React.useState("info");
  const [notes, setNotes] = React.useState('');
  const [authStatus, setAuthStatus] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const { TextArea } = Input;
  const { Option } = Select;

  const handleCancel = () => {
    setAuthStatus(null);
    setNotes('')
    setIsModalOpen(false);
  };

  const handleOk = async () => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
    if(!authStatus) {
        setLoadMessage('Select status first to continue' + str)
        setType('error')
        return;
    }
    if(!notes) {
        setType('error')
        setLoadMessage('Enter notes to continue' + str)
        return;
    }
    setIsLoading(true)
    const dataToSend = {
        internal_id: selectedRecord?.internal_id,
        status: authStatus,
        notes: notes,
        type: selectedRecord?.type
    }
    try {
        const res = await Auth.fetch("/route/update-payment-authorization-status", {
            method: "PUT",
            body: JSON.stringify(dataToSend),
        });
        if(res?.ack) {
            setType('success')
            setLoadMessage(res?.message + str)
            setAuthStatus(null);
            setNotes('')
        } else {
            setType('error')
            setLoadMessage(res?.message + str)
        }

    } catch(err) {
        console.log(err);
    } finally {
        setIsModalOpen(false);
        setIsLoading(false);
        search()
    }
  };

  return (
    <>
      <Modal
        width={800}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        title={"Payment Authorization"}
        confirmLoading={isLoading}
        footer={[
          <>
                <Button
                  size="large"
                  onClick={handleCancel}
                >
                  {"Cancel"}
                </Button>
                <Button
                  type="primary"
                  size="large"
                  loading={isLoading}
                  onClick={handleOk}
                >
                  {"Save"}
                </Button>
          </>,
        ]}
      >
        
          <Row  gutter={[16, 16]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
                  Status
                </h4>
                <Select
                  style={{
                    width: "100%",
                  }}
                  value={authStatus}
                  size="large"
                  placeholder="Select Status"
                  onChange={(value) => {
                    setAuthStatus(value);
                  }}
                >
                  {DiscountStatuses.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
                  Notes
                </h4>
            <TextArea
              autoSize={{ minRows: 6, maxRows: 12 }}
              label="Notes"
              placeholder="Enter Any Specific Notes"
              onChange={(e) => setNotes(e.target.value)}
              value={notes}
            />
            </Col>
          </Row>

          
      </Modal>
    </>
  );
};

export default DiscountModal;
