import * as React from "react";

import {
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import AuthService from "../../Components/auth/AuthService";
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from "@mui/system";
import Message from "../../Components/common/Message";
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { availableDays, vehicleTypes } from "../../Components/common/constants";
import DatePicker from 'react-date-picker';
import moment from "moment";
import _ from "lodash";
import { useLoadingDispatch, showLoading, hideLoading } from '../../context/loadingContext';
import { FormButton } from "../../Components/UiElements/UiElements";


const useStyles = makeStyles((theme) => ({
  dateField: {
    marginRight: "10px"
  }
}));

const Auth = new AuthService();

export default function Roaster(props) {
  const classes = useStyles();

  const navigate = useNavigate();
  const params = useParams();
  var layoutDispatch = useLoadingDispatch();

  const [id, setId] = React.useState(params.id ? params.id : 0);
  const [roasterDate, setRoasterDate] = React.useState(new Date());


  const [selectedDays, setSelectedDays] = React.useState([]);

  const [drivers, setDrivers] = React.useState([]);
  const [selectedDrivers, setSelectedDrivers] = React.useState([]);

  const [vehicles, setVehicles] = React.useState([]);
  const [selectedVehicles, setSelectedVehicles] = React.useState([]);

  const [helpers, setHelpers] = React.useState([]);
  const [selectedHelpers, setSelectedHelpers] = React.useState([]);

  const [vehicleType, setVehicleType] = React.useState({ id: "", title: "" });

  const [roasterDateError, setRoasterDateError] = React.useState("");
  const [driverError, setDriverError] = React.useState("");
  const [helperError, setHelperError] = React.useState("");
  const [vehicleError, setVehicleError] = React.useState("");

  const [locked, setLocked] = React.useState(false);
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (id > 0) {
      showLoading(layoutDispatch);
      Auth.fetch(`/roaster/roaster?id=${id}`)
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack) {
            setRoasterDate(res.roaster.roaster_date);
            const locked1 = res.completed_route ? true : false;
            setLocked(locked1);
            const selected_vehicles1 = res.selected_vehicles;
            const selected_drivers1 = res.selected_drivers;
            const selected_helpers1 = res.selected_helpers;
            showLoading(layoutDispatch);
            Auth.fetch(`/roaster/roaster-meta?roaster_date=${res.roaster.roaster_date}`)
              .then((resV) => {
                hideLoading(layoutDispatch);
                if (resV.ack) {

                  let selected_vehicles = [];
                  let selected_drivers = [];
                  let selected_helpers = [];

                  const vehicles1 = resV.vehicles;
                  vehicles1.map((vehicle) => {
                    let vehicle_type = _.find(vehicleTypes, function (o) {
                      return o.id == vehicle.type;
                    });
                    vehicle.title += ' (' + vehicle_type.title + ')';
                  });

                  const drivers1 = resV.drivers;
                  drivers1.map((driver) => {
                    if (Number(driver.fixed) > 0) {
                      driver.title += ' (F)';
                    }
                    else {
                      driver.title += ' (E)';
                    }
                  });
                  const helpers1 = resV.helpers;
                  helpers1.map((helper) => {
                    if (Number(helper.fixed) > 0) {
                      helper.title += '(F)';
                    }
                    else {
                      helper.title += '(E)';
                    }
                  });

                  selected_vehicles1.map((sv) => {
                    let v = vehicles1.filter(v1 => v1.id == sv.vehicle_id);
                    if (v.length > 0) {
                      selected_vehicles.push(v[0]);
                    }
                  });
                  selected_drivers1.map((sd) => {
                    let d = drivers1.filter(d1 => d1.id == sd.driver_id);
                    if (d.length > 0) {
                      selected_drivers.push(d[0]);
                    }
                  });
                  selected_helpers1.map((sh) => {
                    let h = helpers1.filter(h1 => h1.id == sh.helper_id);
                    if (h.length > 0) {
                      selected_helpers.push(h[0]);
                    }
                  })

                  setSelectedVehicles(selected_vehicles);
                  setSelectedDrivers(selected_drivers);
                  setSelectedHelpers(selected_helpers);
                  setVehicles(vehicles1);
                  setDrivers(drivers1);
                  setHelpers(helpers1);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    else {
      showLoading(layoutDispatch);
      Auth.fetch(`/roaster/roaster-meta?roaster_date=${moment(roasterDate).format('YYYY-MM-DD')}`)
        .then((resV) => {
          hideLoading(layoutDispatch);
          if (resV.ack) {
            let vehicles1 = resV.vehicles;

            let selected_vehicles = [];
            vehicles1.map((vehicle) => {

              let vehicle_type = _.find(vehicleTypes, function (o) {
                return o.id == vehicle.type;
              });
              vehicle.title += ' (' + vehicle_type.title + ')';
              selected_vehicles.push(vehicle);
            });
            setSelectedVehicles(selected_vehicles);
            setVehicles(vehicles1);

            let drivers1 = resV.drivers;
            let selected_drivers = [];
            drivers1.map((driver) => {
              if (Number(driver.fixed) > 0) {
                driver.title += ' (F)';
                selected_drivers.push(driver);
              }
              else {
                driver.title += ' (E)';
              }
            });
            setSelectedDrivers(selected_drivers);
            setDrivers(drivers1);

            let helpers1 = resV.helpers;
            let selected_helpers = [];
            helpers1.map((helper) => {
              if (Number(helper.fixed) > 0) {
                helper.title += '(F)';
                selected_helpers.push(helper);
              }
              else {
                helper.title += '(E)';
              }
            });
            setSelectedHelpers(selected_helpers);
            setHelpers(helpers1);

          }
        })
        .catch((err) => {
          console.log(err);
        });

    }

  }, []);

  const onClickCancel = () => {
    navigate("/rosters");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (roasterDate === "") {
      setRoasterDateError("Required");
      return;
    } else {
      setRoasterDateError("");
    }

    if (selectedVehicles.length == 0) {
      setVehicleError("Required");
      return;
    } else {
      setVehicleError("");
    }

    if (selectedDrivers.length == 0) {
      setDriverError("Required");
      return;
    } else {
      setDriverError("");
    }

    if (selectedHelpers.length == 0) {
      setHelperError("Required");
      return;
    } else {
      setHelperError("");
    }

    if (id > 0) {
      setLoadMessage("");
      showLoading(layoutDispatch);
      Auth.fetch("/roaster/roaster", {
        method: "PUT",
        body: JSON.stringify({
          selected_vehicles: selectedVehicles,
          selected_drivers: selectedDrivers,
          selected_helpers: selectedHelpers,
          id: id
        }),
      })
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/rosters");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLoading(true);
      setLoadMessage("");
      showLoading(layoutDispatch);
      Auth.fetch("/roaster/roaster", {
        method: "POST",
        body: JSON.stringify({
          roaster_date: moment(roasterDate).format('YYYY-MM-DD'),
          selected_vehicles: selectedVehicles,
          selected_drivers: selectedDrivers,
          selected_helpers: selectedHelpers,
        }),
      })
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/rosters");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChangeVehicle = (event, values) => {
    console.log(values)
    setSelectedVehicles(values)
  }

  const onChangeDriver = (event, values) => {
    console.log(values)
    setSelectedDrivers(values)
  }

  const onChangeHelper = (event, values) => {
    console.log(values)
    setSelectedHelpers(values)
  }

  const handleChangeDate = (date) => {
    setRoasterDate(date);
    setSelectedVehicles([]);
    setSelectedDrivers([]);
    setSelectedHelpers([]);

    showLoading(layoutDispatch);
    Auth.fetch(`/roaster/roaster-meta?roaster_date=${moment(date).format('YYYY-MM-DD')}`)
      .then((resV) => {
        hideLoading(layoutDispatch);
        if (resV.ack) {
          let vehicles1 = resV.vehicles;
          let selected_vehicles = [];
          vehicles1.map((vehicle) => {
            let vehicle_type = _.find(vehicleTypes, function (o) {
              return o.id == vehicle.type;
            });
            vehicle.title += ' (' + vehicle_type.title + ')';
            selected_vehicles.push(vehicle);
          });
          setSelectedVehicles(selected_vehicles);
          setVehicles(vehicles1);

          let drivers1 = resV.drivers;
          let selected_drivers = [];
          drivers1.map((driver) => {
            if (Number(driver.fixed) > 0) {
              driver.title += '(F)';
              selected_drivers.push(driver);
            }
            else {
              driver.title += '(E)';
            }
          });
          setSelectedDrivers(selected_drivers);
          setDrivers(drivers1);

          let helpers1 = resV.helpers;
          let selected_helpers = [];
          helpers1.map((helper) => {
            if (Number(helper.fixed) > 0) {
              helper.title += '(F)';
              selected_helpers.push(helper);
            }
            else {
              helper.title += '(E)';
            }
          });
          setSelectedHelpers(selected_helpers);
          setHelpers(helpers1);

        }
      })
      .catch((err) => {
        console.log(err);
      });

  }

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "100%" },
      }}
      noValidate
      autoComplete="off"
    >
      <h1> {id > 0 ? "Update" : "Add"} Roster {locked ? "(Route Completed)" : ""}</h1>

      <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>


        <Grid item xs={12}>
          <DatePicker label={"Noor"} value={roasterDate} format={"dd-MM-yyyy"} disabled={id > 0 ? true : false} onChange={handleChangeDate} className={classes.dateField} />
          <br />
          <>
          <Box display="flex" alignItems="center" justifyContent={'end'} marginBottom={2} marginTop={2}>
        <Box display="flex" alignItems="center" marginRight={2}>
          <Box 
            width={20} 
            height={20} 
            bgcolor="rgb(142, 169, 219)" 
            marginRight={1} 
            borderRadius={1}
          />
          <Typography variant="body2">Available with Advisories</Typography>
        </Box>
        <Box display="flex" alignItems="center" marginRight={2}>
          <Box 
            width={20} 
            height={20} 
            bgcolor="rgb(198, 224, 180)" 
            marginRight={1} 
            borderRadius={1}
          />
          <Typography variant="body2">Short Route</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Box 
            width={20} 
            height={20} 
            bgcolor="rgb(84, 130, 53)" 
            marginRight={1} 
            borderRadius={1}
          />
          <Typography variant="body2">Long Route</Typography>
        </Box>
      </Box>
          </>
          {vehicles.length > 0 &&
            <Autocomplete
              multiple
              id="vehicles"
              style={{ width: "80%" }}
              options={vehicles}
              getOptionLabel={(option) => option.title}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              filterSelectedOptions
              value={selectedVehicles}
              onChange={onChangeVehicle}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const backgroundColor = 
                    option.status === 1 ? "rgb(142, 169, 219)" :
                    option.status === 2 ? "rgb(198, 224, 180)" :
                    option.status === 3 ? "rgb(84, 130, 53)" :  
                    "#E0E0E0"; 
            
                  return (
                    <Chip
                      key={option.id}
                      label={option.title}
                      {...getTagProps({ index })}
                      style={{
                        backgroundColor: backgroundColor,
                        color: "#000",
                      }}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Available Vehicles"
                  placeholder="Available Vehicles"
                  error={vehicleError === "" ? false : true}
                  helperText={vehicleError}
                />
              )}
            />
          }
          <br />
          {drivers.length > 0 &&
            <Autocomplete
              multiple
              id="drivers"
              style={{ width: "80%" }}
              options={drivers}
              getOptionLabel={(option) => option.title}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              filterSelectedOptions
              value={selectedDrivers}
              onChange={onChangeDriver}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Available Driver"
                  placeholder="Available Driver"
                  error={driverError === "" ? false : true}
                  helperText={driverError}
                />
              )}
            />
          }
          <br />
          {helpers.length > 0 &&
            <Autocomplete
              multiple
              id="helpers"
              style={{ width: "80%" }}
              options={helpers}
              getOptionLabel={(option) => option.title}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              filterSelectedOptions
              value={selectedHelpers}
              onChange={onChangeHelper}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Available Helpers"
                  placeholder="Available Helpers"
                  error={helperError === "" ? false : true}
                  helperText={helperError}
                />
              )}
            />
          }
        </Grid>
      </Grid>
      {!locked &&
        <Box style={{ float: "right", marginTop: "40px" }}>
          <FormButton
            type="close"
            sx={{ marginRight: "10px" }}
            onClick={onClickCancel}
          >
            Cancel
          </FormButton>
          <FormButton type="save" onClick={handleSubmit}>
            Save
          </FormButton>
        </Box>
      }

      <Message type={type} msg={loadMessage} />
    </Box>
  );
}
