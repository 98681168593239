import React, { useEffect, useState } from "react";
import { Button, Divider, Menu, Modal, Tabs } from "antd";
import AuthService from "../auth/AuthService";
import config, { env, tms_uploads_cdn } from "../config";
import { BgColorsOutlined, DownloadOutlined } from "@ant-design/icons";

const Auth = new AuthService();

const ImportModal = ({ isModalOpen, setIsModalOpen }) => {

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = async () => {
    setIsModalOpen(false);
  };

  const fuelItem = [
    {
        key: '1',
        label: (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span><BgColorsOutlined /> Fuel Entry</span>
              <Button icon={<DownloadOutlined />} >
              <a
                href={`${tms_uploads_cdn}/${env}/imports/templates/Fuel+Transaction+Template.csv`}
                target="_blank"
                >
                 Download
              </a>
              </Button>
            </div>
          ),
    }
  ]

  const serviceItems = [
    {
        key: '1',
        label: (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span><BgColorsOutlined /> Service Entry</span>
              <Button icon={<DownloadOutlined />} >
              <a
                href={`${tms_uploads_cdn}/${env}/imports/templates/Service+Transaction+Template.csv`}
                target="_blank"
                >
                 Download
              </a>
              </Button>
            </div>
          ),
    }
  ]

  return (
    <>
      <Modal
        width={800}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        title={"Import Templates"}
        footer={null}
      >
        <>
        <Divider />
        <Tabs
        defaultActiveKey='1'
        tabPosition={'left'}
        items={[
            {
            label: `Fuel`,
            key: '1',
            children: <>
            <span style={{ fontWeight: '500'}}>Browse Fuel Import Templates</span><br/>
            <span style={{ color: 'rgb(116 115 114', fontSize: '13px'}}>CSV files with column headers that match the Fuel fields for each Import type. Some Import Types allow you to download a prefilled template so that you can update your existing data.</span><br/>
            <div style={{ marginTop: '10px'}}> 
            <Menu
                style={{
                width: '100%',
                }}
                selectable={false}
                mode="vertical"
                items={fuelItem}
            />
            </div>
            </>,
            },
            {
            label: `Service`,
            key: '2',
            children: <>
            <span style={{ fontWeight: '500'}}>Browse Service Import Templates</span><br/>
            <span style={{ color: 'rgb(116 115 114', fontSize: '13px'}}>CSV files with column headers that match the Fuel fields for each Import type. Some Import Types allow you to download a prefilled template so that you can update your existing data.</span><br/>
            <div style={{ marginTop: '10px'}}> 
            <Menu
                style={{
                width: '100%',
                }}
                selectable={false}
                mode="vertical"
                items={serviceItems}
            />
            </div>
            </>,
            }
        ]}
      />
        </>
          
      </Modal>
    </>
  );
};

export default ImportModal;
