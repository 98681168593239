import React, { useState } from "react";

import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import AuthService from "../../Components/auth/AuthService";
import moment from "moment";
import { vehicleTypes } from "../../Components/common/constants";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import _ from "lodash";
import { ActionDialog } from "../../Components/actionDialog/ActionDialog";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
import { FormButton } from "../../Components/UiElements/UiElements";
import { EditFilled, PlusCircleFilled, SearchOutlined } from "@ant-design/icons";
import { Space, Table, Tag, Button as AntButton, DatePicker as AntDate } from "antd/es";
import Message from "../../Components/common/Message";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  tblTopDiv: {},
  tblsearch: {
    float: "left",
  },
  addIcon: {
    float: "right",
  },
  dateField: {
    width: "100%",
    marginRight: "10px",
    height: "40px",
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
  antAddIcon: {
    "& .anticon-plus-circle:hover": {
      color: "#4096ff !important"
    }
  },
}));

const Auth = new AuthService();

export default function RoasterList() {
  const classes = useStyles();
  const navigate = useNavigate();
  var layoutDispatch = useLoadingDispatch();

  const [roasters, setRoasters] = React.useState([]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [limit] = React.useState(50);

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");

  //State For Action Dialog
  const [actionDialogProps, setActionDialogProps] = useState({
    message: "",
    type: "",
    isOpen: false,
  });

  const roasterColumns = [
    {
      title: 'Date',
      dataIndex: 'roaster_date',
      key: 'roaster_date',
      width: 135,
    },
    {
      title: 'Vehicles',
      render(record) {
        return (
          record?.vehicle_roasters.map((vehicle, index)=>(
            <Space>
            <Tag style={{ margin:'4px'}} color="blue" key={index}>
            {vehicle?.registration_number}
          </Tag>
          </Space>
          ))
        );
      }
    },
    {
      title: 'Drivers',
      render(record) {
        return (
          record?.driver_roasters.map((vehicle, index)=>(
            <Tag style={{ margin:'4px'}} color={"geekblue"} key={index}>
            {vehicle?.driver_name}
          </Tag>
          ))
        );
      }
    },
    {
      title: 'Helpers',
      render(record) {
        return (
          record?.helper_roasters.map((vehicle, index)=>(
            <Tag style={{ margin:'4px'}} key={index}>
            {vehicle?.helper_name}
          </Tag>
          ))
        );
      }
    },
    {
      title: 'Action',
      key: 'action',
      align: "right",
      dataIndex: '',
      render(record) {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <EditFilled  onClick={() => handleRowClick(record.id)}  style={{ fontSize: '25px', cursor: "pointer", color: "#006cb8"}} />
          </div>
        );
      },
    },
  ];


  const ShowActionDialog = (
    message,
    type,
    isOpen,
    OnOkCallback,
    OnCancellCallback
  ) => {
    setActionDialogProps((prevState) => ({
      ...prevState,
      message: message,
      type: type,
      isOpen: isOpen,
      onOk: OnOkCallback,
      onCancel: OnCancellCallback,
    }));
  };

  React.useEffect(() => {
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    setStartDate(firstDay);
    setEndDate(lastDay);

    showLoading(layoutDispatch);
    Auth.post(`/roaster/roasters`, {
      start_date: moment(firstDay).format("YYYY-MM-DD"),
      end_date: moment(lastDay).format("YYYY-MM-DD"),
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack) {
          let roasters1 = res.roasters;

          let roasters2 = [];
          roasters1.map((roaster) => {
            let check = roasters2.filter(
              (r) => r.roaster_date == roaster.roaster_date
            );
            if (check.length > 0) {
              if (roaster.type == 1) {
                // vehicle
                let vehicle_type = _.find(vehicleTypes, function (o) {
                  return o.id == roaster.record_type;
                });
                check[0].vehicle_roasters.push({
                  vehicle_id: roaster.object_id,
                  registration_number:
                    roaster.object_title + " (" + vehicle_type.title + ")",
                });
              } else if (roaster.type == 2) {
                // driver

                check[0].driver_roasters.push({
                  driver_id: roaster.object_id,
                  driver_name:
                    roaster.object_title +
                    " (" +
                    (Number(roaster.record_type) > 0 ? "F" : "E") +
                    ")",
                });
              } else if (roaster.type == 3) {
                // helper
                check[0].helper_roasters.push({
                  helper_id: roaster.object_id,
                  helper_name:
                    roaster.object_title +
                    " (" +
                    (Number(roaster.record_type) > 0 ? "F" : "E") +
                    ")",
                });
              }
            } else {
              let roasterObj = {};
              roasterObj.id = roaster.id;
              roasterObj.roaster_date = roaster.roaster_date;
              roasterObj.vehicle_roasters = [];
              roasterObj.driver_roasters = [];
              roasterObj.helper_roasters = [];

              if (roaster.type == 1) {
                // vehicle
                let vehicle_type = _.find(vehicleTypes, function (o) {
                  return o.id == roaster.record_type;
                });
                roasterObj.vehicle_roasters.push({
                  vehicle_id: roaster.object_id,
                  registration_number:
                    roaster.object_title + " (" + vehicle_type.title + ")",
                });
              } else if (roaster.type == 2) {
                // driver
                roasterObj.driver_roasters.push({
                  driver_id: roaster.object_id,
                  driver_name:
                    roaster.object_title +
                    " (" +
                    (Number(roaster.record_type) > 0 ? "F" : "E") +
                    ")",
                });
              } else if (roaster.type == 3) {
                // helper
                roasterObj.helper_roasters.push({
                  helper_id: roaster.object_id,
                  helper_name:
                    roaster.object_title +
                    " (" +
                    (Number(roaster.record_type) > 0 ? "F" : "E") +
                    ")",
                });
              }

              roasters2.push(roasterObj);
            }
          });
          console.log(roasters2);
          console.log(roasters2.length);
          setRoasters(roasters2);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleRowClick = (id) => {
    navigate(`/rosters/${id}`)
  };

  const handleSearch = () => {
    setRoasters([]);
    showLoading(layoutDispatch);
    Auth.post(`/roaster/roasters`, {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack) {
          let roasters1 = res.roasters;

          let roasters2 = [];
          roasters1.map((roaster) => {
            let check = roasters2.filter(
              (r) => r.roaster_date == roaster.roaster_date
            );
            if (check.length > 0) {
              if (roaster.type == 1) {
                // vehicle
                let vehicle_type = _.find(vehicleTypes, function (o) {
                  return o.id == roaster.record_type;
                });
                check[0].vehicle_roasters.push({
                  vehicle_id: roaster.object_id,
                  registration_number:
                    roaster.object_title + " (" + vehicle_type.title + ")",
                });
              } else if (roaster.type == 2) {
                // driver
                check[0].driver_roasters.push({
                  driver_id: roaster.object_id,
                  driver_name:
                    roaster.object_title +
                    " (" +
                    (Number(roaster.record_type) > 0 ? "F" : "E") +
                    ")",
                });
              } else if (roaster.type == 3) {
                // helper
                check[0].helper_roasters.push({
                  helper_id: roaster.object_id,
                  helper_name:
                    roaster.object_title +
                    " (" +
                    (Number(roaster.record_type) > 0 ? "F" : "E") +
                    ")",
                });
              }
            } else {
              let roasterObj = {};
              roasterObj.id = roaster.id;
              roasterObj.roaster_date = roaster.roaster_date;
              roasterObj.vehicle_roasters = [];
              roasterObj.driver_roasters = [];
              roasterObj.helper_roasters = [];

              if (roaster.type == 1) {
                // vehicle
                let vehicle_type = _.find(vehicleTypes, function (o) {
                  return o.id == roaster.record_type;
                });
                roasterObj.vehicle_roasters.push({
                  vehicle_id: roaster.object_id,
                  registration_number:
                    roaster.object_title + " (" + vehicle_type.title + ")",
                });
              } else if (roaster.type == 2) {
                // driver
                roasterObj.driver_roasters.push({
                  driver_id: roaster.object_id,
                  driver_name: roaster.object_title,
                });
              } else if (roaster.type == 3) {
                // helper
                roasterObj.helper_roasters.push({
                  helper_id: roaster.object_id,
                  helper_name: roaster.object_title,
                });
              }

              roasters2.push(roasterObj);
            }
          });
          console.log(roasters2);
          console.log(roasters2.length);
          setRoasters(roasters2);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (id) => {
    // if (window.confirm("Are you sure to want to delete this record?")) {
    ShowActionDialog(
      "Are you sure to want to delete this record?",
      "confirm",
      true,
      function () {
        const params = {
          method: "DELETE",
          body: JSON.stringify({
            id: id,
          }),
        };
        // setLoading(true);
        setLoadMessage("");
        showLoading(layoutDispatch);
        Auth.fetch(`/roaster/roaster`, params)
          .then((res) => {
            hideLoading(layoutDispatch);
            if (res.ack) {
              setType("info");
              setLoadMessage(res.message);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
              // setLoading(false);
            } else {
              setLoadMessage("error");
              setLoadMessage(res.status);
              // setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };

  return (
    <div>
            <h2 style={{marginBottom: '10px', marginTop: 0}}>Rosters</h2>
      <Grid container rowSpacing={2} columnSpacing={1.5}>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1.5}>
          <label htmlFor="pcnDate">Start Date:</label>
          {/* <DatePicker
                label={"StartDate"}
                value={startDate}
                format={"dd-MM-yyyy"}
                onChange={(date) => {
                  setStartDate(date);
                }}
                className={classes.dateField}
              /> */}
         <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={startDate ? dayjs(startDate) : null}
               onChange={(_, dateStr) => {
                setStartDate(dateStr);
              }}
              placeholder="Select Start Date" 
               />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1.5}>
          <label htmlFor="pcnDate">End Date:</label>
          {/* <DatePicker
                label={"EndDate"}
                value={endDate}
                format={"dd-MM-yyyy"}
                onChange={(date) => {
                  setEndDate(date);
                }}
                className={classes.dateField}
              /> */}
              <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={endDate ? dayjs(endDate) : null}
               onChange={(_, dateStr) => {
                setEndDate(dateStr);
              }}
              placeholder="Select End Date" 
               />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
        <AntButton
            type="primary"
            size="large"
            onClick={handleSearch}
            style={{ marginLeft: '5px', marginTop: "22px"}}
            icon={<SearchOutlined />}
          >
            Search
          </AntButton>
          {/* <FormButton type="search" onClick={handleSearch} sx={{ height: "40px", marginTop: "22px"}}>
              Search
          </FormButton> */}
        </Grid>
      </Grid>
      {/* <Button variant="contained" style={{marginBottom: "15px"}} onClick={()=>{window.location = "/import-roasters"}}>Import Roasters</Button> */}
      <div className={classes.tblTopDiv}></div>

      <Grid container >
        <Grid item xs={12} sm={12} md={10} lg={8} xl={6}>
          <Grid container >
            {/* <Grid item xs={12} sm={6} md={4.5} sx={{ display: "flex", alignItems: "center" }}>
              <label id="startData" style={{ paddingRight: "2px" }}>
                {" "}
                Start Date:
              </label>
              <DatePicker
                label={"Noor"}
                value={startDate}
                format={"dd-MM-yyyy"}
                onChange={(date) => {
                  setStartDate(date);
                }}
                className={classes.dateField}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4.5} sx={{ display: "flex" }}>
              <label id="endDate" style={{ paddingRight: "4px" }}>
                End Date:
              </label>
              <DatePicker
                label={"Noor"}
                value={endDate}
                format={"dd-MM-yyyy"}
                onChange={(date) => {
                  setEndDate(date);
                }}
                className={classes.dateField}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} sx={{ display: "flex" ,alignItems: "center" }}>
              <FormButton type="search" onClick={handleSearch}>
                Search
              </FormButton>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={4} xl={6}>
          <div className={classes.antAddIcon}>
        <PlusCircleFilled style={{ fontSize: "30px", color:"#1677FF", float: 'right', marginBottom: '10px'}}  onClick={() =>navigate("/rosters/new")} />
            {/* <Link href="/roster/new">
              <AddCircleIcon fontSize="large" sx={{
                color: appTheme.btnColor,
              }}/>
            </Link> */}
          </div>
        </Grid>
      </Grid>
      <div className={classes.antTable}>
      <Table
          dataSource={roasters}
          style={{ overflowX: "auto" }}
          columns={roasterColumns}
          pagination={false}
          bordered
          footer={()=>`List of Roasters - Total Records: ${roasters.length === 0 ? "0" : roasters.length  }`}
        />
      </div>
        <Message type={type} msg={loadMessage} />
      <ActionDialog
        message={actionDialogProps.message}
        type={actionDialogProps.type}
        isOpen={actionDialogProps.isOpen}
        onClose={actionDialogProps.onClose}
        onSubmit={() => {
          actionDialogProps.onOk();
        }}
        onCancel={() =>
          setActionDialogProps((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
      />
    </div>
  );
}
