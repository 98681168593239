import React from 'react';

function Contact() {
	return <address>
		Magna Foodservice Limited <br/>
		Unit 19-21, Phoenix Distribution Park<br/>
		Phoenix Way, Heston<br/>
		Hounslow,<br/>
		Middlesex,<br/>
		TW5 9NB<br/>
		United Kingdom<br/>
	</address>
}

export default Contact;
