import React, { useState } from "react";
import { Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AuthService from "../../Components/auth/AuthService";
import { InputBaseField } from "../../Components/InputFields/InputFields";
import { FormButton } from "../../Components/UiElements/UiElements";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
import { ActionDialog } from "../../Components/actionDialog/ActionDialog";
import { Table } from "antd/es";
import { DeleteFilled, EditFilled, EditTwoTone, PlusCircleFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Message from "../../Components/common/Message";

const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  tblTopDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  tblsearch: {
    display: "flex",
    // float: "left",
  },
  addIcon: {
    // float: "right",
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
}));

const Auth = new AuthService();

export default function AisleList() {
  const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const navigate = useNavigate();

  const [aisles, setAisles] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(null);
  const [limit] = React.useState(50);

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");

  //State For Action Dialog
  const [actionDialogProps, setActionDialogProps] = useState({
    message: "",
    type: "",
    isOpen: false,
  });

  const aisleColumns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Levels',
      dataIndex: 'levels',
      key: 'levels',
    },
    {
      title: 'Depth',
      dataIndex: 'depth',
      key: 'depth',
    },
    {
      title: 'Total Locations',
      dataIndex: 'total_locations',
      key: 'total_locations',
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: '',
      align: "right",
      render(record) {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <EditFilled  onClick={() => handleRowClick(record.id)}  style={{ fontSize: '25px', cursor: "pointer", marginRight: "5px", color: "#001529"}} />
            <DeleteFilled onClick={() => handleDelete(record.id)} style={{ fontSize: '25px', cursor: "pointer", color: "#cd1437" }} />
          </div>
        );
      },
    },
  ];


  const ShowActionDialog = (
    message,
    type,
    isOpen,
    OnOkCallback,
    OnCancellCallback
  ) => {
    setActionDialogProps((prevState) => ({
      ...prevState,
      message: message,
      type: type,
      isOpen: isOpen,
      onOk: OnOkCallback,
      onCancel: OnCancellCallback,
    }));
  };

  React.useEffect(() => {
    showLoading(layoutDispatch);
    Auth.post(`/aisle/aisles`, {
      limit: limit,
      page: page,
      searchText: searchText,
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack) {
          setAisles(res.aisles);
          setTotalPages(Math.ceil(res.total_count / limit));
          setTotalCount(res.total_count)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleRowClick = (id) => {
    navigate(`/aisle/${id}`)

  };

  const Search = () => {
    showLoading(layoutDispatch);
    Auth.post(`/aisle/aisles`, {
      limit: limit,
      page: 1,
      searchText: searchText,
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack) {
          setAisles(res.aisles);
          setTotalPages(Math.ceil(res.total_count / limit));
  setTotalCount(res.total_count);

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleDelete = (id) => {
    // if (window.confirm("Are you sure to want to delete this record?")) {
    ShowActionDialog(
      "Are you sure to want to delete this record?",
      "confirm",
      true,
      function () {
        const params = {
          method: "DELETE",
          body: JSON.stringify({
            id: id,
          }),
        };
        // setLoading(true);
        showLoading(layoutDispatch);
        Auth.fetch(`/aisle/aisle`, params)
          .then((res) => {
            hideLoading(layoutDispatch);
            if (res.ack) {
              setType("info");
              setLoadMessage(res.message);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
              // setLoading(false);
            } else {
              setLoadMessage("error");
              setLoadMessage(res.status);
              // setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };

  return (
    <div>
      {/* <Button variant="contained" style={{marginBottom: "15px"}} onClick={()=>{window.location = "/import-aisles"}}>Import Aisles</Button> */}
      <div className={classes.tblTopDiv}>
        <div className={classes.tblsearch}>
          {/* <Button
            style={{ float: "right", height: "55px"}}
            variant="contained"
            color="primary"
            onClick={Search}
            size="large"
            endIcon={<SearchIcon />}
          >
            Search
          </Button> */}
          {/* <TextField
            style={{ float: "right", width: "350px", height: "10px", marginRight: "5px" }}
            id="standard-adornment-password"
            type="text"
            label="Search"
            variant="outlined"
            name="searchText"
            value={searchText}
            onChange={handleSearch}
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       <IconButton aria-label="Search By Student Name" onClick={Search}>
            //         <SearchIcon />
            //       </IconButton>
            //     </InputAdornment>
            //   ),
            // }}
          /> */}
          <InputBaseField
            id="searchText"
            name="searchText"
            value={searchText}
            onChange={handleSearch}
            placeholder="Search"
          />
          <FormButton
            sx={{ float: "right", height: "35.63px", marginLeft: "10px" }}
            type="search"
            onClick={Search}
          >
            Search
          </FormButton>
        </div>
        <div>
        <PlusCircleFilled style={{ fontSize: "30px", color:"#006cb8"}}  onClick={() =>navigate("/aisle/new")} />

          {/* <Link href="/aisle/new">
            <AddCircleIcon fontSize="large"  sx={{
                color: appTheme.btnColor,
              }}/>
          </Link> */}
        </div>
      </div>
      <div className={classes.antTable}>
      <Table
        columns={aisleColumns}
        style={{ overflowX: "auto" }}
        dataSource={aisles}
        pagination={false} 
        // footer={()=>`List of Aisle - Total Records: ${aisles.length === 0 ? "0" : aisles.length  }`}
        footer={() => {
          const startRecord = (page - 1) * limit + 1;
          const endRecord = Math.min(page * limit, aisles.length ? totalCount : 0); 
          return `Showing ${startRecord}-${endRecord} of ${aisles.length ? totalCount : "0"} Records`;
        }}
        />
      </div>
      <Message type={type} msg={loadMessage} />
      <div style={{ float: "right", margin: "10px" }}>
        <Pagination
          page={page}
          count={totalPages}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </div>
      <ActionDialog
        message={actionDialogProps.message}
        type={actionDialogProps.type}
        isOpen={actionDialogProps.isOpen}
        onClose={actionDialogProps.onClose}
        onSubmit={() => {
          actionDialogProps.onOk();
        }}
        onCancel={() =>
          setActionDialogProps((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
      />
    </div>
  );
}
