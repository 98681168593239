import React from 'react';
import { FormButton } from './UiElements/UiElements';
import { Button } from '@mui/material';
import Papaparse from 'papaparse';

function DownloadInvoices() {

	const readCsv = (file) => {
		const reader = new FileReader();
		reader.readAsBinaryString(file);
		reader.addEventListener('load', function (e) {
			const data = e.target.result;
			Papaparse.parse(data, {
				complete: function (results) {
					console.log("results: ", results.data);
				},
			});
		});
	};

	const invoiceDownloads = (invoices) => {
		for (var idx = 0; idx < invoices.length; idx++) {
			console.log(invoices[idx])
			window.open(`https://5615243.app.netsuite.com/app/accounting/print/hotprint.nl?regular=T&sethotprinter=T&formnumber=221&whence=&printtype=transaction&trantype=custinvc&recordtype=&label=Invoice&id=${invoices[idx]}`);
		}
	}
	const submitFile = (e) => {
		e.preventDefault();

		console.log('inside upload')
		let files = e.target.files;

		const reader = new FileReader();
		reader.readAsBinaryString(files[0]);
		reader.addEventListener('load', function (e) {
			const data = e.target.result;
			Papaparse.parse(data, {
				complete: function (results) {
					console.log("results: ", results.data);
					invoiceDownloads(results.data);
				},
			});
		});
	}
	return (
		<div>
			<Button sx={{ background: "#001529", ":hover": { background: "#204265" } }} variant="contained" component="label">
				Upload Invoices
				<input
					type="file"
					accept="csv"
					hidden
					onChange={(e) => {
						submitFile(e);
					}}
				/>
			</Button>
		</div>
	)
}

export default DownloadInvoices;
