import React from 'react';
import { Result, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Fallback() {
  const navigate = useNavigate()
  return (
    <Result
      className="p-bottom-0"
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist or inaccessible."
      extra={
        <Link to={'/dashboard'}>
          <Button type="primary" onClick={()=> navigate('/dashboard')}>Back To Dashboard</Button>
        </Link>
      }
    />
  );
}
export default Fallback;
