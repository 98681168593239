import {
  ClockCircleOutlined,
  EyeFilled,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Progress, Table, Tag, Tooltip } from "antd";
import React from "react";
import {
  formatCurrency,
  formatWeight,
} from "../../Components/common/CommonFun";
import { dashboardColors, schedules } from "../../Components/common/constants";

const RoutesTable = ({ onView, data, total, loading }) => {
  const getSchedule = (id) => {
    return schedules?.find((v) => v.id == id)?.title;
  };

  const getProgress = (total, completed) => {
    if (total === 0) return 0;
    const percentage = (completed / total) * 100;
    return Math.round(percentage);
  };

  const columns = [
    {
      title: (
        <div className="flex">
          <span>Route</span>
          <Tag color={dashboardColors.Routes}>{total?.routes ?? 0}</Tag>
        </div>
      ),
      dataIndex: "title",
      key: "title",
      width: 130,
      render: (_, record) => {
        return (
          <Tooltip title={`${record.title} (${record?.code})`}>
            <span className="truncate">{`${record.title} (${record?.code})`}</span>
          </Tooltip>
        );
      },
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Vehicle",
      dataIndex: "vehicle",
      key: "vehicle",
      width: 100,
    },
    {
      title: "Driver",
      dataIndex: "driver_name",
      key: "driver_name",
      width: 190,
      render: (driver) => (
        <Tooltip title={driver}>
          <Tag
            color="cyan"
            style={{
              display: "inline-flex",
              alignItems: "center",
              paddingBlock: 2,
            }}
          >
            <Avatar
              className="driver-avatar"
              size={20}
              icon={<UserOutlined />}
            />
            <span className="truncate" style={{ maxWidth: "140px" }}>
              {driver}
            </span>
          </Tag>
        </Tooltip>
      ),
      sorter: (a, b) => a.driver_name.localeCompare(b.driver_name),
    },
    {
      title: "Schedule",
      dataIndex: "schedule_id",
      key: "schedule_id",
      width: 80,
      render: (i) => (
        <Tag color="blue" icon={<ClockCircleOutlined />}>
          {getSchedule(i)}
        </Tag>
      ),
    },
    {
      title: (
        <div className="flex">
          <span>Orders</span>
          <Tag color={dashboardColors.Orders}>{total?.orders ?? 0}</Tag>
        </div>
      ),
      dataIndex: "total_orders",
      key: "total_orders",
      width: 100,
    },
    {
      title: (
        <div className="flex">
          <span>Revenue</span>
          <Tag color={dashboardColors.Revenue}>
            {formatCurrency(total?.revenue) ?? 0}
          </Tag>
        </div>
      ),
      dataIndex: "order_value",
      key: "order_value",
      width: 150,
      render: (amount) => {
        return <span>{formatCurrency(amount)}</span>;
      },
    },
    {
      title: (
        <div className="flex">
          <span>Weight</span>
          <Tag color={dashboardColors.Weight}>
            {formatWeight(total?.weight ?? 0)}
          </Tag>
        </div>
      ),
      dataIndex: "order_weight",
      key: "order_weight",
      width: 160,
      render: (weight) => {
        return <span>{formatWeight(weight)}</span>;
      },
    },
    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
      width: 170,
      render: (_, pct) => {
        const percentage = getProgress(
          pct?.total_orders,
          pct?.completed_orders
        );
        return (
          <Progress
            percent={percentage}
            status="active"
            size={["100%", 10]}
            strokeColor={
              percentage < 50
                ? "#f5222d"
                : percentage < 75
                ? "#faad14"
                : "#52c41a"
            }
          />
        );
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: 100,
      render: (_, record) => {
        return (
          <Button
            variant="filled"
            color="primary"
            shape="circle"
            className="action-btn"
            onClick={() => onView(record)}
          >
            <EyeFilled />
          </Button>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={{ y: 400 }}
      loading={loading}
    />
  );
};

export default RoutesTable;
