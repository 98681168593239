import * as React from "react";

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { colors } from "../../Components/common/constants";
import AuthService from "../../Components/auth/AuthService";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/system";
import Message from "../../Components/common/Message";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import DraggableList from "./DraggableList";
import { vehicleTypes, schedules } from "../../Components/common/constants";

import _ from "lodash";
import { FormButton } from "../../Components/UiElements/UiElements";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";

const Auth = new AuthService();

export default function RouteForm(props) {
  const navigate = useNavigate();
  const params = useParams();
  var layoutDispatch = useLoadingDispatch();

  const [id, setId] = React.useState(params.id ? params.id : 0);

  const [code, setCode] = React.useState("");
  const [codeError, setCodeError] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [titleError, setTitleError] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [descriptionError, setDescriptionError] = React.useState("");
  const [color, setColor] = React.useState("");
  const [colorError, setColorError] = React.useState("");

  const [vehicles, setVehicles] = React.useState([]);
  const [vehicle, setVehicle] = React.useState({
    id: "",
    registration_number: "",
  });
  const [vehicleError, setVehicleError] = React.useState("");

  const [drivers, setDrivers] = React.useState([]);
  const [driver, setDriver] = React.useState({ id: "", first_name: "" });
  const [driverError, setDriverError] = React.useState("");

  const [helpers, setHelpers] = React.useState([]);
  const [helper, setHelper] = React.useState({ id: "", first_name: "" });
  const [helperError, setHelperError] = React.useState("");

  const [schedule, setSchedule] = React.useState({ id: "", title: "" });
  const [scheduleError, setScheduleError] = React.useState("");

  const [customers, setCustomers] = React.useState([]);
  const [selectedCustomers, setSelectedCustomers] = React.useState([]);
  const [customerError, setCustomerError] = React.useState("");

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    Auth.fetch(`/route/routes-meta`)
      .then((res1) => {
        const customers1 = res1.customers;
        const vehicles1 = res1.vehicles;
        const drivers1 = res1.drivers;
        const helpers1 = res1.helpers;
        setCustomers(customers1);
        setVehicles(vehicles1);
        setDrivers(drivers1);
        setHelpers(helpers1);
        if (id > 0) {
          showLoading(layoutDispatch);
          Auth.fetch(`/route/route?id=${id}`)
            .then((res) => {
              hideLoading(layoutDispatch);
              if (res.ack) {
                setCode(res.route.code);
                setTitle(res.route.title);
                setDescription(res.route.description);
                const clr = _.find(colors, function (c) {
                  return c.title == res.route.color;
                });
                setColor(clr);

                let selected_vehicle = vehicles1.filter(
                  (v) => v.id == res.route.preferred_vehicle_id
                );
                if (selected_vehicle.length > 0) {
                  setVehicle(selected_vehicle[0]);
                }
                let selected_driver = drivers1.filter(
                  (v) => v.id == res.route.preferred_driver_id
                );
                if (selected_driver.length > 0) {
                  setDriver(selected_driver[0]);
                }
                let selected_helper = helpers1.filter(
                  (v) => v.id == res.route.preferred_helper_id
                );
                if (selected_helper.length > 0) {
                  setHelper(selected_helper[0]);
                }
                let selected_schedule = schedules.filter(
                  (v) => v.id == res.route.preferred_schedule_id
                );
                if (selected_schedule.length > 0) {
                  setSchedule(selected_schedule[0]);
                }
                let selected_customers1 = res.selected_customers;
                let selected_customers = [];
                selected_customers1.map((sc) => {
                  let check = customers1.filter((c) => c.netsuite_customer_id == sc.netsuite_customer_id);
                  if (check.length > 0) selected_customers.push(check[0]);
                });
                setSelectedCustomers(selected_customers);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onClickCancel = () => {
    navigate("/routes");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!(id > 0)) {
      if (code === "") {
        setCodeError("Required");
        return;
      } else {
        setCodeError("");
      }
    }

    if (title === "") {
      setTitleError("Required");
      return;
    } else {
      setTitleError("");
    }

    if (description === "") {
      setDescriptionError("Required");
      return;
    } else {
      setDescriptionError("");
    }

    if (!(color && color.id)) {
      setColorError("Required");
      return;
    } else {
      setColorError("");
    }

    setLoadMessage("");
    if (id > 0) {
      showLoading(layoutDispatch);
      Auth.fetch("/route/route", {
        method: "PUT",
        body: JSON.stringify({
          title: title,
          description: description,
          color: color.title,
          selected_customers: selectedCustomers,
          selected_vehicle: vehicle,
          selected_driver: driver,
          selected_helper: helper,
          selected_schedule: schedule,
          id: id,
        }),
      })
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/routes");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLoading(true);
      showLoading(layoutDispatch);
      Auth.fetch("/route/route", {
        method: "POST",
        body: JSON.stringify({
          code: code,
          title: title,
          description: description,
          color: color.title,
          selected_customers: selectedCustomers,
          selected_vehicle: vehicle,
          selected_schedule: schedule,
        }),
      })
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/routes");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChangeCustomers = (event, values) => {
    setSelectedCustomers(values);
  };

  const onChangeVehicle = (event, values) => {
    setVehicle(values);
    setDriver({ id: "", first_name: "" });
  };

  const onChangeDriver = (event, values) => {
    if (Number(values.vehicle_type) < Number(vehicle.type)) {
      let rand = Math.random() * 50; // for unique message each time
      let str = " ";
      for (let i = 0; i < rand; i++) {
        str += " ";
      }
      setType("error");
      setLoadMessage(
        "Driver can't be assigned to a " +
          vehicleTypes.filter((obj) => obj.id == vehicle.type)[0].title +
          str
      );
      setDriver({ id: "", first_name: "" });
      return;
    } else {
      setDriver(values);
    }
  };

  const onChangeHelper = (event, values) => {
    setHelper(values);
  };

  const onChangeSchedule = (event, values) => {
    setSchedule(values);
  };

  const shopSort = (selected_customers) => {
    setSelectedCustomers(selected_customers);
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "100%" },
      }}
      noValidate
      autoComplete="off"
    >
      <h1> {id > 0 ? "Update" : "Add"} Route</h1>

      <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
        <Grid item xs={10} sm={4}>
          <TextField
            value={code}
            onChange={(event) => {
              let val = event.target.value.replace(/\s/g, "");
              setCode(val.toUpperCase());
            }}
            label="Code"
            placeholder="Code (Not Editable)"
            id="code"
            disabled={id > 0 ? true : false}
            error={codeError === "" ? false : true}
            helperText={codeError}
            autoFocus={!(id > 0) ? true : false}
          />
          <br />
          <TextField
            value={title}
            onChange={(event) => {
              setTitle(event.target.value);
            }}
            label="Title"
            id="title"
            error={titleError === "" ? false : true}
            helperText={titleError}
            autoFocus={id > 0 ? true : false}
          />
          <br />
          <TextField
            value={description}
            onChange={(event) => {
              setDescription(event.target.value);
            }}
            label="Description"
            id="description"
            error={descriptionError === "" ? false : true}
            helperText={descriptionError}
          />
          <br />
          <FormControl
            style={{
              width: "85%",
              marginLeft: "9px",
              marginTop: "10px",
              float: "left",
            }}
          >
            <InputLabel id="color-lable">Color</InputLabel>
            <Select
              labelId="color-lable"
              id="color"
              value={color}
              label="Color"
              onChange={(event) => {
                setColor(event.target.value);
              }}
              error={colorError === "" ? false : true}
              helperText={colorError}
            >
              {colors.map((clr) => {
                return (
                  <MenuItem value={clr}>
                    <span
                      style={{
                        width: "40px",
                        height: "40px",
                        background: clr.id,
                        marginRight: "10px",
                      }}
                    ></span>
                    {clr.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {color && color.id && (
            <span
              style={{
                float: "right",
                width: "50px",
                height: "55px",
                background: color.id,
                marginTop: "10px",
              }}
            ></span>
          )}
          <br />
          <br />
          {vehicles.length > 0 && (
            <Autocomplete
              id="tags-standard"
              options={vehicles}
              getOptionLabel={(option) =>
                option.registration_number +
                (option.type > 0
                  ? " (" +
                    vehicleTypes.filter((obj) => obj.id == option.type)[0]
                      .title +
                    ")"
                  : "")
              }
              value={vehicle}
              onChange={onChangeVehicle}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Preferred Vehicles"
                  placeholder="Preferred Vehicles"
                  error={vehicleError === "" ? false : true}
                  helperText={vehicleError}
                />
              )}
            />
          )}
          <br />
          {drivers.length > 0 && (
            <Autocomplete
              id="tags-standard"
              options={drivers}
              getOptionLabel={(option) =>
                option.first_name +
                (option.vehicle_type > 0
                  ? " (" +
                    vehicleTypes.filter(
                      (obj) => obj.id == option.vehicle_type
                    )[0].title +
                    ")"
                  : "")
              }
              value={driver}
              onChange={onChangeDriver}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Preferred Drivers"
                  placeholder="Preferred Drivers"
                  error={driverError === "" ? false : true}
                  helperText={driverError}
                />
              )}
            />
          )}
          <br />
          {helpers.length > 0 && (
            <Autocomplete
              id="tags-standard"
              options={helpers}
              getOptionLabel={(option) => option.first_name}
              value={helper}
              onChange={onChangeHelper}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Preferred Helpers"
                  placeholder="Preferred Helpers"
                  error={helperError === "" ? false : true}
                  helperText={helperError}
                />
              )}
            />
          )}

          <br />
          {schedules.length > 0 && (
            <Autocomplete
              id="tags-standard"
              options={schedules}
              getOptionLabel={(option) => option.title}
              value={schedule}
              onChange={onChangeSchedule}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Preferred Schedules"
                  placeholder=" Preferred Schedules"
                  error={scheduleError === "" ? false : true}
                  scheduleText={scheduleError}
                />
              )}
            />
          )}

          {customers.length > 0 && (
            <Autocomplete
              multiple
              id="customers"
              options={customers}
              getOptionLabel={(option) =>
                "(" +
                option.code +
                ") - " +
                option.title +
                " [" +
                option.postcode +
                "]" +
                (option.route_code ? " - (" + option.route_code + ")" : "")
              }
              // getOptionDisabled={option => Number(option.route_id) > 0 && id != option.route_id}

              isOptionEqualToValue={(option, value) => option.id === value.id}
              filterSelectedOptions
              value={selectedCustomers}
              onChange={onChangeCustomers}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Customers"
                  placeholder="Customers"
                  error={customerError === "" ? false : true}
                  helperText={customerError}
                />
              )}
            />
          )}
          <br />
        </Grid>
      </Grid>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
        <Grid item xs={12} sm={10} md={6}>
          <DraggableList shops={selectedCustomers} shopSort={shopSort} />
        </Grid>
      </Grid>
      <Box style={{ float: "right", marginTop: "40px" }}>
        <FormButton type="close" onClick={onClickCancel}>
          Cancel
        </FormButton>
        <FormButton type="save" onClick={handleSubmit}>
          Save
        </FormButton>
        {/* <Button
          variant="outlined"
          style={{ marginRight: "10px" }}
          onClick={onClickCancel}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Save
        </Button> */}
      </Box>

      <Message type={type} msg={loadMessage} />
    </Box>
  );
}
