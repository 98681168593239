import React, { useState } from "react";
import { Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AuthService from "../../Components/auth/AuthService";
import { vehicleColors, vehicleMakeOptions, vehicleModelOptions, vehicleTypeOptions, vehicleTypes } from "../../Components/common/constants";
import { InputBaseField } from "../../Components/InputFields/InputFields";
import { FormButton } from "../../Components/UiElements/UiElements";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
import { ActionDialog } from "../../Components/actionDialog/ActionDialog";
import { DeleteFilled, EditFilled, HistoryOutlined, PlusCircleFilled, SearchOutlined } from "@ant-design/icons";
import { Table, Button as AntButton } from "antd/es";
import { useNavigate } from "react-router-dom";
import Message from "../../Components/common/Message";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  tblTopDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  tblsearch: {
    display: "flex",
    // float: "left",
  },
  addIcon: {
    // float: "right",
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
  antAddIcon: {
    "& .anticon-plus-circle:hover": {
      color: "#4096ff !important"
    }
  },
}));

const Auth = new AuthService();

export default function VehicleList() {
  const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const navigate = useNavigate();
  const userType = useSelector((state) => state.AppState?.userTypes?.type);

  const [vehicles, setVehicles] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [limit] = React.useState(50);
  const [totalCount, setTotalCount] = React.useState(null);
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");

  //State For Action Dialog
  const [actionDialogProps, setActionDialogProps] = useState({
    message: "",
    type: "",
    isOpen: false,
  });

  const VehcleColumns = [
    {
      title: 'Registration Number',
      dataIndex: 'registration_number',
      key: 'registration_number',
    },
    {
      title: 'Weight Type',
      render: (record) => {
        return record?.type
          ? vehicleTypes.find(obj => obj.id.toString() === record.type.toString())?.title
          : '';
      },
    },
    {
      title: 'Vehicle Type',
      render: (record) => {
        return record?.vehicle_spec
          ? vehicleTypeOptions.find(obj => obj.id.toString() === record?.vehicle_spec?.toString())?.title
          : '';
      },
    },
    {
      title: 'Max Weight (KGs)',
      render: (record) => {
        return record?.max_weight
          ? record?.max_weight
          : '';
      },
    },
    {
      title: 'Height (Meters)',
      render: (record) => {
        return record?.height
          ? record?.height
          : '';
      },
    },
    {
      title: 'Year',
      dataIndex: "year",
      key: "year"
    },
    {
      title: 'Make',
      render: (record) => {
        return record?.make
          ? vehicleMakeOptions.find(obj => obj.id.toString() === record?.make?.toString())?.title
          : '';
      },
    },
    {
      title: 'Model',
      render: (record) => {
        return record?.model
          ? vehicleModelOptions.find(obj => obj.id.toString() === record?.model?.toString())?.title
          : '';
      },
    },
    {
      title: 'Color',
      render: (record) => {
        return record?.color
          ? vehicleColors.find(obj => obj.id.toString() === record?.color?.toString())?.title
          : '';
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: "right",
      dataIndex: '',
      render(record) {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            {
              userType != 2 && <HistoryOutlined onClick={() => handleHistory(record.id)} style={{ fontSize: '25px', cursor: "pointer", marginRight: "5px", color: "#001529"}} />
            }
            <EditFilled  onClick={() => handleRowClick(record.id)}  style={{ fontSize: '25px', cursor: "pointer", marginRight: "5px", color: "#006cb8"}} />
            <DeleteFilled onClick={() => handleDelete(record.id)} style={{ fontSize: '25px', cursor: "pointer", color: "#cd1437" }} />

          </div>
        );
      },
    },
  ];


  const ShowActionDialog = (
    message,
    type,
    isOpen,
    OnOkCallback,
    OnCancellCallback
  ) => {
    setActionDialogProps((prevState) => ({
      ...prevState,
      message: message,
      type: type,
      isOpen: isOpen,
      onOk: OnOkCallback,
      onCancel: OnCancellCallback,
    }));
  };

  React.useEffect(() => {
    showLoading(layoutDispatch);
    Auth.post(`/vehicle/vehicles`, {
      limit: limit,
      page: page,
      searchText: searchText,
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack) {
          setVehicles(res.vehicles);
          setTotalPages(Math.ceil(res.total_count / limit));
          setTotalCount(res.total_count)

        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleRowClick = (id) => {
    navigate(`/vehicles/${id}`)
  };
  const handleHistory = (id) => {
    navigate(`/maintenance/${id}`)
  }

  const Search = () => {
    showLoading(layoutDispatch);
    Auth.post(`/vehicle/vehicles`, {
      limit: limit,
      page: 1,
      searchText: searchText,
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack) {
          setVehicles(res.vehicles);
          setTotalPages(Math.ceil(res.total_count / limit));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleDelete = (id) => {
    // if (window.confirm("Are you sure to want to delete this record?")) {
    ShowActionDialog(
      "Are you sure to want to delete this record?",
      "confirm",
      true,
      function () {
        const params = {
          method: "DELETE",
          body: JSON.stringify({
            id: id,
          }),
        };
        // setLoading(true);
        showLoading(layoutDispatch);
        Auth.fetch(`/vehicle/vehicle`, params)
          .then((res) => {
            hideLoading(layoutDispatch);
            if (res.ack) {
              setType("info");
              setLoadMessage(res.message);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
              // setLoading(false);
            } else {
              setLoadMessage("error");
              setLoadMessage(res.status);
              // setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };

  return (
    <div>
            <h2 style={{marginBottom: '10px', marginTop: 0}}>Vehicles</h2>
      {/* <Button variant="contained" style={{marginBottom: "15px"}} onClick={()=>{window.location = "/import-vehicles"}}>Import Vehicles</Button> */}
      <div className={classes.tblTopDiv}>
      <div style={{display: "flex", flexDirection: "column", gap: "8px"}}>
          <div style={{display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px"}}>
            <InputBaseField
            id="searchText"
            name="searchText"
            value={searchText}
            onChange={handleSearch}
            placeholder="Search"
          />
            
          <AntButton
            type="primary"
            size="large"
            onClick={Search}
            style={{ marginLeft: '5px'}}
            icon={<SearchOutlined />}
          >
            Search
          </AntButton>
          </div>
          </div>
        {/* <div className={classes.tblsearch}>
          <InputBaseField
            id="searchText"
            name="searchText"
            value={searchText}
            onChange={handleSearch}
            placeholder="Search"
          />
          <FormButton
            sx={{ float: "right", height: "35.63px", marginLeft: "10px" }}
            type="search"
            onClick={Search}
          >
            Search
          </FormButton>
        </div> */}
        <div>
          <div className={classes.antAddIcon}>
            <PlusCircleFilled style={{ fontSize: "30px", color:"#1677FF"}}  onClick={() =>navigate("/vehicles/new")} />
          </div>
          {/* <Link href="/driver/new">
            <AddCircleIcon fontSize="large" sx={{
                color: appTheme.btnColor,
              }} />
          </Link> */}
        </div>
        {/* <div className={classes.addIcon}>
          <Link href="/vehicle/new">
            <AddCircleIcon fontSize="large" sx={{
                color: appTheme.btnColor,
              }} />
          </Link>
        </div> */}
      </div>
      <div className={classes.antTable}>
      <Table
          dataSource={vehicles}
          style={{ overflowX: "auto" }}
          columns={VehcleColumns}
          pagination={false}
          // footer={()=>`List of Vehicles - Total Records: ${vehicles.length === 0 ? "0" : vehicles.length  }`}
          footer={() => {
            const startRecord = (page - 1) * limit + 1;
            const endRecord = Math.min(page * limit, vehicles.length ? totalCount : 0); 
            return `Showing ${startRecord}-${endRecord} of ${vehicles.length ? totalCount : "0"} Records`;
          }}
        />
      </div>
      <Message type={type} msg={loadMessage} />
      <div style={{ float: "right", margin: "10px" }}>
        <Pagination
          page={page}
          count={totalPages}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </div>
      <ActionDialog
        message={actionDialogProps.message}
        type={actionDialogProps.type}
        isOpen={actionDialogProps.isOpen}
        onClose={actionDialogProps.onClose}
        onSubmit={() => {
          actionDialogProps.onOk();
        }}
        onCancel={() =>
          setActionDialogProps((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
      />
    </div>
  );
}
