const initialState = {
  type: '',
  roles: []
};

const userTypes = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_TYPE":
      return {
        ...state,
        type: action.payload,
      };
      case "SET_USER_ROLES":
        return {
          ...state,
          roles: action.payload,
        };
        case "RESET_ROLES":
          return initialState
    default:
      return state;
  }
};

export default userTypes;
