import * as React from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
//components//
import SideBar from "./SideBar";
import Header from "./Header";
import { styled } from "@mui/material/styles";
import Loading from "../loading/Loading";

const Main = styled("main")(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "calc( 100% - 64px )",
  // background: "#001529"
  background: "#00467a"
}));

export default function Layout({ children }) {
  return (
    <Box>
      <Header />
      <Box sx={{ width: "100%", marginTop: "64px" }}>
        <Main>
          <SideBar />
          <Box sx={{ width: "100%" ,padding:"15px", background: "white",
          overflow: "auto",
          backgroundColor:"#fffff" }}>
            <Outlet />
          </Box>
        </Main>
      </Box>
      <Loading />
    </Box>
    // <Copyright />
  );
}
