let appTheme = {
  header: {
    bgColor: "transparent",
    bgColor1: "#343a40",
    // headerBorder: ".5px solid #FFFFFF",
    headerTextColor: "#343a40",
  },
  sidebar: {
    bgColor: "transparent",
    bgHoverColor: "#ebe9e9",
    menuBgHover: "#343a40",
    menuTextHover: "#d2d6de",
    menuTextColor: "#d2d6de",
    menuSvgHover: "#d2d6de",
    menuSvgColor: "#d2d6de",
    menuSlectedBgColor: "#3a6194",
    menuSlectedTextColor: "#d2d6de",
    menuSlectedSvgColor: "#d2d6de",
  },
  footer: {},
  btnColor: "#343a40",
  btnBgHover:"#007bff",
  cancelBtn: "#32303B",

};


export { appTheme };
