import React, { useReducer, useEffect } from "react";
import { createContext } from "react";

const LayoutState = createContext();
const LayoutDispatch = createContext();

const LayoutReducer = (state = { isSidebarOpen: true }, action) => {
  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      return {
        ...state,
        isSidebarOpen: action.payload,
      };

    default: {
      throw new Error(`Invalid action: ${action.type}`);
    }
  }
};
const LayoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LayoutReducer, {
    isSidebarOpen: true,
  });

  return (
    <LayoutState.Provider value={state}>
      <LayoutDispatch.Provider value={dispatch}>
        {children}
      </LayoutDispatch.Provider>
    </LayoutState.Provider>
  );
};
function useLayoutState() {
  var context = React.useContext(LayoutState);
  if (context === undefined) {
    throw new Error("useLayoutState must be used within a LayoutProvider");
  }
  return context;
}
function useLayoutDispatch() {
  var context = React.useContext(LayoutDispatch);
  if (context === undefined) {
    throw new Error("useLayoutDispatch must be used within a LayoutProvider");
  }
  return context;
}
const toggleSidebar = (dispatch, payload) => {
  dispatch({ type: "TOGGLE_SIDEBAR", payload });
};

export {
  LayoutProvider,
  useLayoutState,
  toggleSidebar,
  useLayoutDispatch,
};
