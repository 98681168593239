import React, { useEffect } from 'react'
import { GoogleMap, useJsApiLoader, Marker, MARKER_LAYER, OverlayView, MarkerClusterer, InfoWindow, Polyline } from '@react-google-maps/api';
import { colors } from "../../Components/common/constants";
import { netsuite_url } from '../../Components/config';

const containerStyle = {
    width: '100%',
    height: '360px',
    border: "1px solid lightgray"
};

const center = {
    lat: 51.48665772344187,
    lng: -0.3971688021055391
};

const zoom = 8;

export default function AllRoutesMap(props) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAgOgGQ9BhLC5Udq2-9-d2ek8UZaqWUMNE"
    })

    const [map, setMap] = React.useState(null)
    const [path, setPath] = React.useState([])
    const [routes, setRoutes] = React.useState([])
    const [paths, setPaths] = React.useState([])
    const [color, setColor] = React.useState([]);
    const [markers, setMarkers] = React.useState([])
    const [activeMarker, setActiveMarker] = React.useState(null);

    React.useEffect(() => {
        setMarkers(props.markers);
    }, [props.markers]);

    React.useEffect(() => {

        setRoutes(props.routes);

        if (props.routes.length > 0) {
            let paths = [];
            props.routes.map((route) => {

                let p = {};
                p.color = route.colorObj;
                p.route = [];
                p.markers = [];
                p.route.push(center);
                route.orders.map((mark) => {
                    let obj = { lat: Number(mark.customerLatitude), lng: Number(mark.customerLongitude) };
                    p.route.push(obj);

                    let newMarker = {
                        lat: Number(mark.customerLatitude),
                        lng: Number(mark.customerLongitude),
                        customerName: mark.customerName,
                        invoiceNo: mark.invoiceNo,
                        shipZip: mark.shipZip,
                        orderWeight: mark.orderWeight,
                        customerName: mark.customerName,
                        customerOpeningTime: mark.customerOpeningTime,
                        customerClosingTime: mark.customerClosingTime,
                        customerInternalId: mark.customerInternalId,
                        invInternalId: mark.invInternalId,
                        color: route.colorObj
                    }
                    p.markers.push(newMarker);
                });
                p.route.push(center);
                paths.push(p);
            });
            setPaths(paths);
            // setMarkers(markers);
        }
    }, [props.routes])

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);
        map.setZoom(zoom);

        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const handleMarkerClick = (marker) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    }

    const divStyle = {
        background: `white`,
        border: `1px solid #ccc`,
        padding: 15
    }

    const options = {
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: 30000,
        paths: [
            { lat: 37.772, lng: -122.214 },
            { lat: 21.291, lng: -157.821 },
            { lat: -18.142, lng: 178.431 },
            { lat: -27.467, lng: 153.027 }
        ],
        zIndex: 1
    };


    return isLoaded ? (
        <div>
            <GoogleMap
                mapContainerStyle={props.containerStyle}
                center={center}

                onLoad={onLoad}
                onUnmount={onUnmount}
            >
                <Marker key={0}
                    position={center}
                    icon={`/assets/images/theme/magna.png`}
                    onClick={() => { handleMarkerClick(-1) }}
                >
                    {activeMarker === -1 ? (
                        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                            <div>
                                <p> Magna Head Office</p>
                            </div>
                        </InfoWindow>
                    ) : null}
                </Marker>
                {markers.length > 0 && markers.map((marker, index) => {
                    return (<>
                        <Marker key={index}
                            position={{ lat: Number(marker.customerLatitude), lng: Number(marker.customerLongitude) }}
                            icon={props.color ? `/assets/images/numbers/${props.color.name}/${index + 1}.png` : ''}
                            onClick={() => { handleMarkerClick(index) }}
                        >
                            {activeMarker === index ? (
                                <InfoWindow onCloseClick={() => setActiveMarker(null)} style={{ padding: "5px" }}>
                                    <div>
                                        <p> Customer Name: <a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${marker.customerInternalId}`} target="_blank">{marker.customerName}</a></p>
                                        <p> {Number(marker.invInternalId) > 0 ? (<span>Invoice: <a href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${marker.invInternalId}`} target="_blank">{marker.invoiceNo}</a></span>) : (<span>Order: <a href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${marker.internalId}`} target="_blank">{marker.saleOrderNo}</a></span>)}</p>
                                        <p> PostCode: {marker.shipZip}</p>
                                        <p> Weight: {marker.orderWeight.toFixed(2)} KG</p>
                                        <p> Opening Hours: {marker.customerOpeningTime}</p>
                                        <p> Closing Hours: {marker.customerClosingTime}</p>
                                    </div>
                                </InfoWindow>
                            ) : null}
                        </Marker>
                    </>)
                })
                }
                {paths.length > 0 && paths.map((path, idx) => {
                    return (
                        path.markers.length > 0 && path.markers.map((marker, index) => {
                            return (<>
                                <Marker key={idx + "" + index}
                                    position={{ lat: marker.lat, lng: marker.lng }}
                                    icon={marker.color ? `/assets/images/numbers/${marker.color.name}/${index + 1}.png` : ''}
                                    onClick={() => { handleMarkerClick(idx + "" + index) }}
                                >
                                    {activeMarker === idx + "" + index ? (
                                        <InfoWindow onCloseClick={() => setActiveMarker(null)} style={{ padding: "5px" }}>
                                            <div>
                                                <p> Customer Name: <a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${marker.customerInternalId}`} target="_blank">{marker.customerName}</a></p>
                                                <p> {Number(marker.invInternalId) > 0 ? (<span>Invoice: <a href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${marker.invInternalId}`} target="_blank">{marker.invoiceNo}</a></span>) : (<span>Order: <a href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${marker.internalId}`} target="_blank">{marker.saleOrderNo}</a></span>)}</p>
                                                <p> Post Code: {marker.shipZip}</p>
                                                <p> Weight: {marker.orderWeight.toFixed(2)} KG</p>
                                                <p> Opening Hours: {marker.customerOpeningTime}</p>
                                                <p> Closing Hours: {marker.customerClosingTime}</p>
                                            </div>
                                        </InfoWindow>
                                    ) : null}
                                </Marker>
                            </>)
                        })
                    )
                })
                }
                {paths.length > 0 && paths.map((path, index) => {
                    return (
                        <>
                            <Polyline
                                key={index}
                                path={path.route}
                                geodesic={true}
                                options={{ strokeColor: path.color ? path.color.code : "#000", strokeWeight: 2 }}
                            />
                        </>)
                })
                }
            </GoogleMap>
        </div>
    ) : <></>
}