import React, { useEffect, useLayoutEffect, useState } from "react";
import { Autocomplete, Box, Grid, IconButton, Modal, Pagination, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AuthService from "../../Components/auth/AuthService";
import { useNavigate } from "react-router-dom";
import {
  hideLoading,
  showLoading,
  useLoadingDispatch,
} from "../../context/loadingContext";
import { Button, Table, DatePicker as AntDate } from "antd/es";
import { DeleteFilled, DownloadOutlined, EditFilled, PlusCircleFilled } from "@ant-design/icons";
// import DatePicker from "react-date-picker";
import moment from "moment";
import { discountStatus } from "../../Components/common/constants";
import Message from "../../Components/common/Message";
import DiscountModal from "../../Components/Modals/DiscountModal";
import config from "../../Components/config";
import dayjs from "dayjs";
const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  tblTopDiv: {},
  tblsearch: {
    display: "flex",
    float: "left",
  },
  addIcon: {
    float: "right",
  },
  dateField: {
    width: "100%",
    marginRight: "10px",
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "thin solid #b6babf",
      borderRadius: '5px',
    }
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
  antAddIcon: {
    "& .anticon-plus-circle:hover": {
      color: "#4096ff !important"
    }
  },
}));

const Auth = new AuthService();

export default function ServiceReport() {

  const classes = useStyles();
  const navigate = useNavigate();
  var layoutDispatch = useLoadingDispatch();

  const [page, setPage] = React.useState(1);
  const [pageChange, setPageChange] = React.useState(false);
  const [limit] = React.useState(50);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(null);

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [allServiceRecords, setAllServiceRecords] = React.useState([]);
  const [allVehicle, setAllVehicle] = React.useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState("");

  const currentDate = new Date();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(lastDayOfMonth);


  const fuelCol = [
    {
      title: 'Date',
      dataIndex: "service_date",
      key: "service_date",
    },
    {
      title: 'Vehicle',
      render: (record) => {
        return record?.vehicle_id
          ? allVehicle.filter((obj) => obj.id == record.vehicle_id)[0]?.registration_number
          : ""
      },
    },
    {
      title: 'Vendor',
      key: "vendor",
      dataIndex: "vendor"
    },
    {
      title: 'Service Type',
      render: (record) => {
        return record?.service_type == 1
          ? "Valet"
          : "Valet"
      },
    },
  ];

  const search = (download) => {
    let search = {};
    if (startDate) search.start_date = moment(startDate).format("YYYY-MM-DD");
    if (endDate) search.end_date = moment(endDate).format("YYYY-MM-DD");
    if (selectedVehicle) search.vehicle_id = selectedVehicle.id;
    if (page && pageChange) search.page = page;

    if (download) search.download = 1;


    // showLoading(layoutDispatch);
    setIsLoading(true)
    Auth.fetch("/report/service-records", {
      method: "POST",
      body: JSON.stringify(search),
    }).then((res) => {
    //   hideLoading(layoutDispatch);
    setIsLoading(false)
      if (res?.ack) {
        if (download) {
          window.open(res.file_url, "_blank", "noreferrer");
        } else {
          setAllServiceRecords(res?.service_entries);
          setTotalPages(Math.ceil(res.total_count / limit));
  setTotalCount(res.total_count);

          setLoadMessage(res?.message)
          setType('success')
        }
       
      } else {
        setAllServiceRecords([]);
        setLoadMessage(res?.message)
        setType('error')
      }
    });
  };

  const handleChange = (event, value) => {
    setPage(value);
    setPageChange(true)
  };

  useEffect(() => {
    const searchTimeout = setTimeout(() => search(), 1000);

    return () => {
        clearTimeout(searchTimeout);
    };
}, [startDate, endDate, selectedVehicle, page]);

const getAllVehicles = async () => {
  showLoading(layoutDispatch);
  try {
    const response = await Auth.fetch(`/vehicle/vehicles`);
    if (response) {
      hideLoading(layoutDispatch);
      if (response?.ack) {
        setAllVehicle(response.vehicles);
      }
    }
  } catch (err) {
    console.log(err);
  }
}

useLayoutEffect(()=> {
  getAllVehicles()
},[])


  const updateFilters = (newValues) => {
    const existingFiltersString = localStorage.getItem('ServiceFilters');
    const existingFilters = existingFiltersString ? JSON.parse(existingFiltersString) : {};
    Object.assign(existingFilters, newValues);
    existingFilters.timestamp = new Date().getTime();
    const updatedFiltersString = JSON.stringify(existingFilters);
    localStorage.setItem('ServiceFilters', updatedFiltersString);
  };

  const getFiltersOrDefault = () => {
    const existingFiltersString = localStorage.getItem('ServiceFilters');
    const existingFilters = existingFiltersString ? JSON.parse(existingFiltersString) : {};
    const currentTime = new Date().getTime();
    const expirationTime = 30 * 60 * 1000;
    if (existingFilters.timestamp && currentTime - existingFilters.timestamp < expirationTime) {

      if (!existingFilters.status) {
        existingFilters.status = '';
      }
      return existingFilters;
    } else {
      localStorage.removeItem('ServiceFilters');
      return {};
    }
  };

  useEffect(() => {
    const initialState = getFiltersOrDefault();
    setSelectedVehicle(initialState?.vehicle);

    if (!initialState?.startDate) {
      setStartDate(firstDayOfMonth);
      initialState.startDate = firstDayOfMonth

    } else {
      setStartDate(initialState.startDate);
    }
    if (!initialState?.endDate) {
      setEndDate(lastDayOfMonth);
      initialState.endDate = lastDayOfMonth

    } else {
      setEndDate(initialState.endDate);
    }
    initialState.timestamp = new Date().getTime();
    updateFilters(initialState)
  }, []);

  return (
    <div>
      <h2 style={{marginBottom: '10px', marginTop: 0}}>Service Reports</h2>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={8}>
        </Grid>
        <Grid item xs={4}>
        <Button
            style={{ float: "right" }}
            type="primary"
            size='large'
            icon={<DownloadOutlined />}
            onClick={() => { search(1) }}
          >
            Download
          </Button>
        </Grid>
      </Grid>
      <Grid container rowSpacing={2} columnSpacing={1.5}>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="pcnDate">Start Date:</label>
          <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={startDate ? dayjs(startDate) : null}
               onChange={(_, dateStr) => {
                setStartDate(dateStr);
                updateFilters({ startDate: dateStr })
                setPageChange(false)
                setPage(1)
              }}
              placeholder="Select Date" 
               />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="pcnDate">End Date:</label>
          <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={endDate ? dayjs(endDate) : null}
               onChange={(_, dateStr) => {
                setEndDate(dateStr);
                updateFilters({ endDate: dateStr })
                setPageChange(false)
                setPage(1)
              }}
              placeholder="Select Date" 
               />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="route">Vehicle</label>
          <Autocomplete
            id="vehicles"
            size="small"
            options={allVehicle}
            getOptionLabel={(option) => option.registration_number ? option.registration_number : ''}
            value={selectedVehicle}
            onChange={(e, values) => {
              setSelectedVehicle(values);
              setPageChange(false)
              setPage(1)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Vehicle"
              />
            )}
          />
        </Grid>
      </Grid>

      <div className={classes.antTable}>
        <Table
          columns={fuelCol}
          style={{ overflowX: "auto", marginTop: '10px' }}
          pagination={false}
          // bordered
          dataSource={allServiceRecords}
          loading={isLoading}
          // footer={() => `List of Service Records - Total Records: ${allServiceRecords?.length === 0 ? "0" : allServiceRecords?.length}`}
          footer={() => {
            const startRecord = (page - 1) * limit + 1;
            const endRecord = Math.min(page * limit, allServiceRecords.length ? totalCount : 0); 
            return `Showing ${startRecord}-${endRecord} of ${allServiceRecords.length ? totalCount : "0"} Records`;
          }}
        />
      </div>

      <div style={{ float: "right", margin: "10px" }}>
        <Pagination
          page={page}
          count={totalPages}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </div>
      <Message type={type} msg={loadMessage} />
    </div>
  );
}
