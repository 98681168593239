import { netsuite_url } from '../config';

export const data = {
  Routes: [
    // {
    //   title: "",
    //   dataIndex: "internalId",
    //   className: "custom-grid-hide-col",
    //   width: 10,
    //   sorter: (a, b) => {
    //     a = a.internalId != null ? a.internalId.toString() : "";
    //     b = b.internalId != null ? b.internalId.toString() : "";
    //     return a.localeCompare(b);
    //   },
    // },
    {
      title: "Postcode",
      dataIndex: "shipZip",
      width:100,
      sorter: (a, b) => {
        a = a.shipZip != null ? a.shipZip.toString() : "";
        b = b.shipZip != null ? b.shipZip.toString() : "";
        return a.localeCompare(b);
      },
    },
    {
      title: "Opening Time",
      dataIndex: "customerOpeningTime",
      width: 50,
      sorter: (a, b) => { return a.customerOpeningTime - b.customerOpeningTime},
      render: (text, record) => (
        <div >
          {`${text}:00`}
        </div>
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "customerProjectId",
      className:'width300',
      width:250,
      sorter: (a, b) => {
        a = a.customerProjectId != null ? a.customerProjectId.toString() : "";
        b = b.customerProjectId != null ? b.customerProjectId.toString() : "";
        return a.localeCompare(b);
      },      
    },
    {
      title: "Shop Code",
      dataIndex: "customerName",
      width:100,
      sorter: (a, b) => {
        a = a.customerName != null ? a.customerName.toString() : "";
        b = b.customerName != null ? b.customerName.toString() : "";
        return a.localeCompare(b);
      },
      render: (text, record) => (
        <a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${record.customerInternalId}`} target="_blank">
          {text}
        </a>
      ),
    },
    {
      title: "Invoice",
      dataIndex: "invoiceNo",
      width:100,
      sorter: (a, b) => {
        a = a.invoiceNo != null ? a.invoiceNo.toString() : "";
        b = b.invoiceNo != null ? b.invoiceNo.toString() : "";
        return a.localeCompare(b);
      },
      render: (text, record) => (
        <a href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${record.invInternalId}`} target="_blank">
          {text}
        </a>
      ),
    },
    {
      title: "Order",
      dataIndex: "saleOrderNo",
      width:100,
      sorter: (a, b) => {
        a = a.saleOrderNo != null ? a.saleOrderNo.toString() : "";
        b = b.saleOrderNo != null ? b.saleOrderNo.toString() : "";
        return a.localeCompare(b);
      },
      render: (text, record) => (
        <a href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${record.internalId}`} target="_blank">
          {text}
        </a>
      ),
    },
    {
      title: "Weight",
      dataIndex: "orderWeight",
      width:80,    
      fixed: "right",  
      sorter: (a, b) => { return a.orderWeight - b.orderWeight},
      render: (text, record) => (
        <div >
          {`${text.toFixed(2)} KG`}
        </div>
      ),
    },
  ],
};
