import React, { useState } from "react";

import { Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import AuthService from "../../Components/auth/AuthService";
import { InputBaseField } from "../../Components/InputFields/InputFields";
import { FormButton } from "../../Components/UiElements/UiElements";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
import { ActionDialog } from "../../Components/actionDialog/ActionDialog";
import { DeleteFilled, EditFilled, PlusCircleFilled } from "@ant-design/icons";
import { Table } from "antd/es";
import Message from "../../Components/common/Message";
import { statuses, userRoles, userTypes } from "../../Components/common/constants";

const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  tblTopDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  tblsearch: {
    display: "flex",
  },
  addIcon: {
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
}));

const Auth = new AuthService();

export default function UserList() {
  const classes = useStyles();
  const navigate = useNavigate();
  var layoutDispatch = useLoadingDispatch();

  const [users, setUsers] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(null);

  const [limit] = React.useState(50);

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");

  //State For Action Dialog
  const [actionDialogProps, setActionDialogProps] = useState({
    message: "",
    type: "",
    isOpen: false,
  });

  const userColumn = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Type',
      render: (record) => {
        return record?.type
          ? userTypes.find(obj => obj.id.toString() === record.type.toString())?.title
          : '';
      },
    },
    {
      title: 'Status',
      render: (record) => {
        return record.status != null
          ? statuses.find(obj => obj.id.toString() === record.status.toString())?.title
          : '';
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: "right",
      dataIndex: '',
      render(record) {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <EditFilled onClick={() => handleRowClick(record.id)} style={{ fontSize: '25px', cursor: "pointer", marginRight: "5px", color: "#001529" }} />
            {/* <DeleteFilled onClick={() => handleDelete(record.id)} style={{ fontSize: '25px', cursor: "pointer", color: "#cd1437" }} /> */}
          </div>
        );
      },
    },
  ];
  const ShowActionDialog = (
    message,
    type,
    isOpen,
    OnOkCallback,
    OnCancellCallback
  ) => {
    setActionDialogProps((prevState) => ({
      ...prevState,
      message: message,
      type: type,
      isOpen: isOpen,
      onOk: OnOkCallback,
      onCancel: OnCancellCallback,
    }));
  };

  React.useEffect(() => {
    showLoading(layoutDispatch);
    Auth.post(`/user/users`, {
      limit: limit,
      page: page,
      searchText: searchText,
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack) {
          setUsers(res.users);
          setTotalPages(Math.ceil(res.total_count / limit));
          setTotalCount(res.total_count);

        } else {
          setType("error");
          setLoadMessage(res.message);
        }
      })
      .catch((err) => {
        hideLoading(layoutDispatch);
        setLoadMessage("error");
      });
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleRowClick = (id) => {
    navigate(`/user/${id}`)
  };

  const Search = () => {
    showLoading(layoutDispatch);
    Auth.post(`/user/users`, {
      limit: limit,
      page: 1,
      searchText: searchText,
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack) {
          setUsers(res.users);
          setTotalPages(Math.ceil(res.total_count / limit));
          setTotalCount(res.total_count);

        } else {
          setType("error");
          setLoadMessage(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleDelete = (id) => {
    // if (window.confirm("Are you sure to want to delete this record?")) {
    ShowActionDialog(
      "Are you sure to want to delete this record?",
      "confirm",
      true,
      function () {
        console.log("Are you sure to delete this record");

        const params = {
          method: "DELETE",
          body: JSON.stringify({
            id: id,
          }),
        };
        // setLoading(true);
        showLoading(layoutDispatch);
        Auth.fetch(`/user/user`, params)
          .then((res) => {
            hideLoading(layoutDispatch);
            if (res.ack) {
              setType("info");
              setLoadMessage(res.message);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
              // setLoading(false);
            } else {
              setType("error");
              setLoadMessage(res.message);
              // setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };

  return (
    <div>
      <div className={classes.tblTopDiv}>
        <div className={classes.tblsearch}>
          <InputBaseField
            id="searchText"
            name="searchText"
            value={searchText}
            onChange={handleSearch}
            placeholder="Search"
          />
          <FormButton
            type="search"
            onClick={Search}
            sx={{ float: "right", height: "35.63px", marginLeft: "10px" }}
          >
            Search
          </FormButton>
        </div>
        <div>
          <PlusCircleFilled style={{ fontSize: "30px", color: "#006cb8" }} onClick={() => navigate("/user/new")} />
        </div>
      </div>
      <div className={classes.antTable}>
      <Table
        dataSource={users}
        style={{ overflowX: "auto" }}
        columns={userColumn}
        pagination={false}
        // footer={() => `List of Users - Total Records: ${users.length === 0 ? "0" : users.length}`}
        footer={() => {
          const startRecord = (page - 1) * limit + 1;
          const endRecord = Math.min(page * limit, users.length ? totalCount : 0); 
          return `Showing ${startRecord}-${endRecord} of ${users.length ? totalCount : "0"} Records`;
        }}
      />
      </div>
      <div style={{ float: "right", margin: "10px" }}>
        <Pagination
          page={page}
          count={totalPages}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </div>
      <ActionDialog
        message={actionDialogProps.message}
        type={actionDialogProps.type}
        isOpen={actionDialogProps.isOpen}
        onClose={actionDialogProps.onClose}
        onSubmit={() => {
          actionDialogProps.onOk();
        }}
        onCancel={() =>
          setActionDialogProps((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
      />
      <Message type={type} msg={loadMessage} />
    </div>
  );
}
