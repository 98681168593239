import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import AuthService from "../../Components/auth/AuthService";
import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  vehicleTypes,
  maintenanceStatuses,
  maintenanceIssues,
} from "../../Components/common/constants";
import { FormButton } from "../../Components/UiElements/UiElements";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
import { ActionDialog } from "../../Components/actionDialog/ActionDialog";
import { EditFilled, EditTwoTone } from "@ant-design/icons";
import { Table } from "antd";
import { useNavigate } from "react-router-dom";
import Message from "../../Components/common/Message";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  addIcon: {
    float: "right",
  },
  antTable: {
    marginTop: '10px',
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
}));

const Auth = new AuthService();

export default function MaintenanceList() {
  const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const navigate = useNavigate();
  const [maintenances, setMaintenances] = React.useState([]);
  const [allVehicles, setAllVehicles] = React.useState([]);
  const [selectedVehicle, setSelectedVehicle] = React.useState("");
  const [vehicleType, setVehicleType] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");

  //State For Action Dialog
  const [actionDialogProps, setActionDialogProps] = useState({
    message: "",
    type: "",
    isOpen: false,
  });
  const ShowActionDialog = (
    message,
    type,
    isOpen,
    OnOkCallback,
    OnCancellCallback
  ) => {
    setActionDialogProps((prevState) => ({
      ...prevState,
      message: message,
      type: type,
      isOpen: isOpen,
      onOk: OnOkCallback,
      onCancel: OnCancellCallback,
    }));
  };

  const mainColumns = [
    {
      title: "Status",
      width: 200,
      render(record) {
        return {
          props: {
            style: {background: record.background_color}
          },
          children: <span>
            {record.status != null
                    ? maintenanceStatuses.filter(
                      (obj) => obj.id == record.status
                    )[0].title
                    : "-"}
            </span>
        };
      }
    },
    {
      title: 'Registration No.	',
      dataIndex: 'registration_number',
      key: 'registration_number',
      width: 150,
    },
    {
      title: 'Total Cost',
      render: (record) => {
        return record.total_cost
        ? "£" +
        Number(record.total_cost).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })
        : ""}
      },
    {
      title: 'Vehicle Type	',
      render: (record) => {
        return record.type
        ? vehicleTypes.filter((obj) => obj.id == record.type)[0].title
        : ""
      },
    },
    {
      title: 'Issue',
      render: (record) => {
        return record.issue_type
        ? maintenanceIssues.filter((obj) => obj.id == record.issue_type)[0].title : ""
      },
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes'
    },
    {
      title: 'Last Updated',
      render: (record) => {
        return record.createdAt
          ? moment(record.createdAt).format("YYYY-MM-DD HH:mm:ss")
          : ""
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: "right",
      dataIndex: '',
      render(record) {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <EditFilled  onClick={() => handleRowClick(record.id)}  style={{ fontSize: '25px', cursor: "pointer", color: "#006cb8"}} />
          </div>
        );
      },
    },
  ];

  const handleRowClick = (id) => {
    navigate(`/maintenance/${id}`)
  };

  const Search = async () => {
    showLoading(layoutDispatch);

    let search = {};
    if (vehicleType) search.vehicle_type = vehicleType?.id;
    if (status) search.status = status?.id;
    if (selectedVehicle) search.vehicle_id = selectedVehicle?.id

    const datacheck = Object.keys(search).length
    try {
      const res = await Auth.fetch("/maintenance/maintenances", {
        method: "POST",
        ...(datacheck > 0 && { body: JSON.stringify(search) }),
      })
      if (res.ack) {
        setMaintenances(res.maintenances);
        hideLoading(layoutDispatch);
      }
    } catch (error) {
      setLoadMessage(error.message)
      setType('error')
    }
  };

  const getAllVehicles = async () => {
    showLoading(layoutDispatch);
    try {
      const response = await Auth.fetch(`/vehicle/vehicles`);
      if (response) {
        hideLoading(layoutDispatch);
        if (response.ack) {
          setAllVehicles(response.vehicles);
        }
      }
    } catch (err) {
      console.log(err);
    } finally{
      hideLoading(layoutDispatch);
    }
  }

  const handleDelete = (id) => {
    ShowActionDialog(
      "Are you sure to want to delete this record?",
      "confirm",
      true,
      function () {
        const params = {
          method: "DELETE",
          body: JSON.stringify({
            id: id,
          }),
        };
        showLoading(layoutDispatch);
        Auth.fetch(`/maintenance/maintenance`, params)
          .then((res) => {
            hideLoading(layoutDispatch);
            if (res.ack) {
              setType("info");
              setLoadMessage(res.message);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              setLoadMessage("error");
              setLoadMessage(res.status);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };

  React.useEffect(()=> {
    const timeOutId = setTimeout(() => Search(), 1000);
    return () => clearTimeout(timeOutId);
    
  }, [vehicleType, status, selectedVehicle])

  const updateFilters = (newValues) => {
    const existingFiltersString = localStorage.getItem('MaintenanceListFilters');
    const existingFilters = existingFiltersString ? JSON.parse(existingFiltersString) : {};
  
    Object.assign(existingFilters, newValues);

    existingFilters.timestamp = new Date().getTime();
  
    const updatedFiltersString = JSON.stringify(existingFilters);
  
    localStorage.setItem('MaintenanceListFilters', updatedFiltersString);
  };

  const getFiltersOrDefault = () => {
    const existingFiltersString = localStorage.getItem('MaintenanceListFilters');
    const existingFilters = existingFiltersString ? JSON.parse(existingFiltersString) : {};
    const currentTime = new Date().getTime();
    const expirationTime = 30 * 60 * 1000;
    

    if (existingFilters.timestamp && currentTime - existingFilters.timestamp < expirationTime) {
      if (!existingFilters.vehicleType) {
        existingFilters.vehicleType = '';
      }
  
      if (!existingFilters.vehicle) {
        existingFilters.vehicle = '';
      }
  
      if (!existingFilters.status) {
        existingFilters.status = '';
      }

      return existingFilters;
    } else {
      localStorage.removeItem('MaintenanceListFilters');
      return {};
    }
  };

  React.useEffect(() => {
    const initialState = getFiltersOrDefault();
    setSelectedVehicle(initialState?.vehicle);
    setStatus(initialState?.status);
    setVehicleType(initialState?.vehicleType);
    initialState.timestamp = new Date().getTime();
    updateFilters( initialState )

    getAllVehicles()

  }, []);

  return (
    <div>
            <h2 style={{marginBottom: '10px', marginTop: 0}}>Maintenance</h2>
      <Grid container rowSpacing={2} columnSpacing={1.5}>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <Autocomplete
            id="vehicles"
            size="small"
            options={allVehicles}
            getOptionLabel={(option) => option.registration_number ? option.registration_number : ''}
            value={selectedVehicle}
            onChange={(e, values) => {
              setSelectedVehicle(values);
              updateFilters({ vehicle: values })

            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Registration No"
                placeholder="Registration No"
              />
            )}
          />
          </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <Autocomplete
            id="vehicle-type"
            size="small"
            options={vehicleTypes}
            getOptionLabel={(option) => option.title ? option.title : ''}
            value={vehicleType}
            onChange={(e, values) => {
              setVehicleType(values);
              updateFilters({ vehicleType: values })
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Vehicle Type"
                placeholder="Vehicle Type"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <Autocomplete
            id="status"
            size="small"
            options={maintenanceStatuses}
            getOptionLabel={(option) => option.title ? option.title : ''}
            value={status}
            onChange={(e, values) => {
              setStatus(values);
              updateFilters({ status: values })
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Status"
                placeholder="Status"
              />
            )}
          />
        </Grid>
      </Grid>
      <div className={classes.antTable}>
      <Table
          dataSource={maintenances}
          style={{ overflowX: "auto" }}
          columns={mainColumns}
          pagination={false}
          footer={() => ` List of Maintenances - Total Records: ${maintenances.length}`}
        />
      </div> 
      <Message type={type} msg={loadMessage} />
      <ActionDialog
        message={actionDialogProps.message}
        type={actionDialogProps.type}
        isOpen={actionDialogProps.isOpen}
        onClose={actionDialogProps.onClose}
        onSubmit={() => {
          actionDialogProps.onOk();
        }}
        onCancel={() =>
          setActionDialogProps((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
      />
    </div>
  );
}
