import {Button} from '@mui/material';
import { styled } from '@mui/material/styles';


const FormFooterButton=styled(`div`)(({theme})=>({
  padding: " 0px 0 7px",
    position: "fixed",
    bottom: "0px",
    width: "82%",
    paddingLeft: "13.8%",
    display: "flex",
    zIndex: 3
}));
const SaveButton=styled(Button)(({theme})=>({
  // backgroundColor: theme.palette.primary.main,
  textTransform: 'none',
  borderColor: theme.palette.primary.main,
  color: 'white',
  background:"#1677ff",
  marginRight: 8,
  minWidth: 90,
  height: "fit-content",
  padding: "6px 16px",
  fontSize:'14px',
  fontFamily: "ArtegraSansAltRegular",
  '&:hover': {
    // backgroundColor: '#596270',
    // backgroundColor: "#204265",
    background:"#4096ff",

    // opacity: 0.65,
    borderColor: theme.palette.primary.blue,
    color: 'white',
  },
  "&:disabled": {
    backgroundColor: '#596270',
    borderColor: theme.palette.primary.blue,
    color: 'white',
  },
  // '& .MuiButton-startIcon svg': {
  //   marginLeft: "-10px",
  // }

}));
const ResetButton=styled(Button)(({theme})=>({
  textTransform: 'none',
    backgroundColor: '#DDDDDD',
    borderColor: '#F3F3F3',
    // background:"#343a40",
    color: theme.palette.common.lightBlack,
    marginRight: 8,
    minWidth: 90,
    padding: "6px 16px",
    fontSize:'14px',
    height: "fit-content",
  fontFamily: "ArtegraSansAltRegular",
    "& img":{
      width:"16px",
      height:"16px"
    },
    '&:hover': {
      backgroundColor: "#007bff",
      borderColor: theme.palette.primary.blue,
      color: 'white',
      "& img": {
        filter: 'brightness(9)',
      }
    },
    "&:disabled": {
      backgroundColor: '#DDDDDD',
      borderColor: '#F3F3F3',
      color: theme.palette.primary.blue,
    }
}));
const UpdatedButton=styled(Button)(({theme})=>({
  textTransform: 'none',
  backgroundColor: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  color: 'white',
  marginRight: 8,
  minWidth: 90,
    height: "fit-content",
    fontSize:'14px',
  fontFamily: "ArtegraSansAltRegular",
  '&:hover': {
    // backgroundColor: '#596270',
    backgroundColor: theme.palette.primary.blue,
    borderColor: theme.palette.primary.blue,
    color: 'white',
  },
  "&:disabled": {
    backgroundColor: '#596270',    
    borderColor: theme.palette.primary.blue,
    color: 'white',
  }
}));
const DeleteButton=styled(Button)(({theme})=>({
  textTransform: 'none',
    backgroundColor: '#dd3232',
    borderColor: '#dd3232',
    color: 'white',
    marginRight: 8,
    minWidth: 90,
    height: "fit-content",
    fontSize:'14px',
  fontFamily: "ArtegraSansAltRegular",
    '&:hover': {
      backgroundColor: '#596270',
      borderColor: theme.palette.primary.blue,
      color: 'white',
    },
    "&:disabled": {
      backgroundColor: '#596270',
      borderColor: theme.palette.primary.blue,
      color: 'white',
    },
    '& .MuiButton-startIcon img': {
      width: 20,
      marginBottom: "-2px",
      marginLeft: "8px",
    }
}));
const PrintButton=styled(Button)(({theme})=>({
  textTransform: 'none',
  background:"#006cb8",
  borderColor: theme.palette.primary.main,
    color: 'white',
    marginRight: 8,
    minWidth: 90,
    height: "fit-content",
    fontSize:'14px',
  fontFamily: "ArtegraSansAltRegular",
    '&:hover': {
      // backgroundColor: '#596270',
      backgroundColor: "#204265",
      borderColor: theme.palette.primary.blue,
      color: 'white',
    },
    "&:disabled": {
      backgroundColor: '#596270',
      borderColor: theme.palette.primary.blue,
      color: 'white',
    },
    '& .MuiButton-startIcon img': {
      width: 18,
      marginBottom: "-2px",
      marginLeft: "8px",
      filter: "brightness(9)",
    }

}));
const ErrorMessageArea=styled(Button)(({theme})=>({
  color: "red",
  margin: "-8px 0px 0px 5px",
  position: "relative",
  minHeight: "15px",
  "& span": {
    position: "absolute",
    top: "-5px",
    whiteSpace: "nowrap"
  }
}));
const StyledErrorMessage=styled(Button)(({theme})=>({
  color: "red",
  margin: "-1.5px 0px 0px 5px",
  position: "relative",
  minHeight: "15px",
  "& span": {
    position: "absolute",
    top: "-5px",
    whiteSpace: "nowrap"
  }
}));
export {FormFooterButton,SaveButton,ResetButton,DeleteButton,UpdatedButton,PrintButton,ErrorMessageArea,StyledErrorMessage}
