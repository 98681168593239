import {
  Fade,
  Modal,
  Grid,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Slide from "@mui/material/Slide";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import CloseIcon from "@mui/icons-material/Close";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../../context/loadingContext";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundcolor: "red",
    },
  },
  img: {
    outline: "none",
  },
}));
const PreviewModal = ({
  open,
  handleClose,
  side,
  imageData,
  VehicleRestoration,
}) => {
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [screenWidth, setScreenWidth] = useState("");
  const [previewData, setPreviewData] = useState(null);
  const { date1ImagePath, date2ImagePath } = imageData;
  const [loadingState, setLoading] = useState({
    loadingImage1: true,
    loadingImage2: true,
  });

  var layoutDispatch = useLoadingDispatch();
  const classes = useStyles();
  useEffect(() => {
    setScreenWidth(window.innerWidth);
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // inner modal
  const handleCloseModal = () => {
    setOpenPreviewModal(false);
  };
  useEffect(() => {
    if (loadingState.loadingImage1 || loadingState.loadingImage2) {
      showLoading(layoutDispatch);
    } else {
      hideLoading(layoutDispatch);
    }
  }, [loadingState.loadingImage1, loadingState.loadingImage2]);
  return (
    <>
      {/* //===============inner modal============== */}
      <Modal
        className={classes.modal}
        open={openPreviewModal}
        onClose={handleCloseModal}
      >
        <Fade in={openPreviewModal} timeout={500} className={classes.img}>
          {previewData !== null && (
            <img
              src={previewData}
              onLoad={() => hideLoading(layoutDispatch)}
              alt="asd"
              style={{
                maxHeight: "90%",
                maxWidth: "90%",
                borderRadius: "10px",
              }}
            />
          )}
        </Fade>
      </Modal>
      <Dialog
        fullScreen
        open={open}
        onClose={!openPreviewModal && handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {
              <Typography sx={{ ml: 2, flex: 2 }} variant="h6" component="div">
                Compare Vehicle Imagess
              </Typography>
            }
            <Typography
              sx={{ ml: 2, flex: screenWidth > 650 ? 2 : 1 }}
              variant="h6"
              component="div"
            >
              {VehicleRestoration}{" "}
              {side && (
                <>
                  {"("}
                  {side === "f"
                    ? "Front "
                    : side === "r"
                    ? "Right"
                    : side === "l"
                    ? "Left"
                    : side === "t"
                    ? "Tail"
                    : ""}
                  {")"}
                </>
              )}
            </Typography>
            {screenWidth > 650 && (
              <Button autoFocus color="inherit" onClick={handleClose}>
                Close
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <List sx={{}}>
          <Grid
            container
            spacing={2}
            sx={{ paddingY: "30px", paddingX: "30px" }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                textAlign: "center",
                height: "80vh",
              }}
            >
              {date1ImagePath !== null && (
                <img
                  src={date1ImagePath?.imagePath}
                  onClick={() => {
                    setPreviewData(date1ImagePath?.imagePath);
                    showLoading(layoutDispatch);
                    setOpenPreviewModal(true);
                  }}
                  onLoad={() =>
                    setLoading((state) => ({
                      ...state,
                      loadingImage1: false,
                    }))
                  }
                  alt="asd"
                  style={{
                    height: "100%",
                    width: "100%",
                    cursor: "pointer",
                    objectFit: "fill",
                    borderRadius: "10px",
                  }}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                textAlign: "center",
                height: "80vh",
              }}
            >
              {date2ImagePath !== null && (
                <img
                  src={date2ImagePath?.imagePath}
                  alt="asd"
                  onLoad={() =>
                    setLoading((state) => ({
                      ...state,
                      loadingImage2: false,
                    }))
                  }
                  style={{
                    height: "100%",
                    width: "100%",
                    cursor: "pointer",
                    objectFit: "fill",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    showLoading(layoutDispatch);
                    setPreviewData(date2ImagePath?.imagePath);
                    setOpenPreviewModal(true);
                  }}
                />
              )}
            </Grid>
          </Grid>
        </List>
      </Dialog>
    </>
  );
};

export default PreviewModal;
