import React, { useEffect, useState } from "react";
import { Card, Col, Divider, Row, Statistic, Table, Collapse, Button, Input, Checkbox, Select, DatePicker, message, Tooltip, Image } from "antd";
import "./reports.css";
import { makeStyles } from "@mui/styles";
import { CheckOutlined, CloseOutlined, CloudDownloadOutlined, ExpandAltOutlined } from "@ant-design/icons";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import { alignProperty } from "@mui/material/styles/cssUtils";
import { StockChangeReasons, userRolesTypes } from "../../Components/common/constants";
import { env, netsuite_url, tms_uploads_cdn } from "../../Components/config";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
import moment from "moment";
import { ActionDialog } from "../../Components/actionDialog/ActionDialog";



const colors = ["#f0f0f0", "#e6f7ff", "#faf0e6", "#fcf8e3"];
const Auth = new AuthService();


const useStyles = makeStyles((theme) => ({
  dataSheet: {
    "& .ant-card .ant-card-body": {
      padding: "4px",
      // backgroundColor: 'rgb(240, 240, 240)',
    },
    "& .ant-table-thead>tr>th": {
      padding: "0px 4px !important"
    },
    "& .ant-statistic .ant-statistic-content": {
      fontSize: "12px",
      fontWeight: "bold",
    },
    "& .ant-statistic .ant-statistic-title": {
      fontSize: "12px",
    },
  },
}));

const StockDiscrepanciesReport = ({ stockDiscrepanciesData, setStockDiscrepanciesData, deliveryQuantities1 }) => {
  const classes = useStyles();
  const { TextArea } = Input
  var layoutDispatch = useLoadingDispatch();

  console.log('stockDiscrepanciesData', stockDiscrepanciesData);
  

  const UserType = useSelector((state) => state.AppState?.userTypes?.type);
  const { roles } = useSelector((state) => state.AppState?.userTypes);

  const matchedRoles = roles
    .map((role) => {
      return userRolesTypes.find((userRole) => userRole.id === role.role_id);
    })
    .filter(Boolean);
  const includesFinance = matchedRoles.some((item) => item.title === "Finance");
  const includesWareHouseManager = matchedRoles.some((item) => item.title === "Warehouse Manager");
  const [selectedDates, setSelectedDates] = useState({});

  useEffect(() => {
    const initialDates = stockDiscrepanciesData.reduce((acc, data, index) => {
      acc[index] = data?.postingDateInitial
        ? dayjs(data.postingDateInitial)
        : null;
      return acc;
    }, {});

    setSelectedDates(initialDates);
  }, [stockDiscrepanciesData]);

  const handleDateChange = (date, index) => {
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [index]: date,
    }));
  };

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [isLoading, setIsLoading] = React.useState("");
  const [deliveryQuantities, setDeliveryQuantities] = React.useState({});
  // const [disabledInputs, setDisabledInputs] = React.useState({});
  // const [tableCheckbox, setTableCheckbox] = React.useState({});

  const [disabledRoutes, setDisabledRoutes] = React.useState([]);

  
  const [returnedStockCheckbox, setReturnedStockCheckbox] = React.useState({});
  const [returnedStockDisableInputs, setReturnedStockDisableInputs] = React.useState({});

  const [actionDialogProps, setActionDialogProps] = useState({
    message: "",
    type: "",
    isOpen: false,
  });

  const ShowActionDialog = (
    message,
    type,
    isOpen,
    OnOkCallback,
    OnCancellCallback
  ) => {
    setActionDialogProps((prevState) => ({
      ...prevState,
      message: message,
      type: type,
      isOpen: isOpen,
      onOk: OnOkCallback,
      onCancel: OnCancellCallback,
    }));
  };


  useEffect(() => {
    if (stockDiscrepanciesData.length > 0) {
        const initialDeliveryQuantities = {};
        
        stockDiscrepanciesData.forEach((discrepancy) => {
            discrepancy.tableData.forEach((item) => {
                initialDeliveryQuantities[item.itemPalletId] = {
                    item_pallet_id: item.itemPalletId,
                    returned_quantity_to_stock: item.returnedQuantityToStock || 0,
                    returned_quantity_to_stock_status: item.returnedQuantityToStockStatus,
                };

                if (item.returnedQuantityToStockStatus === item.returnedQuantity && item.returnedQuantityToStockStatus > 0 && item.returnedQuantity > 0) {
                    setReturnedStockDisableInputs((prevState) => ({
                        ...prevState,
                        [item.itemPalletId]: true,
                    }));
                    setReturnedStockCheckbox((prevState) => ({
                        ...prevState,
                        [item.itemPalletId]: true,
                    }));
                }
            });
        });
        
        setDeliveryQuantities(initialDeliveryQuantities);
    }
}, [stockDiscrepanciesData]);


useEffect(() => {
  localStorage.setItem("disabledStockRoutes", JSON.stringify(disabledRoutes));
}, [disabledRoutes]);

useEffect(() => {
  // Retrieve previously stored disabledRoutes from localStorage
  const storedDisabledRoutes = JSON.parse(localStorage.getItem("disabledStockRoutes")) || [];

  const newDisabledRoutes = stockDiscrepanciesData
    .filter(stock => stock.credit_notes_posted_by > 0)
    .map(stock => stock.routeStatusID);

  // Merge stored disabledRoutes with new ones, avoiding duplicates
  const updatedDisabledRoutes = [...new Set([...storedDisabledRoutes, ...newDisabledRoutes])];

  // Update the state and localStorage
  setDisabledRoutes(updatedDisabledRoutes);
  localStorage.setItem("disabledStockRoutes", JSON.stringify(updatedDisabledRoutes));
}, [stockDiscrepanciesData]);

useEffect(() => {
  if (stockDiscrepanciesData) {
    const updatedCheckboxState = stockDiscrepanciesData.reduce((acc, dataItem) => {
      dataItem.tableData.forEach((item) => {
        if (item.returnedQuantity === item.returnedQuantityToStock && item.returnedQuantityToStockStatus > 0 && item.returnedQuantity > 0) {
          acc[item.itemPalletId] = true;
        }
      });
      return acc;
    }, {});

    setReturnedStockCheckbox((prevState) => ({
      ...prevState,
      ...updatedCheckboxState,
    }));
    setReturnedStockDisableInputs((prevState) => ({
      ...prevState,
      ...updatedCheckboxState,
    }));
  }
}, [stockDiscrepanciesData]);



  const { Panel } = Collapse;
  const { Option } = Select;

  const readOnly = localStorage.getItem("read_only") === "true";
  const generateColor = (index) => {
    const colors = ["#f0f0f0"];
    return colors[0];
  };

  const handleReturnToStockQuantityChange = (value, record, commentId) => {
    const isFullQuantity = value === record.returnedQuantity;
    if (value === "full") {
      value = record.returnedQuantity;
      commentId = 1;
    }
    if (value === "notFull") {
      value = 0;
      commentId = null;
    }
    const updatedDeliveryQuantities = {
      ...deliveryQuantities,
      [record.itemPalletId]: {
        ...deliveryQuantities[record.itemPalletId],
        returned_quantity_to_stock: value,
        returned_quantity_to_stock_status: commentId,
      },
    };
    if (isFullQuantity) {
      setReturnedStockDisableInputs((prevState) => ({
        ...prevState,
        [record.itemPalletId]: true,
      }));
      setReturnedStockCheckbox((prevState) => ({
        ...prevState,
        [record.itemPalletId]: true,
      }));
    }
    setDeliveryQuantities(updatedDeliveryQuantities);
  };

  const handleReturndStockCheckboxChange = (isChecked, rank) => {
    setReturnedStockDisableInputs((prevState) => ({
      ...prevState,
      [rank]: isChecked,
    }));

    setReturnedStockCheckbox((prevState) => ({
      ...prevState,
      [rank]: isChecked,
    }));
  };

  const handleSwitchToggle = async (itemPalletID, returnedQuantity, checked) => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
    const dataToSend = {
      pallet_item_id: itemPalletID,
      returned_quantity: returnedQuantity,
      returned_quantity_approved_rec: checked ? 1 : 0
    }
    try {
      const response = await Auth.fetch("/route/change-quantity-received-status", {
        method: "POST",
        body: JSON.stringify(dataToSend)
      });
      if (response.ack) {
        setType('success')
        setLoadMessage(response.message + str)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const dataCol = [
    {
      title: "S.No.",
      dataIndex: "rank",
      key: "rank",
      width: 55
    },
    {
      title: "Shop Code",
      // dataIndex: "customerName",
      // key: "customerName",
      render: (text,record) => {
        return <span><a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${record.customerInternalId}`} target="_blank">{record.customerName}</a></span>;
      },
      width: 100
    },
    {
      title: "Shop Name",
      dataIndex: "customerProjectId",
      key: "customerProjectId",
      width: 200
    },
    // {
    //   title: "Invoice",
    //   // dataIndex: "invoiceNo",
    //   render: (text, record) => {
    //     return <span><a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${record.invoiceInternalId}`} target="_blank">{record.invoiceNo}</a></span>;
    //   },
    //   width: 100
    // },
    {
      title: 'Order No.',
      render: (text, record) => {
          return (
              <span>
                  {record.invoice_no != null && record?.invoice_no != "" ? (
                      <a 
                          href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${record.invoiceInternalId}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                      >
                          {record.invoice_no}
                      </a>
                  ) : (
                      <a 
                          href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${record.internalId}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                      >
                          {record.saleOrderNo}
                      </a>
                  )}
              </span>
          );
      },
      width: 100
    },
    {
      title: "Item Code",
      dataIndex: "itemId",
      key: "itemId",
      width: 100
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      width: 200
    },
    {
      title: "Ordered QTY", 
      dataIndex: "quantity",
      key: "quantity",
      width: 100
    },
    {
      title: "Returned QTY", 
      dataIndex: "orgReturnedQuantity",
      key: "orgReturnedQuantity",
      width: 100,
      align: 'center'
    },
    {
      title: "Reason",
      dataIndex: "status",
      key: "status",
      width: 150

    },
    {
      title: "Warehouse Received QTY (Quarantine)",
      dataIndex: "returnedQuantity",
      render: (text, record) => (
       
          <span 
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: record.returnedQuantity != record.orgReturnedQuantity ? '#ff0000' : '',
            fontSize: '16px',
            fontWeight: "bold"
          }}
        >{record.returnedQuantity || 0}</span>
          
      ),
      width: 120
    },
    {
      title: "Returned To Stock QTY",
      dataIndex: "returned_quantity_to_stock",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "center",
          }}
        >
          <Input
            type="number"
            style={{ width: "75px", marginRight: "5px" }}
            value={
              deliveryQuantities[record.itemPalletId]?.returned_quantity_to_stock || 0
            }
            disabled={returnedStockDisableInputs[record.itemPalletId]}
            onChange={(e) => {
              const newValue = Math.max(0, e.target.value);
              handleReturnToStockQuantityChange(
                newValue,
                record,
                deliveryQuantities[record.itemPalletId]?.returned_quantity_to_stock_status
              );
            }}
          />
          <Checkbox
            checked={returnedStockCheckbox[record.itemPalletId]}
            onChange={(e) => {
              const isChecked = e.target.checked;
              handleReturndStockCheckboxChange(isChecked, record.itemPalletId);
              handleReturnToStockQuantityChange(
                isChecked ? "full" : "notFull",
                record,
                deliveryQuantities[record.itemPalletId]?.returned_quantity_to_stock_status
              );
            }}
            disabled={readOnly}
          >
            Full
          </Checkbox>
        </div>
      ),
      width: 140,
      align: 'center'

    },
    {
      title: "Stock Difference",
      render: (record) => {
        const returnedQuantityToStock = deliveryQuantities[record.itemPalletId]?.returned_quantity_to_stock || 0;
        const difference =  record.orgReturnedQuantity - returnedQuantityToStock;
        return <span>{difference}</span>;
      },
      width: 100,
      align: 'center'
    },
    {
      title: "Stock Difference Status",
      width: 150,
      render: (record) => {
        const stockStatus =
          deliveryQuantities[record.itemPalletId]?.returned_quantity_to_stock_status;
        return (
          <Select
            style={{
              width: "100%",
            }}
            value={stockStatus}
            placeholder="Select Status"
            onChange={(value) =>
              handleReturnToStockQuantityChange(
                deliveryQuantities[record.itemPalletId]?.returned_quantity_to_stock,
                record,
                value
              )
            }
            disabled={readOnly}
          >
            {StockChangeReasons.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.title}
              </Option>
            ))}
          </Select>
        );
      },
    },
  ];

  const [expandAll, setExpandAll] = useState(false);

  const onClickExpand = () => {
    let stockDiscrepanciesData1 = [...stockDiscrepanciesData];

    if (expandAll) {
      stockDiscrepanciesData1.map((route, rIdx) => {
            route.expanded = false;
        })
    }
    else {
      stockDiscrepanciesData1.map((route, rIdx) => {
            route.expanded = true;
        });
    }
    setStockDiscrepanciesData(stockDiscrepanciesData1);
    setExpandAll(!expandAll);
};

const handleRouteExpand = (route_idx) => {
  const updatedData = [...stockDiscrepanciesData];

  updatedData[route_idx].expanded = !updatedData[route_idx].expanded;

  setStockDiscrepanciesData(updatedData);
}

useEffect(() => {
  if (stockDiscrepanciesData.length === 1) {
    setStockDiscrepanciesData(prevData => {
      return prevData.map(item => ({
        ...item,
        expanded: true
      }));
    });
  }
}, []);

const saveInfo = async (index) => {
  setIsLoading(true)
  const discrepancyData = stockDiscrepanciesData[index];

  // const filteredQuantities = discrepancyData.tableData.reduce((result, item) => {
  //   if (deliveryQuantities[item.itemPalletId] !== undefined) {
  //     result[item.itemPalletId] = deliveryQuantities[item.itemPalletId];
  //   }
  //   return result;
  // }, {});

  const filteredQuantities = discrepancyData.tableData.reduce((result, item) => {
    // Check if the quantity exists for the itemPalletId
    if (deliveryQuantities[item.itemPalletId] !== undefined) {
      // Push only the quantity into the result array
      result.push(deliveryQuantities[item.itemPalletId]);
    }
    return result;
  }, []); 
  
  const dataToSend = {
    discrepancies: filteredQuantities,
    route_status_id : discrepancyData?.routeStatusID
  };
  // const data = Object.values(deliveryQuantities)
  // const dataToSend = {
  //   discrepancies: data
  // }
  let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
  try {
    const response = await Auth.fetch(
      "/route/update-stock-discrepancies",
      {
        method: "POST",
        body: JSON.stringify(dataToSend),
      }
    );
    if (response.ack) {
      setType("success");
      setLoadMessage(response.message + str);
      // setIsLoading(false)
    } else {
      setType("error");
      setLoadMessage(response.message + str);
    }
  } catch (err) {
    console.log(err)
  } finally {
    setIsLoading(false)
  }
}

const postDiscrepenciesNetsuite = async (routeStatusID, index) => {
  let rand = Math.random() * 50;
  let str = " ";
  for (let i = 0; i < rand; i++) {
    str += " ";
  }
  const discrepancyData = stockDiscrepanciesData[index];
  const filteredQuantities = discrepancyData.tableData.reduce((result, item) => {
    // Check if the quantity exists for the itemPalletId
    if (deliveryQuantities[item.itemPalletId] !== undefined) {
      // Push only the quantity into the result array
      result.push(deliveryQuantities[item.itemPalletId]);
    }
    return result;
  }, []); 

  const updateData = {
    discrepancies: filteredQuantities,
    route_status_id : discrepancyData?.routeStatusID
  };

  const selectedDate = selectedDates[index];
  const postData = {
    route_status_id: routeStatusID,
    credit_notes_posting_date_ns: moment(new Date(selectedDate)).format("DD/MM/YYYY"),
    credit_notes_posting_date: moment(new Date(selectedDate)).format("YYYY-MM-DD"),
  };

  ShowActionDialog(
    "Are you sure to want to Post discrepencies?",
    "confirm",
    true,
    async function () {
    showLoading(layoutDispatch);

    try {
      const response = await Auth.fetch(
        "/route/update-stock-discrepancies",
        {
          method: "POST",
          body: JSON.stringify(updateData),
        }
      );
      if (response.ack) {
        setType("success");
        setLoadMessage(response.message + str);
        try {
          const response = await Auth.fetch("/route/update-cn-to-netsuite", {
            method: "POST",
            body: JSON.stringify(postData),
          });
          if (response.ack) {
            setType("success");
            setLoadMessage(response.message + str);
            const updatedDisabledRoutes = [...disabledRoutes, routeStatusID];
            setDisabledRoutes(updatedDisabledRoutes);
            localStorage.setItem(
              "disabledStockRoutes",
              JSON.stringify(updatedDisabledRoutes)
            );
          } else {
            setType("error");
            setLoadMessage(response.message + str);
          }
        } catch (err) {
          console.log(err);
          message.error("Something went wrong, try again!");
        } finally {
          hideLoading(layoutDispatch)
        }
      } else {
        setType("error");
        setLoadMessage(response.message + str);
      }
    } catch (err) {
      console.log(err)
    } finally {
      hideLoading(layoutDispatch)
    }
    
    }
  );
    
};

const downloadCSVFile = (id) => {
  const dataObject = stockDiscrepanciesData.find((item, idx) => idx === id);

  // Extract formatted_date and driver fields
  const formattedDate = dataObject.formatted_date.replace(/ /g, '-'); // Replace spaces with dashes

  // Define the filename
  const filename = `${formattedDate}_${dataObject.driverRoute}_StockDiscrepancy.csv`;

  // Define fields to exclude
  const excludedFields = ['expanded', 'formatted_date', 'routeStatusID'];

  // Define a mapping object to rename tableData headers
  const tableDataHeaderMapping = {
    rank: 'S No',
    customerName: 'Shop Code',
    customerProjectId: 'Shop Name',
    itemId: 'Item Code',
    itemName: 'Item Name',
    invoiceNo: 'Invoice No',
    quantity: 'Ordered Quantity',
    orgReturnedQuantity: 'Returned Quantity',
    returnedQuantity: 'Warehouse Received QTY (Quarantine)',
    returnedQuantityToStock: "Returned To Stock QTY",
    status: 'Reason',
    returnedQuantityToStockStatus: "Stock Difference Status" ,
    stockDiff : "Stock Difference",
  };

  // Define fields to exclude from tableData
  const excludedTableDataFields = [
    'invoiceInternalId',
    'palletOrderID',
    'customerInternalId',
    'itemPalletId',
    'returnedQuantityApprovedRec',
    'returnedQuantityApprovedSup',
    
  ];

  // Define the sequence of simple fields
  const simpleFieldsSequence = ['route', 'driver', 'truck', 'driverRoute'];

  // Define the sequence of table fields
  const tableFieldsSequence = ['rank', 'customerName', 'invoiceNo', 'itemId', 'itemName',  'quantity', 'orgReturnedQuantity', 'status', 'returnedQuantity', 'returnedQuantityToStock', 'stockDiff', 'returnedQuantityToStockStatus'];

  // Format simple fields data into CSV string
  let csvData = '';

  simpleFieldsSequence.forEach(field => {
    if (excludedFields.includes(field)) return;

    let fieldName = field;
    if (field === 'route') fieldName = 'Route No';
    if (field === 'driver') fieldName = 'Driver';
    if (field === 'truck') fieldName = 'Truck Reg.';
    if (field === 'driverRoute') fieldName = 'Route';

    csvData += `${fieldName},${dataObject[field]}\n`;
  });

  // Process tableData if it exists
  const tableDataField = 'tableData';
  if (dataObject[tableDataField]) {
    // Map headers to their renamed counterparts and exclude unwanted fields
    const renamedHeaders = tableFieldsSequence
      .filter(header => !excludedTableDataFields.includes(header))
      .map(header => tableDataHeaderMapping[header] || header);
    
    // Add tableData headers to CSV
    csvData += `${renamedHeaders.join(',')}\n`;

    // Loop through each row in tableData and add to CSV
    dataObject[tableDataField].forEach(row => {
      const rowData = tableFieldsSequence
        .filter(key => !excludedTableDataFields.includes(key))
        .map(key => {
          if (key === 'returnedQuantityToStockStatus') {
              return row[key]  ? StockChangeReasons.filter((obj) => obj.id == row[key])[0].title  : '';
          }
          if(key === 'stockDiff') {
            return row['orgReturnedQuantity'] - row['returnedQuantityToStock']
          }
          return row[key];
      });
      csvData += `${rowData.join(',')}\n`;
    });
  }

  // Create a Blob object from the CSV data
  const blob = new Blob([csvData], { type: 'text/csv' });

  // Create a temporary URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element to trigger the download
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);

  // Append the link to the body and click it to trigger the download
  document.body.appendChild(link);
  link.click();

  // Clean up: remove the link and revoke the URL
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

const genExtra = (idx, data) => {
    
  const posted = data?.stock_discrepancies_updated_by > 0 ? true : false;
  const postedNS = data?.credit_notes_posted_by > 0 ? true : false;
  const completed = data?.start_status === 2 ? true : false;

  return (
    <div style={{ display: 'flex', alignItems: 'center'}}>
      <Button
            style={{
              marginRight: '5px',
              color: completed  ? 'green' : 'red',
              cursor: 'alias',
              pointerEvents: "none",
              padding: '4px 4px'
            }}
            icon={completed ? <CheckOutlined/> : <CloseOutlined />}
              >
                Route Completed
          </Button>
      {
          <Button
            style={{
              marginRight: '5px',
              color: posted ? 'green' : 'red',
              cursor: 'alias',
              pointerEvents: "none",
              padding: '4px 4px'
            }}
            icon={posted ? <CheckOutlined/> : <CloseOutlined />}
              >
                Discrepancy Updated
          </Button>
        
      }
      {/* {
          <Button
            style={{
              marginRight: '5px',
              color: postedNS || disabledRoutes.includes(data.routeStatusID) ? 'green' : 'red',
              cursor: 'alias',
              pointerEvents: "none",
              padding: '4px 4px'
            }}
            icon={postedNS || disabledRoutes.includes(data.routeStatusID) ? <CheckOutlined/> : <CloseOutlined />}
              >
                Credit Note Posted
          </Button>
        
      } */}
      {/* <Button
    type="primary"
    style={{ padding: '4px 4px'}}
    onClick={(e) => {
      e.stopPropagation();
      downloadCSVFile(idx);
    }}
  >
    Download
  </Button> */}
  <Tooltip title="Download">
    <CloudDownloadOutlined
    style={{ fontSize: '25px', color: '#1677FF'}}
    onClick={(e) => {
      e.stopPropagation();
      downloadCSVFile(idx);
    }}
     />
     </Tooltip>
  </div>
  )
};


// const genExtra = (id, data) => (
//   <Button
//     type="primary"
//     size="large"
//     onClick={(e) => {
//       e.stopPropagation()
//       downloadCSVFile(id)
//     }
//     } 
//   >
//     Download
//   </Button>
// );

useEffect(() => {
  message.config({
    top: window.innerHeight - 100,
    duration: 3,
  });
}, []);


  return (
    <div className={classes.dataSheet}>
      <div style={{display: "flex", alignItems: 'center', justifyContent: 'space-between'}}>
      <h3>Stock Discrepancies</h3>
      <Button 
      type="primary" 
      style={{ float: 'right'}} 
      onClick={onClickExpand}
      icon={<ExpandAltOutlined />}>
          {expandAll ? "Collapse All" : "Expand All"}
        </Button>
      </div>
      
      {stockDiscrepanciesData?.length > 0 &&
        stockDiscrepanciesData?.sort((a, b) => a.driverRoute.localeCompare(b.driverRoute)).map((data, index) => (
          <>
            <div className="view-page">              
              <Collapse style={{margin: '10px 0px'}} activeKey={data.expanded ? index.toString() : ''} onChange={() => { handleRouteExpand(index)}}>
              <Panel header={
              <>
              <span style={{ fontWeight: 'bold'}}>Route: </span>
              <span style={{ marginRight: '10px'}}>{data.driverRoute}</span>
              <span style={{ fontWeight: 'bold'}}>Driver: </span>
              <span style={{ marginRight: '10px'}}>{data.driver}</span>
              <span style={{ fontWeight: 'bold'}}>Vehicle Reg: </span>
              <span >{data.truck}</span>
              </>
              } extra={genExtra(index, data)} key={index}>
              <Row gutter={[16, 16]}>
                <Col span={4}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Statistic
                        title="Route No"
                        value={data.route}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={4}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Statistic
                        title="Truck"
                        value={data.truck}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Statistic
                        title="Route"
                        value={data.driverRoute}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Statistic
                        title="Driver"
                        value={data.driver}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
              <Collapse style={{ margin: "10px 0px" }}>
                    <Panel
                      header={
                        <>
                          <span style={{ fontWeight: "bold" }}>
                            Driver Return Files & Notes
                          </span>
                        </>
                      }
                      key={index}
                    >
                      <TextArea autoSize 
                    name="notes"
                    value={data.driver_returns_notes}
                    // onChange={(e) => handleInputChange(index, e)}
                    placeholder="Notes"
                  />
                      <Row
                        gutter={[6, 16]}
                        style={{
                          marginTop: "10px",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                      </Row>
                      {data.driver_returns_files?.length > 0 && <h4>Driver Return Files:</h4>}
                      <br />
                      <Image.PreviewGroup>
                        {data?.driver_returns_files?.length > 0 &&
                          data?.driver_returns_files
                            .map((item) => {
                              const imageUrl = `${tms_uploads_cdn}/${
                                env
                              }/driver_returns/${data.routeStatusID}/${item}`;
                              return (
                                <div
                                  style={{
                                    position: "relative",
                                    marginRight: "10px",
                                    display: "inline-block",
                                  }}
                                >
                                  <Image
                                    width={200}
                                    height={150}
                                    style={{
                                      padding: "5px",
                                      border: "1px solid lightgrey",
                                    }}
                                    src={imageUrl}
                                  />
                                </div>
                              );
                            })}
                      </Image.PreviewGroup>
                    </Panel>
                  </Collapse>
                  <Collapse style={{ margin: "10px 0px" }}>
                    <Panel
                      header={
                        <>
                          <span style={{ fontWeight: "bold" }}>
                            Warehouse Files & Notes
                          </span>
                        </>
                      }
                      key={index}
                    >
                       <>
                  <TextArea autoSize 
                    name="notes"
                    value={data.notes}
                    placeholder="Notes"
                  />
                      <Row
                        gutter={[6, 16]}
                        style={{
                          marginTop: "10px",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                      
                      </Row>
                      {data.files?.length > 0 && <h4>Current Files:</h4>}
                      <br />
                      <Image.PreviewGroup>
                        {data?.files?.length > 0 &&
                          data?.files
                            .map((item) => {
                              const imageUrl = `${tms_uploads_cdn}/${
                                env
                              }/goods_return/${data.routeStatusID}/${item}`;
                              return (
                                <div
                                  style={{
                                    position: "relative",
                                    marginRight: "10px",
                                    display: "inline-block",
                                  }}
                                >
                                  <Image
                                    width={200}
                                    height={150}
                                    style={{
                                      padding: "5px",
                                      border: "1px solid lightgrey",
                                    }}
                                    src={imageUrl}
                                  />
                                  
                                </div>
                              );
                            })}
                      </Image.PreviewGroup>
                      
                  </>
                    </Panel>
                  </Collapse>
              <Row
                    gutter={[6, 16]}
                    style={{
                      marginTop: "10px",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    {!disabledRoutes.includes(data.routeStatusID) && (
                      <>
                        {(UserType == 1 || includesFinance || includesWareHouseManager) && (
                          <>
                        <Button
                         type="primary"
                          // size="large"
                          disabled={
                            data.stock_discrepancies_posted == 1 ||
                            disabledRoutes.includes(data.routeStatusID)
                          }
                           loading={isLoading}
                            // style={{ marginRight: "10px"}}
                             onClick={()=>saveInfo(index)}>
                               Update Discrepancies
                               </Button>

                          </>
                        )}
                      </>
                    )}
                    {/* {!disabledRoutes.includes(data.routeStatusID) && (
                      <>
                        {(UserType == 1 || includesFinance || includesWareHouseManager) && (
                          <>
                          <Divider type="vertical" style={{ height: '30px', backgroundColor: 'black' }} />
                          </>
                        )}
                      </>
                    )} */}

                {/* {(UserType == 1 || includesFinance || includesWareHouseManager) && (
                      <>
                        <DatePicker
                          format={"YYYY-MM-DD"}
                          style={{ marginRight: "10px", width: 160 }}

                          value={
                            selectedDates[index]
                              ? dayjs(selectedDates[index])
                              : null
                          }
                          onChange={(_, dateStr) => {
                            handleDateChange(dateStr, index);
                          }}
                          placeholder="Select Date"
                          disabled={
                            data.credit_notes_posted_by > 0 ||
                            disabledRoutes.includes(data.routeStatusID)
                          }
                        />
                      </>
                    )} */}
                    
                    {/* {!disabledRoutes.includes(data.routeStatusID) && (
                      <>
                        {(UserType == 1 || includesFinance || includesWareHouseManager) && (
                          <>

                              <Button
                              style={{ marginRight: "10px"}}
                                disabled={
                                  data.credit_notes_posted_by > 0 ||
                                  disabledRoutes.includes(data.routeStatusID)
                                }
                                onClick={() => postDiscrepenciesNetsuite(data.routeStatusID, index)}
                                type="primary"
                              >
                                Post Discrepencies to Netsuite
                              </Button>
                          </>
                        )}
                      </>
                    )} */}
                    

                    {(data.credit_notes_posted_by > 0 ||
                      disabledRoutes.includes(data.routeStatusID)) && (
                      <Link
                        to={
                          env === "uat"
                            ? `https://5615243-sb1.app.netsuite.com/app/common/search/searchresults.nl?searchtype=Transaction&CUSTBODY_TMS_BATCH_ID=${data.routeStatusID}&style=NORMAL&CUSTBODY_TMS_BATCH_IDtype=STARTSWITH&report=&grid=&searchid=2812&dle=F&sortcol=Transction_ORDTYPE9_raw&sortdir=ASC&csv=HTML&OfficeXML=F&pdf=&size=1000&_csrf=v0kQGugWvoAwvKSOMu20tORjQ628avVC-SmoUnGj2TEe0XEhNnycNAn5eTQSfUaw7t3uKubjwYmiY_pck4HHwVlrJZFZYVAeeVlJLPlBi50VeHau1eZJoPWEuYoBfWbtIPSPVcgEmbgR2b3cW8jS2V1xHe3JK7F6dDPWWwJ4eZw%3D&twbx=F`
                            : `https://5615243.app.netsuite.com/app/common/search/searchresults.nl?searchid=2760&whence=&&CUSTBODY_TMS_BATCH_ID=${data.routeStatusID}&style=NORMAL&CUSTBODY_TMS_BATCH_IDtype=STARTSWITH&report=&grid=&searchid=2707&dle=F&sortcol=Transction_ORDTYPE9_raw&sortdir=ASC&csv=HTML&OfficeXML=F&pdf=&size=1000`
                        }
                        target="_blank"
                        style={{ marginLeft: "5px" }}
                      >
                        View Credit Notes
                      </Link>
                    )}
                  </Row>
              <Table
                  style={{ overflowX: "auto", marginTop: "10px"}}
                  columns={dataCol}
                  bordered
                  dataSource={data.tableData}
                  pagination={false}
                  scroll={{
                    y: 400,
                  }}
                />

<Divider style={{ height: "3px", background: "#006cb8" }} />
                 
              </Panel>
              </Collapse>

            </div>
          </>
        ))}
      <Message type={type} msg={loadMessage} />
      <ActionDialog
        message={actionDialogProps.message}
        type={actionDialogProps.type}
        isOpen={actionDialogProps.isOpen}
        onClose={actionDialogProps.onClose}
        onSubmit={() => {
          actionDialogProps.onOk();
        }}
        onCancel={() =>
          setActionDialogProps((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
      />
    </div>
  );
};

export default StockDiscrepanciesReport;
