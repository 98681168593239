import { Checkbox, OutlinedInput, Typography, RadioGroup,NativeSelect,Autocomplete } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { styled } from '@mui/material/styles';
const FormInputLabel = styled(Typography)(({ theme }) => ({
  width: '100%',
  fontSize: 14,
  fontWeight: 500,
  paddingLeft: 2,
  color: theme.palette.primary.lightBlack,
  fontFamily: 'ArtegraSansAltRegular',
}));

const Input = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  borderRadius: "4px",
  border: "1px solid",
  fontFamily: "ArtegraSansAltRegular",
  borderColor: theme.palette.common.grey,
  backgroundColor: theme.palette.common.white,
  // marginBottom: 4,
  "& .MuiInputBase-input": {
    fontSize: "14px",
    padding: "0px 12px",
    // minHeight: "35.63px",
    minHeight: "40px",
    color: theme.palette.primary.lightBlack,
    '&:focus': {
      outline: 0,
      // border: "1px grey",
      borderRadius: "4px",
      // borderColor: theme.palette.primary.blue,
    },
  },
  "& .MuiInputBase-root": {
    '&:hover': {
      outline: 0,
      border: 'none',
      border: "1px solid",
      borderRadius: "4px",
      borderColor: theme.palette.common.grey,
    },
  },
  "& .Mui-disabled": {
    backgroundColor: theme.palette.common.grey,
    color: "#4A4A4A"
  },
  "& input::-webkit-outer-spin-button": {
    appearance: "none",
    margin: 0
  },
  "& input::-webkit-inner-spin-button": {
    appearance: "none",
    margin: 0
  },
  "& input[type=number]": {
    appearance: "textfield"
  },
  "& .MuiFormHelperText-root.Mui-error": {
    display: "none",
    "&:after": {
      border: 'none'
    }
  },
  "& .MuiInput-underline:before": {
    border: 'none'
  },
  "& .MuiInput-underline.Mui-error:after": {
    transform: 'none',
    border: "none",
  }
}));

const CheckBox = styled(Checkbox)(({ theme }) => ({
  marginLeft: 0,
  paddingRight: 5,
  "& + .MuiFormControlLabel-label": {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    paddingLeft: 2,
    color: theme.palette.primary.lightBlack,
    fontFamily: 'ArtegraSansAltRegular',
  },
  "& .Mui-checked": {
    color: theme.palette.primary.main,
  }
}));

const RadioBoxGroup = styled(RadioGroup)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    paddingLeft: 2,
    fontFamily: 'ArtegraSansAltRegular',
  },
  "& label": {
    marginRight: "10px"
  },
  "& .MuiButtonBase-root": {
    marginLeft: 0,
    paddingRight: "5px",
  }
}))

const CustomSelect = styled(NativeSelect)(({ theme }) => ({ 
  "& svg":{
    color: theme.palette.common.solidBlue,
  },
  "& .MuiInputBase-input": {
    width: "100%",
    // marginBottom: 4,
    fontSize: "14px",
    border: "1px solid",
    borderRadius: "4px",
    backgroundColor: "white",
    minHeight: "35.63px !important",
    outline: 0,
    borderColor: theme.palette.common.grey,
    fontFamily: "ArtegraSansAltRegular",
    // fontSize: "14px",
    // minHeight: "35.63px",
    padding: "0px 24px 0px 12px",
    // borderColor: theme.palette.common.grey,
    // color: theme.palette.primary.lightBlack,    
    '&:focus': {
      outline: 0,
      border: "1px solid",
      borderRadius: "4px",
      borderColor: theme.palette.primary.blue,
    },
  },

  "& .Mui-disabled": {
    color: "#4A4A4A",
    backgroundColor: "#f3f3f3",
  },
  "& input::-webkit-outer-spin-button": {
    margin: 0,
    appearance: "none",
  },
  "& input::-webkit-inner-spin-button": {
    margin: 0,
    appearance: "none",
  },
  "& input[type=number]": {
    appearance: "textfield"
  },
}))

const AutoCompleteInput = styled(Autocomplete)(({ theme }) => ({
  marginBottom: 4,
  minHeight:'37.63px', 
  "& .MuiFormControl-root":{
    width:"100%",
    "& .MuiAutocomplete-inputRoot":{
      padding:0
    },
    "& .MuiInputBase-input": {
      width: "100%",
      fontSize: "14px",
      // padding: "0px 12px",
      padding:'0 56px 0 12px ',
      height: "35.63px",
      minHeight: "35.63px",
      borderRadius: "4px",
      border: "1px solid",
      fontFamily: "ArtegraSansAltRegular",
      borderColor: theme.palette.common.grey,
      backgroundColor: theme.palette.common.white,
    "& .Mui-disabled": {
      backgroundColor: theme.palette.common.grey,
      color: "#4A4A4A"
    },
    "& input":{
      height: 35.63,
      fontSize: 14,
      padding: '0 12px',
    }
  },
}
}));

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  width: "100%",
  fontSize: "14px",
  padding: "8px 12px",
  minHeight: "35.63px",
  borderRadius: "4px",
  border: "1px solid",
  fontFamily: "ArtegraSansAltRegular",
  borderColor: theme.palette.common.lightBlack,
  backgroundColor: theme.palette.common.white,
  marginBottom: 4,
  '&:focus': {
    outline: 0,
    border: "1px solid",
    borderRadius: "4px",
    borderColor: theme.palette.primary.blue,
  },
}));
export { FormInputLabel, Input, CheckBox, RadioBoxGroup, CustomSelect,AutoCompleteInput,StyledDatePicker }