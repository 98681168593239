import React, { useState, useEffect } from 'react';
import { Button, Grid, TextField, IconButton, Tooltip, InputAdornment } from '@mui/material'

import { Stack } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import LockIcon from '@mui/icons-material/Lock';
import SearchIcon from '@mui/icons-material/Search';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Map from './Map';
import AuthService from "../../Components/auth/AuthService";
import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { colors, schedules, vehicleTypes } from "../../Components/common/constants";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Message from '../../Components/common/Message';
import { netsuite_url } from '../../Components/config';
import _ from "lodash";
import DraggableTable from './DraggableTable';
import AllRoutes from './AllRoutes';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import Autocomplete from '@mui/material/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormButton } from '../../Components/UiElements/UiElements';
import { SelectField, DatePickerField, InputBaseField } from '../../Components/InputFields/InputFields';
import { ActionDialog } from '../../Components/actionDialog/ActionDialog';
import { useLoadingDispatch, showLoading, hideLoading } from '../../context/loadingContext';
import AllRoutesMap from './AllRoutesMap';
import DataGrid from '../../Components/dataGrid/DataGrid';
import { LockFilled, PrinterFilled, UnlockFilled } from '@ant-design/icons';
import { Button as AntButton, DatePicker as AntDate, Col, Row } from 'antd'
import dayjs from 'dayjs';


const useStyles = makeStyles((theme) => ({
    dateField: {
        marginRight: "10px"
    },
    tblDiv: {
        width: "50%",
        float: "left",

    },
    tbl: {
        height: "340px",
        overflow: "visible"
    },
    mapDiv: {
        float: "right",
        width: "48%"
    },
    tblRow: {
        // cursor: "pointer"
    },
    chkBox: {
        margin: "0px",
        padding: "0px !important"
    }
}));

const mainMapStyle = {
    width: '100%',
    height: '450px',
    border: "1px solid lightgray"
};

const routesMapStyle = {
    width: '100%',
    height: '430px',
    border: "1px solid lightgray"
};

export default function Routes(props) {
    const classes = useStyles();
    var layoutDispatch = useLoadingDispatch();

    const [routeCompleteStatus, setRouteCompleteStatus] = React.useState(false);
    const [orders, setOrders] = React.useState(props.orders);
    const [rowsData, setRowsData] = useState(props.orders);
    const [deliveryDate, setDeliveryDate] = React.useState(props.deliveryDate);
    const [prevDeliveryDate, setPrevDeliveryDate] = React.useState(props.deliveryDate);
    const [selectedOrders, setSelectedOrders] = React.useState([]);
    const [markers, setMarkers] = React.useState([]);
    const [routes, setRoutes] = React.useState([]);
    const [vehicles, setVehicles] = React.useState([]);
    const [drivers, setDrivers] = React.useState([]);
    const [helpers, setHelpers] = React.useState([]);

    const [collapsedRoutes, setCollapsedRoutes] = React.useState([]);

    const [selectedRoute, setSelectedRoute] = React.useState({ id: "", title: "" });

    const [loadMessage, setLoadMessage] = React.useState("");
    const [type, setType] = React.useState("");
    const [expandAll, setExpandAll] = useState(false);
    const [currentRouteSaveIdx, setCurrentRouteSaveIdx] = useState(-1);
    const [currentRouteLockIdx, setCurrentRouteLockIdx] = useState(-1);
    const [flgSaveAllRoutes, setFlgSaveAllRoutes] = useState(0);
    const [completedRoutes, setCompletedRoutes] = React.useState([]);


    const [headerLocked, setHeaderLocked] = useState({});
    const [tempSelections, setTempSelections] = useState({});


    // Function to toggle lock state based on route ID
    const toggleHeaderLock = (id) => {
        setHeaderLocked((prev) => ({
            ...prev,
            [id]: !prev[id], // Toggle the lock state for the specific route ID
        }));
    };

    useEffect(() => {
        const initialLockedState = {};
        routes.forEach((route) => {
            if (route.status === 0) {
                initialLockedState[route.id] = true; // Lock header if status is 0
            }
        });
        setHeaderLocked(initialLockedState);
    }, [routes]);

    console.log('routes', routes)


    const [searchValue, setSearchValue] = useState("");

    const Auth = new AuthService();

    console.log('completedRoutes', completedRoutes);

    // useEffect(() => {
    //     if (orders.length > 0) {
    //         syncOrders();
    //     }
    //     Auth.fetch(`/route/completed-routes`)
    //         .then((res) => {
    //             if (res.ack) {
    //                 let completedRoutes1 = res.completed_routes;
    //                 let routesCompleted = [];
    //                 let dates = [];
    //                 dates.completed = [];
    //                 completedRoutes1.map((cr) => {
    //                     dates.completed.push(moment(cr.delivery_date).toDate());
    //                 });
    //                 routesCompleted[0] = dates;
    //                 setCompletedRoutes(routesCompleted);
    //             }
    //             else {
    //                 let temp = {};
    //                 temp.completed = [];
    //                 temp.completed.push(new Date());
    //                 setCompletedRoutes(temp);
    //                 setCompletedRoutes([]);
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }, [])

    React.useEffect(() => {
        if (orders.length > 0) {
            syncOrders();
        }
        Auth.fetch(`/route/completed-routes`)
            .then((res) => {
                if (res.ack) {
                    let completedRoutes1 = res.completed_routes;
                    let dates = completedRoutes1.map((cr) => moment(cr.delivery_date).toDate());
                    setCompletedRoutes(dates);
                } else {
                    setCompletedRoutes([new Date()]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const highlightedMomentDates = completedRoutes.map(date => dayjs(date));

    const dateCellRender = (current) => {
        const currentDate = dayjs(current);
        const isHighlighted = highlightedMomentDates.some(date => date.isSame(currentDate, 'day'));

        return (
            <div className={isHighlighted ? 'highlighted-cell' : ''}>
                {currentDate.date()}
            </div>
        );
    };


    const onClickExpand = () => {
        let collapsedRoutes1 = collapsedRoutes;
        let routes1 = JSON.parse(JSON.stringify(routes));

        if (expandAll) {
            routes1.map((route, rIdx) => {
                route.expanded = false;
                collapsedRoutes1[rIdx] = false;
            })
        }
        else {
            routes1.map((route, rIdx) => {
                route.expanded = true;
                collapsedRoutes1[rIdx] = true;
            });
        }
        setRoutes(routes1);;
        setCollapsedRoutes(collapsedRoutes1);
        setExpandAll(!expandAll);
    };

    const handleRouteExpand = (route_idx) => {
        let routes1 = JSON.parse(JSON.stringify(routes));

        routes1[route_idx].expanded = routes1[route_idx].expanded == true ? false : true;
        setRoutes(routes1);

        let collapsedRoutes1 = collapsedRoutes;
        collapsedRoutes1[route_idx] = collapsedRoutes1[route_idx] == true ? false : true;
        setCollapsedRoutes(collapsedRoutes1);
    }

    //State For Action Dialog
    const [actionDialogProps, setActionDialogProps] = useState({
        message: '',
        type: '',
        isOpen: false
    });
    const ShowActionDialog = (message, type, isOpen, OnOkCallback, OnCancellCallback) => {
        setActionDialogProps(prevState => ({
            ...prevState,
            message: message,
            type: type,
            isOpen: isOpen,
            onOk: OnOkCallback,
            onCancel: OnCancellCallback,
        }));
    }

    const syncOrders = () => {

        let routes1 = JSON.parse(JSON.stringify(routes));
        let orders1 = JSON.parse(JSON.stringify(orders));

        routes1.map((route) => {
            if (route.orders.length > 0) {
                orders1 = orders1.concat(route.orders);
            }
            route.orders = [];
        });

        orders1.sort(function (a, b) {
            return a.shipZip - b.shipZip;
        });

        // setRoutes([]);

        showLoading(layoutDispatch);
        Auth.fetch(`/route/complete-route-status?delivery_date=${moment(deliveryDate).format('YYYY-MM-DD')}`)
            .then((res) => {
                hideLoading(layoutDispatch);
                if (res.ack) {
                    setRouteCompleteStatus(true);
                }
                else {
                    setRouteCompleteStatus(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        showLoading(layoutDispatch);
        Auth.post(`/route/all-routes`)
            .then((res) => {
                hideLoading(layoutDispatch);
                if (res.ack) {
                    let routes1 = res.routes;

                    routes1.map((o, rIdx) => {
                        o.orders = [];
                        o.colorObj = {};
                        o.routeWeight = 0;
                        o.selectedVehicle = 0;
                        o.selectedDriver = 0;
                        o.selectedHelper = 0;
                        o.selectedSchedule = 0;
                        o.expanded = collapsedRoutes[rIdx] != undefined ? collapsedRoutes[rIdx] : false;
                        o.status = 1;
                        let clr = colors.filter(obj => obj.title === o.color);
                        if (clr.length > 0) {
                            o.colorObj.code = clr[0].id;
                            o.colorObj.name = clr[0].title;
                        }
                    });

                    // let orders1 = JSON.parse(JSON.stringify(orders));

                    showLoading(layoutDispatch);
                    Auth.fetch(`/route/saved-route?delivery_date=${moment(deliveryDate).format('YYYY-MM-DD')}`)
                        .then((res1) => {
                            hideLoading(layoutDispatch);
                            if (res1.ack) {
                                let route_orders = res1.routes;

                                if (route_orders.length > 0) {
                                    let unique_routes = []
                                    route_orders.map((ro) => {
                                        let check_route = unique_routes.filter(r => r.route_id == ro.route_id);
                                        if (check_route.length > 0) {
                                            check_route[0].orders.push({ internalId: ro.netsuite_order_id, rank: ro.rank, route_order_id: ro.route_order_id });
                                        }
                                        else {
                                            let r = {};
                                            r.route_id = ro.route_id;
                                            r.vehicle_id = ro.vehicle_id;
                                            r.driver_id = ro.driver_id;
                                            r.helper_id = ro.helper_id;
                                            r.schedule_id = ro.schedule_id;
                                            r.status = ro.status;
                                            r.orders = [];
                                            r.orders.push({ internalId: ro.netsuite_order_id, rank: ro.rank, route_order_id: ro.route_order_id });
                                            unique_routes.push(r)
                                        };
                                    });

                                    if (unique_routes.length > 0) {
                                        unique_routes.map((ur, urIdx) => {
                                            let currentRoute = routes1.filter(r => r.id == ur.route_id);
                                            if (currentRoute.length > 0) {
                                                currentRoute[0].selectedDriver = ur.driver_id;
                                                currentRoute[0].selectedVehicle = ur.vehicle_id;
                                                currentRoute[0].selectedHelper = ur.helper_id;
                                                currentRoute[0].selectedSchedule = ur.schedule_id;
                                                currentRoute[0].status = ur.status;

                                                let currentSelectedOrders = [];
                                                let ordersToRemove = [];
                                                unique_routes[urIdx].orders.map((ord) => {
                                                    let nsOrder = orders1.filter(o => o.internalId == ord.internalId);
                                                    if (nsOrder[0]) {
                                                        nsOrder[0].rank = ord.rank;
                                                        nsOrder[0].route_order_id = ord.route_order_id;
                                                        currentSelectedOrders.push(nsOrder[0]);
                                                        ordersToRemove.push(Number(nsOrder[0].internalId));
                                                    }
                                                });

                                                currentSelectedOrders.sort(function (a, b) {
                                                    return a.rank - b.rank;
                                                });

                                                currentRoute[0].orders = currentSelectedOrders;
                                                currentRoute[0].routeWeight = 0;
                                                currentRoute[0].orders.map((order) => {
                                                    currentRoute[0].routeWeight += Number(order.orderWeight);
                                                });

                                                _.remove(orders1, function (n) {
                                                    return ordersToRemove.includes(Number(n.internalId));
                                                });
                                            }
                                        });
                                    }
                                }

                            }

                            setRoutes(routes1);
                            console.log(routes1);
                            setOrders(orders1);
                            setRowsData(orders1);
                            setLoadMessage("");
                            showLoading(layoutDispatch);

                            Auth.fetch(`/vehicle/vehicles-roaster?delivery_date=${moment(deliveryDate).format('YYYY-MM-DD')}`)
                                .then((resV) => {
                                    hideLoading(layoutDispatch);
                                    if (resV.ack) {
                                        let vehicles1 = resV.vehicles

                                        let assignedVehicle = _.filter(vehicles1, function (o) {
                                            let check = routes1.filter(r => r.selectedVehicle == o.id);
                                            if (check.length > 0) {
                                                return true;
                                            }
                                            return false;
                                        });

                                        if (assignedVehicle.length > 0) {
                                            assignedVehicle.map((v) => {
                                                v.disabled = true;
                                            })
                                        }
                                        setVehicles(vehicles1);
                                    }
                                    else {
                                        setType("error");
                                        setLoadMessage(resV.message);
                                    }
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                            showLoading(layoutDispatch);
                            Auth.fetch(`/driver/drivers-roaster?delivery_date=${moment(deliveryDate).format('YYYY-MM-DD')}`)
                                .then((resD) => {
                                    hideLoading(layoutDispatch);
                                    if (resD.ack) {
                                        let drivers1 = resD.drivers

                                        let assignedDriver = _.filter(drivers1, function (o) {
                                            let check = routes1.filter(r => r.selectedDriver == o.id);
                                            if (check.length > 0) {
                                                return true;
                                            }
                                            return false;
                                        });

                                        if (assignedDriver.length > 0) {
                                            assignedDriver.map((d) => {
                                                d.disabled = true;
                                            })
                                        }
                                        setDrivers(drivers1);
                                    }
                                    else {
                                        setType("error");
                                        setLoadMessage(resD.message);
                                    }
                                })
                                .catch((err) => {
                                    console.log(err);
                                });


                            showLoading(layoutDispatch);
                            Auth.fetch(`/helper/driver-helpers-roaster?delivery_date=${moment(deliveryDate).format('YYYY-MM-DD')}`)
                                .then((resH) => {
                                    hideLoading(layoutDispatch);
                                    if (resH.ack) {
                                        let driver_helpers1 = resH.driver_helpers

                                        let assignedHelper = _.filter(driver_helpers1, function (o) {
                                            let check = routes1.filter(r => r.selectedHelper == o.id);
                                            if (check.length > 0) {
                                                return true;
                                            }
                                            return false;
                                        });

                                        if (assignedHelper.length > 0) {
                                            assignedHelper.map((d) => {
                                                d.disabled = true;
                                            })
                                        }
                                        setHelpers(driver_helpers1);
                                    }
                                    else {
                                        setType("error");
                                        setLoadMessage(resH.message);
                                    }
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }


    const syncRoutesData = () => {

        let routes1 = JSON.parse(JSON.stringify(routes));
        let orders1 = JSON.parse(JSON.stringify(orders));

        routes1.map((route) => {
            if (route.orders.length > 0) {
                orders1 = orders1.concat(route.orders);
            }
            route.orders = [];
        });

        orders1.sort(function (a, b) {
            return a.shipZip - b.shipZip;
        });

        // setRoutes([]);

        showLoading(layoutDispatch);
        Auth.fetch(`/route/complete-route-status?delivery_date=${moment(deliveryDate).format('YYYY-MM-DD')}`)
            .then((res) => {
                hideLoading(layoutDispatch);
                if (res.ack) {
                    setRouteCompleteStatus(true);
                }
                else {
                    setRouteCompleteStatus(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        showLoading(layoutDispatch);
        Auth.post(`/route/all-routes`)
            .then((res) => {
                hideLoading(layoutDispatch);
                if (res.ack) {
                    let routes1 = res.routes;

                    routes1.map((o, rIdx) => {
                        o.orders = [];
                        o.colorObj = {};
                        o.routeWeight = 0;
                        o.selectedVehicle = 0;
                        o.selectedDriver = 0;
                        o.selectedHelper = 0;
                        o.selectedSchedule = 0;
                        o.expanded = collapsedRoutes[rIdx] != undefined ? collapsedRoutes[rIdx] : false;
                        o.status = 1;
                        let clr = colors.filter(obj => obj.title === o.color);
                        if (clr.length > 0) {
                            o.colorObj.code = clr[0].id;
                            o.colorObj.name = clr[0].title;
                        }
                    });

                    showLoading(layoutDispatch);
                    Auth.fetch(`/route/saved-route?delivery_date=${moment(deliveryDate).format('YYYY-MM-DD')}`)
                        .then((res1) => {
                            hideLoading(layoutDispatch);
                            if (res1.ack) {
                                let route_orders = res1.routes;

                                if (route_orders.length > 0) {
                                    let unique_routes = []
                                    route_orders.map((ro) => {
                                        let check_route = unique_routes.filter(r => r.route_id == ro.route_id);
                                        if (check_route.length > 0) {
                                            check_route[0].orders.push({ internalId: ro.netsuite_order_id, rank: ro.rank, route_order_id: ro.route_order_id });
                                        }
                                        else {
                                            let r = {};
                                            r.route_id = ro.route_id;
                                            r.vehicle_id = ro.vehicle_id;
                                            r.driver_id = ro.driver_id;
                                            r.helper_id = ro.helper_id;
                                            r.schedule_id = ro.schedule_id;
                                            r.status = ro.status;
                                            r.orders = [];
                                            r.orders.push({ internalId: ro.netsuite_order_id, rank: ro.rank, route_order_id: ro.route_order_id });
                                            unique_routes.push(r)
                                        };
                                    });

                                    if (unique_routes.length > 0) {
                                        unique_routes.map((ur, urIdx) => {
                                            let currentRoute = routes1.filter(r => r.id == ur.route_id);
                                            if (currentRoute.length > 0) {
                                                currentRoute[0].selectedDriver = ur.driver_id;
                                                currentRoute[0].selectedVehicle = ur.vehicle_id;
                                                currentRoute[0].selectedHelper = ur.helper_id;
                                                currentRoute[0].selectedSchedule = ur.schedule_id;
                                                currentRoute[0].status = ur.status;

                                                let currentSelectedOrders = [];
                                                let ordersToRemove = [];
                                                unique_routes[urIdx].orders.map((ord) => {
                                                    let nsOrder = orders1.filter(o => o.internalId == ord.internalId);
                                                    if (nsOrder[0]) {
                                                        nsOrder[0].rank = ord.rank;
                                                        nsOrder[0].route_order_id = ord.route_order_id;
                                                        currentSelectedOrders.push(nsOrder[0]);
                                                        ordersToRemove.push(Number(nsOrder[0].internalId));
                                                    }
                                                });

                                                currentSelectedOrders.sort(function (a, b) {
                                                    return a.rank - b.rank;
                                                });

                                                currentRoute[0].orders = currentSelectedOrders;
                                                currentRoute[0].routeWeight = 0;
                                                currentRoute[0].orders.map((order) => {
                                                    currentRoute[0].routeWeight += Number(order.orderWeight);
                                                });

                                                _.remove(orders1, function (n) {
                                                    return ordersToRemove.includes(Number(n.internalId));
                                                });
                                            }
                                        });
                                    }
                                }

                            }

                            setRoutes(routes1);
                            console.log(routes1);
                            setOrders(orders1);
                            setRowsData(orders1);
                            // setLoadMessage("");
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    useEffect(() => {
        if (currentRouteSaveIdx != -1) {
            handleSaveRoute(currentRouteSaveIdx, undefined, 1);
            setCurrentRouteSaveIdx(-1);
        }
    }, [currentRouteSaveIdx]);

    useEffect(() => {
        if (currentRouteLockIdx != -1) {
            handleSaveRoute(currentRouteLockIdx, routes[currentRouteLockIdx].status, 1);
            setCurrentRouteLockIdx(-1);
        }
    }, [currentRouteLockIdx]);


    useEffect(() => {
        if (flgSaveAllRoutes > 0) {
            saveAllRoutes();
        }
    }, [flgSaveAllRoutes]);


    const handleCompleteRouting = () => {
        // if (window.confirm("Are you sure to want to complete the routing, once the route is completed you won't be able to open it for editing?")) {
        ShowActionDialog("Are you sure to want to complete the routing, once the route is completed you won't be able to open it for editing?", "confirm", true, function () {

            setLoadMessage("");
            showLoading(layoutDispatch);
            Auth.fetch("/route/complete-route", {
                method: "POST",
                body: JSON.stringify({
                    delivery_date: moment(prevDeliveryDate).format('YYYY-MM-DD')
                }),
            })
                .then((res) => {
                    hideLoading(layoutDispatch);
                    if (res.ack) {
                        setType("success")
                        setLoadMessage("Route completed succesfully!");
                        setRouteCompleteStatus(true);
                    }
                    else {
                        setType("error")
                        setLoadMessage(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        })
    }

    const handleMoveOrders = (e, values) => {
        // setSelectedRoute(e.target.value);

        const selected = values;

        let r = JSON.parse(JSON.stringify(routes));
        let o = JSON.parse(JSON.stringify(orders));

        let selectedRoute = _.find(r, function (o) {
            return o.id == selected.id;
        });
        let currentSelectedOrders = _.filter(orders, function (o) {
            return selectedOrders.includes(o.internalId);//o.selected == true;
        });

        if (currentSelectedOrders.length == 0) {
            let rand = Math.random() * 50; // for unique message each time
            let str = " ";
            for (let i = 0; i < rand; i++) {
                str += " ";
            }
            setType('error');
            setLoadMessage('Please select orders to move' + str)
            // alert('Please select orders to move')
        }
        else {
            console.log(selectedRoute)
            console.log(currentSelectedOrders);

            let selected_orders = [];
            currentSelectedOrders.map(ord => {
                selected_orders.push(ord.internalId);
            });
            setLoadMessage("")
            Auth.fetch(`/route/add-route-order`, {
                method: "POST",
                body: JSON.stringify({
                    route_id: selectedRoute.id,
                    delivery_date: moment(deliveryDate).format('YYYY-MM-DD'),
                    orders: selected_orders
                }),
            })
                .then((res) => {
                    hideLoading(layoutDispatch);
                    if (res.ack) {
                        setType("success")
                        setLoadMessage("Order added succesfully!");


                        selectedRoute.orders = _.concat(selectedRoute.orders, currentSelectedOrders);
                        selectedRoute.routeWeight = 0;
                        selectedRoute.orders.map((order) => {
                            selectedRoute.routeWeight += Number(order.orderWeight);
                        });
                        setRoutes(r);

                        _.remove(o, function (n) {
                            return selectedOrders.includes(n.internalId);// n.selected == true;
                        });

                        setOrders(o);
                        setRowsData(o);
                        setSelectedOrders([]);
                        setSelectedRoute({ id: "", title: "" });

                        syncRoutesData();
                    }
                    else {
                        // refresh the orders

                        syncRoutesData();
                        setType("error")
                        setLoadMessage("Order assigned to another route, please refresh to get latest updates")
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    const saveAllRoutes = () => {
        routes.map((route, rIdx) => {
            if (route.orders.length > 0)
                handleSaveRoute(rIdx);
        })
    }

    const printSchedule = () => {

        let validate = true;

        routes.map((route) => {
            if (route.orders.length > 0) {
                if (Number(route.selectedVehicle) == 0 || Number(route.selectedDriver) == 0 || Number(route.selectedSchedule) == 0) {
                    validate = false;
                }
            }
        });

        if (!validate) {
            let rand = Math.random() * 50; // for unique message each time
            let str = " ";
            for (let i = 0; i < rand; i++) {
                str += " ";
            }
            setType('error');
            setLoadMessage("Please assign all the parameters to the route" + str);
        }
        else {
            setLoadMessage("");
            setType("success");
            setLoadMessage("Downloading routes schedule");
            Auth.fetch(`/route/download-route-schedule?delivery_date=${moment(deliveryDate).format('YYYY-MM-DD')}`)
                .then((res) => {
                    if (res.ack) {
                        window.open(res.file_url, '_blank', 'noreferrer');
                    }
                    else {

                        setType("error")
                        setLoadMessage("Route schedule not printed ")
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    const handleSelectAllOrder = (e) => {

        let status = e.target.checked == true ? 1 : 0;
        let o = JSON.parse(JSON.stringify(selectedOrders))

        if (status == 1) {
            orders.map((order) => {
                o.push(order.internalId)
            })
            setSelectedOrders(o);
        }
        else {
            setSelectedOrders([]);
        }
    }

    const handleSelectOrder = (e, id, index) => {

        let o = JSON.parse(JSON.stringify(selectedOrders));
        let status = e.target.checked == true ? 1 : 0;
        if (status == 1) {
            o.push(id)
            setSelectedOrders(o);
        }
        else {
            _.remove(o, function (n) {
                return n == id;
            });
            setSelectedOrders(o);
        }
    }

    const handleRemoveOrderFromRoute = (route_idx, id) => {

        setLoadMessage("Deleting route order!");
        setType("success");
        showLoading(layoutDispatch);
        Auth.fetch(`/route/remove-route-order`, {
            method: "POST",
            body: JSON.stringify({
                netsuite_order_id: id,
                route_id: routes[route_idx].id,
                delivery_date: moment(deliveryDate).format('YYYY-MM-DD'),
            }),
        })
            .then((res) => {
                hideLoading(layoutDispatch);
                if (res.ack) {
                    setLoadMessage("Route order removed succesfully!");

                    let routes1 = JSON.parse(JSON.stringify(routes));
                    let orders1 = JSON.parse(JSON.stringify(orders));

                    let selectedOrder = _.find(routes1[route_idx].orders, function (n) {
                        return n.internalId == id;
                    });
                    selectedOrder.selected = 0;
                    selectedOrder.rank = 0;
                    selectedOrder.route_order_id = 0;
                    orders1.unshift(selectedOrder);
                    _.remove(routes1[route_idx].orders, function (n) {
                        return n.internalId == id;
                    });

                    routes1[route_idx].routeWeight = 0;
                    routes1[route_idx].orders.map((order) => {
                        routes1[route_idx].routeWeight += Number(order.orderWeight);
                    });
                    setOrders(orders1);
                    setRowsData(orders1);
                    setRoutes(routes1);
                    setCurrentRouteSaveIdx(route_idx);
                }
                else {

                    setType("error")
                    setLoadMessage(res.message);

                    if (res.route_status != undefined) {
                        let routes1 = JSON.parse(JSON.stringify(routes));
                        routes1[route_idx].status = res.route_status;
                        setRoutes(routes1);
                        setCurrentRouteSaveIdx(route_idx);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }

    const handleOrderSort = (rIdx, rOrders) => {
        let orders1 = JSON.parse(JSON.stringify(orders));
        let routes1 = JSON.parse(JSON.stringify(routes));

        let rOrders1 = JSON.parse(JSON.stringify(rOrders));
        routes1[rIdx].orders = [];
        // routes1[rIdx].orders = rOrders1;

        showLoading(layoutDispatch);
        Auth.fetch(`/route/get-route-orders`, {
            method: "POST",
            body: JSON.stringify({
                route_id: routes1[rIdx].id,
                delivery_date: moment(deliveryDate).format('YYYY-MM-DD'),
            }),
        })
            .then((res) => {
                hideLoading(layoutDispatch);
                if (res.ack) {
                    console.log(res.route);
                    const route_orders = res.route;

                    for (let i = 0; i < rOrders1.length; i++) {
                        let check = route_orders.filter(ro => ro.netsuite_order_id == rOrders1[i].internalId);
                        if (check.length == 0) {
                            let ord = rOrders1[i];
                            orders1.unshift(ord);
                            rOrders1.splice(i, 1)
                        }
                    }
                    routes1[rIdx].orders = rOrders1;
                    setOrders(orders1);
                    setRowsData(orders1);
                    setRoutes(routes1);
                    setCurrentRouteSaveIdx(rIdx);
                }
            });
    }

    const handleLockRoute = (locked, rIdx) => {
        setTempSelections((prev) => ({ ...prev, [rIdx]: {} }));
        let type = (locked ? "Lock" : "Unlock");

        let orders1 = JSON.parse(JSON.stringify(orders));
        let routes1 = JSON.parse(JSON.stringify(routes));
        let rOrders1 = JSON.parse(JSON.stringify(routes[rIdx].orders));

        let rand = Math.random() * 50; // for unique message each time
        let str = " ";
        for (let i = 0; i < rand; i++) {
            str += " ";
        }
        setLoadMessage("");
        Auth.fetch(`/route/get-route-orders`, {
            method: "POST",
            body: JSON.stringify({
                route_id: routes1[rIdx].id,
                delivery_date: moment(deliveryDate).format('YYYY-MM-DD'),
            }),
        })
            .then((res) => {
                // hideLoading(layoutDispatch);
                if (res.ack) {

                    const route_orders = res.route;

                    for (let i = 0; i < rOrders1.length; i++) {
                        let check = route_orders.filter(ro => ro.netsuite_order_id == rOrders1[i].internalId);
                        if (check.length == 0) {
                            let ord = rOrders1[i];
                            orders1.unshift(ord);
                            rOrders1.splice(i, 1)
                        }
                    }

                    routes1[rIdx].orders = rOrders1;
                    // check locked routes locked again [status is 1-0 and locked is 0-1]
                    if (res.route[0].status != locked) {
                        setOrders(orders1);
                        setRowsData(orders1);

                        routes1[rIdx].status = res.route[0].status;
                        setRoutes(routes1);
                        setType('error');
                        setLoadMessage('Route already ' + (locked ? 'Locked' : 'Unlocked') + str)
                    }
                    else {
                        if (routes1[rIdx].orders.length > 0 && Number(routes1[rIdx].selectedDriver) > 0 && Number(routes1[rIdx].selectedVehicle) && Number(routes1[rIdx].selectedSchedule)) {
                            let assignedVehicle = _.find(vehicles, function (o) {
                                return o.id == routes1[rIdx].selectedVehicle;
                            });

                            // let assignedVehicleType = _.find(vehicleTypes, function (o) {
                            //     return o.id == assignedVehicle?.type;
                            // });

                            if (Number(routes1[rIdx].routeWeight) > assignedVehicle?.max_weight) {
                                setType('error');
                                setLoadMessage('Route weight ' + Number(routes1[rIdx].routeWeight).toFixed(2) + "KG, should be less than " + assignedVehicle?.max_weight + "KG" + str);
                                // alert('Route weight ' + Number(routes1[rIdx].routeWeight).toFixed(2) + "KG, should be less than " + assignedVehicleType.maxWeight + "KG");
                                return;
                            }

                            // if (window.confirm(`Are you sure to ${type} this route?`)) {
                            ShowActionDialog(`Are you sure to ${type} this route?`, "confirm", true, function () {

                                // let routes1 = JSON.parse(JSON.stringify(routes));
                                routes1[rIdx].status = type == "Unlock" ? 1 : 0;

                                setOrders(orders1);
                                setRowsData(orders1);
                                setRoutes(routes1);

                                setCurrentRouteLockIdx(rIdx);
                                // setCurrentRouteLockStatus(rIdx);
                            })
                        }
                        else {
                            setType('error');
                            setLoadMessage('Please assign all the parameters to the route' + str)
                            // alert('Please assign Vehicle and Driver to the route')
                        }
                    }

                }
            });
    }

    const handleLockAllRoutes = () => {
        ShowActionDialog("Are you sure to want to lock all the routes?", "confirm", true, function () {

            // if (orders.length > 0) {
            //     let rand = Math.random() * 50; // for unique message each time
            //         let str = " ";
            //         for (let i = 0; i < rand; i++) {
            //             str += " ";
            //         }
            //         setType('error');
            //         setLoadMessage('There should not be any unplanned orders' + str)
            // }
            // else 
            {
                let validate = true;
                let reqBody = {
                    orders: [],
                    invoices: []
                };
                routes.map((route) => {

                    if (route.orders.length > 0 && Number(route.selectedDriver) > 0 && Number(route.selectedVehicle) && Number(route.selectedSchedule)) {
                        let assignedVehicle = _.find(vehicles, function (o) {
                            return o.id == route.selectedVehicle;
                        });

                        // let assignedVehicleType = _.find(vehicleTypes, function (o) {
                        //     return o.id == assignedVehicle?.type;
                        // });

                        if (Number(route.routeWeight) > assignedVehicle?.max_weight) {
                            validate = false;
                        }

                        let assignedDriver = _.find(drivers, function (o) {
                            return o.id == route.selectedDriver;
                        });

                        route.orders.map((order) => {
                            if (Number(order.invInternalId) > 0) {
                                let invObj = {};
                                invObj.order_id = order.invInternalId;
                                invObj.route_code = route.code;
                                invObj.route_weight = route.routeWeight;
                                invObj.driver_code = assignedDriver.code;
                                invObj.vehicle_number = assignedVehicle.registration_number;

                                reqBody.invoices.push(invObj);
                            }
                            else {
                                validate = false;
                            }
                        })
                    }
                })
                if (!validate) {
                    let rand = Math.random() * 50; // for unique message each time
                    let str = " ";
                    for (let i = 0; i < rand; i++) {
                        str += " ";
                    }
                    setType('error');
                    setLoadMessage('Please assign all the parameters to the route and make sure all orders are invoiced' + str)
                    // alert('Please assign Vehicle and Driver to the route')
                }
                else {

                    setLoadMessage("");
                    showLoading(layoutDispatch);
                    Auth.fetch("/route/lock-all-routes", {
                        method: "POST",
                        body: JSON.stringify(reqBody),
                    })
                        .then((res) => {
                            hideLoading(layoutDispatch);
                            if (res.ack) {
                                setType("success")
                                setLoadMessage("Route completed succesfully!");
                                setRouteCompleteStatus(true);
                            }
                            else {
                                setType("error")
                                setLoadMessage(res.message)
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }
        })
    }

    const handleSaveRoute = (rIdx, status, sync) => {
        let order_ids = [];
        let invoice_ids = [];
        if (routes[rIdx].orders.length > 0) {
            routes[rIdx].orders.map((order) => {
                if (Number(order.invInternalId) > 0)
                    invoice_ids.push(order.invInternalId);
                order_ids.push(order.internalId);
            });
        }
        let reqBody = {};
        reqBody.route_id = routes[rIdx].id;
        reqBody.route_code = routes[rIdx].code;
        reqBody.route_title = routes[rIdx].title;
        reqBody.route_weight = routes[rIdx].routeWeight;

        reqBody.orders = order_ids;
        reqBody.invoices = invoice_ids;
        reqBody.vehicle_id = routes[rIdx].selectedVehicle;
        reqBody.driver_id = routes[rIdx].selectedDriver;
        reqBody.helper_id = routes[rIdx].selectedHelper;
        reqBody.schedule_id = routes[rIdx].selectedSchedule;

        let assignedVehicle = _.find(vehicles, function (o) {
            return o.id == routes[rIdx].selectedVehicle;
        });
        let assignedDriver = _.find(drivers, function (o) {
            return o.id == routes[rIdx].selectedDriver;
        });

        reqBody.vehicle_number = assignedVehicle ? assignedVehicle.registration_number : "";
        reqBody.driver_code = assignedDriver ? assignedDriver.code : '';


        reqBody.status = status != undefined ? status : routes[rIdx].status;
        reqBody.delivery_date = moment(deliveryDate).format('YYYY-MM-DD');

        reqBody.status_change = status != undefined ? 1 : 0;

        setType("success")
        setLoadMessage("Saving route. Please wait ....");

        showLoading(layoutDispatch);
        Auth.fetch(`/route/save-route`, {
            method: "POST",
            body: JSON.stringify(reqBody),
        })
            .then((res) => {
                hideLoading(layoutDispatch);
                if (res.ack) {
                    setLoadMessage("Route saved succesfully!");
                    if (sync)
                        syncRoutesData();
                }
                else {
                    setType("error")
                    setLoadMessage(res.message ? ("Error: " + res.message) : "Error occured while saving route. Refreshing route ..., ")

                    if (sync)
                        syncRoutesData();
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }


    const handleTempSelection = (type, value, rIdx) => {
        setTempSelections((prev) => ({
            ...prev,
            [rIdx]: {
                ...prev[rIdx],
                [type]: value
            }
        }));
    };

    const handleUpdateClick = (rIdx) => {

        let rand = Math.random() * 50;
        let str = " ";
        for (let i = 0; i < rand; i++) {
            str += " ";
        }
        const temp = tempSelections[rIdx];


        if (temp) {
            if (Object.keys(temp).length === 0) {
                setLoadMessage(`Please choose values again to update. ${str}`)
                setType("error")
            }

            let routes1 = JSON.parse(JSON.stringify(routes));

            if (temp.vehicle) {
                let assignedVehicle = _.find(vehicles, function (o) {
                    return o.id == temp.vehicle;
                });

                if (Number(routes1[rIdx].routeWeight) > assignedVehicle?.max_weight) {
                    setType('error');
                    setLoadMessage('Route weight ' + Number(routes1[rIdx].routeWeight).toFixed(2) + "KG, should be less than " + assignedVehicle?.max_weight + "KG" + str);
                    return;
                } else {
                    routes1[rIdx].selectedVehicle = temp.vehicle
                }
            };

            //driver Change in locked route case
            if (temp.driver) {

                let assignedVehicle = _.find(vehicles, function (o) {
                    return o.id == routes1[rIdx].selectedVehicle;
                });

                let assignedDriver = _.find(drivers, function (o) {
                    return o.id == temp.driver;
                });
                if (Number(assignedDriver.vehicle_type) < Number(assignedVehicle.type)) {
                    let rand = Math.random() * 50; // for unique message each time
                    let str = " ";
                    for (let i = 0; i < rand; i++) {
                        str += " ";
                    }
                    setType('error');
                    setLoadMessage("Driver can't be assigned to a " + vehicleTypes.filter(obj => obj.id == assignedVehicle.type)[0].title + str);
                    return;
                } else {

                }
                routes1[rIdx].selectedDriver = temp.driver
            };

            //Helper and schedule update incase locked route
            if (temp.helper) routes1[rIdx].selectedHelper = temp.helper;
            if (temp.schedule) routes1[rIdx].selectedSchedule = temp.schedule;

            setRoutes(routes1);
            setCurrentRouteSaveIdx(rIdx);

            // Clear tempSelections after update
            setTempSelections((prev) => ({ ...prev, [rIdx]: {} }));
        }
    };

    const handleAssignVehicle = (id, rIdx) => {
        let rand = Math.random() * 50;
        let str = " ";
        for (let i = 0; i < rand; i++) {
            str += " ";
        }
        let routes1 = JSON.parse(JSON.stringify(routes));
        let prevId = routes1[rIdx].selectedVehicle;


        let assignedVehicle = _.find(vehicles, function (o) {
            return o.id == routes1[rIdx].selectedVehicle;
        });
        let prevAssignedVehicle = _.find(vehicles, function (o) {
            return o.id == prevId;
        });

        let assignedDriver = _.find(drivers, function (o) {
            return o.id == routes1[rIdx].selectedDriver;
        });


        routes1[rIdx].selectedVehicle = id;

        if (assignedDriver)
            assignedDriver.disabled = false;

        routes1[rIdx].selectedDriver = 0;

        if (assignedVehicle)
            assignedVehicle.disabled = true;

        if (prevAssignedVehicle)
            prevAssignedVehicle.disabled = false;

        setVehicles(vehicles);
        setDrivers(drivers);
        setRoutes(routes1);

        setCurrentRouteSaveIdx(rIdx);
    }



    const handleAssignDriver = (id, rIdx) => {

        let routes1 = JSON.parse(JSON.stringify(routes));

        let assignedVehicle = _.find(vehicles, function (o) {
            return o.id == routes1[rIdx].selectedVehicle;
        });

        let prevAssignedDriver = _.find(drivers, function (o) {
            return o.id == routes1[rIdx].selectedDriver;
        });

        let assignedDriver = _.find(drivers, function (o) {
            return o.id == id;
        });

        if (Number(assignedDriver.vehicle_type) < Number(assignedVehicle.type)) {
            let rand = Math.random() * 50; // for unique message each time
            let str = " ";
            for (let i = 0; i < rand; i++) {
                str += " ";
            }
            setType('error');
            setLoadMessage("Driver can't be assigned to a " + vehicleTypes.filter(obj => obj.id == assignedVehicle.type)[0].title + str);
            routes1[rIdx].selectedDriver = 0;
            return;
        }
        else {
            routes1[rIdx].selectedDriver = id;


            if (assignedDriver)
                assignedDriver.disabled = true;

            if (prevAssignedDriver)
                prevAssignedDriver.disabled = false;

        }
        setRoutes(routes1);
        setCurrentRouteSaveIdx(rIdx);
    }

    const handleAssignHelper = (id, rIdx) => {
        let routes1 = JSON.parse(JSON.stringify(routes));
        routes1[rIdx].selectedHelper = id;
        setRoutes(routes1);
        setCurrentRouteSaveIdx(rIdx);
    }

    const handleAssignSchedule = (id, rIdx) => {
        let routes1 = JSON.parse(JSON.stringify(routes));
        routes1[rIdx].selectedSchedule = id;
        setRoutes(routes1);
        setCurrentRouteSaveIdx(rIdx);
    }

    const loadPrevRoute = () => {
        if (prevDeliveryDate == deliveryDate) {
            let rand = Math.random() * 50; // for unique message each time
            let str = " ";
            for (let i = 0; i < rand; i++) {
                str += " ";
            }
            setType('error');
            setLoadMessage("Route date can not be the same" + str);
            // alert("Route date can not be the same");
            return;
        }

        // if (window.confirm("All previous changes will be overwritten. Do you wish to continue?")) {
        ShowActionDialog("All previous changes will be overwritten. Do you wish to continue?", "confirm", true, function () {

            let routes1 = JSON.parse(JSON.stringify(routes));
            let orders1 = JSON.parse(JSON.stringify(orders));

            let ordersToRemove = [];
            routes1.map((route) => {
                if (route.orders.length > 0) {
                    let tempOrders = JSON.parse(JSON.stringify(route.orders));
                    ordersToRemove = ordersToRemove.concat(tempOrders);
                    route.orders = [];
                    route.routeWeight = 0;
                    route.status = 1;
                }
            });

            ordersToRemove.map((order) => {
                order.selected = 0;
                orders1.unshift(order);
            })

            setLoadMessage("");
            showLoading(layoutDispatch);
            Auth.fetch(`/route/complete-route?template_delivery_date=${moment(prevDeliveryDate).format('YYYY-MM-DD')}&current_delivery_date=${moment(deliveryDate).format('YYYY-MM-DD')}`)
                .then((res) => {
                    hideLoading(layoutDispatch);
                    if (res.ack) {
                        setType("success");
                        setLoadMessage("Route loaded succesfully!");

                        const completed_orders = res.orders;
                        // console.log(completed_orders);
                        // console.log(routes);
                        // console.log(orders);

                        // let routes1 = JSON.parse(JSON.stringify(routes));
                        // let orders1 = JSON.parse(JSON.stringify(orders));

                        routes1.map((route) => {
                            route.orders = []; // empty previous orders
                            let saved_orders = completed_orders.filter(co => co.route_id == route.id);
                            let ordersToRemove = [];
                            if (saved_orders.length > 0) {

                                let assignedVehicle = _.find(vehicles, function (o) {
                                    return o.id == saved_orders[0].vehicle_id;
                                });
                                route.selectedVehicle = assignedVehicle ? saved_orders[0].vehicle_id : 0;

                                let assignedDriver = _.find(drivers, function (o) {
                                    return o.id == saved_orders[0].driver_id;
                                });
                                route.selectedDriver = assignedDriver ? saved_orders[0].driver_id : 0;

                                let assignedHelper = _.find(helpers, function (o) {
                                    return o.id == saved_orders[0].helper_id;
                                });
                                route.selectedHelper = assignedHelper ? saved_orders[0].helper_id : 0;

                                let assignedSchedule = _.find(schedules, function (o) {
                                    return o.id == saved_orders[0].schedule_id;
                                });
                                route.selectedSchedule = assignedSchedule ? saved_orders[0].schedule_id : 0;

                                route.status = 1;
                                route.routeWeight = 0;

                                saved_orders.map((saved_order) => {
                                    let current_orders = orders1.filter(o1 => o1.shipZip == saved_order.shipZip);
                                    if (current_orders.length > 0) {
                                        current_orders.map((c_order, cIdx) => {
                                            route.routeWeight += Number(c_order.orderWeight);
                                            route.orders.push(c_order);

                                            ordersToRemove.push(Number(c_order.internalId));
                                        });
                                    }
                                });
                                _.remove(orders1, function (n) {
                                    return ordersToRemove.includes(Number(n.internalId));
                                });
                            }
                        });

                        // console.log(routes1)
                        // console.log(orders1)

                        setRoutes(routes1);
                        setRowsData(orders1);
                        setOrders(orders1);
                        setFlgSaveAllRoutes(flgSaveAllRoutes + 1);
                    }
                    else {
                        setType("error");
                        setLoadMessage(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

        })
        // else
        //     return;
    }

    const loadFixedRoute = () => {

        // This function loads the fixed route set in the system, for each route we have the list of customers and their sequence
        // if (window.confirm("All previous changes will be overwritten. Do you wish to continue?")) {
        ShowActionDialog("All previous changes will be overwritten. Do you wish to continue?", "confirm", true, function () {

            let routes1 = JSON.parse(JSON.stringify(routes));
            let orders1 = JSON.parse(JSON.stringify(orders));

            let ordersToRemove = [];
            routes1.map((route) => {
                if (route.orders.length > 0) {
                    let tempOrders = JSON.parse(JSON.stringify(route.orders));
                    ordersToRemove = ordersToRemove.concat(tempOrders);
                    route.orders = [];
                    route.routeWeight = 0;
                    route.status = 1;
                }
            });

            ordersToRemove.map((order) => {
                order.selected = 0;
                orders1.unshift(order);
            })

            setLoadMessage("");
            showLoading(layoutDispatch);
            Auth.fetch(`/route/fixed-route?current_delivery_date=${moment(deliveryDate).format('YYYY-MM-DD')}`)
                .then((res) => {
                    hideLoading(layoutDispatch);
                    if (res.ack) {
                        setType("success");
                        setLoadMessage("Route loaded succesfully!");

                        const customers1 = res.customers;

                        routes1.map((route) => {
                            route.orders = []; // empty previous orders
                            console.log(route)
                            let fixed_route_orders = customers1.filter(c => c.route_id == route.id);
                            let ordersToRemove = [];
                            if (fixed_route_orders.length > 0) {

                                let assignedVehicle = _.find(vehicles, function (o) {
                                    return o.id == route.preferred_vehicle_id;
                                });
                                route.selectedVehicle = assignedVehicle ? route.preferred_vehicle_id : 0;

                                let assignedDriver = _.find(drivers, function (o) {
                                    return o.id == route.preferred_driver_id;
                                });
                                route.selectedDriver = assignedDriver ? route.preferred_driver_id : 0;

                                let assignedHelper = _.find(helpers, function (o) {
                                    return o.id == route.preferred_helper_id;
                                });
                                route.selectedHelper = assignedHelper ? route.preferred_helper_id : 0;

                                let assignedSchedule = _.find(schedules, function (o) {
                                    return o.id == route.preferred_schedule_id;
                                });
                                route.selectedSchedule = assignedSchedule ? route.preferred_schedule_id : 0;

                                route.status = 1;
                                route.routeWeight = 0;

                                fixed_route_orders.map((saved_order) => {
                                    let current_orders = orders1.filter(o1 => Number(o1.customerInternalId) == Number(saved_order.netsuite_customer_id));
                                    if (current_orders.length > 0) {
                                        current_orders.map((c_order, cIdx) => {
                                            route.routeWeight += Number(c_order.orderWeight);
                                            route.orders.push(c_order);

                                            ordersToRemove.push(Number(c_order.internalId));
                                        });
                                    }
                                });
                                _.remove(orders1, function (n) {
                                    return ordersToRemove.includes(Number(n.internalId));
                                });
                            }
                        });

                        // console.log(routes1)
                        // console.log(orders1)

                        setRoutes(routes1);
                        setRowsData(orders1);
                        setOrders(orders1);
                        setFlgSaveAllRoutes(flgSaveAllRoutes + 1);
                    }
                    else {
                        setType("error");
                        setLoadMessage(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

        })
        // else
        //     return;
    }
    const handleSearch = (e) => {

        const currValue = e.target.value;
        setSearchValue(currValue);
        if (currValue && currValue !== undefined && currValue !== null && currValue !== "") {
            let filteredData = [];
            filteredData = [...orders];
            filteredData = filteredData.filter(entry => {
                var hasValue = false;

                for (var prop in entry) {
                    if (Object.prototype.hasOwnProperty.call(entry, prop)) {
                        hasValue = entry[prop]?.toString().toLowerCase().includes(currValue.toLowerCase());
                        if (hasValue)
                            break;
                    }
                }
                return hasValue;
            });
            setRowsData(filteredData);
        } else {
            const filteredData = [...orders];
            setRowsData(filteredData);
        }
    }

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box sx={{ minWidth: 120 }}>
                        <Grid container spacing={2}>
                            {!routeCompleteStatus &&
                                <Grid item xs={12} md={12} lg={12} xl={12}>
                                    {/* <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Move selected orders to route</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            style={{ marginBottom: "5px" }}
                                            value={selectedRoute}
                                            label="Move selected orders to route"
                                            disabled={routeCompleteStatus}
                                            onChange={handleMoveOrders}
                                        >
                                            {

                                                routes.map((r) => {
                                                    return (<MenuItem value={r.id} disabled={r.status == 0 ? true : false}>{r.title + " (" + r.code + ")"}</MenuItem>)
                                                })
                                            }
                                        </Select>

                                    </FormControl> */}
                                    <Autocomplete
                                        id="move-orders"
                                        style={{ width: "400px" }}
                                        options={routes}
                                        getOptionLabel={(option) => option.title}
                                        getOptionDisabled={option => (option.status == 0 ? true : false)}
                                        value={selectedRoute}
                                        onChange={handleMoveOrders}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                disabled={routeCompleteStatus}
                                                label="Move selected orders to route"
                                                placeholder="Move selected orders to route"
                                            />
                                        )}
                                    />
                                    {/* <SelectField
                                    id="selectedRoute"
                                    name="selectedRoute"
                                    value={selectedRoute}
                                    onChange={handleMoveOrders}
                                    options={routes}
                                    placeholder="Select Route"/> */}
                                </Grid>
                            }
                            <Grid item xs={12} md={12} lg={12} xl={12}>
                                <Grid container spacing={1} >
                                    <>
                                        {
                                            !routeCompleteStatus ? (
                                                <Grid item xs={4}>
                                                    <InputBaseField
                                                        type="search"
                                                        name="searchValue"
                                                        value={searchValue}
                                                        onChange={handleSearch}
                                                        placeholder="Search"
                                                        EndIcon={SearchIcon}
                                                    />
                                                </Grid>
                                            ) : (
                                                <Grid sx={{ display: "flex", justifyContent: "center" }} item xs={12}>

                                                    <span style={{ paddingTop: '200px' }}>The routing has been completed for selected date.</span>
                                                </Grid>
                                            )
                                        }
                                    </>
                                    {/* <input name="searchValue" value={searchValue} placeholder="Search" onChange={handleSearch}/> */}
                                    {/* {!routeCompleteStatus &&
                                            <Stack direction="column">
                                                <p style={{ margin: 0 }}>Unplanned Orders: <b>{orders.length}</b></p>
                                                <p style={{ margin: 0 }}>Weight: <b>{(orders.reduce((count, current) => count + current.orderWeight, 0)).toFixed(2)} KG</b></p>
                                            </Stack>
                                        } */}
                                    {/* // </Grid> */}

                                    <Grid item xs={4}>
                                        {!routeCompleteStatus &&
                                            <Stack direction="column" >
                                                <p style={{ margin: 0 }}>Unplanned Orders: <b>{orders.length}</b></p>
                                                <p style={{ margin: 0 }}>Weight: <b>{(orders.reduce((count, current) => count + current.orderWeight, 0)).toFixed(2)} KG</b></p>
                                            </Stack>
                                        }
                                    </Grid>
                                    <Grid item xs={4}>
                                        <>
                                            {/* <Grid container spacing={1}> */}
                                            {!routeCompleteStatus &&
                                                // <Grid item xs={6}>
                                                <FormButton type="noIcon" onClick={handleCompleteRouting} disabled={orders.length > 0 ? true : false} style={{ float: "right" }}>
                                                    Complete Routing
                                                </FormButton>
                                                //     {/* <Button variant="contained" style={{ float: "right", width: "180px", height: "55px" }} onClick={handleCompleteRouting} disabled={orders.length > 0 ? true : false}>
                                                //             Complete Routing
                                                //         </Button> */}
                                                // {/* </Grid> */}
                                            }
                                            {/* <Grid item xs={6}> */}

                                            {/* <AllRoutes routes={routes} markers={orders} /> */}

                                            {/* </Grid> */}
                                            {/* </Grid> */}
                                        </>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    {!routeCompleteStatus &&
                        <>
                            <DataGrid
                                size="small"
                                columnKey="Routes"
                                rowsData={rowsData}
                                isSelection={true}
                                isPagination={false}
                                scroll={{ x: 900, y: 240 }}
                                selectedRows={selectedOrders}
                                onSelectChange={(selectedRowKeys) => { setSelectedOrders(selectedRowKeys) }}
                            />
                            {/* <TableContainer component={Paper} className={classes.tbl}>
                            <Table aria-label="Routes" size="small" dense stickyHeader>
                                <caption>List of Invoices </caption>
                                <TableHead>
                                    <TableRow>
                                        <TableCell> */}
                            {/* <Checkbox
                                            color="primary"
                                            className={classes.chkBox}
                                            inputProps={{
                                                'aria-label': 'select ',
                                            }}
                                            onChange={handleSelectAllOrder}
                                        /> */}
                            {/* </TableCell>
                                        <TableCell style={{ fontWeight: "bold" }}>Postcode</TableCell>
                                        <TableCell style={{ fontWeight: "bold" }}>Opening Time</TableCell>
                                        <TableCell style={{ fontWeight: "bold", width: "300px" }}>Customer Name</TableCell>
                                        <TableCell style={{ fontWeight: "bold" }}>Shop Code</TableCell>
                                        <TableCell style={{ fontWeight: "bold" }}>Invoice</TableCell>
                                        <TableCell style={{ fontWeight: "bold" }}>Order</TableCell>
                                        <TableCell style={{ fontWeight: "bold" }}>Weight</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orders.map((row, index) => (
                                        <TableRow style={index % 2 ? { background: "#D3D3D3" } : { background: "white" }} key={row.id} className={classes.tblRow}>
                                            <TableCell >
                                                <Checkbox
                                                    style={{
                                                        transform: "scale(1.2)",
                                                    }}
                                                    color="primary"
                                                    className={classes.chkBox}
                                                    inputProps={{
                                                        'aria-label': 'select ',
                                                    }}
                                                    // value={row.internalId}
                                                    disabled={routeCompleteStatus}
                                                    checked={selectedOrders.includes(row.internalId) ? true : false}
                                                    onChange={(e) => { handleSelectOrder(e, row.internalId, index) }}
                                                />
                                            </TableCell>
                                            <TableCell >{row.shipZip}</TableCell>
                                            <TableCell >{row.customerOpeningTime}:00</TableCell>
                                            <TableCell >{row.customerProjectId}</TableCell>
                                            <TableCell ><a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${row.customerInternalId}`} target="_blank">{row.customerName}</a></TableCell>
                                            <TableCell ><a href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${row.invInternalId}`} target="_blank">{row.invoiceNo}</a></TableCell>
                                            <TableCell ><a href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${row.internalId}`} target="_blank">{row.saleOrderNo}</a></TableCell>
                                            <TableCell >{row.orderWeight.toFixed(2)} KG</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer> */}
                        </>
                    }
                </Grid>
                <Grid item xs={6}>
                    {<AllRoutesMap containerStyle={routesMapStyle} routes={routes} markers={orders} />}
                </Grid>
            </Grid >
            <hr />
            {!routeCompleteStatus &&
                <>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12}>
                            <Stack direction="row" gap="10px">
                                {/* <DatePicker label={"Noor"} value={prevDeliveryDate} format={"dd-MM-yyyy"}
                            onChange={(date) => {
                                setPrevDeliveryDate(date)
                            }}
                            highlightedDatesList={completedRoutes}
                            className={classes.dateField} /> */}
                                <AntDate
                                    format={'YYYY-MM-DD'}
                                    style={{ width: 160 }}
                                    value={prevDeliveryDate ? dayjs(prevDeliveryDate) : null}
                                    onChange={(_, dateStr) => {
                                        setPrevDeliveryDate(dateStr);
                                    }}
                                    dateRender={dateCellRender}
                                />
                                {/* <DatePickerField
                                    todayButton="Today"
                                    format='yyyy-MM-dd'
                                    selected={prevDeliveryDate}
                                    onChange={(date) => setPrevDeliveryDate(date)}

                                    highlightedDatesList={completedRoutes}
                                /> */}
                                <FormButton type="noIcon" onClick={loadPrevRoute}>Load Route By Date</FormButton>
                                OR
                                <FormButton type="noIcon" onClick={loadFixedRoute}>Load Fixed Route</FormButton>
                            </Stack>
                        </Grid>
                        {/* <Button variant="contained" onClick={loadPrevRoute}>Load Route</Button> */}

                    </Grid>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <br />
                            <hr />
                        </Grid>
                    </Grid>
                </>

            }
            <Stack direction='row' alignItems='center' justifyContent={"space-between"} justifyItems={"space-between"} sx={{ width: "100%" }}>
                <Stack direction='row' alignItems='center' sx={{ width: "70%" }}>
                    <p>Total Routes: <b>{routes.reduce((count, current) => count + (current.orders.length > 0 ? 1 : 0), 0)}</b>, Total Orders: <b>{routes.reduce((count, current) => count + current.orders.length, 0)}</b>, Total Weight: <b>{(routes.reduce((count, current) => count + current.routeWeight, 0)).toFixed(2)} KG</b></p>
                    {/* <FormButton sx={{ marginLeft: '10px' }} type="save" onClick={saveAllRoutes}>Save All Routes</FormButton> */}
                    {/* <FormButton sx={{ marginLeft: '10px' }} type="print" onClick={printSchedule}>Print Scehdule</FormButton> */}
                    <AntButton

                        // variant="contained"
                        type="primary"
                        size="large"
                        onClick={printSchedule}
                        style={{ marginLeft: '10px' }}
                        icon={<PrinterFilled />}
                    >
                        Print Scehdule
                    </AntButton>
                </Stack>
                <Stack direction='row' alignItems='center' justifyContent={"flex-end"} sx={{ width: "30%" }}>
                    {/* <FormButton sx={{ marginLeft: '10px', float: "right" }} type="lock" onClick={handleLockAllRoutes}>Lock All Routes</FormButton> */}
                    <FormButton sx={{ marginLeft: '10px', float: "right" }} type="collapse" onClick={onClickExpand}>{expandAll ? "Collapse" : "Expand"} All Routes</FormButton>
                </Stack>
                {/* <Button variant="contained" onClick={saveAllRoutes}>Save All Routes</Button> */}
                <br />
            </Stack>
            {
                routes.map((route, rIdx) => {
                    return (
                        (route.orders.length > 0) &&
                        <Accordion expanded={route.expanded} onChange={() => { handleRouteExpand(rIdx) }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={route.code}
                                id={route.code}
                            >
                                <Typography style={{ fontSize: "20px" }}>
                                    {route.title + " (" + route.code + ")"} &nbsp; &nbsp;(Orders: {route.orders.length},
                                    Total Weight: {route.routeWeight.toFixed(2)} KG
                                    {drivers.length > 0 && route.selectedDriver > 0 ? ", Driver: " + ((drivers.filter(v => v.id == route.selectedDriver)[0] != undefined ? drivers.filter(v => v.id == route.selectedDriver)[0].driver_name : "")) : ""}
                                    {vehicles.length > 0 && route.selectedVehicle > 0 ? ", Vehicle: " + ((vehicles.filter(v => v.id == route.selectedVehicle)[0] != undefined ? vehicles.filter(v => v.id == route.selectedVehicle)[0].registration_number : "")) : ""}
                                    {helpers.length > 0 && route.selectedHelper > 0 ? ", Helper: " + ((helpers.filter(v => v.id == route.selectedHelper)[0] != undefined ? helpers.filter(v => v.id == route.selectedHelper)[0].helper_name : "")) : ""}
                                    {schedules.length > 0 && route.selectedSchedule > 0 ? ", Schedule: " + ((schedules.filter(v => v.id == route.selectedSchedule)[0] != undefined ? schedules.filter(v => v.id == route.selectedSchedule)[0].title : "")) : ""}
                                    )
                                    &nbsp;<b>{route.status == 0 ? "Locked" : ""}</b></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Row gutter={[4, 16]} style={{ marginBottom: '4px' }}>
                                    <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id={route.code + '-vehicle'}>Vehicle</InputLabel>
                                            <Select
                                                labelId={route.code + '-vehicle'}
                                                id="select-vehicle"
                                                // value={route.selectedVehicle}
                                                value={route.status === 0 && tempSelections[rIdx]?.vehicle !== undefined ? tempSelections[rIdx].vehicle : route.selectedVehicle}

                                                label="Assign Vehicle"
                                                disabled={headerLocked[route.id]}
                                                // disabled={route.status == 0 ? true : false}
                                                onChange={(e) => {
                                                    if (route.status === 0) {
                                                        handleTempSelection("vehicle", e.target.value, rIdx);
                                                    } else {
                                                        handleAssignVehicle(e.target.value, rIdx, route?.status)
                                                    }
                                                }}
                                            >
                                                <MenuItem value={0}>{"Select Vehicle"}</MenuItem>
                                                {
                                                    vehicles.map((r) => {
                                                        return (<MenuItem value={r.id} /*disabled={r.disabled}*/>{r.max_weight ? `${r.registration_number}- ${vehicleTypes.filter(obj => obj.id == r.type)[0].title} - (${r.max_weight} KGs)` : `${r.registration_number} - ${vehicleTypes.filter(obj => obj.id == r.type)[0].title} `}</MenuItem>)
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id={route.code + '-driver'}>Driver</InputLabel>
                                            <Select
                                                labelId={route.code + '-driver'}
                                                id="select-driver"
                                                // value={route.selectedDriver}
                                                value={route.status === 0 && tempSelections[rIdx]?.driver !== undefined ? tempSelections[rIdx].driver : route.selectedDriver}
                                                label="Assign Driver"
                                                // disabled={route.status == 0 ? true : (route.selectedVehicle ? false : true)}
                                                disabled={headerLocked[route.id] ? true : (route.selectedVehicle ? false : true)}
                                                onChange={(e) => {
                                                    if (route.status === 0) {
                                                        handleTempSelection("driver", e.target.value, rIdx);
                                                    } else {
                                                        handleAssignDriver(e.target.value, rIdx, route?.status)

                                                    }
                                                }}
                                            >
                                                <MenuItem value={0}>{"Select Driver"}</MenuItem>
                                                {
                                                    drivers.map((r) => {
                                                        return (<MenuItem value={r.id} /*disabled={r.disabled}*/>{r.first_name + " " + r.last_name} ({vehicleTypes.filter(obj => obj.id == r.vehicle_type)[0].title})</MenuItem>)
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col xs={24} sm={4} md={4} lg={4} xl={4}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id={route.code + '-helper'}>Helper</InputLabel>
                                            <Select
                                                labelId={route.code + '-helper'}
                                                id="select-helper"
                                                // value={route.selectedHelper}
                                                value={route.status === 0 && tempSelections[rIdx]?.helper !== undefined ? tempSelections[rIdx].helper : route.selectedHelper}

                                                label="Assign Helper"
                                                // disabled={route.status == 0 ? true : (route.selectedDriver ? false : true)}
                                                disabled={headerLocked[route.id] ? true : (route.selectedDriver ? false : true)}
                                                onChange={(e) => {
                                                    // handleAssignHelper(e.target.value, rIdx)
                                                    if (route.status === 0) {
                                                        handleTempSelection("helper", e.target.value, rIdx);
                                                    } else {
                                                        handleAssignHelper(e.target.value, rIdx, route?.status)

                                                    }
                                                }}
                                            >
                                                <MenuItem value={0}>{"Select Helper"}</MenuItem>
                                                <MenuItem value={-1}>{"--No Helper--"}</MenuItem>
                                                {
                                                    helpers.map((r) => {
                                                        return (<MenuItem value={r.id} /*disabled={r.disabled}*/>{r.first_name + " " + r.last_name}</MenuItem>)
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col xs={24} sm={3} md={3} lg={3} xl={3}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id={route.code + '-schedule'}>Schedule</InputLabel>
                                            <Select
                                                labelId={route.code + '-schedule'}
                                                id="select-schedule"
                                                // value={route.selectedSchedule}
                                                value={route.status === 0 && tempSelections[rIdx]?.schedule !== undefined ? tempSelections[rIdx].schedule : route.selectedSchedule}
                                                label="Assign Schedule"
                                                // disabled={route.status == 0 ? true : false}
                                                disabled={headerLocked[route.id]}
                                                onChange={(e) => {
                                                    // handleAssignSchedule(e.target.value, rIdx)
                                                    if (route.status === 0) {
                                                        handleTempSelection("schedule", e.target.value, rIdx);
                                                    } else {
                                                        handleAssignSchedule(e.target.value, rIdx, route?.status)

                                                    }
                                                }}
                                            >
                                                <MenuItem value={0}>{"Select Schedule"}</MenuItem>
                                                {
                                                    schedules.map((r) => {
                                                        return (<MenuItem value={r.id}>{r.title}</MenuItem>)
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Col>

                                    {!routeCompleteStatus &&
                                        <>

                                            <Col xs={24} sm={2} md={2} lg={2} xl={2}>
                                                {
                                                    !headerLocked[route?.id] && route?.status == 0 && (

                                                        <AntButton size='large' type='primary' onClick={() => handleUpdateClick(rIdx, route.status)} >
                                                            Update
                                                        </AntButton>

                                                    )
                                                }
                                                {
                                                    headerLocked[route?.id] && route.status == 0 ? (
                                                        <Tooltip title={headerLocked[route?.id] ? 'Unlock Header' : 'Lock Headers'}>
                                                            <AntButton size='large' icon={headerLocked[route?.id] ? <UnlockFilled /> : <LockFilled />} onClick={() => toggleHeaderLock(route?.id)}>
                                                            </AntButton>
                                                        </Tooltip>) : null
                                                }

                                            </Col>




                                            <Col xs={24} sm={5} md={5} lg={5} xl={5} style={{ display: 'flex', justifyContent: 'end' }}>

                                                <AntButton type={route.status != 0 ? "primary" : 'default'} danger={route.status == 0} size='large' icon={route.status == 0 ? <LockFilled /> : <UnlockFilled />} onClick={() => { handleLockRoute(route.status, rIdx) }}>
                                                    {route.status == 0 ? 'Unlock Route' : 'Lock Route'}
                                                </AntButton>
                                            </Col>
                                        </>
                                    }
                                </Row>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        {/* <p>{route.title + " (" + route.code + ")"}</p> */}

                                        <DraggableTable orders={route.orders} status={route.status} rIdx={rIdx} handleRemoveOrderFromRoute={handleRemoveOrderFromRoute} orderSort={handleOrderSort} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {<Map containerStyle={routesMapStyle} markers={route.orders} color={route.colorObj} type={"polyLine"} />}
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }

            <Message type={type} msg={loadMessage} />

            <ActionDialog
                message={actionDialogProps.message}
                type={actionDialogProps.type}
                isOpen={actionDialogProps.isOpen}
                onClose={actionDialogProps.onClose}
                onSubmit={() => { actionDialogProps.onOk() }}
                onCancel={() => setActionDialogProps(prevState => ({
                    ...prevState,
                    isOpen: false,
                }))
                }
            />
        </div >);

}
