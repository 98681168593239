import { createTheme } from "@mui/material/styles";

// const primary = '#213B87';
// const primary = '#11284B';
const primary='#1677ff';
const secondary = '#F4F4F4';
const black = "#000000";
const white = "#FFFFFF";
const solidBlue = '#1677ff';
const blue = '#213B87';
const green = '#00A29E';
const grey = '#F4F4F4';  
const lightGreen = '#F4F4F4';
const lightBlack = '#222A31';

const theme = createTheme({
  palette: {
    mode: 'light',
    common: {
      black: black,
      white: white,
      solidBlue: solidBlue,
      blue: blue,
      green: green,
      grey: grey,
      lightGreen: lightGreen,
      lightBlack: lightBlack,
    },
    primary: {
      main: primary,
      blue: blue,
      green: green,
    },
    secondary: {
      main: secondary,
      grey: grey,
      lightGreen: lightGreen,
      lightBlack: lightBlack,
  },
  MuiSelect: {
    icon: {
      color: "#B9B9B9",
    },
    
  },
  
}
});

export default theme;