import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from '@emotion/styled';
import { netsuite_url } from '../../Components/config';
// import CustomAvatar from '../TableComponents/CustomAvatar'
// import { ReactComponent as RedArrow } from '../../assets/icons/High.svg'
// import { ReactComponent as YellowArrow } from '../../assets/icons/Medium.svg'
// import { ReactComponent as BlueArrow } from '../../assets/icons/Low.svg'

const PalletInformation = styled.div`
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: flex-start;
  padding: 5px 15px;
  min-height: 80px;
  border-radius: 5px;
  max-width: 311px;
  /* background: ${({ isDragging }) =>
    isDragging ? 'rgba(255, 59, 59, 0.15)' : 'white'}; */
  background: white;
  margin-top: 5px;

  .secondary-details {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    width: 100%;
    font-size: 12px;
    font-weight: 400px;
    color: #7d7d7d;
  }
  .secondary-details p {
    margin: 0px 0px;
  }

  .label {
    margin: 5px 0px;
    font-size:15px;
  }

  /* .priority{ */
  /* margin-right: 12px; */
  /* align-self: center;
    svg{
      width: 12px !important;
      height: 12px !important;
      margin-right: 12px; */
  /* margin-top: 2px; */
  /* } */
  /* } */
`;

const PalletCard = ({ item, index }) => {
  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <PalletInformation>
            {/* <p className="label">Routing Sequence: {item.rank}</p> */}
            <p className="label">Customer: <a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${item.customerInternalId}`} target="_blank">{item.customerName}</a>,&nbsp; 
              {Number(item.invInternalId) > 0 ? (<span>Invoice: <a href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${item.invInternalId}`} target="_blank">{item.invoiceNo}</a></span>) : (<span>Order: <a href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${item.internalId}`} target="_blank">{item.saleOrderNo}</a></span>)}
              
            </p>
            <div className="secondary-details">
              <p>
                <span style={{ fontSize: '14px', fontWeight: "bold"}}>
                  Weight: {item.orderWeight.toFixed(2)} KG
                </span>
                <span style={{ float: "right", fontSize: "20px" }}>
                  <b>Drop: {item.rank}</b>
                </span>

              </p>
            </div>
          </PalletInformation>
        </div>
      )}
    </Draggable>
  );
};

export default PalletCard;

// <span className="priority">
// {item.Priority === 'High' ? (<RedArrow />) : item.Priority === 'Medium' ? (<YellowArrow />) : (<BlueArrow />)}
// </span>
// <div><CustomAvatar name={item.Assignee} isTable={false} size={16} /></div>
