import AuthService from "../../../Components/auth/AuthService";
import { store } from "../../../store/index";

const Auth = new AuthService();

export const userPreference = async () => {
  try {
    const res = await Auth.fetch("/dashboard/dashboard");

    if (res) {
      const parseData = JSON.parse(res.dashboard.dashboard_json);
      store.dispatch({
        type: "SET_CHART_PREFERENCE",
        payload: parseData.chartItems,
      });
      store.dispatch({
        type: "SET_STAT_PREFERENCE",
        payload: parseData.statItems,
      });
    }
  } catch (err) {
    console.log("Error", err);
  }
};

export const updatePreference = async (data) => {
  try {
    const res = await Auth.post("/dashboard/save-dashboard", {
      dashboard_json: JSON.stringify(data),
    });

    if (res) {
      userPreference();
    }
  } catch (err) {
    console.log("Error", err);
  }
};
