import React, { useEffect, useState } from "react";
import { Empty, Table } from "antd";
import { data as Columns } from "./GridColumns";
import LoadingIcon from "../../assets/images/icons/loaderIcon.gif";
import "./styles.css";
function DataGrid({
  isLoading,
  pageSize = 10,
  columnKey,
  rowsData,
  isPagination,
  columnCode,
  onRowClick,
  isSelection,
  selectedRows,
  onSelectChange,
  ...props
}) {
  const [tableData, setTableData] = useState([]);

  const handleSelectChange = (selectedRowKeys) => {
    onSelectChange(selectedRowKeys);
  };

  const loadData = (filterValues) => {
    setTableData(
      rowsData.map((rowItem, index) => {
        rowItem.key = rowItem.internalId;
        return { ...rowItem };
      })
    );
  };
  useEffect(() => {
    loadData();
  }, [ rowsData]);
  return (
    <div className="custom-grid">
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => onRowClick && onRowClick(record),
          };
        }}
        locale={{
          emptyText: (
            <Empty
              image={isLoading && LoadingIcon}
              description={isLoading ? "Loading..." : "No Record Found"}
              imageStyle={{ height: 30, display: !isLoading ? "none" : "" }}
            />
          ),
        }}
        checkStrictly={true}
        rowClassName={(record, index) => (onRowClick ? "claimRow" : "")}
        scroll={true}
        pagination={
          isPagination
            ? {
                defaultPageSize: pageSize,
                showSizeChanger: true,
                pageSizeOptions: [5, 10, 20, 30],
              }
            : false
        }
        dataSource={tableData}
        columns={Columns[columnKey]}
        rowSelection={
          isSelection
            ? {columnTitle:<></>, selectedRowKeys: selectedRows, onChange: handleSelectChange }
            : null
        }
        {...props}
      />
    </div>
  );
}

export default DataGrid;
