import React from 'react';
import { Dialog, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLoadingState } from '../../context/loadingContext';

const LoadingDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    "& .MuiBackdrop-root":{
        backgroundColor:'#4848485c'
        // backgroundColor: 'transparent',
    }
}));

function useLoading() {
  const { isLoading } = useLoadingState();
//   console.log('isLoading,',isLoading);
  return (
        <LoadingDialog
            fullScreen
            open={isLoading}
        >
            < CircularProgress size={60} thickness={5} />
        </LoadingDialog >
    )
}

export default useLoading