import * as React from 'react';

import { SortableContainer, SortableHandle, SortableElement, arrayMove } from 'react-sortable-hoc'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import { OtherHouses } from '@mui/icons-material';
import { netsuite_url } from '../../Components/config';
import _ from "lodash";
const DragHandle = SortableHandle(({ style, index }) => (
    <span style={{ ...style, ...{ cursor: 'move'} }}> {':::: '+index} </span>)
)

const TableBodySortable = SortableContainer(({ children, displayRowCheckbox }) => (
    <TableBody displayRowCheckbox={displayRowCheckbox} >
        {children}
    </TableBody >
))

TableBodySortable.muiName = 'TableBody'


const useStyles = makeStyles((theme) => ({
    dateField: {
        marginRight: "10px"
    },
    tblDiv: {
        width: "100%",
        float: "left",

    },
    tbl: {
        overflow: "visible",
        marginTop: "10px"
    },
    mapDiv: {
        float: "right",
        width: "48%"
    },
    tblRow: {
        // cursor: "pointer"
    },
    chkBox: {
        margin: "0px",
        padding: "0px !important"
    }
}));
export default function DraggableList(props) {
    const classes = useStyles();
    const [shops, setShops] = React.useState(props.shops);

    React.useEffect(() => {
        setShops(props.shops);
    }, [props.shops])

    // Handler for traversing completion, helper arrayMove is used
    const onSortEnd = ({ oldIndex, newIndex, rIdx }) => {

        const shops1 = arrayMove(shops, oldIndex, newIndex);
        setShops(shops1);

        props.shopSort(shops1);
    };

    const Row = SortableElement(({ data, idx }) => {

        return (
            <TableRow key={data.id} className={classes.tblRow}>
                <TableCell style={{ padding: "3px" }} >
                <DragHandle index={idx} />
                </TableCell>
                <TableCell >{data.code}</TableCell>
                <TableCell >{data.title}</TableCell>
                <TableCell >{data.postcode}</TableCell>
                <TableCell >{data.opening_time}</TableCell>
            </TableRow>
        )
    })

    return (
        <>
            {/* <p style={{float: "right"}}> (Total Shops: {shops.length}) </p> */}
            <TableContainer component={Paper} className={classes.tbl}>
                <Table aria-label="Routes" size="small" dense stickyHeader >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Code</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Name</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Postcode</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Opening Time</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBodySortable onSortEnd={onSortEnd} useDragHandle displayRowCheckbox={false}>

                        {shops.length > 0 && shops.map((row, index) => {
                            return (
                                <Row
                                    index={index}
                                    idx={index+1}
                                    key={row.invoiceNo}
                                    data={row}
                                />
                            )
                        })}
                    </TableBodySortable>
                </Table>
            </TableContainer>
        </>)
}