import { Button, Grid, Stack, TextField } from "@mui/material";
import React, { useEffect } from "react";
import Map from "./Map";
import AuthService from "../../Components/auth/AuthService";
import DatePicker from "react-date-picker";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Message from "../../Components/common/Message";
import Routes from "./Routes";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import moment from "moment/moment";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { FormButton } from "../../Components/UiElements/UiElements";
import {
  CheckBoxField,
  InputBaseField,
  RadioboxField,
  SelectField,
  AutocompleteField,
  DatePickerField,
} from "../../Components/InputFields/InputFields";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
import dayjs from "dayjs";
import { DatePicker as AntDate } from "antd";
const useStyles = makeStyles((theme) => ({
  dateField: {
    marginRight: "10px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DataSync() {
  const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();

  const [orders, setOrders] = React.useState([]);
  const [deliveryDate, setDeliveryDate] = React.useState(new Date());

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [messageKey, setMessageKey] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Auth = new AuthService();

  const syncDataFromNetsuite = () => {
    setType("success");
    setOrders([]);
    setMessageKey(!messageKey);
    setLoadMessage("Loading orders from Netsuite. Please wait ....");
    showLoading(layoutDispatch);
    Auth.post(`/netsuite/sync-orders`, {
      date: moment(deliveryDate).format("DD/MM/YYYY"),
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack) {
          // setLoadMessage("Orders loaded .")
          const orders = res.orders;
          let orderData = [];
          orders.map((order) => {
            if (order.saleOrderType == 2) {
              let check = orderData.filter(
                (r) => r.internalId == order.internalId
              );
              if (check.length > 0) {
                let nsItem = {};
                nsItem.itemId = order.itemId;
                nsItem.itemName = order.itemName;
                nsItem.quantity = order.quantity;
                nsItem.itemWeight = order.itemWeight;
                check[0].orderWeight =
                  Number(check[0].orderWeight) +
                  Number(
                    order.itemWeight.substring(0, order.itemWeight.length - 2)
                  ) *
                    Number(order.quantity);
                nsItem.casesInPallet = order.casesInPallet;
                nsItem.unitPrice = order.unitPrice;
                nsItem.netAmount = order.netAmount;
                nsItem.grossAmount = order.grossAmount;
                nsItem.grossAmountIncludingTax = order.grossAmountIncludingTax;

                check[0].items.push(nsItem);
              } else {
                let nsOrder = {};

                nsOrder.internalId = order.internalId;
                nsOrder.invInternalId = order.invInternalId;
                nsOrder.saleOrderNo = order.saleOrderNo;
                nsOrder.invoiceNo = order.invoiceNo;
                nsOrder.tranDate = order.tranDate;
                nsOrder.deliverDate = order.deliverDate;
                nsOrder.customerName = order.customerName;
                nsOrder.customerInternalId = order.customerInternalId;
                nsOrder.customerProjectId = order.customerProjectId;
                nsOrder.shipAddress1 = order.shipAddress1;
                nsOrder.shipAddress2 = order.shipAddress2;
                nsOrder.shipCity = order.shipCity;
                nsOrder.shipState = order.shipState;
                nsOrder.shipZip = order.shipZip;
                nsOrder.shipCountryCode = order.shipCountryCode;
                nsOrder.customerLatitude = order.customerLatitude;
                nsOrder.customerLongitude = order.customerLongitude;
                nsOrder.customerOpeningTime = order.customerOpeningTime;
                nsOrder.customerClosingTime = order.customerClosingTime;
                nsOrder.customerMaxvehicleLoadType =
                  order.customerMaxvehicleLoadType;
                nsOrder.platesInOrder = order.platesInOrder;
                nsOrder.customerOffLoadingMethod =
                  order.customerOffLoadingMethod;
                nsOrder.saleOrderType = order.saleOrderType;
                nsOrder.status = order.status;
                nsOrder.addressShippingAttentiona =
                  order.addressShippingAttentiona;
                nsOrder.orderCollectionToBePicked =
                  order.orderCollectionToBePicked;
                nsOrder.orderWeight = 0;

                nsOrder.orderWeight =
                  Number(
                    order.itemWeight.substring(0, order.itemWeight.length - 2)
                  ) * Number(order.quantity);

                nsOrder.items = [];

                let nsItem = {};
                nsItem.itemId = order.itemId;
                nsItem.itemName = order.itemName;
                nsItem.quantity = order.quantity;
                nsItem.itemWeight = order.itemWeight;
                nsItem.casesInPallet = order.casesInPallet;
                nsItem.unitPrice = order.unitPrice;
                nsItem.netAmount = order.netAmount;
                nsItem.grossAmount = order.grossAmount;
                nsItem.grossAmountIncludingTax = order.grossAmountIncludingTax;

                nsOrder.items.push(nsItem);

                orderData.push(nsOrder);
              }
            }
          });
          setOrders(orderData);
          // setOrders(res.orders);
        } else {
          setType("warning");
          setMessageKey(!messageKey);
          setLoadMessage("Orders not found! .");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const options = ["Sync from Netsuite", "Load Route"];

  const handleLoadOrder = () => {
    console.info(`You clicked load order`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={10} xl={8}>
          <Stack direction={{ xs: "column", sm: "row" }} gap="10px">
            {/* <DatePicker label={"Noor"} value={deliveryDate} format={"dd-MM-yyyy"} onChange={(date) => {
          setDeliveryDate(date)
        }} className={classes.dateField} /> */}

            {/* <InputBaseField
          type='date'
          id='deliveryDate'
          name='deliveryDate'
          value={deliveryDate}
          onchange={(e) => { setDeliveryDate(e.target.value) }}
        /> */}

            {/* <DatePickerField
              todayButton="Today"
              selected={deliveryDate}
              onChange={(date) => setDeliveryDate(date)}
            /> */}
            <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: 160}}
               value={deliveryDate ? dayjs(deliveryDate) : null}
               onChange={(_, dateStr) => {
                setDeliveryDate(dateStr);
              }}
               />
            <FormButton type="sync" onClick={syncDataFromNetsuite}>
              {" "}
              Sync Orders from Netsuite
            </FormButton>
          </Stack>
        </Grid>
      </Grid>
      {/* <Button variant="contained" onClick={syncDataFromNetsuite}> Sync Orders from Netsuite</Button> */}
      {/* <React.Fragment>
        <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
          <Button onClick={selectedIndex == 0 ? syncDataFromNetsuite : handleLoadOrder}>{options[selectedIndex]}</Button>
          <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment> */}
      <Box sx={{ width: "100%" }}>
        <br />
        {orders.length > 0 && (
          <Routes orders={orders} deliveryDate={deliveryDate} />
        )}
      </Box>
      <Message key={messageKey} type={type} msg={loadMessage} />
    </div>
  );
}
