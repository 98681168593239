import * as React from 'react';

import { SortableContainer, SortableHandle, SortableElement, arrayMove } from 'react-sortable-hoc'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import { OtherHouses } from '@mui/icons-material';
import { netsuite_url } from '../../Components/config';
import _ from "lodash";
const DragHandle = SortableHandle(({ style, index }) => (
    <span style={{ ...style, ...{ cursor: 'move'} }}> {':::: '+index} </span>)
)

const TableBodySortable = SortableContainer(({ children, displayRowCheckbox }) => (
    <TableBody displayRowCheckbox={displayRowCheckbox} >
        {children}
    </TableBody >
))

TableBodySortable.muiName = 'TableBody'


const useStyles = makeStyles((theme) => ({
    dateField: {
        marginRight: "10px"
    },
    tblDiv: {
        width: "50%",
        float: "left",

    },
    tbl: {
        height: "425px",
        overflowY: "auto",
        // marginTop: "10px"
    },
    mapDiv: {
        float: "right",
        width: "48%"
    },
    tblRow: {
        // cursor: "pointer"
    },
    chkBox: {
        margin: "0px",
        padding: "0px !important"
    }
}));
export default function DraggableTable(props) {
    const classes = useStyles();
    const [orders, setOrders] = React.useState(props.orders);
    const [totalWeight, setTotalWeight] = React.useState(0);

    React.useEffect(() => {
        setOrders(props.orders);

        let tWeight = 0;
        props.orders.map((order) => {
            tWeight += Number(order.orderWeight);
        });
        setTotalWeight(tWeight);
    }, [props.orders])

    const hanldeDeleteRow = (id) => {
        props.handleRemoveOrderFromRoute(props.rIdx, id);
    }

    // Handler for traversing completion, helper arrayMove is used
    const onSortEnd = ({ oldIndex, newIndex, rIdx }) => {

        const orders1 = arrayMove(orders, oldIndex, newIndex);
        setOrders(orders1);

        props.orderSort(props.rIdx, orders1);
    };

    const Row = SortableElement(({ data, idx }) => {

        return (
            <TableRow key={data.id} className={classes.tblRow}>
                <TableCell style={{ padding: "3px" }} >
                {props.status == 1 ? (<DragHandle index={idx} />) : idx+"."}
                </TableCell>
                <TableCell >{data.shipZip}</TableCell>
                <TableCell >{data.customerOpeningTime}:00</TableCell>
                <TableCell >{data.customerProjectId}</TableCell>
                <TableCell ><a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${data.customerInternalId}`} target="_blank">{data.customerName}</a></TableCell>
                <TableCell ><a href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${data.invInternalId}`} target="_blank">{data.invoiceNo}</a></TableCell>
                <TableCell ><a href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${data.internalId}`} target="_blank">{data.saleOrderNo}</a></TableCell>
                <TableCell >{data.orderWeight.toFixed(2)} KG</TableCell>
                <TableCell >{props.status == 1 && <CloseIcon style={{ float: "right", cursor: "pointer" }} onClick={() => { hanldeDeleteRow(data.internalId) }} />}</TableCell>
            </TableRow>
        )
    })

    return (
        <>
            {/* <p style={{float: "right"}}> (Total Orders: {orders.length}) </p> */}
            <TableContainer component={Paper} className={classes.tbl}>
                <Table aria-label="Routes" size="small" dense  >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Postcode</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Opening Time</TableCell>
                            <TableCell style={{ fontWeight: "bold", width: "350px" }}>Customer Name</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Shop Code</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Invoice</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Order</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Weight</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBodySortable onSortEnd={onSortEnd} useDragHandle displayRowCheckbox={false}>

                        {orders.length > 0 && orders.map((row, index) => {
                            return (
                                <Row
                                    index={index}
                                    idx={index+1}
                                    key={row.invoiceNo}
                                    data={row}
                                />
                            )
                        })}
                    </TableBodySortable>
                </Table>
            </TableContainer>
        </>)
}