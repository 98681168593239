import React, { useEffect, useLayoutEffect, useState } from "react";
import { Autocomplete, Box, Grid, IconButton, Modal, Pagination, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AuthService from "../../Components/auth/AuthService";
import { useNavigate } from "react-router-dom";
import {
  hideLoading,
  showLoading,
  useLoadingDispatch,
} from "../../context/loadingContext";
import { Button, Table, DatePicker as AntDate, Tag } from "antd/es";
import { CloseCircleOutlined, DeleteFilled, DownloadOutlined, EditFilled, ExclamationCircleOutlined, HistoryOutlined, PlusCircleFilled } from "@ant-design/icons";
// import DatePicker from "react-date-picker";
import moment from "moment";
import { discountStatus, driverTypes, vehicleDueOptions } from "../../Components/common/constants";
import Message from "../../Components/common/Message";
import DiscountModal from "../../Components/Modals/DiscountModal";
import config from "../../Components/config";
import dayjs from "dayjs";
const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  tblTopDiv: {},
  tblsearch: {
    display: "flex",
    float: "left",
  },
  addIcon: {
    float: "right",
  },
  dateField: {
    width: "100%",
    marginRight: "10px",
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "thin solid #b6babf",
      borderRadius: '5px',
    }
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
  antAddIcon: {
    "& .anticon-plus-circle:hover": {
      color: "#4096ff !important"
    }
  },
}));

const Auth = new AuthService();

export default function ContactReport() {

  const classes = useStyles();
  const navigate = useNavigate();
  var layoutDispatch = useLoadingDispatch();

  const [page, setPage] = React.useState(1);
  const [pageChange, setPageChange] = React.useState(false);
  const [limit] = React.useState(50);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(null);

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [allFuelRecords, setAllFuelRecords] = React.useState([]);
  const [allDrivers, setAllDrivers] = React.useState([]);
  const [selectedDriver, setSelectedDriver] = useState("");
  const [selectedDueStatus, setSelectedDueStatus] = useState("");


  const currentDate = new Date();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(lastDayOfMonth);

  const getStatusInfo = (date) => {
    const daysDifference = date.diff(moment(), "days");
  
    if (daysDifference < 0) {
      return {
        color: "#ff002a",
        text: "Overdue",
        icon: <CloseCircleOutlined />,
      };
    } else if (daysDifference <= 30) {
      return {
        color: "#ec942c",
        text: "Due Soon",
        icon: <ExclamationCircleOutlined />,
      };
    } else {
      return {
        color: "#339900",
        text: "Upcoming",
        icon: <HistoryOutlined />,
      };
    }
  };

  const fuelCol = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 135,
    },
    {
      title: 'Driver',
      render: (record) => {
        const driver = allDrivers?.filter((obj) => obj.id == record?.id)[0]
          return (
            <>
            <a onClick={() => navigate(`/drivers/${record?.id}`)}>{record?.driver_name}</a>
            </>
          )
      },
    },
    {
      title: 'Due Date',
      render: (record)=> {
        return (
          <>
          <span>{moment(record.due_date, "YYYY-MM-DD").format("YYYY-MM-DD")}</span>
        </>
        )
      }
    },
    {
      align: "center",
      title: "Status",
      render: ({ due_date }) => {
        const { color, text, icon } = getStatusInfo(moment(due_date, "YYYY-MM-DD"));
        return (
          <Tag color={color} icon={icon} style={{ marginLeft: '4px' }}>
            {text}
          </Tag>
        );
      },
    },
    // {
    //   title: 'Email',
    //   dataIndex: "email",
    //   key: "email",
    // },
    {
      title: 'Phone',
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: 'Driver Type',
      render: (record) => {
        const driver = driverTypes?.filter((obj) => obj.id == record?.driver_type)[0]
          return (
            <>{driver?.title}</>
          )
      },
    },
  ];

  const search = (download) => {
    let search = {};
    if (startDate) search.start_date = moment(startDate).format("YYYY-MM-DD");
    if (endDate) search.end_date = moment(endDate).format("YYYY-MM-DD");
    if (selectedDriver) search.driver_id = selectedDriver.id;
    if (selectedDueStatus) search.status = selectedDueStatus.id;
    if (page && pageChange) search.page = page;

    if (download) search.download = 1;


    // showLoading(layoutDispatch);
    setIsLoading(true)
    Auth.fetch("/report/driver-license-checks", {
      method: "POST",
      body: JSON.stringify(search),
    }).then((res) => {
    //   hideLoading(layoutDispatch);
    setIsLoading(false)
      if (res?.ack) {
        if (download) {
          window.open(res.file_url, "_blank", "noreferrer");
        } else {
          setAllFuelRecords(res?.driver_license_checks);
          setTotalPages(Math.ceil(res.total_count / limit));
  setTotalCount(res.total_count);

          setLoadMessage(res?.message)
          setType('success')
        }
       
      } else {
        setAllFuelRecords([]);
        setLoadMessage(res?.message)
        setType('error')
      }
    });
  };

  const handleChange = (event, value) => {
    setPage(value);
    setPageChange(true)
  };

  useEffect(() => {
    const searchTimeout = setTimeout(() => search(), 1000);

    return () => {
        clearTimeout(searchTimeout);
    };
}, [startDate, endDate, selectedDriver, selectedDueStatus, page]);

const getAllDrivers = async () => {
  showLoading(layoutDispatch);
  try {
    const response = await Auth.fetch(`/driver/drivers`);
    if (response) {
      hideLoading(layoutDispatch);
      if (response?.ack) {
        setAllDrivers(response.drivers);
      }
    }
  } catch (err) {
    console.log(err);
  }
}

useLayoutEffect(()=> {
  getAllDrivers()
},[])


  const updateFilters = (newValues) => {
    const existingFiltersString = localStorage.getItem('driverReportFilters');
    const existingFilters = existingFiltersString ? JSON.parse(existingFiltersString) : {};
    Object.assign(existingFilters, newValues);
    existingFilters.timestamp = new Date().getTime();
    const updatedFiltersString = JSON.stringify(existingFilters);
    localStorage.setItem('driverReportFilters', updatedFiltersString);
  };

  const getFiltersOrDefault = () => {
    const existingFiltersString = localStorage.getItem('driverReportFilters');
    const existingFilters = existingFiltersString ? JSON.parse(existingFiltersString) : {};
    const currentTime = new Date().getTime();
    const expirationTime = 30 * 60 * 1000;
    if (existingFilters.timestamp && currentTime - existingFilters.timestamp < expirationTime) {

      if (!existingFilters.status) {
        existingFilters.status = '';
      }
      return existingFilters;
    } else {
      localStorage.removeItem('driverReportFilters');
      return {};
    }
  };

  useEffect(() => {
    const initialState = getFiltersOrDefault();
    setSelectedDriver(initialState?.vehicle);

    if (!initialState?.startDate) {
      setStartDate(firstDayOfMonth);
      initialState.startDate = firstDayOfMonth

    } else {
      setStartDate(initialState.startDate);
    }
    if (!initialState?.endDate) {
      setEndDate(lastDayOfMonth);
      initialState.endDate = lastDayOfMonth

    } else {
      setEndDate(initialState.endDate);
    }
    initialState.timestamp = new Date().getTime();
    updateFilters(initialState)
  }, []);

  return (
    <div>
      <h2 style={{marginBottom: '10px', marginTop: 0}}>Driver Contact Report</h2>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={8}>
        </Grid>
        <Grid item xs={4}>
        <Button
            style={{ float: "right" }}
            type="primary"
            size='large'
            icon={<DownloadOutlined />}
            onClick={() => { search(1) }}
          >
            Download
          </Button>
        </Grid>
      </Grid>
      <Grid container rowSpacing={2} columnSpacing={1.5}>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="pcnDate">Start Date:</label>
          <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={startDate ? dayjs(startDate) : null}
               onChange={(_, dateStr) => {
                setStartDate(dateStr);
                updateFilters({ startDate: dateStr })
                setPageChange(false)
                setPage(1)
              }}
              placeholder="Select Date" 
               />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="pcnDate">End Date:</label>
          <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={endDate ? dayjs(endDate) : null}
               onChange={(_, dateStr) => {
                setEndDate(dateStr);
                updateFilters({ endDate: dateStr })
                setPageChange(false)
                setPage(1)
              }}
              placeholder="Select Date" 
               />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="route">Driver</label>
          <Autocomplete
            id="driver"
            size="small"
            options={allDrivers}
            getOptionLabel={(option) => option.driver_name ? option.driver_name : ''}
            value={selectedDriver}
            onChange={(e, values) => {
              setSelectedDriver(values);
              setPageChange(false)
              setPage(1)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Driver"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
          <label htmlFor="Status">Status</label>
          <Autocomplete
            id="dueStatus"
            size="small"
            options={vehicleDueOptions}
            getOptionLabel={(option) => option.title ? option.title : ''}
            value={selectedDueStatus}
            onChange={(e, values) => {
              setSelectedDueStatus(values);
              setPageChange(false)
              setPage(1)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Status"
              />
            )}
          />
        </Grid>
      </Grid>

      <div className={classes.antTable}>
        <Table
          columns={fuelCol}
          style={{ overflowX: "auto", marginTop: '10px' }}
          pagination={false}
          // bordered
          dataSource={allFuelRecords}
          loading={isLoading}
          // footer={() => `List of Driver Contact Records - Total Records: ${allFuelRecords?.length === 0 ? "0" : allFuelRecords?.length}`}
          footer={() => {
            const startRecord = (page - 1) * limit + 1;
            const endRecord = Math.min(page * limit, allFuelRecords.length ? totalCount : 0); 
            return `Showing ${startRecord}-${endRecord} of ${allFuelRecords.length ? totalCount : "0"} Records`;
          }}
        />
      </div>

      <div style={{ float: "right", margin: "10px" }}>
        <Pagination
          page={page}
          count={totalPages}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </div>
      <Message type={type} msg={loadMessage} />
    </div>
  );
}
