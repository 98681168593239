import React, { useEffect, useRef, useState } from "react";
import AuthService from "../../Components/auth/AuthService";
import DatePicker from "react-date-picker";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { Box, Grid } from "@mui/material";
import Message from "../../Components/common/Message";
import { FormButton } from "../../Components/UiElements/UiElements";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";

import {tms_uploads_cdn, env, google_maps_key, netsuite_url} from "../../Components/config"

import DriverSheetReport from "./DriverSheetReport";
import GoodsReturnReport from "./GoodsReturnReport";
import StockDiscrepanciesReport from "./StockDiscrepanciesReport";
import { Divider, Select, Button as AntButton, Result, Collapse, Row, Col, Card, Switch, Descriptions, Tag, Image, Table, Modal, Badge, DatePicker as AntDate, message } from "antd";
import { deliveryStatuses, userRolesTypes, vehicleDefects } from "../../Components/common/constants";
import CashUpReport from "./CashUpReport";
import FinancialSummaryReport from "./FinancialSummaryReport";
import AmountDiscrepanciesReport from "./AmountDiscrepanciesReport";
import { useSelector } from "react-redux";
import { CheckOutlined, CloseCircleOutlined, CloseOutlined, EditFilled, EditOutlined, WarningOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { GoogleMap, InfoWindow, LoadScript, Marker, Polyline, useJsApiLoader } from "@react-google-maps/api";
import BlueMarker from '../../assets/images/BlueMarker.png'
import TruckMarker from '../../assets/images/MagnaTruck.png'
import { LocalShipping } from "@mui/icons-material";
const { Option } = Select;

const useStyles = makeStyles((theme) => ({
  
  dataSheet: {
    "& .ant-checkbox .ant-checkbox-inner": {
      width: "36px",
      height: "36px"
    },
    "& .ant-checkbox+span": {
      fontSize: '16px',
      paddingTop: '6px'
    },
    "& .ant-checkbox-checked .ant-checkbox-inner:after": {
      width: '10px',
      height: '22px'
    },
    "& .ant-switch .ant-switch-inner": {
      transition: 'padding-inline-start 0.0s ease-in-out, padding-inline-end 0.0s ease-in-out;'
    }
  },
  dateField: {
    marginRight: "10px",
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "thin solid #b6babf",
      borderRadius: '5px',
    },
    "& .react-date-picker__button svg ": {
      stroke: "#b6babf",
    }
  },
  selectStyle: {
    "& .ant-select .ant-select-selector": {
      borderBottom: '1px solid #d9d9d9',
      borderTop: '0px',
      borderLeft: '0px',
      borderRight: '0px',
      borderRadius: '0px'
    }
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
}));


const Auth = new AuthService();
export default function LocationReport() {

  const { roles } = useSelector((state) => state.AppState?.userTypes);
  const UserType = useSelector((state) => state.AppState?.userTypes?.type);
  const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const [deliveryDate, setDeliveryDate] = React.useState(new Date());
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [routes, setRoutes] = React.useState([]);
  const [selectedDriverRoute, setSelectedDriverRoute] = useState(null);


  const [isHomeInfoOpen, setIsHomeInfoOpen] = useState(false);
  const [routeHoveredMarker, setRouteHoveredMarker] = useState(null);

  const [routeClickedMarker, setRouteClickedMarker] = useState(null);
  const [driverClickedMarker, setDriverClickedMarker] = useState(null);

  const [routeOrders, setRouteOrders] = useState([]);
  const [driverLocations, setDriverLocations] = useState([]);
  const [routeOrdersPolyLine, setRouteOrdersPolyLine] = useState([]);
  const [driverOrdersPolyLine, setDriverOrdersPolyLine] = useState([]);

 const mapsCheck = window.google

  const { Panel } = Collapse;
  const { Option } = Select;


  const matchedRoles = roles
    .map((role) => {
      return userRolesTypes.find((userRole) => userRole.id === role.role_id);
    })
    .filter(Boolean);
  const includesFinance = matchedRoles.some(item => item.title === "Finance")
  const includesRouting = matchedRoles.some(item => item.title === "Routing")
  const includesWareHouse = matchedRoles.some(item => item.title === "Warehouse User")
  const includesWareHouseManager = matchedRoles.some(item => item.title === "Warehouse Manager")

  useEffect(() => {
    message.config({
      top: window.innerHeight - 100, 
      duration: 3, 
    });
  }, [])


  const loadRoutes = async () => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
    setSelectedDriverRoute(null)
    setRoutes([]);

    showLoading(layoutDispatch)
    try {
        const routeRes = await Auth.post(`/route/load-locked-routes`, {
        delivery_date: moment(deliveryDate).format("YYYY-MM-DD"),
      })
      if(routeRes.ack) {
        if(routeRes?.routes.length > 0) {

          setRoutes(routeRes?.routes)
        } else {
          setRoutes([])
          message.error('No Route Found')
        }
      }
    } catch (err) {
      console.log(err);
      message.error('Something went wrong')
    } finally {
      hideLoading(layoutDispatch)
    }
  }

  const getRouteOrderLocations = async (routeID) => {
    showLoading(layoutDispatch)
    const DataToSend = {
      route_status_id: routeID
    }
    try {
      const res = await Auth.fetch("/report/route-orders-location", {
        method: "POST",
        body: JSON.stringify(DataToSend),
      })
      if(res?.ack) {
        console.log('LocationsRoute', res);
        const validRouteOrders = res?.route_orders.filter(item => item.latitude && item.longitude);
        setRouteOrders(validRouteOrders)
        const routeOrdersPolyLine = [
          center,
          ...validRouteOrders.filter(item => item.latitude && item.longitude).map(item => ({
            lat: item.latitude,
            lng: item.longitude,
          })),
          center
        ];
        
        setRouteOrdersPolyLine(routeOrdersPolyLine)
      } else {
        setRouteOrders([])
      }

    } catch (err) {
      console.log(err);
    } finally {
      hideLoading(layoutDispatch)
    }
  }

  const getDriverLocations = async (routeID) => {
    showLoading(layoutDispatch)
    const DataToSend = {
      route_status_id: routeID
    }
    try {
      const res = await Auth.fetch("/report/driver-live-location", {
        method: "POST",
        body: JSON.stringify(DataToSend),
      })
      if(res?.ack) {
        console.log('LocationsDriver', res);
        const validLocations = res?.driver_locations.filter(item => item.latitude && item.longitude);
        setDriverLocations(validLocations)
        const driverPolyLine = [
          center,
          ...validLocations.filter(item => item.latitude && item.longitude).map(item => ({
            lat: item.latitude,
            lng: item.longitude,
          })),
        ];
        setDriverOrdersPolyLine(driverPolyLine)
      } else {
        setDriverLocations([])
      }

    } catch (err) {
      console.log(err);
    } finally {
      hideLoading(layoutDispatch)
    }
  }

  const getCorespondingData = (routeID) => {
    const obj = routes?.find((item)=> item.id === routeID)
    console.log('obj',obj);
    
    getRouteOrderLocations(routeID)
    getDriverLocations(routeID)
  }

  const center = {
    lat: 51.48665772344187,
    lng: -0.3971688021055391
  };

  const centerCheck = {
    lat: 51.48665772344187,
    lng: -0.3971688021055391
  };

  const containerStyle = {
    width: '100%',
    height: '400px',
    marginTop: '50px'
  };

  return (
    <div>
      <h2 style={{marginBottom: '10px', marginTop: 0}}>Location Report</h2>
        <Grid
          container
          spacing={2}
          sx={{ alignItems: "flex-end", display: "flex" }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: 160}}
               size="large"
               value={deliveryDate ? dayjs(deliveryDate) : null}
               onChange={(_, dateStr) => {
                setDeliveryDate(dateStr);
              }}
              placeholder="Select Date"
              />
              <AntButton type="primary" size="large" onClick={loadRoutes} >Load Routes</AntButton>

            </Box>
          </Grid>
        </Grid>

        {routes.length > 0 && (
          <div className={classes.selectStyle}>
          <Select
            style={{
              width: "75%",
              margin: "2px",
              fontSize: '14px',
              marginTop: '15px'
            }}
            // allowClear
            showSearch
            placeholder="Select Route"
            onChange={(value) => {
              setSelectedDriverRoute(value);
              getCorespondingData(value)
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {routes.slice().sort((a, b) => a.title.localeCompare(b.title)).map((item) => (
              <Option key={item.id} value={item.id}>
                {`${item.title} (${item.code}) - ${item.registration_number} - ${item.driver_name} `}
              </Option>
            ))}
          </Select>
        </div>
        )}

        {
          selectedDriverRoute && (
            <LoadScript googleMapsApiKey={google_maps_key}>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={8}
            >
              <Marker position={center} icon={`/assets/images/theme/magna.png`}
              onMouseOver={() => setIsHomeInfoOpen(true)}
              onMouseOut={() => setIsHomeInfoOpen(false)}
              >
                {isHomeInfoOpen && (
                <InfoWindow>
                <div>
                  <p>Magna Head Office.</p>
                </div>
              </InfoWindow>
                )}
              </Marker>

              {
              routeOrders && routeOrders.map((item, index) => (
                <Marker 
                  key={index} 
                  position={{ lat: item?.latitude, lng: item?.longitude }}
                  // onMouseOver={() => setRouteHoveredMarker(item.rank)}
                  // onMouseOut={() => setRouteHoveredMarker(null)}
                  onClick={() => setRouteClickedMarker(index)}
                  
                  label={{
                    text: `${item?.rank}`,  
                    color: "white",  
                    fontSize: "14px", 
                    fontWeight: "bold",
                  }}
                >
                  {routeClickedMarker === index ? (
                    <InfoWindow onCloseClick={() => setRouteClickedMarker(null)}>
                      <div>
                        <p>{`Rank: ${item?.rank}`}</p>
                        <p> Customer Name: <a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${item.customer_internal_id}`} target="_blank">{item.customer_name}</a></p>
                        <p> {Number(item.inv_internal_id) > 0 ? (<span>Invoice: <a href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${item.inv_internal_id}`} target="_blank">{item.invoice_no}</a></span>) : (<span>Order: <a href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${item.internal_id}`} target="_blank">{item.sale_order_no}</a></span>)}</p>
                        <p>{`Postcode: ${item?.ship_zip}`}</p>
                        <p>{`Opening Hours: ${item?.customer_opening_time}`}</p>
                      </div>
                    </InfoWindow>
                  ) : null}
                </Marker>
              ))
            }
            {
            driverLocations && driverLocations.map((item, index) => (
              <Marker
                key={index}
                position={{ lat: item?.latitude, lng: item?.longitude }}
                onClick={() => setDriverClickedMarker(index)}
                // icon={index === driverLocations.length - 1 ? TruckMarker : {
                //   path: mapsCheck.maps.SymbolPath.CIRCLE,
                //   scale: 4, 
                //   fillColor: "red",
                //   fillOpacity: 1,
                //   strokeWeight: 0
                // }} 
                icon={
                  index === driverLocations.length - 1
                    ? TruckMarker
                    : window.google && window.google.maps
                    ? {
                        path: window.google.maps.SymbolPath.CIRCLE,
                        scale: 4,
                        fillColor: "red",
                        fillOpacity: 1,
                        strokeWeight: 0,
                      }
                    : null
                }>
                  {driverClickedMarker === index ? (
                    <InfoWindow onCloseClick={() => setDriverClickedMarker(null)}>
                      <div>
                        <p>{`Time: ${moment(item?.createdAt).format('HH:MM:ss')}`}</p>
                      </div>
                    </InfoWindow>
                  ) : null}
                </Marker>
              
            ))
          }

              <Polyline 
              path={routeOrdersPolyLine}
              geodesic={true}
              options={{
                strokeColor: "#FF0000",
                strokeOpacity: 1.0,
                strokeWeight: 3,
              }}
            />

            <Polyline 
              path={driverOrdersPolyLine}
              geodesic={true}
              options={{
                strokeColor: "#1677ff",
                strokeOpacity: 1.0,
                strokeWeight: 3,
              }}
            />
            </GoogleMap>
           </LoadScript>
          )
        }

        <Message type={type} msg={loadMessage} />
    </div>
  );
}
