import * as React from "react";
import {
  AppBar,
  Avatar,
  Stack,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  MenuItem,
  Menu,
  Tooltip,
} from "@mui/material";
//icons and images

import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import AppLogo from "../../assets/images/PrefectosLogos.png";
import UserIcon from "../../assets/images/icons/headerIccon/profile.png";
import MenuOpenIcon from "../../assets/images/icons/menu-open.svg";
import MenuCloseIcon from "../../assets/images/icons/menu-close.svg";
import NotificationIcon1 from "../../assets/images/icons/headerIccon/bell-ring.png";
import LogOut from "../../assets/images/icons/headerIccon/shutdown.png";
import { styled } from "@mui/material/styles";
import AuthService from "../auth/AuthService";
import {
  toggleSidebar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";
import { appTheme } from "../Utils";
import { LoginOutlined } from "@mui/icons-material";

const Appbar = styled(AppBar)(({ theme }) => ({
  height: "64px",
  width: "100%",
  minHeight: "64px",
  boxShadow: "context",
  // background: "#001529",
  background: "#00467a"
}));
const ToolBar = styled(Toolbar)(({ theme }) => ({
  minWidth: "100%",
  height: "100%",
}));
const Applogo = styled("img")(({ theme }) => ({
  width: "180px",
  height: "40px",
  marginRight: "30px",
}));
const MenuIcon = styled("img")(({ theme }) => ({
  width: "18px",
  color: theme.palette.primary.main,
}));
export default function Header() {
  const Auth = new AuthService();
  var layoutDispatch = useLayoutDispatch();
  const { isSidebarOpen } = useLayoutState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [screenWidth, setScreenWidth] = React.useState("");
  //=================For Mobial Case Show A Menue==================

  const open = Boolean(anchorEl2);
  const handleClick = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl2(null);
  };
  // Update the screen width when the window is resized
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  React.useEffect(() => {
    setScreenWidth(window.innerWidth);
    // Attach the resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    if(window.innerWidth < 768) toggleSidebar(layoutDispatch, false)
  }, [window.innerWidth]);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );
  const handleLogout = () => {
    Auth.logout();
  };

  return (
    <>
      <Appbar position="fixed">
        <ToolBar>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%", height: "100%" }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                width: "210px",
                // backgroundColor: appTheme.header.bgColor1,
                marginLeft: "-24px",
                paddingLeft: "16px",
              }}
            >
              <Applogo src={AppLogo} alt="app-logo" />
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{
                  padding: "4px",
                  background: "white",
                  // border: "1px solid #E2E2E2",
                  borderRadius: "5px",
                  height: "30px",
                  ml: "5px",
                  "&:hover": {
                    backgroundColor: "#e3f3f3",
                  },
                }}
                onClick={() => toggleSidebar(layoutDispatch, !isSidebarOpen)}
              >
                {isSidebarOpen ? (
                  <MenuIcon src={MenuCloseIcon} />
                ) : (
                  <MenuIcon src={MenuOpenIcon} />
                )}
              </IconButton>
            </Box>
            {screenWidth <= 600 && (
              <>
                <Button
                  id="fade-button"
                  aria-controls={open ? "fade-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <Avatar src={UserIcon} sx={{ margin: "0 15px" }} />
                </Button>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEl2}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={handleClose}>
                    <Tooltip title="Notifications">
                      <IconButton
                        size="large"
                        aria-label="show 17 new notifications"
                        color="inherit"
                      >
                        <Badge badgeContent={0} color="error">
                          <img
                            src={NotificationIcon1}
                            style={{
                              width: "24px",
                            }}
                          />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      handleLogout();
                    }}
                  >
                    <Tooltip title="Logout">
                      <IconButton
                        className="logout-icon"
                        size="large"
                        onClick={() => {
                          handleClose();
                          handleLogout();
                        }}
                        // edge="end"
                        // aria-label="account of current user"
                        // aria-controls={menuId}
                        // aria-haspopup="true"
                        // onClick={handleProfileMenuOpen}
                        // color="inherit"
                      >
                        {/* <img
                          src={LogOut}
                          style={{
                            width: "28px",
                          }}
                        /> */}
                        <LoginOutlined sx={{ color: "white"}} />
                      </IconButton>
                    </Tooltip>
                  </MenuItem>
                </Menu>
              </>
            )}
            {screenWidth > 600 && (
              <Box sx={{ display: "flex" }}>
                <Stack direction="row">
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ padding: "0 15px" }}
                  >
                    <Avatar src={UserIcon} sx={{ margin: "0 15px" }} />
                    <Stack
                      direction="column"
                      justifyContent="space-evenly"
                      sx={{ height: "100%" }}
                    >
                      <Typography variant="h4" sx={{ lineHeight: "32px" }}>
                        {undefined}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ color: 'white' }}
                      >
                        {localStorage.getItem("logged_in_user_name")}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Tooltip title="Notifications">
                    <IconButton
                      size="large"
                      aria-label="show 17 new notifications"
                      color="inherit"
                    >
                      <Badge badgeContent={0} color="error">
                        <img
                          src={NotificationIcon1}
                          style={{
                            width: "24px",
                          }}
                        />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Logout">
                    <IconButton
                      size="large"
                      onClick={handleLogout}
                      // edge="end"
                      // aria-label="account of current user"
                      // aria-controls={menuId}
                      // aria-haspopup="true"
                      // onClick={handleProfileMenuOpen}
                      // color="inherit"
                    >
                      {/* <img src={LogoutIcon} alt="notification" /> */}
                      {/* <LogoutIcon
                        sx={{
                          color: appTheme.header.headerTextColor,
                        }}
                      /> */}
                      {/* <img
                        src={LogOut}
                        style={{
                          width: "24px",
                        }}
                      /> */}
                        <LoginOutlined sx={{ color: "white"}}/>
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Box>
            )}
          </Stack>
        </ToolBar>
      </Appbar>
      {renderMenu}
    </>
  );
}
