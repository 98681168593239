import * as React from "react";

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import AuthService from "../../Components/auth/AuthService";
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from "@mui/system";
import Message from "../../Components/common/Message";
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { FormButton } from "../../Components/UiElements/UiElements";
import { useLoadingDispatch, showLoading, hideLoading } from '../../context/loadingContext';
import { locationTypes, yesNo } from "../../Components/common/constants";

import moment from "moment";
import DatePicker from "react-date-picker";

const Auth = new AuthService();

export default function Location(props) {

  const navigate = useNavigate();
  const params = useParams();
  var layoutDispatch = useLoadingDispatch();

  const [id, setId] = React.useState(params.id ? params.id : 0);

  const [code, setCode] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [locationType, setLocationType] = React.useState({ id: "", title: "" });
  const [palletCapacity, setPalletCapacity] = React.useState("");
  const [height, setHeight] = React.useState("");
  const [width, setWidth] = React.useState("");
  const [isTransit, setIsTransit] = React.useState({ id: "", title: "" });

  const [aisle, setAisle] = React.useState({ id: "", title: "" });
  const [aisles, setAisles] = React.useState([]);


  const [codeError, setCodeError] = React.useState("");
  const [titleError, setTitleError] = React.useState("");
  const [aisleError, setAisleError] = React.useState("");
  const [locationTypeError, setLocationTypeError] = React.useState("");
  const [palletCapacityError, setPalletCapacityError] = React.useState("");
  const [heightError, setHeightError] = React.useState("");
  const [widthError, setWidthError] = React.useState("");
  const [isTransitError, setIsTransitError] = React.useState("");

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    Auth.fetch(`/aisle/aisles`)
      .then((res1) => {
        if (res1.ack) {
          const aisles1 = res1.aisles;
          setAisles(aisles1);

          if (id > 0) {
            showLoading(layoutDispatch);
            Auth.fetch(`/location/location?id=${id}`)
              .then((res) => {
                hideLoading(layoutDispatch);
                if (res.ack) {
                  setCode(res.location.code);
                  setTitle(res.location.title);

                  const aisle1 = aisles1.find(obj => obj.id == res.location.aisle_id);
                  setAisle(aisle1);

                  const locationType1 = locationTypes.find(obj => obj.id == res.location.type);
                  setLocationType(locationType1);

                  setPalletCapacity(res.location.pallet_capacity);
                  setHeight(res.location.height);
                  setWidth(res.location.width);

                  const isTransit1 = yesNo.find(obj => obj.id == res.location.is_transit);
                  setIsTransit(isTransit1);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });


  }, []);

  const onClickCancel = () => {
    navigate("/locations");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (code === "") {
      setCodeError("Required");
      return;
    } else {
      setCodeError("");
    }
    if (title === "") {
      setTitleError("Required");
      return;
    } else {
      setTitleError("");
    }

    if (aisle == undefined || aisle.id == undefined || aisle.id == "") {
      setAisleError("Required");
      return;
    } else {
      setAisleError("");
    }

    if (locationType == undefined || locationType.id == undefined || locationType.id == "") {
      setLocationTypeError("Required");
      return;
    } else {
      setLocationTypeError("");
    }

    if (palletCapacity === "") {
      setPalletCapacityError("Required");
      return;
    } else {
      setPalletCapacityError("");
    }

    if (width === "") {
      setWidthError("Required");
      return;
    } else {
      setWidthError("");
    }

    if (height === "") {
      setHeightError("Required");
      return;
    } else {
      setHeightError("");
    }

    if (isTransit === "") {
      setIsTransitError("Required");
      return;
    } else {
      setIsTransitError("");
    }

    if (id > 0) {
      showLoading(layoutDispatch);
      Auth.fetch("/location/location", {
        method: "PUT",
        body: JSON.stringify({
          title: title,
          code: code,
          type: locationType,
          aisle_id: aisle,
          pallet_capacity: palletCapacity,
          height: height,
          width: width,
          is_transit: isTransit,
          id: id
        }),
      })
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/locations");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLoading(true);
      showLoading(layoutDispatch);
      Auth.fetch("/location/location", {
        method: "POST",
        body: JSON.stringify({
          title: title,
          code: code,
          type: locationType,
          aisle_id: aisle,
          pallet_capacity: palletCapacity,
          height: height,
          width: width,
          is_transit: isTransit,
        }),
      })
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/locations");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChangeAisle = (event, values) => {
    console.log(values)
    setAisle(values)
  }

  const onChangeLocationType = (event, values) => {
    console.log(values)
    setLocationType(values)
  }

  const onChangeIsTransit = (event, values) => {
    console.log(values)
    setIsTransit(values)
  }


  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "100%" },
      }}
      noValidate
      autoComplete="off"
    >
      <h1> {id > 0 ? "Update" : "Add"} Location</h1>

      <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>


        <Grid item xs={10} sm={4}>
          <TextField
            value={code}
            onChange={(event) => {
              setCode(event.target.value);
            }}
            label="Code"
            id="code"
            error={codeError === "" ? false : true}
            helperText={codeError}
          />
          <br />
          <TextField
            value={title}
            onChange={(event) => {
              setTitle(event.target.value);
            }}
            label="Title"
            id="title"
            error={titleError === "" ? false : true}
            helperText={titleError}
          />
          <br />
          <Autocomplete
            id="aisle"
            options={aisles}
            getOptionLabel={(option) => option.title}
            value={aisle}
            onChange={onChangeAisle}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Aisle"
                placeholder="Aisle"
                error={aisleError === "" ? false : true}
                helperText={aisleError}
              />
            )}
          />
          <br />
          <Autocomplete
            id="locationType"
            options={locationTypes}
            getOptionLabel={(option) => option.title}
            value={locationType}
            onChange={onChangeLocationType}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Location Type"
                placeholder="Location Type"
                error={locationTypeError === "" ? false : true}
                helperText={locationTypeError}
              />
            )}
          />
          <br />
          <TextField
            type="number"
            value={palletCapacity}
            onChange={(event) => {
              setPalletCapacity(event.target.value);
            }}
            label="Pallet Capacity"
            id="palletCapacity"
            error={palletCapacityError === "" ? false : true}
            helperText={palletCapacityError}
          />
          <br />
          <TextField
            type="number"
            value={width}
            onChange={(event) => {
              setWidth(event.target.value);
            }}
            label="Width"
            id="width"
            error={widthError === "" ? false : true}
            helperText={widthError}
          />
          <br />
          <TextField
            type="number"
            value={height}
            onChange={(event) => {
              setHeight(event.target.value);
            }}
            label="Height"
            id="height"
            error={heightError === "" ? false : true}
            helperText={heightError}
          />
          <br />
          <Autocomplete
            id="isTransit"
            options={yesNo}
            getOptionLabel={(option) => option.title}
            value={isTransit}
            onChange={onChangeIsTransit}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Is Transit"
                placeholder="Is Transit Location"
                error={isTransitError === "" ? false : true}
                helperText={isTransitError}
              />
            )}
          />
          <br />

        </Grid>
      </Grid>
      <Box style={{ float: "right", marginTop: "40px" }}>
        <FormButton
          type="close"
          style={{ marginRight: "10px" }}
          onClick={onClickCancel}
        >
          Cancel
        </FormButton>
        <FormButton type="save" onClick={handleSubmit}>
          Save
        </FormButton>
        {/* <Button
          variant="outlined"
          style={{ marginRight: "10px" }}
          onClick={onClickCancel}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Save
        </Button> */}
      </Box>

      <Message type={type} msg={loadMessage} />
    </Box>
  );
}
