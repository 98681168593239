import React from "react";
import { Card, Col, Divider, Row, Statistic, Table, Switch, Button } from "antd";
import "./reports.css";
import { makeStyles } from "@mui/styles";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";

const colors = ["#f0f0f0", "#e6f7ff", "#faf0e6", "#fcf8e3"];
const Auth = new AuthService();


const useStyles = makeStyles((theme) => ({
  dataSheet: {
    "& .ant-card .ant-card-body": {
      padding: "4px",
      backgroundColor: "rgb(240, 240, 240)",
    },
    "& .ant-statistic .ant-statistic-content": {
      fontSize: "12px",
      fontWeight: "bold",
    },
    "& .ant-statistic .ant-statistic-title": {
      fontSize: "12px",
    },
    "& .example-custom-input": {
      width: "100% !important",
    },
    "& .react-datepicker-wrapper": {
      width: "100% !important",
    },
  },
}));

const FinancialSummaryReport = ({ amountDiscrepanciesData }) => {
  const classes = useStyles();
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");

  const generateColor = (index) => {
    const colors = ["#f0f0f0", "#e6f7ff", "#faf0e6", "#fcf8e3"];
    return colors[index % colors.length];
  };

  const handleSwitchToggle = async (orderPalletID, checked) => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
    const dataToSend = {
      pallet_order_id: orderPalletID,
      cash_approved: checked ? 1 : 0
    }
    console.log('dataToSend', dataToSend)
    try {
      const response = await Auth.fetch("/route/change-cash-approved-status", {
        method: "POST",
        body: JSON.stringify(dataToSend)
      });
      if (response.ack) {
        setType('success')
        setLoadMessage(response.message + str)
      }
    } catch (err) {
      console.log(err)
    }
  }

  console.log("amountDiscrepanciesData", amountDiscrepanciesData);
  const dataCol = [
    {
      title: "S.No.",
      dataIndex: "route",
      key: "routeNo",
    },
    {
      title: "Driver Name",
      dataIndex: "driver",
      key: "driver",
    },
    {
      title: "Truck Reg",
      dataIndex: "truck",
      key: "truck",
    },
    {
      title: "Route Name",
      dataIndex: "driverRoute",
      key: "driverRoute",
    },
    {
      title: "Cash Expected",
      dataIndex: "totalCash",
      key: "totalCash",
      render: (text, record) => 
        `£${Number(record.totalCash).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) || '0.00'}`,
    },
    // {
    //   title: "Cash Counted",
    //   dataIndex: "cashReceived",
    //   key: "cashReceived",
    //   // render: (text, record) => Number(record.cashReceived).toFixed(2) || 0
    //   render: (text, record) => Number(record.cashReceived).toLocaleString(undefined, {
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2
    //   }) || '0.00',
    // },
    {
      title: "Cash Counted",
      dataIndex: "cashReceived",
      key: "cashReceived",
      render: (text, record) => {
        const value = Number(record.cashReceived) || 0;
        const expected = Number(record.totalCash) || 0;
        const isGreen = value > expected;
        const isRed = value < expected;
        const style = {
          color: isGreen ? "green" : isRed ? "red" : "black",
          fontWeight: isGreen || isRed ? "bold" : "normal"
        };
    
        return (
          <span style={ style }>
            {`£${value.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }) || '0.00'}`}
          </span>
        );
      }
    },
    {
      title: "Card Expected",
      dataIndex: "totalCard",
      key: "totalCard",
      render: (text, record) => 
        `£${Number(record.totalCard).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) || '0.00'}`,
    },
    {
      title: "Card Counted",
      dataIndex: "cardReceived",
      key: "cardReceived",
      // render: (text, record) => Number(record.cardReceived).toLocaleString(undefined, {
      //   minimumFractionDigits: 2,
      //   maximumFractionDigits: 2
      // }) || '0.00',
      render: (text, record) => {
        const value = Number(record.cardReceived) || 0;
        const expected = Number(record.totalCard) || 0;
        const isGreen = value > expected;
        const isRed = value < expected;
        const style = {
          color: isGreen ? "green" : isRed ? "red" : "black",
          fontWeight: isGreen || isRed ? "bold" : "normal"
        };
        return (
          <span style={ style }>
            {`£${value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }) || '0.00'}`}
          </span>
        );
      }
    },
  ];

  const convertArrayOfObjectsToCSV = (array) => {
    // Define the field mappings
    const fieldMap = {
      route: 'S No.', // Rename 'Route' to 'Serial Number'
      driver: "Driver Name",
      truck: " Truck Reg.",
      driverRoute: "Route",
      totalCash: "Total Cash",
      totalAccount: 'Total Account',
      totalCard: 'Total Card'

      // Add more field mappings as needed
    };
  
    // Filter out unwanted fields and rename them
    const filteredArray = array.map(obj => {
      const newObj = {};
      Object.keys(obj).forEach(key => {
        if (key !== 'formatted_date' && key !== 'routeStatusID' && key !== 'cashReceived') { // Exclude 'formatted_date' field
          if (fieldMap[key]) {
            newObj[fieldMap[key]] = obj[key]; // Rename field
          } else {
            newObj[key] = obj[key]; // Keep the field as is
          }
        }
      });
      return newObj;
    });
  
    // Generate CSV
    const header = Object.keys(filteredArray[0]).join(',');
    const rows = filteredArray.map(obj => Object.values(obj).join(','));
    return header + '\n' + rows.join('\n');
  };
  

  // Function to trigger the download of the CSV file
  const downloadCSV = () => {
    const csv = convertArrayOfObjectsToCSV(amountDiscrepanciesData);
    const filename = `${amountDiscrepanciesData[0].formatted_date}AmountDis.csv`;
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const totalCashexpected = amountDiscrepanciesData.reduce(
    (accumulator, currentValue) => accumulator + (currentValue.totalCash || 0),
    0
  );
  const totalCashCountedSum = amountDiscrepanciesData.reduce(
    (accumulator, currentValue) =>
      accumulator + (currentValue.cashReceived || 0),
    0
  );
  const totalCardExpected = amountDiscrepanciesData.reduce(
    (accumulator, currentValue) => accumulator + (currentValue.totalCard || 0),
    0
  );
  const totalCardCounted =  amountDiscrepanciesData.reduce(
    (accumulator, currentValue) =>
      accumulator + (currentValue.cardReceived || 0),
    0
  );

  return (
    <div className={classes.dataSheet}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <h3>Amount Discrepancies</h3>
      {
        amountDiscrepanciesData.length > 0 && <Button type="primary" style={{ float: 'right'}} onClick={downloadCSV}>Download Discrepancies</Button>

      }
      </div>
      {amountDiscrepanciesData.length > 0 &&

        <>
          <div className="view-page">
          <div>
  <Row
    gutter={[16, 16]}
    style={{
      background: "#f0f2f5",
      padding: "8px",
      borderRadius: "8px",
    }}
  >
    {/* Cash Section */}
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
      <div
        style={{
          background: "#ffffff",
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Total Cash Expected"
                value={`£${totalCashexpected.toFixed(2) || 0}`}
                valueStyle={{
                  color: "#3f8600",
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Total Cash Counted"
                value={`£${totalCashCountedSum.toFixed(2) || 0}`}

                valueStyle={{
                  color: "#3f8600",
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Cash Difference"
                value={`£${(totalCashCountedSum - totalCashexpected).toFixed(2) || 0}`}

                valueStyle={{
                  color:
                    totalCashexpected - totalCashCountedSum > 0
                      ? "#cf1322"
                      : "#3f8600",
                }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </Col>

    {/* Card Section */}
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
      <div
        style={{
          background: "#ffffff",
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Total Card Expected"
                value={`£${totalCardExpected.toFixed(2) || 0}`}

                valueStyle={{
                  color: "#3f8600",
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Total Card Counted"
                value={`£${totalCardCounted.toFixed(2) || 0}`}

                valueStyle={{
                  color: "#3f8600",
                }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Card>
              <Statistic
                title="Card Difference"
                value={`£${(totalCardCounted - totalCardExpected).toFixed(2) || 0}`}

                valueStyle={{
                  color:
                    totalCardExpected - totalCardCounted > 0
                      ? "#cf1322"
                      : "#3f8600",
                }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </Col>
  </Row>
</div>


            <>
              <Table
                style={{ overflowX: "auto", marginTop: "10px" }}
                columns={dataCol}
                dataSource={amountDiscrepanciesData}
                pagination={false}
              />
            </>
            {/* <Divider style={{ height: '5px', background: 'red'}} /> */}
            <Divider style={{ height: '8px', background: '#006cb8' }} />

          </div>
        </>
      }
      <Message type={type} msg={loadMessage} />
    </div>
  );
};

export default FinancialSummaryReport;
