// For Later Login Page Setup

import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './login.css';
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import AuthService from '../../Components/auth/AuthService';
import Message from '../../Components/common/Message';
import { makeStyles } from "@mui/styles";
import AppLogo from "../../assets/images/prefectosblack.png";
import { useDispatch } from 'react-redux';


const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
    antlabel: {
      "& .ant-form-item-label > label": {
        fontSize: '18px',
        fontWeight: 700,
      },
    },
  }));

function Copyright() {
    return (
      <Typography marginTop={5} variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://prefectos.com/">
          Prefectos
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

function Login() {
  // const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("info");
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch()
  const isAuthenticated = Auth.loggedIn();

  useEffect(() => {
    console.log("load signin ...");
    if (isAuthenticated) return navigate("/dashboard");
  }, []);

  const onFinish = async ({ email, password }) => {
    setLoadMessage("");
    setIsLoading(true)
    try {
    const result = await Auth.login(email, password);
    if (result.ack) {
      localStorage.removeItem('PcnFilters');
      localStorage.removeItem('AccidentFilters');
      localStorage.removeItem('MainReportFilters')
      localStorage.removeItem('MaintenanceListFilters')
      setIsLoading(false)
      dispatch({ type: 'SET_USER_TYPE', payload: result.type })
      if(result.type === 0) {
        dispatch({ type: 'SET_USER_ROLES', payload: result?.roles })
        navigate("/dashboard")
      } else if (result.type === 1) {
        dispatch({ type: 'SET_USER_ROLES', payload: result?.roles })
        navigate("/dashboard");
      } else {
        dispatch({ type: 'SET_USER_ROLES', payload: result?.roles })
        navigate("/dashboard");
      }
    } else {
        setType("error");
        setLoadMessage(result.message);
        // setError(result.status);
        setIsLoading(false)
    }
    } catch (err) {
        setType("error");
        setLoadMessage(err.message);
        // setError(err.status);
        setIsLoading(false)
    }
  };

  return (
    <>
    {/* <div>
        <div className="split left"></div>
        <div className="split right"></div>
    </div> */}
    <div className="main-div">
      <Card bordered className="login-card">
        <div className='img-div'>
        <img height={"80px"} src={AppLogo}/>
        </div>
        <Form layout="vertical" onFinish={onFinish} autoComplete="off">
        <div className={classes.antlabel}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please input your email',
              },
            ]}
          >
            <Input size='large' prefix={<UserOutlined />} placeholder="Email Address" />
          </Form.Item>
          </div>
        <div className={classes.antlabel}>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password',
              },
            ]}
          >
            <Input.Password size='large' prefix={<LockOutlined />} type="password" placeholder="Password" />
          </Form.Item>
          </div>
          <Form.Item className='button-container'>
            <Button loading={isLoading} style={{height: '40px', width: '120px', padding: '5px', fontSize:"16px" }} size='large' type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
        <Copyright />
      </Card>
    </div>
    <Message type={type} msg={loadMessage} />
    </>
  );
}
export default Login;
