import React, { forwardRef } from 'react';
import { InputAdornment, Stack, FormControlLabel, Radio, Autocomplete, TextField } from '@mui/material';
import { FormInputLabel, Input, CheckBox, RadioBoxGroup, CustomSelect,AutoCompleteInput,StyledDatePicker } from './styles';
import { Search as SearchIcon } from '@mui/icons-material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './styles.css';
const InputBaseField = ({ id, name, value, label, placeholder, isDisabled, StartIcon, EndIcon, onChange, type = "text", ...props }) => {

    const handleChange = (e) => {
        onChange(e);
    }

    return (
        <Stack>
            {label && <FormInputLabel>{label}</FormInputLabel>}
            <Input
                id={id}
                type={type}
                name={name}
                value={value}
                fullWidth
                onChange={handleChange}
                disabled={isDisabled}
                placeholder={placeholder}
                startAdornment={StartIcon ? <InputAdornment position="start"><StartIcon /></InputAdornment> : null}
                endAdornment={EndIcon ? <InputAdornment position="end"> <EndIcon /></InputAdornment> : null}
                {...props}
            />
        </Stack>
    )
}

const CheckBoxField = ({ id, name, checked, label, value, color = "primary", onChange, isDisabled, labelPlacement = "end", ...props }) => {
    const onChangeEvent = (event) => {
        if (onChange)
            onChange(event);
    };
    return (
        <>
            <FormControlLabel
                control={
                    <CheckBox
                        id={id}
                        name={name}
                        // color={color}
                        value={value}
                        checked={checked}
                        disabled={isDisabled}
                        onChange={onChangeEvent}
                        {...props}
                    />
                }
                label={label}
                labelPlacement={labelPlacement}
            />

        </>
    );
}

function RadioboxField({ id, name, value, options, onChange, isDisabled, labelPlacement = "end", orientation = "row", ...props }) {

    const onChangeEvent = (event) => {
        onChange(event);
    };
    return (
        <>
            <RadioBoxGroup
                aria-label={id}
                id={id}
                name={name}
                value={value}
                orientation={orientation}
                onChange={onChangeEvent}
                {...props}
            >
                {options.map((option, i) => (
                    <FormControlLabel
                        key={i}
                        labelPlacement={labelPlacement}
                        value={option.value}
                        control={
                            <Radio
                                color="primary"
                                disabled={isDisabled}
                            />}
                        label={option.label}
                    // disabled={props.Disabled == undefined ? false : props.Disabled}
                    />
                ))}
            </RadioBoxGroup>
        </>
    );
}

const SelectField = ({ id, name, value, label, options, onChange, isDisabled, placeholder, inputRef, ...props }) => {
    const onChangeEvent = (event) => {
        onChange(event);
    };
    return (
        <Stack>
            {label && <FormInputLabel>{label}</FormInputLabel>}
            <CustomSelect
                id={id}
                name={name}
                disableUnderline
                value={value}
                inputRef={inputRef}
                inputProps={inputRef}
                onChange={onChangeEvent}
                input={
                    <Input
                        inputRef={inputRef}
                        disabled={isDisabled}
                    />
                }
                {...props}
            >
                {
                    placeholder ? (<option value="">{placeholder}</option>) : ('')
                }
                {
                    options.map((option, i) => (<option key={i} value={option.value}>{option.label}</option>
                    ))
                }
            </CustomSelect>
        </Stack>
    )
}

const AutocompleteField = ({ id, name, value, label, placeholder, isDisabled,options, onChange, ...props }) => {

    const handleChange = (e) => {
        console.log('event',e)
        onChange(e);
    }

    return (
        <Stack>{console.log('options',options)}
            {label && <FormInputLabel>{label}</FormInputLabel>}
            <AutoCompleteInput
                disablePortal
                id={id}
                name={name}
                value={value}
                options={options}
                disabled={isDisabled}
                placeholder={placeholder}
                onChange={handleChange}
                renderInput={(params) => <TextField type="search" {...params} />}
            />
        </Stack>
    )
}
const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
const DatePickerField = ({ id, name, value,label,isCenter, isDisabled, onChange,highlightedDatesList, dateFormat, timeFormat, ...props }) => {

    const handleChange = (date) => {
        onChange(date);
    }

    return (
        <Stack style={isCenter?{
            display:"flex",
            justifyContent:"center",
            flexDirection:"row"
            
            
            }:{}}>
            {label && <FormInputLabel>{label}</FormInputLabel>}
            <DatePicker
                dateFormat={dateFormat ? dateFormat : "dd-MM-yyyy"}
                todayButton="Today"
                selected={value}
                id={id}
                name={name}
                value={value}
                fullWidth
                disabled={isDisabled}
                onChange={(date) => handleChange(date)}
                highlightDates={highlightedDatesList}
                customInput={<ExampleCustomInput />}
                className="react-custom-datepicker"
                timeFormat="p"
                timeIntervals={15}
                {...props}
                />
        </Stack>
    )
}
export { InputBaseField, CheckBoxField, RadioboxField, SelectField, AutocompleteField,DatePickerField }