import { createStore, applyMiddleware, compose } from 'redux';
import {thunk} from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './reducers/RootReducer';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistStore, persistReducer } from 'redux-persist';
import config from "../Components/config"


const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialState = {};

let middleware = [];

if (config.env === 'prod') {
  middleware = [...middleware, thunk];
} else {
  middleware = [...middleware, thunk, logger];
}

const persistConfig = {
  key: 'root',
  storage,
  // Whitelist only the reducers you want to persist
  whitelist: ['AppState', 'UIState'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  // rootReducer,
  persistedReducer,
  initialState,
  composeEnhancer(applyMiddleware(...middleware)),
);

const persistor = persistStore(store);

export { store, persistor };;
