import * as React from "react";

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import AuthService from "../../Components/auth/AuthService";
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from "@mui/system";
import Message from "../../Components/common/Message";
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { FormButton } from "../../Components/UiElements/UiElements";
import { useLoadingDispatch, showLoading, hideLoading } from '../../context/loadingContext';
import moment from "moment";
import DatePicker from "react-date-picker";

const Auth = new AuthService();

export default function Aisle(props) {

  const navigate = useNavigate();
  const params = useParams();
  var layoutDispatch = useLoadingDispatch();

  const [id, setId] = React.useState(params.id ? params.id : 0);

  const [title, setTitle] = React.useState("");
  const [levels, setLevels] = React.useState("");
  const [depth, setDepth] = React.useState("");
  const [locations, setLocations] = React.useState("");

  const [titleError, setTitleError] = React.useState("");
  const [levelsError, setLevelsError] = React.useState("");
  const [depthError, setDepthError] = React.useState("");
  const [locationsError, setLocationsError] = React.useState("");

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {

    if (id > 0) {
      showLoading(layoutDispatch);
      Auth.fetch(`/aisle/aisle?id=${id}`)
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack) {
            setTitle(res.aisle.title);
            setLevels(res.aisle.levels);
            setDepth(res.aisle.depth);
            setLocations(res.aisle.total_locations);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

  }, []);

  const onClickCancel = () => {
    navigate("/aisles");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (title === "") {
      setTitleError("Required");
      return;
    } else {
      setTitleError("");
    }

    if (levels === "") {
      setLevelsError("Required");
      return;
    } else {
      setLevelsError("");
    }
    if (depth === "") {
      setDepthError("Required");
      return;
    } else {
      setDepthError("");
    }
    if (locations === "") {
      setLocationsError("Required");
      return;
    } else {
      setLocationsError("");
    }

    if (id > 0) {
      showLoading(layoutDispatch);
      Auth.fetch("/aisle/aisle", {
        method: "PUT",
        body: JSON.stringify({
          title: title,
          levels: levels,
          depth: depth,
          total_locations: locations,
          id: id
        }),
      })
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/aisles");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLoading(true);
      showLoading(layoutDispatch);
      Auth.fetch("/aisle/aisle", {
        method: "POST",
        body: JSON.stringify({
          title: title,
          levels: levels,
          depth: depth,
          total_locations: locations,
        }),
      })
        .then((res) => {
          hideLoading(layoutDispatch);
          if (res.ack === true) {
            setType("success");
            setLoadMessage(res.message);
            setTimeout(() => {
              navigate("/aisles");
            }, 1000);
            setLoading(false);
          } else {
            setType("error");
            setLoadMessage(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "100%" },
      }}
      noValidate
      autoComplete="off"
    >
      <h1> {id > 0 ? "Update" : "Add"} Aisle</h1>

      <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>


        <Grid item xs={10} sm={4}>
          <TextField
            value={title}
            onChange={(event) => {
              setTitle(event.target.value);
            }}
            label="Title"
            id="title"
            error={titleError === "" ? false : true}
            helperText={titleError}
          />
          <br />
          <TextField
            value={levels}
            onChange={(event) => {
              setLevels(event.target.value);
            }}
            label="Levels"
            id="levels"
            error={levelsError === "" ? false : true}
            helperText={levelsError}
          />
          <br />
          <TextField
            value={depth}
            onChange={(event) => {
              setDepth(event.target.value);
            }}
            label="Depth"
            id="depth"
            error={depthError === "" ? false : true}
            helperText={depthError}
          />
          <br />
          <TextField
            value={locations}
            onChange={(event) => {
              setLocations(event.target.value);
            }}
            label="Locations"
            id="locations"
            error={locationsError === "" ? false : true}
            helperText={locationsError}
          />
          <br />

        </Grid>
      </Grid>
      <Box style={{ float: "right", marginTop: "40px" }}>
        <FormButton
          type="close"
          style={{ marginRight: "10px" }}
          onClick={onClickCancel}
        >
          Cancel
        </FormButton>
        <FormButton type="save" onClick={handleSubmit}>
          Save
        </FormButton>
        {/* <Button
          variant="outlined"
          style={{ marginRight: "10px" }}
          onClick={onClickCancel}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Save
        </Button> */}
      </Box>

      <Message type={type} msg={loadMessage} />
    </Box>
  );
}
