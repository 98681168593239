import React from "react";
//mui
import {
    Button,
    FormHelperText
} from "@mui/material";
//icons and images
import ResetIcon from '../../assets/images/icons/reset-icon.png';
import {
    Save as SaveIcon,
    Add as AddIcon,
    ArrowBackIos as ArrowBackIosIcon,
    ArrowForwardIos as ArrowForwardIosIcon,
    Search as SearchIcon,
    Delete as DeleteIcon,
    Print as PrintIcon,
    Close as CloseIcon,
    Sync as SyncIcon,
    UnfoldMoreDouble as UnfoldMoreDoubleIcon,
    Lock as LockIcon
} from '@mui/icons-material';

//styled components
import { FormFooterButton, SaveButton, ResetButton, DeleteButton, UpdatedButton, PrintButton, ErrorMessageArea, StyledErrorMessage } from './styles';

function FooterBtn({ children, ...props }) {
    return (
        <>
            <FormFooterButton {...props}>
                {children}
            </FormFooterButton>
        </>
    )
}
function FormButton({ id, type, onClick, size = "medium", children, disabled, ...props }) {
    return (
        <>
            {type === "save" ?
                <SaveButton
                    id={id}f
                    size={size}
                    disabled={disabled}
                    onClick={onClick}
                    startIcon={<SaveIcon />}
                    {...props}>
                    {children}</SaveButton>
                : ''
            }
            {type === "back" ?
                <SaveButton
                    id={id}
                    size={size}
                    disabled={disabled}
                    onClick={onClick}
                    startIcon={<ArrowBackIosIcon />}
                    {...props}>
                    {children}</SaveButton>
                : ''
            }
            {type === "next" ?
                <SaveButton
                    id={id}
                    size={size}
                    disabled={disabled}
                    onClick={onClick}
                    endIcon={<ArrowForwardIosIcon />}
                    {...props}>
                    {children}</SaveButton>
                : ''
            }
            {type === "add" ?
                <SaveButton
                    id={id}
                    size={size}
                    disabled={disabled}
                    onClick={onClick}
                    startIcon={<AddIcon />}
                    {...props}>
                    {children}</SaveButton>
                : ''
            }
            {type === "search" ?
                <SaveButton
                    id={id}
                    size={size}
                    disabled={disabled}
                    onClick={onClick}
                    endIcon={<SearchIcon />}
                    {...props}>
                    {children}</SaveButton>
                : ''
            }
            {type === "reset" ?
                <ResetButton
                    id={id}
                    disabled={disabled}
                    onClick={onClick}
                    startIcon={<img src={ResetIcon} alt="reset" />}
                    size={size}
                    {...props}>
                    {children}</ResetButton>
                : ''
            }
            {type === "close" ?
                <ResetButton
                    id={id}
                    disabled={disabled}
                    onClick={onClick}
                    size={size}
                    startIcon={<CloseIcon />}
                    {...props}>
                    {children}</ResetButton>
                : ''
            }

            {type === "delete" ?
                <DeleteButton id={id}
                    disabled={disabled}
                    onClick={onClick} size={size}
                    startIcon={<DeleteIcon />}
                    {...props}
                >{children}</DeleteButton>
                : ''
            }

            {type === "print" ?
                <PrintButton
                    id={id}
                    disabled={disabled}
                    onClick={onClick}
                    size={size}
                    startIcon={<PrintIcon />}
                    {...props}>
                    {children}</PrintButton> : ''}


            {type === "sync" ?
                <SaveButton id={id}
                    disabled={disabled}
                    onClick={onClick}
                    size={size}
                    {...props}
                    startIcon={<SyncIcon />}>
                    {children}</SaveButton>
                : ''
            }
            {type === "collapse" ?
                <SaveButton id={id}
                    disabled={disabled}
                    onClick={onClick}
                    size={size}
                    {...props}
                    startIcon={<UnfoldMoreDoubleIcon />}>
                    {children}</SaveButton>
                : ''
            }
            {type === "lock" ?
                <SaveButton id={id}
                    disabled={disabled}
                    onClick={onClick}
                    size={size}
                    {...props}
                    startIcon={<LockIcon />}>
                    {children}</SaveButton>
                : ''
            }

            {type === "noIcon" ?
                <SaveButton id={id}
                    disabled={disabled}
                    onClick={onClick}
                    size={size}
                    {...props}>
                    {children}</SaveButton>
                : ''
            }
        </>
    )
}
function ErrorMessage({ children, textArea, ...props }) {
    return (
        <>
            {
                textArea === true ?
                    <ErrorMessageArea>
                        <FormHelperText>
                            <span>{children}</span>
                        </FormHelperText>
                    </ErrorMessageArea>
                    : <StyledErrorMessage>
                        <FormHelperText>
                            <span>{children}</span>
                        </FormHelperText>
                    </StyledErrorMessage>

            }

        </>
    )
}
export { FooterBtn, FormButton, ErrorMessage };

