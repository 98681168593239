import * as React from "react";
import jsPDF from "jspdf";
import { renderToString } from "react-dom/server";
import AuthService from "../../Components/auth/AuthService";
import DatePicker from "react-date-picker";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { Box, Button, Grid, Typography, Autocomplete, TextField, } from "@mui/material";
import { Button as AntButton } from "antd";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { selectClasses } from "@mui/material/Select";
import Message from "../../Components/common/Message";

import PalletBuilder from "./PalletBuilder";
import _ from "lodash";

import { vehicleTypes } from "./../../Components/common/constants";
import { ConstructionOutlined } from "@mui/icons-material";
import { FormButton } from "../../Components/UiElements/UiElements";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
import dayjs from "dayjs";
import { DatePicker as AntDate } from "antd";

const useStyles = makeStyles((theme) => ({
  dateField: {
    marginRight: "10px",
       height: "40px",
       "& .react-date-picker__wrapper": {
         border: "thin solid #b6babf",
         borderRadius: '5px',
       },
       "& .react-date-picker__button svg ": {
         stroke: "#b6babf",
       }
  },
  btn: {
    margin: "5px",
  },
}));

const styles = {
  fontFamily: "sans-serif",
  textAlign: "center",
};
const colstyle = {
  width: "30%",
};
const tableStyle = {
  width: "100%",
};

export default function PalletReport() {
  const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const [orders, setOrders] = React.useState([]);
  const [routes, setRoutes] = React.useState([]);
  const [selectedRoute, setSelectedRoute] = React.useState([]);
  const [pallets, setPallets] = React.useState([]);
  // const [noOfPallets, setNoOfPallets] = React.useState(0);
  const [showPrint, setShowPrint] = React.useState(false);
  const [deliveryDate, setDeliveryDate] = React.useState(new Date());
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");

  const Auth = new AuthService();

  const loadRoutes = () => {
    setType("success");
    setRoutes([]);
    setPallets([]);
    setOrders([]);

    setLoadMessage("Loading routes. Please wait ....");
    showLoading(layoutDispatch);  
    Auth.post(`/route/load-locked-routes`, {
      delivery_date: moment(deliveryDate).format("YYYY-MM-DD"),
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        setLoadMessage("Routes loaded .");
        setRoutes(res.routes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadRoutesOrder = (route_status_id, flag) => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
    const startStatus = selectedRoute?.start_status > 0
    if(startStatus & flag) {
      console.log();
      
      setType('error')
      setLoadMessage(`Can't reset pallet builder while route is started ${str}`);
      return
    }
    setOrders([]);
    setPallets([]);

    let route = routes.filter((r) => r.id == route_status_id);

    if (route.length == 0) {
      return;
    }

    setSelectedRoute(route[0]);

    let pallets_count = 0;
    let max_pallet_weight = 0;
    let weight_threshold = 0;

    let vehicle = vehicleTypes.filter((r) => r.id == route[0].vehicle_type);
    pallets_count = vehicle[0].maxPallets;
    max_pallet_weight = vehicle[0].maxPalletWeight;
    weight_threshold = vehicle[0].weightThreshold;

    setType("success");

    setLoadMessage("Loading orders. Please wait ....");

    // Load order Routes
    showLoading(layoutDispatch);
    Auth.post(`/route/load-routes-orders`, {
      route_status_id: route_status_id,
      delivery_date: moment(deliveryDate).format("YYYY-MM-DD"),
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        setLoadMessage("Pallet builder loaded .");
        const orders1 = res.orders;

        let orderData = [];
        orders1.map((order, idx) => {
          let check = orderData.filter((r) => r.internalId == order.internalId);
          if (check.length > 0) {
            let nsItem = {};
            nsItem.itemId = order.itemId;
            nsItem.itemName = order.itemName;
            nsItem.quantity = Number(order.quantity);
            nsItem.unitPrice = Number(order.unitPrice);
            nsItem.grossAmountIncludingTax = Number(
              order.grossAmountIncludingTax
            );
            nsItem.frozenSheetItem = order.frozenSheetItem;
            nsItem.casesInPallet = order.casesInPallet;
            nsItem.itemWeight = Number(order.itemWeight); //.substring(0, order.itemWeight.length - 2));
            nsItem.itemTotalWeight =
              Number(order.itemWeight) * Number(order.quantity); // Number(order.itemWeight.substring(0, order.itemWeight.length - 2)
            check[0].orderWeight =
              Number(check[0].orderWeight) + nsItem.itemTotalWeight;
            check[0].items.push(nsItem);
          } else {
            let nsOrder = {};
            nsOrder.id = order.internalId;

            nsOrder.rank = order.rank;
            nsOrder.internalId = order.internalId;
            nsOrder.invInternalId = order.invInternalId;
            nsOrder.invoiceNo = order.invoiceNo;
            nsOrder.saleOrderNo = order.saleOrderNo;
            nsOrder.deliveryInstructions = order.deliveryInstructions;
            nsOrder.customerName = order.customerName;
            nsOrder.customerProjectId = order.customerProjectId;
            nsOrder.customerInternalId = order.customerInternalId;
            nsOrder.shipZip = order.shipZip;
            nsOrder.customerOpeningTime = order.customerOpeningTime;
            nsOrder.terms = order.terms;
            nsOrder.shipAddress1 = order.shipAddress1;

            let itemTotalWeight =
              Number(order.itemWeight) * Number(order.quantity); // Number(order.itemWeight.substring(0, order.itemWeight.length - 2)
            nsOrder.orderWeight = 0;
            nsOrder.orderWeight = Number(itemTotalWeight);

            nsOrder.items = [];

            let nsItem = {};
            nsItem.itemId = order.itemId;
            nsItem.itemName = order.itemName;
            nsItem.quantity = Number(order.quantity);
            nsItem.unitPrice = Number(order.unitPrice);
            nsItem.grossAmountIncludingTax = Number(
              order.grossAmountIncludingTax
            );
            nsItem.frozenSheetItem = order.frozenSheetItem;
            nsItem.casesInPallet = order.casesInPallet;
            nsItem.itemWeight = Number(order.itemWeight); //.substring(0, order.itemWeight.length - 2));
            nsItem.itemTotalWeight = itemTotalWeight;

            nsOrder.items.push(nsItem);

            orderData.push(nsOrder);
          }
        });

        // Splitting pallets
        // orderData.map((order, ordIndex) => {
        for (let x = 0; x < orderData.length; ) {
          let order = orderData[x];
          let ordIndex = x;
          // console.log('length: ', orderData.length);
          // console.log(ordIndex);
          // console.log(order.orderWeight);
          if (
            Number(order.orderWeight) > max_pallet_weight &&
            order.items.length > 1
          ) {
            let order1 = JSON.parse(JSON.stringify(order));
            let order_items = JSON.parse(JSON.stringify(order1.items));

            // remove the current item
            orderData.splice(ordIndex, 1);

            // console.log(order_items);
            //splitting items

            for (let i = 0; i < order_items.length; ) {
              if (
                order_items[i].casesInPallet > 0 &&
                order_items[i].quantity > order_items[i].casesInPallet
              ) {
                let noOfPallets = Math.ceil(
                  Number(order_items[i].quantity) /
                    Number(order_items[i].casesInPallet)
                );
                let remainingQty = Number(order_items[i].quantity);

                let tempItem = JSON.parse(JSON.stringify(order_items[i]));
                let perQtyPrice =
                  Number(tempItem.grossAmountIncludingTax) /
                  Number(tempItem.quantity);

                order_items.splice(i, 1);

                for (let j = 0; j < noOfPallets; j++) {
                  let tempItem2 = JSON.parse(JSON.stringify(tempItem));

                  if (j == noOfPallets - 1) {
                    // last pallet
                    tempItem2.itemTotalWeight =
                      Number(tempItem.itemWeight) * Number(remainingQty);
                    tempItem2.quantity = Number(remainingQty);
                    tempItem2.grossAmountIncludingTax =
                      Number(perQtyPrice) * Number(remainingQty);
                  } else {
                    tempItem2.itemTotalWeight =
                      Number(tempItem.itemWeight) *
                      Number(tempItem.casesInPallet);
                    tempItem2.quantity = Number(tempItem.casesInPallet);

                    tempItem2.grossAmountIncludingTax =
                      Number(perQtyPrice) * Number(tempItem.casesInPallet);
                  }
                  remainingQty -= Number(tempItem.casesInPallet);
                  order_items.push(tempItem2);
                }
              } else {
                i++;
              }
            }

            console.log(order_items);
            // Sort items by weight desc
            order_items.sort(function (a, b) {
              return b.itemTotalWeight - a.itemTotalWeight;
            });

            do {
              let newOrder = JSON.parse(JSON.stringify(order1));
              newOrder.orderWeight = 0;
              newOrder.items = [];
              let itemsToRemove = [];
              order_items.map((itm, itmIdx) => {
                if (newOrder.items.length == 0 && itmIdx == 0) {
                  let tmpItem = JSON.parse(JSON.stringify(itm));
                  newOrder.orderWeight += Number(itm.itemTotalWeight);
                  newOrder.items.push(tmpItem);
                  itemsToRemove.push(itmIdx);
                  // order_items.splice(itmIdx, 1);
                } else {
                  if (
                    Number(itm.itemTotalWeight) +
                      Number(newOrder.orderWeight) <=
                    max_pallet_weight
                  ) {
                    let tmpItem = JSON.parse(JSON.stringify(itm));
                    newOrder.orderWeight += Number(itm.itemTotalWeight);
                    newOrder.items.push(tmpItem);
                    itemsToRemove.push(itmIdx);
                    // order_items.splice(itmIdx, 1);
                  }
                }
              });

              _.pullAt(order_items, itemsToRemove);

              // If order weight is 0, concat the items to current order and move to next order
              if (Number(newOrder.orderWeight) > 0) {
                orderData.splice(ordIndex, 0, newOrder);
              } else {
                orderData[ordIndex].items = orderData[ordIndex].items.concat(
                  newOrder.items
                );
                x++;
              }
            } while (order_items.length > 0);
          } else {
            x++;
          }
        }

        // console.log(orderData)

        // reset the order ids
        orderData.map((order, idx) => {
          order.id = idx + "" + 1; // Making the ids string for draggable
        });

        let pallets1 = [];

        // if (pallets_count == 4) // 3.5 Tonnes
        // {
        //     let orders_count = 0;
        //     for (let i = 0; i < pallets_count; i++) {
        //         let palletObj = {};
        //         palletObj.title = 'Pallet ' + (i + 1);
        //         palletObj.weight = 0;
        //         palletObj.items = [];

        //         while (orders_count < orderData.length) {
        //             // Max pallet size: 350
        //             if (palletObj.weight < Number(max_pallet_weight) && (Number(palletObj.weight) + Number(orderData[orders_count].orderWeight) < Number(max_pallet_weight))) {
        //                 palletObj.weight = Number(palletObj.weight) + Number(orderData[orders_count].orderWeight);
        //                 palletObj.items.push(orderData[orders_count++]);
        //             }
        //             else {
        //                 break;
        //             }
        //         }
        //         pallets1.push(palletObj);
        //     }
        // }

        // else if (pallets_count == 8) // 7.5 Tonnes
        // {
        //     let orders_count = 0;
        //     for (let i = 0; i < pallets_count; i++) {
        //         let palletObj = {};
        //         palletObj.title = 'Pallet ' + (i + 1);
        //         palletObj.weight = 0;
        //         palletObj.items = [];

        //         while (orders_count < orderData.length) {
        //             if (palletObj.weight < Number(max_pallet_weight) && (Number(palletObj.weight) + Number(orderData[orders_count].orderWeight) <= Number(max_pallet_weight))) {
        //                 palletObj.weight = Number(palletObj.weight) + Number(orderData[orders_count].orderWeight);
        //                 palletObj.items.push(orderData[orders_count++]);
        //             }
        //             else {
        //                 break;
        //             }
        //         }
        //         pallets1.push(palletObj);
        //     }
        // }
        let orders_count = 0;
        for (let i = 0; i < pallets_count; i++) {
          let palletObj = {};
          palletObj.title = "Pallet " + (i + 1);
          palletObj.pallet_id = i + 1;
          palletObj.weight = 0;
          palletObj.items = [];

          while (orders_count < orderData.length) {
            // if last pallet, assign everything
            if (i == pallets_count - 1) {
              palletObj.weight =
                Number(palletObj.weight) +
                Number(orderData[orders_count].orderWeight);
              palletObj.items.push(orderData[orders_count++]);
            } else if (palletObj.items.length >= 2) {
              // if pallet already have 2 orders, move to next pallet
              break;
            } else {
              if (
                palletObj.weight < Number(max_pallet_weight) &&
                Number(palletObj.weight) +
                  Number(orderData[orders_count].orderWeight) <=
                  Number(max_pallet_weight)
              ) {
                palletObj.weight =
                  Number(palletObj.weight) +
                  Number(orderData[orders_count].orderWeight);
                palletObj.items.push(orderData[orders_count++]);
              } else if (
                Number(orderData[orders_count].orderWeight) >=
                  Number(max_pallet_weight) &&
                palletObj.weight == 0
              ) {
                palletObj.weight =
                  Number(palletObj.weight) +
                  Number(orderData[orders_count].orderWeight);
                palletObj.items.push(orderData[orders_count++]);
              } else {
                break;
              }
            }
          }
          pallets1.push(palletObj);
        }
        setOrders(orderData);
        setPallets(pallets1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadPallets = (route_status_id) => {
    setOrders([]);
    setPallets([]);
    setSelectedRoute({});

    let route = routes.filter((r) => r.id == route_status_id);

    if (route.length == 0) {
      return;
    }

    setSelectedRoute(route[0]);

    setType("success");

    setLoadMessage("Loading orders. Please wait ....");

    // Load saved route
    showLoading(layoutDispatch);
    setShowPrint(false);
    Auth.fetch(
      `/route/load-pallet-builder?delivery_date=${moment(deliveryDate).format(
        "YYYY-MM-DD"
      )}&route_status_id=${route_status_id}`
    )
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack) {
          setLoadMessage("Pallet builder loaded .");
          setShowPrint(true);
          let pallet_builder = res.pallet_builder;

          let pallets1 = [];
          pallet_builder.map((pallet, idx) => {
            let checkPallet = pallets1.filter(
              (p) => p.id == pallet.pallet_builder_id
            );
            if (checkPallet.length > 0) {
              // Pallet already added, check for Order
              let checkPalletOrder = checkPallet[0].items.filter(
                (po) => po.internalId == pallet.internal_id
              );
              if (checkPalletOrder.length > 0) {
                // order already added, just add the item

                let palletOrderItem = {};
                palletOrderItem.itemId = pallet.item_id;
                palletOrderItem.itemName = pallet.item_name;
                palletOrderItem.itemTotalWeight = pallet.item_total_weight;
                palletOrderItem.itemWeight = pallet.item_weight;
                palletOrderItem.quantity = pallet.quantity;
                palletOrderItem.unitPrice = pallet.unit_price;
                palletOrderItem.grossAmountIncludingTax =
                  pallet.gross_amount_incl_tax;
                palletOrderItem.frozenSheetItem = pallet.frozen_sheet_item;
                palletOrderItem.casesInPallet = pallet.cases_in_pallet;

                checkPalletOrder[0].items.push(palletOrderItem);

                checkPalletOrder[0].orderWeight += Number(
                  pallet.item_total_weight
                );
                checkPallet[0].weight += Number(pallet.item_total_weight);
              } else {
                // New order, in previous pallet
                let palletOrder = {};
                palletOrder.id = idx + "" + 1;
                palletOrder.rank = pallet.rank;
                palletOrder.customerName = pallet.customer_name;
                palletOrder.customerProjectId = pallet.customer_project_id;
                palletOrder.customerInternalId = pallet.customer_internal_id;
                palletOrder.shipZip = pallet.ship_zip;
                palletOrder.customerOpeningTime = pallet.customer_opening_time;
                palletOrder.terms = pallet.terms;
                palletOrder.shipAddress1 = pallet.shipAddress1;

                palletOrder.internalId = pallet.internal_id;
                palletOrder.invInternalId = pallet.inv_internal_id;
                palletOrder.invoiceNo = pallet.invoice_no;
                palletOrder.saleOrderNo = pallet.sale_order_no;
                palletOrder.deliveryInstructions = pallet.delivery_instructions;
                palletOrder.orderWeight = 0;
                palletOrder.items = [];

                let palletOrderItem = {};
                palletOrderItem.itemId = pallet.item_id;
                palletOrderItem.itemName = pallet.item_name;
                palletOrderItem.itemTotalWeight = pallet.item_total_weight;
                palletOrderItem.itemWeight = pallet.item_weight;
                palletOrderItem.quantity = pallet.quantity;
                palletOrderItem.unitPrice = pallet.unit_price;
                palletOrderItem.grossAmountIncludingTax =
                  pallet.gross_amount_incl_tax;
                palletOrderItem.frozenSheetItem = pallet.frozen_sheet_item;
                palletOrderItem.casesInPallet = pallet.cases_in_pallet;

                palletOrder.items.push(palletOrderItem);

                palletOrder.orderWeight += Number(pallet.item_total_weight);
                checkPallet[0].weight += Number(pallet.item_total_weight);

                checkPallet[0].items.push(palletOrder);
              }
            } else {
              // new pallet
              let palletObj = {};
              palletObj.id = pallet.pallet_builder_id;
              palletObj.title = pallet.title;
              palletObj.pallet_id = pallet.pallet_id;
              palletObj.weight = 0;
              palletObj.items = [];

              if (pallet.internal_id) {
                let palletOrder = {};
                palletOrder.id = idx + "" + 1;
                palletOrder.rank = pallet.rank;
                palletOrder.customerName = pallet.customer_name;
                palletOrder.customerProjectId = pallet.customer_project_id;
                palletOrder.customerInternalId = pallet.customer_internal_id;
                palletOrder.shipZip = pallet.ship_zip;
                palletOrder.customerOpeningTime = pallet.customer_opening_time;
                palletOrder.terms = pallet.terms;
                palletOrder.shipAddress1 = pallet.ship_address1;
                palletOrder.internalId = pallet.internal_id;
                palletOrder.invInternalId = pallet.inv_internal_id;
                palletOrder.invoiceNo = pallet.invoice_no;
                palletOrder.saleOrderNo = pallet.sale_order_no;
                palletOrder.deliveryInstructions = pallet.delivery_instructions;
                palletOrder.orderWeight = 0;
                palletOrder.items = [];

                let palletOrderItem = {};
                palletOrderItem.itemId = pallet.item_id;
                palletOrderItem.itemName = pallet.item_name;
                palletOrderItem.itemTotalWeight = pallet.item_total_weight;
                palletOrderItem.itemWeight = pallet.item_weight;
                palletOrderItem.quantity = pallet.quantity;
                palletOrderItem.unitPrice = pallet.unit_price;
                palletOrderItem.grossAmountIncludingTax =
                  pallet.gross_amount_incl_tax;
                palletOrderItem.frozenSheetItem = pallet.frozen_sheet_item;
                palletOrderItem.casesInPallet = pallet.cases_in_pallet;
                palletOrder.items.push(palletOrderItem);
                palletOrder.orderWeight += Number(pallet.item_total_weight);

                palletObj.items.push(palletOrder);
                palletObj.weight += Number(palletOrder.orderWeight);
              }

              pallets1.push(palletObj);
            }
          });
          console.log(pallets1);
          setPallets(pallets1);
        } else {
          loadRoutesOrder(route_status_id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
          <h2 style={{marginBottom: '10px', marginTop: 0}}>Pallets Builder </h2>
      <Grid
        container
        spacing={2}
        sx={{ alignItems: "flex-end", display: "flex" }}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            {/* <DatePicker
              label={"Noor"}
              value={deliveryDate}
              format={"dd-MM-yyyy"}
              onChange={(date) => {
                setDeliveryDate(date);
                setRoutes([]);
                setPallets([]);
              }}
              className={classes.dateField}
            /> */}
            <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: 160}}
               size="large"
               value={deliveryDate ? dayjs(deliveryDate) : null}
               onChange={(_, dateStr) => {
                setDeliveryDate(dateStr);
                setRoutes([]);
                setPallets([]);
              }}
              placeholder="Select date" 
               />
            {/* <FormButton type="noIcon" onClick={loadRoutes}>
              Load Routes
            </FormButton> */}
            <AntButton type="primary" size="large" onClick={loadRoutes} >Load Routes</AntButton>

          </Box>
        </Grid>
      </Grid>
      <div className={classes.filter}>
        {/* <Button variant="contained" onClick={loadRoutes}>Load Routes</Button> */}
      </div>
      <br />
      <Grid container spacing={2}>
        {routes.length > 0 && (
          <>
            <Grid item xs={6}>
              {/* <p>Route Reports</p> */}
              <Autocomplete
            id="route"
            fullWidth
            size="large"
            options={routes}
            getOptionLabel={(option) => option.title ? `${option.title} (${option.code}) - ${option.registration_number} - ${option.driver_name} ` : ''}
            onChange={(e, values) => {
              loadPallets(values?.id)

            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select completed route"
                label="Select completed route"
              />
            )}
          />
              {/* <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select completed route
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  style={{ marginBottom: "5px" }}
                  label="Select completed route"
                  onChange={(e) => {
                    loadPallets(e.target.value);
                  }}
                >
                  <MenuItem value={0}>{"Select Route"}</MenuItem>
                  {routes.map((r, index) => {
                    return (
                      <MenuItem value={r.id} key={index}>
                        {r.title + " (" + r.code + ")"}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl> */}
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={12}>
                  {selectedRoute.id && (
                    <Typography paragraph={true} sx={{ margin: 0 }}>
                      Vehicle: <b>{selectedRoute.registration_number}</b>{" "}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {selectedRoute.id && (
                    <Typography paragraph={true} sx={{ margin: 0 }}>
                      Driver: <b>{selectedRoute.driver_name}</b>{" "}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={1.5}>
                            {selectedRoute.id && <p>Vehicle: <b>{selectedRoute.registration_number}</b> </p>}
                        </Grid>
                        <Grid item xs={2}>
                            {selectedRoute.id && <p>Driver: <b>{selectedRoute.driver_name}</b> </p>}
                        </Grid> */}
            <Grid item xs={5}>
              <div style={{ float: "right" }}>
                {/* <Button variant="contained" className={classes.btn} onClick={(e) => { loadPallets(selectedRoute.id, 1) }}> Print Reports</Button> */}
                {/* {selectedRoute.id > 0 && <Button onClick={() => { loadRoutesOrder(selectedRoute.id, 1) }}>Reset</Button>} */}
              </div>
            </Grid>
          </>
        )}
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {pallets.length > 0 && (
            <PalletBuilder
              pallets={pallets}
              deliveryDate={deliveryDate}
              selectedRoute={selectedRoute}
              loadRoutesOrder={loadRoutesOrder}
              showPrint={showPrint}
            />
          )}
        </Grid>
      </Grid>
      <Message type={type} msg={loadMessage} />
    </div>
  );
}
