import React from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import {
  ErrorOutlineRounded as ErrorOutlineIcon,
  CheckCircleOutlineRounded as CheckCircleOutlineIcon,
  HelpOutlineRounded as HelpOutlineIcon,
  WarningAmberRounded as WarningAmberRoundedIcon
  // BlockRounded as BlockIcon,
} from '@mui/icons-material';
import { FormButton } from "../UiElements/UiElements";
// import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
// import BlockIcon from '@material-ui/icons/Block';
import { styled } from '@mui/styles'
const MuiDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: "12px 40px",
    borderRadius: "10px",
  },
  "& .MuiDialogActions-root": {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center",
  },
  "& .MuiDialogContentText-root": {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '24px',
    color: '#000000',
    textAlign: 'center',
  }
}));
const MuiDialogTitle = styled(DialogTitle)(({ theme }) => ({
  textAlign: 'center',
  background: '#FFF',
  borderRadius: '8px 8px 0px 0px',
  padding: "7px 24px 0px 24px",
  "& .actionDialogIcon": {
    width: "65px",
    height: "65px",
  },
  "& .confirm": {
    color: '#BF710F'
    // color: "#BDBDBD",
  },
  "& .update": {
    color: '#f9a843',
  },
  "& .success": {
    color: '#6FCF97',
  },
  "& .warning": {
    color: '#EB5757',
  },
}));
const MuiDialogContent = styled(DialogContent)(({ theme }) => ({
  textAlign: "center",
  padding: "8px 0px"
}));
const MuiDialogAction = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: "space-between",
}));
export const ActionDialog = ({ message, type, isOpen, onClose, onSubmit, onCancel, cancelText , ...props }) => {
  const handleSubmit = () => {
    onSubmit();
    onCancel(false);
  };
  const handleClose = () => {
    onCancel(false);
  };

  return (
    <>
      <MuiDialog
        open={isOpen}
        disableEscapeKeyDown  {...props}
        // classes={{ paper: classes.paper }}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}>
        <MuiDialogTitle >
          {type === "warning" ?
            <WarningAmberRoundedIcon className="actionDialogIcon warning" color="#EB5757" />
            : type === "success" ?
              <CheckCircleOutlineIcon className="actionDialogIcon success" color="#6FCF97" />
              : type === "update" ?
                <ErrorOutlineIcon className="actionDialogIcon update" color="#f9a843" />
                : type === "confirm" ?
                  <HelpOutlineIcon className="actionDialogIcon confirm" color="#BDBDBD" />
                  : ''
          }
        </MuiDialogTitle>
        <MuiDialogContent >
          <DialogContentText >
            {
              message ? (
                message.split('<br>').map((item, key) => {
                  return <span key={key}>{item}<br /></span>
                })) : ""
            }
          </DialogContentText>
        </MuiDialogContent>
        <MuiDialogAction>
          <FormButton type="close" onClick={handleClose}>
            {cancelText ? cancelText : "Close"}
          </FormButton>
          {type === "update" || type === "confirm" ?
            <FormButton type="noIcon" onClick={handleSubmit}>
              OK
            </FormButton> : ""}
        </MuiDialogAction>
      </MuiDialog>
    </>
  )
}
