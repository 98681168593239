import React, { useState } from "react";

import { Button, Pagination, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import AuthService from "../../Components/auth/AuthService";
import { driverIssuesActions, driverIssuesTypes, vehicleTypes } from "../../Components/common/constants";
import { InputBaseField } from "../../Components/InputFields/InputFields";
import { FormButton } from "../../Components/UiElements/UiElements";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
import { ActionDialog } from "../../Components/actionDialog/ActionDialog";
import { CloseCircleOutlined, DeleteFilled, EditFilled, ExclamationCircleOutlined, HistoryOutlined, PlusCircleFilled, SearchOutlined } from "@ant-design/icons";
import { Table, Button as AntButton, Tooltip, message, Tag } from "antd/es";
import Message from "../../Components/common/Message";
import DriverHistoryModal from "../../Components/Modals/DriverHistoryModal";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  tblTopDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  tblsearch: {
    display: "flex",
  },
  addIcon: {
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
  antAddIcon: {
    "& .anticon-plus-circle:hover": {
      color: "#4096ff !important"
    }
  },
}));

const Auth = new AuthService();

export default function DriverList() {
  const classes = useStyles();
  const navigate = useNavigate();
  var layoutDispatch = useLoadingDispatch();

  const [drivers, setDrivers] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(null);
  const [limit] = React.useState(50);

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");

  const [historyRecord, setHistoryRecord] = React.useState([]);
  const [driverHistoryModalOpen, setDriverHistoryModalOpen] = React.useState(false);
  const [selectedDriverID, setSelectedDriverID] = React.useState('');

  //State For Action Dialog
  const [actionDialogProps, setActionDialogProps] = useState({
    message: "",
    type: "",
    isOpen: false,
  });

  const getStatusInfo = (date) => {
    const daysDifference = date.diff(moment(), "days");
    const formattedDate = moment(date).format("YYYY-MM-DD");    
  
    if (daysDifference < 0) {
      return {
        color: "#ff002a",
        text:  `${formattedDate}`,
        icon: <CloseCircleOutlined />,
      };
    } else if (daysDifference <= 30) {
      return {
        color: "#ec942c",
        text:  `${formattedDate}`,
        icon: <ExclamationCircleOutlined />,
      };
    } else if (daysDifference > 30) {
      return {
        color: "#339900",
        text:  `${formattedDate}`,
        icon: <HistoryOutlined />,
      };
    } else {
      return {
        color: "white",
        text:  null,
        icon: null,
      }
    }
  };

  const driverColumns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 75,
    },
    // {
    //   title: 'First Name',
    //   dataIndex: 'first_name',
    //   key: 'first_name',
    //   width: 135,
    // },
    // {
    //   title: 'Last Name',
    //   dataIndex: 'last_name',
    //   key: 'last_name',
    //   width: 135,
    // },
    {
      title: "Name",
      width: 175,
      render: ({ first_name, last_name }) => {
        return (
          <>
            {`${first_name} ${last_name}`}
          </>
        );
      },
    },
    
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Max Vehicle Type',
      width: 130,
      render: (record) => {
        return record.vehicle_type
          ? vehicleTypes.find(obj => obj.id.toString() === record.vehicle_type.toString())?.title
          : '';
      },
    },
    {
      title: "Tacho Expiry",
      width: 100,
      render: ({ tacho_card_expiry }) => {
        const info = getStatusInfo(moment(tacho_card_expiry, "YYYY-MM-DD"));
        return (
          <Tag color={info?.color} icon={info?.icon} style={{ marginLeft: '4px' }}>
            {info?.text}
          </Tag>
        );
      },
    },
    {
      title: "License Expiry",
      width: 100,
      render: ({ license_expiry }) => {
        const info = getStatusInfo(moment(license_expiry, "YYYY-MM-DD"));
        return (
          <Tag color={info?.color} icon={info?.icon} style={{ marginLeft: '4px' }}>
            {info?.text}
          </Tag>
        );
      },
    },
    {
      title: "CPC Expiry",
      width: 100,
      render: ({ cpc_expiry }) => {
        const info = getStatusInfo(moment(cpc_expiry, "YYYY-MM-DD"));
        return (
          <Tag color={info?.color} icon={info?.icon} style={{ marginLeft: '4px' }}>
            {info?.text}
          </Tag>
        );
      },
    },
    // {
    //   title: 'Maintenance Cost',
    //   width: 175,
    //   render: (record) => {
    //     return record?.total_cost
    //       ? "£" +
    //           Number(record?.total_cost).toLocaleString(undefined, {
    //             minimumFractionDigits: 2,
    //           })
    //       : '';
    //   },
    // },
    // {
    //   title: 'Notes',
    //   dataIndex: 'notes',
    //   key: 'notes',
    //   width: 300
    // },
    {
      title: 'Action',
      key: 'action',
      align: "right",
      dataIndex: '',
      render(record) {
        return (
          <div  onClick={(e) => e.stopPropagation()}>
            <Tooltip title="History">
            <HistoryOutlined
              style={{
                fontSize: "25px",
                cursor: "pointer",
                marginRight: "5px",
                // color: "green",
              }}
              onClick={()=> {
                getHistory(record)
              }}
            />
            </Tooltip>
            <EditFilled  onClick={() => handleRowClick(record.id)}  style={{ fontSize: '25px', cursor: "pointer", marginRight: "5px", color: "#006cb8"}} />
            <DeleteFilled onClick={() => handleDelete(record.id)} style={{ fontSize: '25px', cursor: "pointer", color: "#cd1437" }} />
          </div>
        );
      },
    },
  ];

  const getHistory = async (data) => {
    setSelectedDriverID(data?.id)
    showLoading(layoutDispatch);
    try {
      const res = await Auth.fetch(`/maintenance/maintenance-driver?driver_id=${data?.id}`)

      if(res?.ack) {
      console.log('res', res);
      if(res?.maintenance.length > 0) {
        setHistoryRecord(res?.maintenance)
        setDriverHistoryModalOpen(true)
      } else {
        setHistoryRecord([])
        setDriverHistoryModalOpen(true)
      }
      } else  {
        message.error(res?.message)
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoading(layoutDispatch)
    }
  }

  React.useEffect(() => {
    message.config({
      top: window.innerHeight - 100, 
      duration: 3, 
    });
  }, [])

  const ShowActionDialog = (
    message,
    type,
    isOpen,
    OnOkCallback,
    OnCancellCallback
  ) => {
    setActionDialogProps((prevState) => ({
      ...prevState,
      message: message,
      type: type,
      isOpen: isOpen,
      onOk: OnOkCallback,
      onCancel: OnCancellCallback,
    }));
  };

  const getDriverData = async () => {
    showLoading(layoutDispatch);
    try {
      const res = await Auth.post(`/driver/drivers`, {
        limit: limit,
        page: page,
        searchText: searchText,
      })
      if (res.ack) {
        setDrivers(res.drivers);
        setTotalPages(Math.ceil(res.total_count / limit));
        setTotalCount(res.total_count)
      }
    } catch (err) {
      console.log(err);
    } finally {
      hideLoading(layoutDispatch);
    }
  } 

  React.useEffect(() => {
    // showLoading(layoutDispatch);
    // Auth.post(`/driver/drivers`, {
    //   limit: limit,
    //   page: page,
    //   searchText: searchText,
    // })
    //   .then((res) => {
    //     hideLoading(layoutDispatch);
    //     if (res.ack) {
    //       setDrivers(res.drivers);
    //       setTotalPages(Math.ceil(res.total_count / limit));
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    getDriverData()
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleRowClick = (id) => {
    navigate(`/drivers/${id}`)
  };

  const Search = () => {
    showLoading(layoutDispatch);
    Auth.post(`/driver/drivers`, {
      limit: limit,
      page: 1,
      searchText: searchText,
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack) {
          setDrivers(res.drivers);
          setTotalPages(Math.ceil(res.total_count / limit));
  setTotalCount(res.total_count);

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleDelete = (id) => {
    // if (window.confirm("Are you sure to want to delete this record?")) {
    ShowActionDialog(
      "Are you sure to want to delete this record?",
      "confirm",
      true,
      function () {
        console.log("Are you sure to delete this record");

        const params = {
          method: "DELETE",
          body: JSON.stringify({
            id: id,
          }),
        };
        // setLoading(true);
        showLoading(layoutDispatch);
        Auth.fetch(`/driver/driver`, params)
          .then((res) => {
            hideLoading(layoutDispatch);
            if (res.ack) {
              setType("info");
              setLoadMessage(res.message);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
              // setLoading(false);
            } else {
              setLoadMessage("error");
              setLoadMessage(res.status);
              // setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };

  return (
    <div>
            <h2 style={{marginBottom: '10px', marginTop: 0}}>Drivers</h2>
      {/* <Button variant="contained" style={{marginBottom: "15px"}} onClick={()=>{window.location = "/import-drivers"}}>Import Drivers</Button> */}
      <div className={classes.tblTopDiv}>
      <div style={{display: "flex", flexDirection: "column", gap: "8px"}}>
          <div style={{display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px"}}>
            <InputBaseField
            id="searchText"
            name="searchText"
            value={searchText}
            onChange={handleSearch}
            placeholder="Search"
          />
            
          <AntButton
            type="primary"
            size="large"
            onClick={Search}
            style={{ marginLeft: '5px'}}
            icon={<SearchOutlined />}
          >
            Search
          </AntButton>
          </div>
          </div>
        {/* <div className={classes.tblsearch}>
          
          <InputBaseField
            id="searchText"
            name="searchText"
            value={searchText}
            onChange={handleSearch}
            placeholder="Search"
          />
          <FormButton
            type="search"
            onClick={Search}
            sx={{ float: "right", height: "35.63px", marginLeft: "10px" }}
          >
            Search
          </FormButton>
        </div> */}
        <div>
          <div className={classes.antAddIcon}>
            <PlusCircleFilled style={{ fontSize: "30px", color:"#1677FF"}}  onClick={() =>navigate("/drivers/new")} />
          </div>
          {/* <Link href="/driver/new">
            <AddCircleIcon fontSize="large" sx={{
                color: appTheme.btnColor,
              }} />
          </Link> */}
        </div>
      </div>
      <div className={classes.antTable}>
      <Table
          dataSource={drivers}
          columns={driverColumns}
          style={{ overflowX: "auto" }}
          pagination={false}
          // footer={()=>`List of Drivers - Total Records: ${drivers.length === 0 ? "0" : drivers.length  }`}
          footer={() => {
            const startRecord = (page - 1) * limit + 1;
            const endRecord = Math.min(page * limit, drivers.length ? totalCount : 0); 
            return `Showing ${startRecord}-${endRecord} of ${drivers.length ? totalCount : "0"} Records`;
          }}
        />
      </div>
      <Message type={type} msg={loadMessage} />
      <div style={{ float: "right", margin: "10px" }}>
        <Pagination
          page={page}
          count={totalPages}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </div>
      <ActionDialog
        message={actionDialogProps.message}
        type={actionDialogProps.type}
        isOpen={actionDialogProps.isOpen}
        onClose={actionDialogProps.onClose}
        onSubmit={() => {
          actionDialogProps.onOk();
        }}
        onCancel={() =>
          setActionDialogProps((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
      />
      <DriverHistoryModal isModalOpen={driverHistoryModalOpen} setIsModalOpen={setDriverHistoryModalOpen} history={historyRecord} setHistory={setHistoryRecord} selectedDriverID={selectedDriverID} getData={getDriverData}/>
      
    </div>
  );
}
