import { Card, Col, Divider, Row, Spin } from "antd";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ReactComponent as DragIcon } from "../../assets/images/icons/drag-icon.svg";
import "./DashboardMain.css";
import DonutChart from "../../Components/Charts/DonutChart";
import BarChart from "../../Components/Charts/BarChart";
import RouteDetail from "./RouteDetail";
import RoutesTable from "./RoutesTable";
import Statistic from "./Statistic";
import _ from "lodash";
import AuthService from "../../Components/auth/AuthService";
import dayjs from "dayjs";
import { updatePreference, userPreference } from "./Service/Preference";
import { useSelector } from "react-redux";
import {
  calculateTotals,
  getOrderById,
} from "../../Components/common/CommonFun";

const Home = () => {
  const { statItems, chartItems: chartItemsFromStore } = useSelector(
    (state) => state.UIState?.preferences
  );

  const [isRouteModalOpen, setIsRouteModalOpen] = useState(false);
  const [routeDetail, setRouteDetail] = useState({});
  const [routesData, setRoutesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [availableVehicleLoading, setAvailableVehicleLoading] = useState(false);
  const [availableVehicle, setAvailableVehicle] = useState(0);
  const [routeHistory, setRouteHistory] = useState({});
  const [routeHistoryLoading, setRouteHistoryLoading] = useState(false);
  const [currentDate] = useState(dayjs().format("YYYY-MM-DD"));

  const totals = useMemo(() => calculateTotals(routesData), [routesData]);

  const initialState = useMemo(
    () => [
      {
        id: "totalRevenue",
        title: "Total Revenue",
        component: DonutChart,
        data: [],
        labels: ["Delivered", "Not Delivered"],
        legendPostfix: "currency",
      },
      {
        id: "totalOrders",
        title: "Total Orders",
        component: DonutChart,
        data: [],
        labels: ["Delivered", "Postponed", "Not Delivered"],
      },
      {
        id: "totalWeight",
        title: "Total Weight",
        component: DonutChart,
        data: [],
        labels: ["Delivered", "Not Delivered"],
        legendPostfix: "kg",
      },
    ],
    []
  );

  const [chartItems, setChartItems] = useState(initialState);

  const Auth = useMemo(() => new AuthService(), []);

  const getRoutesData = useCallback(
    async (date, isLoading = true) => {
      try {
        if (isLoading) setLoading(true);
        const res = await Auth.post("/dashboard/routes", {
          delivery_date: date,
        });
        if (res) {
          setRoutesData(res.routes || []);
        }
      } catch (err) {
        console.error("Error fetching routes:", err);
      } finally {
        if (isLoading) setLoading(false);
      }
    },
    [Auth]
  );

  const getVehicles = useCallback(
    async (date, isLoading = true) => {
      try {
        if (isLoading) setAvailableVehicleLoading(true);
        const res = await Auth.fetch(
          `/dashboard/available-vehicles/?delivery_date=${date}`
        );
        if (res) {
          setAvailableVehicle(
            res?.available_vehicles[0]?.available_vehicles || 0
          );
        }
      } catch (error) {
        console.error("Error fetching vehicles:", error);
      } finally {
        if (isLoading) setAvailableVehicleLoading(false);
      }
    },
    [Auth]
  );

  const getRouteHistory = useCallback(
    async (date, isLoading = true) => {
      try {
        if (isLoading) setRouteHistoryLoading(true);
        const res = await Auth.fetch(
          `/dashboard/route-history/?delivery_date=${date}`
        );
        if (res) {
          const weightData = [];
          const orderData = [];
          const routeData = [];
          const revenueData = [];
          const categories = [];

          res?.route_history?.forEach((item) => {
            categories.push(item.delivery_date);
            weightData.push((item.order_weight / 1000).toFixed(2));
            orderData.push(item.total_orders);
            routeData.push(item.total_routes);
            revenueData.push((item.order_value / 1000).toFixed(2));
          });

          setRouteHistory({
            weightData,
            orderData,
            routeData,
            revenueData,
            categories,
          });
        }
      } catch (error) {
        console.error("Error fetching route history:", error);
      } finally {
        if (isLoading) setRouteHistoryLoading(false);
      }
    },
    [Auth]
  );

  const updateChartItem = useCallback(() => {
    const updatedChartItems = initialState.map((chartItem) => {
      const { id } = chartItem;
      const chartData = {
        totalRevenue: [
          totals?.order_value_delivered || 0,
          (totals?.revenue || 0) - (totals?.order_value_delivered || 0),
        ],
        totalOrders: [
          totals?.completed || 0,
          totals?.postponed || 0,
          (totals?.orders || 0) -
            (totals?.completed || 0) -
            (totals?.postponed || 0),
        ],
        totalWeight: [
          totals?.delivered_weight || 0,
          (totals?.weight || 0) - (totals?.delivered_weight || 0),
        ],
      };

      return {
        ...chartItem,
        data: chartData[id] || [],
        total: totals?.[id.replace("total", "").toLowerCase()] || 0,
        order:
          getOrderById({ data: chartItemsFromStore, id }) || chartItem.order,
      };
    });

    const sortedData = _.sortBy(updatedChartItems, "order");
    setChartItems(sortedData);
  }, [initialState, totals, chartItemsFromStore]);

  const onDragEnd = useCallback(
    (result) => {
      const { source, destination } = result;
      if (!destination) return;

      const reorderedItems = Array.from(chartItems);
      const [movedItem] = reorderedItems.splice(source.index, 1);
      reorderedItems.splice(destination.index, 0, movedItem);

      reorderedItems.forEach((item, index) => {
        item.order = index + 1;
      });

      const body = {
        chartItems: reorderedItems.map(({ id, order }) => ({ id, order })),
        statItems,
      };

      setChartItems(reorderedItems);
      updatePreference(body);
    },
    [chartItems, statItems]
  );

  const handleRouteClick = (routeData) => {
    setIsRouteModalOpen(true);
    setRouteDetail(routeData);
  };

  useEffect(() => {
    userPreference();
    getRoutesData(currentDate);
    getRouteHistory(currentDate);
    getVehicles(currentDate);

    const intervalId = setInterval(() => {
      getRoutesData(currentDate, false);
      getRouteHistory(currentDate, false);
      getVehicles(currentDate, false);
    }, 120000);

    return () => clearInterval(intervalId);
  }, [currentDate, getRoutesData, getRouteHistory, getVehicles]);

  useEffect(() => {
    updateChartItem();
  }, [totals, chartItemsFromStore, updateChartItem]);

  const renderChartCard = useCallback(
    ({ title, component: Component, ...props }) => (
      <Card title={title} bordered extra={<DragIcon />}>
        {loading ? <Spin className="chart-loader" /> : <Component {...props} />}
      </Card>
    ),
    [loading]
  );

  return (
    <div className="dashboard-main">
      <Statistic
        total={totals}
        loading={loading}
        vehicleLoading={availableVehicleLoading}
        totalVehicle={availableVehicle}
      />
      <Divider />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-row-2" direction="horizontal">
          {(provided) => (
            <Row
              gutter={[16, 16]}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {chartItems.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <Col
                      sm={24}
                      md={12}
                      lg={12}
                      xl={8}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {renderChartCard(item)}
                    </Col>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Row>
          )}
        </Droppable>
      </DragDropContext>
      <Divider />
      <Row>
        <Col span={24}>
          <Card title="Routes History" bordered>
            {routeHistoryLoading ? (
              <Spin className="chart-loader" />
            ) : (
              <BarChart data={routeHistory} />
            )}
          </Card>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          <Card title="Routes" bordered>
            <RoutesTable
              data={routesData}
              total={totals}
              onView={handleRouteClick}
              loading={loading}
            />
          </Card>
        </Col>
      </Row>
      {isRouteModalOpen && (
        <RouteDetail
          isModalOpen={isRouteModalOpen}
          handleCancel={() => setIsRouteModalOpen(false)}
          routeDetail={routeDetail}
        />
      )}
    </div>
  );
};

export default Home;
