import App from './Components/App';
import theme from './themes/index';
import ReactDOM from "react-dom/client";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { LayoutProvider } from './context/LayoutContext';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
//fonts
import './assets/fonts/ArtegraSansAlt-Black.ttf';
import './assets/fonts/ArtegraSansAlt-Light.ttf';
import './assets/fonts/ArtegraSansAlt-ExtraBold.ttf';
import './assets/fonts/ArtegraSansAlt-Bold.ttf';
import './assets/fonts/ArtegraSansAlt-Medium.ttf';
import './assets/fonts/ArtegraSansAlt-SemiBold.ttf';
import './assets/fonts/ArtegraSansAlt-Regular.ttf';
import './assets/fonts/ArtegraSansAlt-Thin.ttf';

import './index.css'
import { LoadingProvider } from './context/loadingContext';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import config from "../src/Components/config";

if (config.env === 'prod')
  console.log = console.warn = console.error = () => {};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {/* <LayoutProvider> */}
      <Provider store={store}>
        <PersistGate  persistor={persistor}>
      <LoadingProvider>
        <App />
      </LoadingProvider>
      </PersistGate>
        </Provider>
    {/* </LayoutProvider> */}
  </ThemeProvider>
);