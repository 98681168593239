import React, { useEffect } from 'react'
import { GoogleMap, useJsApiLoader, Marker, MARKER_LAYER, OverlayView, MarkerClusterer, InfoWindow, Polyline } from '@react-google-maps/api';
import { colors } from "../../Components/common/constants";
import { netsuite_url } from '../../Components/config';
const containerStyle = {
    width: '100%',
    height: '360px',
    border: "1px solid lightgray"
};

const center = {
    lat: 51.48665772344187,
    lng: -0.3971688021055391
 
};

const zoom = 8;

export default function Map(props) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAgOgGQ9BhLC5Udq2-9-d2ek8UZaqWUMNE"
    })

    const [map, setMap] = React.useState(null)
    const [path, setPath] = React.useState([])
    const [color, setColor] = React.useState([]);
    const [markers, setMarkers] = React.useState(props.markers)
    const [activeMarker, setActiveMarker] = React.useState(null);

    useEffect(() => {
        setMarkers(props.markers);
    }, [props.markers])

    useEffect(() => {
        if (props.type == "polyLine") {
            setPath([]);

            let p = [];
            p.push(center);
            props.markers.map((mark) => {
                let obj = { lat: Number(mark.customerLatitude), lng: Number(mark.customerLongitude) };
                p.push(obj);
            });
            p.push(center);
            setPath(p);
        }
    }, [markers]);

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);
        map.setZoom(zoom);

        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const handleMarkerClick = (marker) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    }

    const divStyle = {
        background: `white`,
        border: `1px solid #ccc`,
        padding: 15
    }

    const options = {
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: 30000,
        paths: [
            { lat: 37.772, lng: -122.214 },
            { lat: 21.291, lng: -157.821 },
            { lat: -18.142, lng: 178.431 },
            { lat: -27.467, lng: 153.027 }
        ],
        zIndex: 1
    };


    return isLoaded ? (
        <div>
            <GoogleMap
                mapContainerStyle={props.containerStyle}
                center={center}

                onLoad={onLoad}
                onUnmount={onUnmount}
            >
                <Marker key={0}
                    position={center}
                    icon={`/assets/images/theme/magna.png`}
                    onClick={() => { handleMarkerClick(-1) }}
                >
                    {activeMarker === -1 ? (
                        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                            <div>
                                <p> Magna Head Office</p>
                            </div>
                        </InfoWindow>
                    ) : null}
                </Marker>
                {markers.length > 0 && markers.map((marker, index) => {
                    return (<>
                        <Marker key={index}
                            position={{ lat: Number(marker.customerLatitude), lng: Number(marker.customerLongitude) }}
                            icon={props.color ? `/assets/images/numbers/${props.color.name}/${index + 1}.png` : ''}
                            onClick={() => { handleMarkerClick(index) }}
                        >
                            {activeMarker === index ? (
                                <InfoWindow onCloseClick={() => setActiveMarker(null)} style={{ padding: "5px" }}>
                                    <div>
                                        <p> Customer Name: <a href={`${netsuite_url}/app/common/entity/custjob.nl?id=${marker.customerInternalId}`} target="_blank">{marker.customerName}</a></p>
                                        <p> {Number(marker.invInternalId) > 0 ? (<span>Invoice: <a href={`${netsuite_url}/app/accounting/transactions/custinvc.nl?id=${marker.invInternalId}`} target="_blank">{marker.invoiceNo}</a></span>) : (<span>Order: <a href={`${netsuite_url}/app/accounting/transactions/salesord.nl?id=${marker.internalId}`} target="_blank">{marker.saleOrderNo}</a></span>)}</p>
                                        <p> PostCode: {marker.shipZip}</p>
                                        <p> Weight: {marker.orderWeight.toFixed(2)} KG</p>
                                        <p> Opening Hours: {marker.customerOpeningTime}</p>
                                        <p> Closing Hours: {marker.customerClosingTime}</p>
                                    </div>
                                </InfoWindow>
                            ) : null}
                        </Marker>
                    </>)
                })
                }
                {props.type == "polyLine" && path.length > 0 && (
                    <>
                        <Polyline
                            key={"plylne"}
                            path={path}
                            geodesic={true}
                            options={{ strokeColor: props.color ? props.color.code : "#000", strokeWeight: 2 }}
                        />
                    </>)
                }
            </GoogleMap>
        </div>
    ) : <></>
}