import React, { useState } from "react";
  
import { Pagination, IconButton, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import AuthService from "../../Components/auth/AuthService";
import moment from "moment";
import { equipmentMakes, equipmentModels, equipmentOwnership, equipmentStatus, equipmentTypes } from "../../Components/common/constants";
import { InputBaseField } from "../../Components/InputFields/InputFields";
import { FormButton } from "../../Components/UiElements/UiElements";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
import { ActionDialog } from "../../Components/actionDialog/ActionDialog";
import { DeleteFilled, EditFilled, EditTwoTone, HistoryOutlined, PlusCircleFilled, SearchOutlined } from "@ant-design/icons";
import { Table, Button as AntButton } from "antd";
import { useNavigate } from "react-router-dom";
import config from "./../../Components/config";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from '@mui/icons-material/Close';


const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  tblTopDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  tblsearch: {
    display: "flex",
    // float: "left",
  },
  addIcon: {
    // float: "right",
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
  antAddIcon: {
    "& .anticon-plus-circle:hover": {
      color: "#4096ff !important"
    }
  },
  inputStyle: {
    "& .MuiInputBase-root-MuiOutlinedInput-root": {
      marginBottom: '0px !important'
    }
  }
}));

const Auth = new AuthService();

export default function EquipmentList() {
  const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const navigate = useNavigate();

  const [equipments, setEquipments] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(null);

  const [limit] = React.useState(50);

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [filesOpen, setFilesOpen] = React.useState(false);
  const [selectedRecordId, setSelectedRecordId] = React.useState(0);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [screenWidth, setScreenWidth] = React.useState("");
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  React.useEffect(() => {
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleFilesOpen = (row) => {
    if (row?.files) {
      setSelectedRecordId(row.id);
      setSelectedFiles(JSON.parse(row?.files));
    }
    setFilesOpen(true);
  };
  const handleFilesClose = () => setFilesOpen(false);

  //State For Action Dialog
  const [actionDialogProps, setActionDialogProps] = useState({
    message: "",
    type: "",
    isOpen: false,
  });
  const ShowActionDialog = (
    message,
    type,
    isOpen,
    OnOkCallback,
    OnCancellCallback
  ) => {
    setActionDialogProps((prevState) => ({
      ...prevState,
      message: message,
      type: type,
      isOpen: isOpen,
      onOk: OnOkCallback,
      onCancel: OnCancellCallback,
    }));
  };

  const equipmentColumns = [
    {
      title: "Status",
      render(record) {
        return {
          props: {
            style: {background: record.background_color}
          },
          children: <span>
            {record.status != null
            ? equipmentStatus.filter(
              (obj) => obj.id == record.status
            )[0].title
            : "-"}
            </span>
        };
      }
    },
    {
      title: 'Tag No.	',
      dataIndex: 'title',
      key: 'title',
      width: 135,
    },
    {
      title: 'Sr No.',
      dataIndex: 'serial',
      key: 'serial',
    },
    // {row.type ? equipmentTypes.filter((obj) => obj.id == row.type)[0].title : ""}
    {
      title: 'Type',
      render: (record) => {
        return record.type
        ? equipmentTypes.filter((obj) => obj.id == record.type)[0].title : ""
      },
    },
    {
      title: 'Make',
      render: (record) => {
        return record.make
        ? equipmentMakes.filter((obj) => obj.id == record.make)[0].title
        : ""
      },
    },
    {
      title: 'Model',
      render: (record) => {
        return record.model
        ? equipmentModels.filter((obj) => obj.id == record.model)[0].title : ""
      },
    },
    {
      title: 'Ownership',
      render: (record) => {
        return record.ownership
        ? equipmentOwnership.filter((obj) => obj.id == record.ownership)[0].title : ""
      },
    },
    {
      title: 'Due Date',
      render: (record) => {
        return record.due_date ? moment(record.due_date).format("DD-MMM-YYYY") : ""
      },
    },
    {
      title: 'Files',
      render: (record) => {
        return JSON.parse(record.files) != null && JSON.parse(record.files)?.length > 0 ? (
          <Button
            onClick={() => {
              handleFilesOpen(record);
            }}
          >
            {JSON.parse(record.files)?.length + "-Files"}
          </Button>
        ) : (
          <Button
          disabled
          >
            { " 0-Files"}
          </Button>
        )
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: "right",
      dataIndex: '',
      render(record) {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <HistoryOutlined onClick={() => handleHistory(record.id)} style={{ fontSize: '25px', cursor: "pointer", marginRight: "5px", color: "#001529"}} />
            <EditFilled  onClick={() => handleRowClick(record.id)}  style={{ fontSize: '25px', cursor: "pointer", marginRight: "5px", color: "#006cb8"}} />
            <DeleteFilled onClick={() => handleDelete(record.id)} style={{ fontSize: '25px', cursor: "pointer", color: "#cd1437" }} />
          </div>
        );
      },
    },
  ];

  React.useEffect(() => {
    showLoading(layoutDispatch);
    Auth.post(`/equipment/equipments`, {
      limit: limit,
      page: page,
      searchText: searchText,
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack) {
          setEquipments(res.equipments);
          setTotalPages(Math.ceil(res.total_count / limit));
          setTotalCount(res.total_count);

        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleRowClick = (id) => {
    navigate(`/equipments/${id}`)
  };

  const handleHistory = (id) => {
    navigate(`/equipments/maintenance/${id}`)
  }
  const Search = () => {
    showLoading(layoutDispatch);
    Auth.post(`/equipment/equipments`, {
      limit: limit,
      page: 1,
      searchText: searchText,
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack) {
          setEquipments(res.equipments);
          setTotalPages(Math.ceil(res.total_count / limit));
  setTotalCount(res.total_count);

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleDelete = (id) => {
    // if (window.confirm("Are you sure to want to delete this record?")) {
    ShowActionDialog(
      "Are you sure to want to delete this record?",
      "confirm",
      true,
      function () {
        const params = {
          method: "DELETE",
          body: JSON.stringify({
            id: id,
          }),
        };
        // setLoading(true);
        showLoading(layoutDispatch);
        Auth.fetch(`/equipment/equipment`, params)
          .then((res) => {
            hideLoading(layoutDispatch);
            if (res.ack) {
              setType("info");
              setLoadMessage(res.message);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
              // setLoading(false);
            } else {
              setLoadMessage("error");
              setLoadMessage(res.status);
              // setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };

  return (
    <div>
          <h2 style={{marginBottom: '10px', marginTop: 0}}>Equipments</h2>
      {/* <Button variant="contained" style={{marginBottom: "15px"}} onClick={()=>{window.location = "/import-equipments"}}>Import Equipments</Button> */}
      <div className={classes.tblTopDiv}>
      <div style={{display: "flex", flexDirection: "column", gap: "8px"}}>
          <div style={{display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px"}}>
            <InputBaseField
            id="searchText"
            name="searchText"
            value={searchText}
            onChange={handleSearch}
            placeholder="Search"
          />
            
          <AntButton
            
            // variant="contained"
            type="primary"
            size="large"
            onClick={Search}
            style={{ marginLeft: '5px'}}
            icon={<SearchOutlined />}
          >
            Search
          </AntButton>
          </div>
          </div>
        {/* <div className={classes.tblsearch}>
          
          <InputBaseField
            id="searchText"
            name="searchText"
            value={searchText}
            onChange={handleSearch}
            placeholder="Search"
          />
          <FormButton
            sx={{ float: "right", height: "35.63px", marginLeft: "10px" }}
            type="search"
            onClick={Search}
          >
            Search
          </FormButton>
        </div> */}
        <div>
          <div className={classes.antAddIcon}>
            <PlusCircleFilled style={{ fontSize: "30px", color:"#1677FF"}}  onClick={() =>navigate("/equipments/new")} />
          </div>

          {/* <Link href="/equipment/new">
            <AddCircleIcon fontSize="large" sx={{
                color: appTheme.btnColor,
              }}/>
          </Link> */}
        </div>
      </div>
      <div className={classes.antTable}>
      <Table
          dataSource={equipments}
          style={{ overflowX: "auto" }}
          columns={equipmentColumns}
          pagination={false}
          // footer={()=>`List of Equipments - Total Records: ${equipments.length === 0 ? "0" : equipments.length  }`}
          footer={() => {
            const startRecord = (page - 1) * limit + 1;
            const endRecord = Math.min(page * limit, equipments.length ? totalCount : 0); 
            return `Showing ${startRecord}-${endRecord} of ${equipments.length ? totalCount : "0"} Records`;
          }}
        />
      </div>
      {/* <TableContainer component={Paper}>
        <Table
          sx={{ width: "100%" }}
          aria-label="caption table"
          size="small"
          dense
        >
          <caption>List of Equipment</caption>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Tag No.</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Sr No.</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Type</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Make</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Model</TableCell>
              <TableCell style={{ fontWeight: "bold", width: "120px" }}>Ownership</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>LOLER Certification Due Date</TableCell>
              <TableCell style={{ fontWeight: "bold", width: "200px" }} align="right">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {equipments.map((row, index) => (
              <TableRow
                style={
                  index % 2
                    ? { background: "#D3D3D3" }
                    : { background: "white" }
                }
                key={row.id}
                className={classes.row}
              >
                <TableCell style={{ background: row.background_color }}>
                  {row.status != null
                    ? equipmentStatus.filter(
                      (obj) => obj.id == row.status
                    )[0].title
                    : "-"}
                </TableCell>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.serial}</TableCell>
                <TableCell>
                  {row.type ? equipmentTypes.filter((obj) => obj.id == row.type)[0].title : ""}
                </TableCell>
                <TableCell>
                  {row.make
                    ? equipmentMakes.filter((obj) => obj.id == row.make)[0].title
                    : ""}
                </TableCell>
                <TableCell>
                  {row.model
                    ? equipmentModels.filter((obj) => obj.id == row.model)[0].title
                    : ""}
                </TableCell>
                <TableCell>
                  {row.ownership
                    ? equipmentOwnership.filter((obj) => obj.id == row.ownership)[0].title
                    : ""}
                </TableCell>
                <TableCell>{row.due_date ? moment(row.due_date).format("DD-MMM-YYYY") : ""}</TableCell>
                <TableCell align="right">
                  <HistoryIcon
                    className={classes.actionBtn}
                    onClick={() => {
                      handleHistory(row.id);
                    }}
                  />
                  <EditIcon
                    className={classes.actionBtn}
                    onClick={() => {
                      handleRowClick(row.id);
                    }}
                  />
                  <DeleteIcon
                    className={classes.actionBtn}
                    onClick={() => {
                      handleDelete(row.id);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Message type={type} msg={loadMessage} />
      </TableContainer> */}
      <div style={{ float: "right", margin: "10px" }}>
        <Pagination
          page={page}
          count={totalPages}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </div>
      <ActionDialog
        message={actionDialogProps.message}
        type={actionDialogProps.type}
        isOpen={actionDialogProps.isOpen}
        onClose={actionDialogProps.onClose}
        onSubmit={() => {
          actionDialogProps.onOk();
        }}
        onCancel={() =>
          setActionDialogProps((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
      />
      {/* ==================popup for Showing uploaded Image==================== */}
      <Modal
          open={filesOpen}
          onClose={handleFilesClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              overflowY: "auto",
              maxHeight: "90vh",
              transform: "translate(-50%, -50%)",
              width: screenWidth < 610 ? "90%" : 600,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <IconButton
            edge="end"
            color="inherit"
            onClick={handleFilesClose}
            aria-label="close"
            sx={{ position: 'absolute', top: 0, right: 0, marginRight: '5px'}}
        >
          <CloseIcon />
        </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Download the files below
            </Typography>
            {selectedFiles.length > 0 && (
              <p style={{ padding: "0" }}>Files</p>
            )}
            {selectedFiles.length > 0 &&
              selectedFiles.map((file, idx) => {
              const lastHyphenIndex = file.lastIndexOf('-')
              const dotBeforeExtensionIndex = file.lastIndexOf('.');
              const newFileName = file.slice(0, lastHyphenIndex) + file.slice(dotBeforeExtensionIndex);
                return (
                  <li key={idx}>
                    <a
                      href={`${config.tms_uploads_cdn}/${config.env}/equipments/${selectedRecordId}/${file}`}
                      target="_blank"
                    >
                      {newFileName}
                    </a>
                  </li>
                );
              })}
          </Box>
        </Modal>
    </div>
  );
}
