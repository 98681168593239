import React,{useReducer} from 'react';
import { createContext } from 'react';

const LoadingState=createContext();
const LoadingDispatch=createContext();
 


const LoadingReducer=(state={isLoading:false},action)=>{
    switch(action.type){
        case 'SHOW':
            return {
              ...state,
                isLoading:true
            }
            case 'HIDE':
            return {
              ...state,
                isLoading:false
            }
            default:{
                throw new Error(`Invalid action: ${action.type}`);
            }
    }
}
const LoadingProvider=({children})=>{
    const [state,dispatch]=useReducer(LoadingReducer,{
        isLoading:false,
    });

    return (
        <LoadingState.Provider value={state}>
            <LoadingDispatch.Provider value={dispatch}>
            {children}
            </LoadingDispatch.Provider>
        </LoadingState.Provider>
    )
}
function useLoadingState() {
    var context = React.useContext(LoadingState);
    if (context === undefined) {
      throw new Error("useLoadingState must be used within a LoadingProvider");
    }
    return context;
}
function useLoadingDispatch() {
var context = React.useContext(LoadingDispatch);
if (context === undefined) {
    throw new Error("useLoadingDispatch must be used within a LoadingProvider");
}
return context;
}
const showLoading=(dispatch)=>{
    dispatch({type:"SHOW"});
}
const hideLoading=(dispatch)=>{
    dispatch({type:"HIDE"});
}

export { LoadingProvider,useLoadingState,useLoadingDispatch,showLoading,hideLoading };
