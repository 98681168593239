import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Divider,
  Input,
  Modal,
  Row,
  Table,
  Tag,
} from "antd";
import "./reports.css";
import { makeStyles } from "@mui/styles";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import moment from "moment";
import { hideLoading, showLoading, useLoadingDispatch } from "../../context/loadingContext";
import { useSelector } from "react-redux";
import { userRolesTypes } from "../../Components/common/constants";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { env, netsuite_url } from "../../Components/config";
const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    padding: '10px',
    "& .ant-card .ant-card-body": {
      padding: "4px",
      backgroundColor: "rgb(240, 240, 240)",
    },
    "& .ant-card .ant-card-head": {
      padding: "0px 4px",
      // backgroundColor: "rgb(240, 240, 240)",
      minHeight: '40px'
    },
    "& .ant-statistic .ant-statistic-content": {
      fontSize: "12px",
      fontWeight: "bold",
    },
    "& .ant-statistic .ant-statistic-title": {
      fontSize: "12px",
    },
    "& .example-custom-input": {
      width: "100% !important",
    },
    "& .react-datepicker-wrapper": {
      width: "100% !important",
    },
    "& .ant-input[disabled]": {
      background: "none",
      color: "black",
      // fontWeight: "500",
    },
  },
}));

const InventoryReport = ({
  inventoryData,
  setInventoryData,
  deliveryDate,
  getInventoryData,
  setInventoryAdjustmentInternalID,
  inventoryAdjustmentInternalID,
  setInventoryAdjustmentCode,
  inventoryAdjustmentCode,
  setInventoryPostDate,
  inventoryPostDate
}) => {
  const classes = useStyles();
  const [loadMessage, setLoadMessage] = useState("");
  const [type, setType] = useState("");
  const [selectedDates, setSelectedDates] = useState(null);
  var layoutDispatch = useLoadingDispatch();  


  const { roles } = useSelector((state) => state.AppState?.userTypes);
  const UserType = useSelector((state) => state.AppState?.userTypes?.type);
  const matchedRoles = roles
    .map((role) => {
      return userRolesTypes.find((userRole) => userRole.id === role.role_id);
    })
    .filter(Boolean);
  const includesFinance = matchedRoles.some((item) => item.title === "Finance");

  console.log('inventoryData', inventoryData);
  



  const dataCol = [
    {
      title: "Item Code",
      dataIndex: "item_id",
      key: "item_id",
      width: 80,
    },
    {
      title: "Item Name",
      dataIndex: "item_name",
      key: "item_name",
      width: 160,
    },
    {
      title: "Ordered QTY",
      dataIndex: "quantity",
      key: "quantity",
      width: 80,
      align: 'center'
    },
    {
      title: "Delivered QTY",
      dataIndex: "delivered_quantity",
      key: "delivered_quantity",
      width: 80,
      align: 'center'

    },
    {
      title: "Returned QTY",
      dataIndex: "returned_quantity",
      key: "returned_quantity",
      width: 80,
      align: 'center'

    },
    {
      title: "Returned To Stock QTY",
      dataIndex: "returned_quantity_to_stock",
      key: "returned_quantity_to_stock",
      width: 80,
      align: 'center'

    },
    {
      title: "Returned To Damages",
      // dataIndex: "returned_quantity_to_stock",
      render: (text, record) => {
        return (
          <span>
            
              <Tag color="red">{record?.returned_quantity_to_damages}</Tag>
          </span>
        );
      },
      width: 100,
      align: 'center'

    },
    {
      title: "Return to Damages Posted",
      render: (_, record, index) => (
        <Input
        type="number"
          value={record.returned_quantity_to_damages_posted}
          onChange={(e) => handleInputChange(e.target.value, index)}
          disabled={inventoryData?.posted}
        />
      ),
      width: 150,
      align: 'center'
    }
  ];  

  const handleInputChange = (value, index) => {
    const updatedInventoryAdjustments = [...inventoryData.inventory_adjustments];
    updatedInventoryAdjustments[index].returned_quantity_to_damages_posted = value;

    // Update the state with the new adjustments
    setInventoryData({
      ...inventoryData,
      inventory_adjustments: updatedInventoryAdjustments
    });
  };



  const handleSubmit = async () => {
    showLoading(layoutDispatch)
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }

    const dataToSend = {
      delivery_date: moment(deliveryDate).format("YYYY-MM-DD"),
      inventory_adjustments: inventoryData?.inventory_adjustments,
      posting_date: moment(selectedDates).format("YYYY-MM-DD")
    };

    try {
      const response = await Auth.fetch("/route/save-inventory-adjustments", {
        method: "POST",
        body: JSON.stringify(dataToSend),
      });
      if (response.ack) {
        setType("success");
        setLoadMessage(response.message);
        getInventoryData()
      } else {
        setType("error");
        setLoadMessage(response.message + str);
      }
    } catch (error) {
      console.log(error);
      setType('error')
      setLoadMessage('something went wrong' + str)
    } finally {
      hideLoading(layoutDispatch)
    }
  };

  useEffect(() => {
    const initialDates = inventoryData?.posted ?  inventoryPostDate : deliveryDate

    setSelectedDates(initialDates);
  }, [inventoryData]);


  return (
    <div className={classes.dataSheet}>
      

      <Row
                    gutter={[6, 16]}
                    style={{
                      marginTop: "10px",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    {(UserType == 1 || includesFinance) && (
                      <>
                        <DatePicker
                          format={"YYYY-MM-DD"}
                          style={{ width: 160, marginRight: "10px" }}
                          value={
                            selectedDates
                              ? dayjs(selectedDates)
                              : null
                          }
                          onChange={(_, dateStr) => {
                            setSelectedDates(dateStr);
                          }}
                          placeholder="Select Date"
                          disabled={
                            inventoryData?.posted 
                          }
                        />
                      </>
                    )}
                    {
                      !inventoryData?.posted && (
                        <>
                         {(UserType == 1 || includesFinance) && (
                          <>
                              <Button
                                disabled={
                                  inventoryData?.posted 
                                }
                                onClick={() => {
                                  Modal.confirm({
                                    title: 'Post Inventory Adjustments',
                                    centered: true,
                                    content: `Are you sure to post inventory adjustments?`,
                                    onOk:()=> handleSubmit(),
                                    okText: 'Post',
                                    width: 600,
                                    okButtonProps: { size: "large"  },
                                    cancelButtonProps: { size: "large" },
                                  });
                                }}
                                type="primary"
                              >
                                Post to Netsuite
                              </Button>
                          </>
                        )}
                        </>
                      )
                    }
                       

                    {(inventoryData?.posted &&
                      <Link
                        to={`${netsuite_url}/app/accounting/transactions/invadjst.nl?id=${inventoryAdjustmentInternalID}&whence=`}
                        target="_blank"
                        style={{ marginLeft: "5px" }}
                      >
                        <span>
                          View Inventory Adjustment {inventoryAdjustmentCode ? `(${inventoryAdjustmentCode})` : ''}
                          </span>
                      </Link>
                    )}
                  </Row>

      
      {inventoryData?.inventory_adjustments.length > 0 && (
        <>
          <div className="view-page">

            <Table
              style={{ overflowX: "auto", marginTop: "10px" }}
              columns={dataCol}
              dataSource={inventoryData?.inventory_adjustments}
              pagination={false}
            />
            <Divider style={{ height: "2px", background: "#006cb8" }} />
          </div>
        </>
      )}
      <Message type={type} msg={loadMessage} />
    </div>
  );
};

export default InventoryReport;
