import React, { useState } from "react";
import { Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AuthService from "../../Components/auth/AuthService";
import { CheckBoxField, InputBaseField } from "../../Components/InputFields/InputFields";
import { FormButton } from "../../Components/UiElements/UiElements";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
import { ActionDialog } from "../../Components/actionDialog/ActionDialog";
import { DeleteFilled, EditFilled, EditTwoTone } from "@ant-design/icons";
import { Table } from "antd";
import { useNavigate } from "react-router-dom";
import Message from "../../Components/common/Message";

const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  tblTopDiv: {},
  tblsearch: {
    display: "flex",
    float: "left",
  },
  addIcon: {
    float: "right",
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
}));

const Auth = new AuthService();

export default function ProductList() {
  const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const navigate = useNavigate();

  const [products, setProducts] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [showOnHold, setShowOnHold] = React.useState(0);
  const [showInactive, setShowInactive] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(null);

  const [limit] = React.useState(50);

  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");

  //State For Action Dialog
  const [actionDialogProps, setActionDialogProps] = useState({
    message: "",
    type: "",
    isOpen: false,
  });
  const ShowActionDialog = (
    message,
    type,
    isOpen,
    OnOkCallback,
    OnCancellCallback
  ) => {
    setActionDialogProps((prevState) => ({
      ...prevState,
      message: message,
      type: type,
      isOpen: isOpen,
      onOk: OnOkCallback,
      onCancel: OnCancellCallback,
    }));
  };

  const productColumns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 135,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Cases in Pallet',
      dataIndex: 'cases_in_pallets',
      key: 'cases_in_pallets',
      width: 135,
    },
    {
      title: "On Hold",
      width: 100,
      render(record) {
        return {
          props: {
            style: { background: record?.status == 1   ? "#FF0000" : (record?.on_hold == 1 ? "#FF8000" : "") }
          },
          children: <div>{record?.on_hold == 1 ? "Yes":"No"}</div>
        };
      }
    },
    {
      title: "Status",
      width: 100,
      render(record) {
        return {
          props: {
            style: {background: record?.status == 1   ? "#FF0000" : (record?.on_hold == 1 ? "#FF8000" : "")  }
          },
          children: <div>{record?.status == 1 ? "Yes":"No"}</div>
        };
      }
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: '',
      align: "right",
      render(record) {
        return (
          <div  onClick={(e) => e.stopPropagation()}>
            <EditFilled  onClick={() => handleRowClick(record.id)}  style={{ fontSize: '25px', cursor: "pointer", marginRight: "5px", color: "#001529"}} />
            <DeleteFilled onClick={() => handleDelete(record.id)} style={{ fontSize: '25px', cursor: "pointer", color: "#cd1437" }} />
          </div>
        );
      },
    },
  ];

  React.useEffect(() => {
    // showLoading(layoutDispatch);
    Search();
  }, [page, showInactive, showOnHold]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleRowClick = (id) => {
    navigate(`/product/${id}`)
  };

  const Search = () => {
    showLoading(layoutDispatch);

    let onHold = showOnHold == true ? 1 : 0;
    let inactive = showInactive == true ? 1 : 0;

    setProducts([]);

    Auth.post(`/product/products`, {
      limit: limit,
      page: 1,
      searchText: searchText,
      on_hold: onHold,
      in_active: inactive
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack) {
          setProducts(res.products);
          setTotalPages(Math.ceil(res.total_count / limit));
  setTotalCount(res.total_count);

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleDelete = (id) => {
    // if (window.confirm("Are you sure to want to delete this record?")) {
    ShowActionDialog(
      "Are you sure to want to delete this record?",
      "confirm",
      true,
      function () {
        const params = {
          method: "DELETE",
          body: JSON.stringify({
            id: id,
          }),
        };
        // setLoading(true);
        // showLoading(layoutDispatch);
        Auth.fetch(`/product/product`, params)
          .then((res) => {
            // hideLoading(layoutDispatch);
            if (res.ack) {
              setType("info");
              setLoadMessage(res.message);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
              // setLoading(false);
            } else {
              setLoadMessage("error");
              setLoadMessage(res.status);
              // setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };

  const SyncToNetsuite = () => {
    Auth.post(`/product/sync-products`)
      .then((res) => {
        // hideLoading(layoutDispatch);
        if (res.ack) {
          setLoadMessage("success");
          setLoadMessage("Products sync successful");
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleShowInactiveItems = (e, type) => {
    if (type == 1) {
      setShowOnHold(e.target.checked);
    }
    else {
      setShowInactive(e.target.checked);
    }
  }

  return (
    <div>
      {/* <Button variant="contained" style={{marginBottom: "15px"}} onClick={()=>{window.location = "/import-products"}}>Import Products</Button> */}
      <div className={classes.tblTopDiv}>
        <div className={classes.tblsearch}>
          {/* <Button
            style={{ float: "right", height: "55px"}}
            variant="contained"
            color="primary"
            onClick={Search}
            size="large"
            endIcon={<SearchIcon />}
          >
            Search
          </Button> */}
          {/* <TextField
            style={{ float: "right", width: "350px", height: "10px", marginRight: "5px" }}
            id="standard-adornment-password"
            type="text"
            label="Search"
            variant="outlined"
            name="searchText"
            value={searchText}
            onChange={handleSearch}
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       <IconButton aria-label="Search By Student Name" onClick={Search}>
            //         <SearchIcon />
            //       </IconButton>
            //     </InputAdornment>
            //   ),
            // }}
          /> */}
          <InputBaseField
            id="searchText"
            name="searchText"
            value={searchText}
            onChange={handleSearch}
            placeholder="Search"
          />
          <FormButton
            sx={{ float: "right", height: "35.63px", marginLeft: "10px" }}
            type="search"
            onClick={Search}
          >
            Search
          </FormButton>
          <CheckBoxField
            id="checkbox"
            name="showOnHold"
            label="Show On hold"
            value={showOnHold}
            onChange={(e) => { handleShowInactiveItems(e, 1) }}
          />
          <CheckBoxField
            id="checkbox"
            name="showInactive"
            label="Show Inactive"
            value={showOnHold}
            onChange={(e) => { handleShowInactiveItems(e, 2) }}
          />
        </div>
        <div className={classes.addIcon}>
          {/* <FormButton
            sx={{ float: "right", height: "35.63px", marginLeft: "10px" }}
            type="sync"
            onClick={SyncToNetsuite}
          >
            Sync to Netsuite
          </FormButton> */}
        </div>
      </div>
      <div className={classes.antTable}>
      <Table
          dataSource={products}
          style={{ overflowX: "auto" }}
          columns={productColumns}
          pagination={false}
          // footer={()=>`List of Products - Total Records: ${products.length === 0 ? "0" : products.length  }`}
          footer={() => {
            const startRecord = (page - 1) * limit + 1;
            const endRecord = Math.min(page * limit, products.length ? totalCount : 0); 
            return `Showing ${startRecord}-${endRecord} of ${products.length ? totalCount : "0"} Records`;
          }}
        />
      </div>
      <Message type={type} msg={loadMessage} />
      <div style={{ float: "right", margin: "10px" }}>
        <Pagination
          page={page}
          count={totalPages}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </div>
      <ActionDialog
        message={actionDialogProps.message}
        type={actionDialogProps.type}
        isOpen={actionDialogProps.isOpen}
        onClose={actionDialogProps.onClose}
        onSubmit={() => {
          actionDialogProps.onOk();
        }}
        onCancel={() =>
          setActionDialogProps((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
      />
    </div>
  );
}
