const initialState = {
  chartItems: [],
  statItems: [],
};

const dashboardPreference = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CHART_PREFERENCE":
      return {
        ...state,
        chartItems: action.payload,
      };
    case "SET_STAT_PREFERENCE":
      return {
        ...state,
        statItems: action.payload,
      };
    default:
      return state;
  }
};

export default dashboardPreference;
