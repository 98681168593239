import React from 'react';
import { Link } from'react-router-dom';
import { Typography,Stack } from '@mui/material';
import { Divider } from 'antd';

function CopyRight(props) {
    return (
        <Stack direction="column">
                <Divider style={{background: "white", marginBottom: "5px", marginTop:"5px"}} />
        <Typography sx={{color:'#ffffffbf'}} variant="body2" color="text.secondary" align="center" {...props}>
            Copyright ©
        </Typography>
        <Typography sx={{color:'#ffffffbf',"& a":{color:'#96c1df',textDecoration:'none', fontWeight:"600"}}} variant="body2" color="text.secondary" align="center" {...props}>
            <Link color="inherit" to="https://prefectos.com/">
                Prefectos
            </Link>{' '}
            {new Date().getFullYear()}
            {/* {'.'} */}
        </Typography>
        </Stack>
    );
}

export default CopyRight