import { styled } from "@mui/material/styles";
import { Drawer, ListItemButton } from "@mui/material";
import {
  MoveToInbox as MoveToInboxIcon,
  Dashboard as DashboardIcon,
  Route as RouteIcon,
  People as PeopleIcon,
  LocalShipping as LocalShippingIcon,
  Engineering as EngineeringIcon,
  PrecisionManufacturing as PrecisionManufacturingIcon,
  Print as PrintIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  LocationCity as LocationCityIcon,
  AccountBalance as AccountBalanceIcon,
  Inventory2 as Inventory2Icon,
  CorporateFare,
  RecentActors,
  PeopleAlt as PeopleAltIcon,
  AltRoute as AltRouteIcon,
  Home as HomeIcon,
  CarCrash as CarCrashIcon
} from "@mui/icons-material";

//App Theme
import { appTheme } from "../Utils";
import { ReconciliationFilled } from "@ant-design/icons";
const { sidebar } = appTheme;

//SideBar MenuList
const SideBarList = [
  {
    path: "/home",
    title: "Home",
    icon: HomeIcon,
    filterPath: '/home',
    children: null
  },
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: DashboardIcon,
    filterPath: '/dashboard',
    children: null
  },
  {
    path: "/users",
    title: "Users",
    icon: PeopleAltIcon,
    children: null,
  },
  {
    title: "Routing",
    stateName: "routing",
    Icon: AltRouteIcon,
    filterPath: '/routes',
    children: [
      {
        path: "/routes",
        title: "Routes",
        stateName: "routing",
        icon: RouteIcon,
      },
      {
        path: "/vehicles",
        title: "Vehicles",
        stateName: "routing",
        icon: LocalShippingIcon,
      },
      {
        path: "/drivers",
        title: "Drivers",
        stateName: "routing",
        icon: RecentActors,
      },

      {
        path: "/helpers",
        title: "Helpers",
        stateName: "routing",
        icon: PeopleIcon,
      },
      {
        path: "/rosters",
        title: "Rosters",
        stateName: "routing",
        icon: CorporateFare,
      },
      {
        path: "/pcn",
        title: "PCN",
        stateName: "routing",
        icon: PlaylistAddCheckIcon,
      },
      {
        path: "/accident",
        title: "Accident Report",
        stateName: "routing",
        icon: CarCrashIcon,
      },
    ],
  },
  // {
  //   title: "Vehicle",
  //   Icon: LocalShippingIcon,
  //   stateName: "vehicle",
  //   children: [
  //     {
  //       path: "/vehicles",
  //       title: "Vehicles",
  //       stateName: "vehicle",
  //       icon: LocalShippingIcon,
  //     },
  //     {
  //       path: "/vehicle_service",
  //       title: "Service",
  //       stateName: "vehicle",
  //       icon: MiscellaneousServicesOutlined,
  //     },
  //     {
  //       path: "/vehicle_reminder",
  //       title: "Reminder",
  //       stateName: "vehicle",
  //       icon: AccessAlarmOutlined,
  //     },
  //   ],
  // },
  {
    title: "Maintenance",
    Icon: EngineeringIcon,
    stateName: "maintenance",
    children: [
      {
        path: "/equipments",
        title: "Equipment",
        stateName: "maintenance",
        icon: PrecisionManufacturingIcon,
      },
      {
        path: "/maintenance",
        title: "Maintenance",
        stateName: "maintenance",
        icon: EngineeringIcon,
      },
    ],
  },
  // {
  //   title: "WMS ",
  //   Icon: Inventory2Icon,
  //   stateName: "wms",
  //   // iconPath: wmsIcon,
  //   children: [
  //     {
  //       path: "/aisles",
  //       title: "Aisles",
  //       stateName: "wms",
  //       icon: LocationCityIcon,
  //       // iconPath: aisles,
  //     },
  //     {
  //       path: "/locations",
  //       title: "Locations",
  //       stateName: "wms",
  //       icon: AccountBalanceIcon,
  //       // iconPath: locations,
  //     },
  //     {
  //       path: "/products",
  //       title: "Products",
  //       stateName: "wms",
  //       icon: Inventory2Icon,
  //       // iconPath: products,
  //     },
  //   ],
  // },
  {
    title: "Reports",
    Icon: PrintIcon,
    stateName: "report",
    filterPath: '/view_reports',
    children: [
      {
        path: "/pallets_report",
        title: "Pallets Builder",
        stateName: "report",
        icon: MoveToInboxIcon,
      },
      {
        path: "/print_reports",
        title: "Print Reports",
        stateName: "report",
        icon: PrintIcon,
      },
      {
        path: "/view_reports",
        title: "View Reports",
        stateName: "report",
        icon: PlaylistAddCheckIcon,
      },
      ,
      {
        path: "/maintenance_report",
        title: "Maintenance",
        stateName: "report",
        icon: PrintIcon,
      },
    ],
  },
];

const CustomListItemButton = styled(ListItemButton)(({ theme, ...props }) => ({
  color: "#ffffff",
  fontFamily: "Lat",
  fontStyle: "normal",
  fontSize: "16px",
  fontFamilyColor: "ArtegraSansAltBold",
  lineHeight: "16px",
  padding: "1px",
  width: "100%",
  borderRadius: "8px",
  margin: "0px 0",
  backgroundColor: props.myBackgroundColor,
  color: props.svgColor,
  "& svg": {
    color: props.svgColor,
  },
  "&:hover": {
    backgroundColor: '#12151526',
    "& .MuiListItemText-root": {
      color: 'white'
    },
    "& svg": {
      color: "white",
    },
  },
  "&:active": {
    textDecoration: "none",
  },
  "& .MuiTypography-root": {
    fontSize: '14px '
  }
}));
const openedMixin = (theme) => ({
  width: "210px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  width: "48px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(9)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
  "& .MuiListItemIcon-root": {
    width: "24px",
    minWidth: "24px",
  },
  "& .MuiButtonBase-root": {
    display: "flex",
    justifyContent: "center",
  },
  "& svg": {
    width: "24px",
    height: "24px",
  },
  "& .MuiListItemText-root": {
    display: "none",
  },
});
const MyCusomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: "260px",
  padding: "0 12px",
  display: "flex",
  flexDirection: "column",
  minHeight: "calc(100vh - 64px)",
  // backgroundColor:'#5491b9',
  backgroundColor: sidebar.bgColor,
  // backgroundColor: theme.palette.common.solidBlue,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiDrawer-paper": {
    width: "100%",
  },
  "& .MuiPaper-root": {
    border: "none",
    position: "fixed",
    marginTop: "64px",
    backgroundColor: sidebar.bgColor,
    // backgroundColor: theme.palette.common.solidBlue,
    height: "calc(100vh - 64px)",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 10,
  },
  "& a": {
    "&:hover": {
      textDecoration: "none",
    },
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

///side bar StyledComponent

export { SideBarList, CustomListItemButton, MyCusomDrawer };
