import React, { useState } from "react";
import { Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { colors } from "../../Components/common/constants";
import AuthService from "../../Components/auth/AuthService";
import { InputBaseField } from "../../Components/InputFields/InputFields";
import { FormButton } from "../../Components/UiElements/UiElements";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
import { ActionDialog } from "../../Components/actionDialog/ActionDialog";
import { Table, Button as AntButton } from "antd";
import { DeleteFilled, EditFilled, EditTwoTone, PlusCircleFilled, SearchOutlined } from "@ant-design/icons";
import Message from "../../Components/common/Message";

const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  tblTopDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  tblsearch: {
    display: "flex",
  },
  addIcon: {
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
  antAddIcon: {
    "& .anticon-plus-circle:hover": {
      color: "#4096ff !important"
    }
  },
}));

const Auth = new AuthService();

export default function RouteList() {
  const classes = useStyles();
  const navigate = useNavigate();
  var layoutDispatch = useLoadingDispatch();

  const [routes, setRoutes] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(null);
  const [limit] = React.useState(50);
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");

  //State For Action Dialog
  const [actionDialogProps, setActionDialogProps] = useState({
    message: "",
    type: "",
    isOpen: false,
  });
  const ShowActionDialog = (
    message,
    type,
    isOpen,
    OnOkCallback,
    OnCancellCallback
  ) => {
    setActionDialogProps((prevState) => ({
      ...prevState,
      message: message,
      type: type,
      isOpen: isOpen,
      onOk: OnOkCallback,
      onCancel: OnCancellCallback,
    }));
  };

  const routesColumns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 135,
    },
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: "Color",
      width: 150,
      render(record) {
        return <div style={{backgroundColor: record?.colorCode, height: "25px", width: "25px"}}></div>
    }
   },
    {
      title: 'Action',
      key: 'action',
      align: "right",
      dataIndex: '',
      render(record) {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <EditFilled  onClick={() => handleRowClick(record.id)}  style={{ fontSize: '25px', cursor: "pointer", marginRight: "5px", color: "#006cb8"}} />
            <DeleteFilled onClick={() => handleDelete(record.id)} style={{ fontSize: '25px', cursor: "pointer", color: "#cd1437" }} />
          </div>
        );
      },
    },
  ];

  React.useEffect(() => {
    showLoading(layoutDispatch);
    Auth.post(`/route/routes`, {
      limit: limit,
      page: page,
      searchText: searchText,
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack) {
          const routes1 = res.routes;
          if (routes1.length > 0) {
            routes1.map((r) => {
              let colorCode = colors.filter((obj) => obj.title === r.color);
              if (colorCode.length > 0) {
                r.colorCode = colorCode[0].id;
              } else {
                r.colorCode = "#FFF";
              }
            });
            setRoutes(routes1);
          }
          setTotalCount(res.total_count)
          setTotalPages(Math.ceil(res.total_count / limit));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleRowClick = (id) => {
    navigate(`/routes/${id}`)
  };

  const Search = () => {
    showLoading(layoutDispatch);
    Auth.post(`/route/routes`, {
      limit: limit,
      page: 1,
      searchText: searchText,
    })
      .then((res) => {
        hideLoading(layoutDispatch);
        if (res.ack) {
          const routes1 = res.routes;
          if (routes1.length > 0) {
            routes1.map((r) => {
              let colorCode = colors.filter((obj) => obj.title === r.color);
              if (colorCode.length > 0) {
                r.colorCode = colorCode[0].id;
              } else {
                r.colorCode = "#FFF";
              }
            });
            setRoutes(routes1);
          }
          setTotalPages(Math.ceil(res.total_count / limit));
  setTotalCount(res.total_count);

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleDelete = (id) => {
    // if (window.confirm("Are you sure to want to delete this record?")) {
    ShowActionDialog(
      "Are you sure to want to delete this record?",
      "confirm",
      true,
      function () {
        const params = {
          method: "DELETE",
          body: JSON.stringify({
            id: id,
          }),
        };
        // setLoading(true);
        showLoading(layoutDispatch);
        Auth.fetch(`/route/route`, params)
          .then((res) => {
            hideLoading(layoutDispatch);
            if (res.ack) {
              setType("info");
              setLoadMessage(res.message);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
              // setLoading(false);
            } else {
              setLoadMessage("error");
              setLoadMessage(res.status);
              // setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };

  return (
    <div>
            <h2 style={{marginBottom: '10px', marginTop: 0}}>Routes</h2>
      {/* <Button variant="contained" style={{marginBottom: "15px"}} onClick={()=>{window.location = "/import-routes"}}>Import Routes</Button> */}
      <div className={classes.tblTopDiv}>
      <div style={{display: "flex", flexDirection: "column", gap: "8px"}}>
          <div style={{display: "flex", alignItems: "center", flexWrap: "wrap", gap: "8px"}}>
            <InputBaseField
            id="searchText"
            name="searchText"
            value={searchText}
            onChange={handleSearch}
            placeholder="Search"
          />
            
          <AntButton
            type="primary"
            size="large"
            onClick={Search}
            style={{ marginLeft: '5px'}}
            icon={<SearchOutlined />}
          >
            Search
          </AntButton>
          </div>
          </div>
        
        <div>
          <div className={classes.antAddIcon}>
            <PlusCircleFilled style={{ fontSize: "30px", color:"#1677ff"}}  onClick={() =>navigate("/routes/new")} />
          </div>
        </div>
      </div>
      <div className={classes.antTable}>
      <Table
          dataSource={routes}
          columns={routesColumns}
          pagination={false}
          style={{ overflowX: "auto" }}
          // footer={()=>`List of Routes - Total Records: ${routes.length === 0 ? "0" : routes.length  }`}
          footer={() => {
            const startRecord = (page - 1) * limit + 1;
            const endRecord = Math.min(page * limit, routes.length ? totalCount : 0); 
            return `Showing ${startRecord}-${endRecord} of ${routes.length ? totalCount : "0"} Records`;
          }}
        />
      </div>
      <Message type={type} msg={loadMessage} />
      <div style={{ float: "right", margin: "10px" }}>
        <Pagination
          page={page}
          count={totalPages}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </div>
      <ActionDialog
        message={actionDialogProps.message}
        type={actionDialogProps.type}
        isOpen={actionDialogProps.isOpen}
        onClose={actionDialogProps.onClose}
        onSubmit={() => {
          actionDialogProps.onOk();
        }}
        onCancel={() =>
          setActionDialogProps((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
      />
    </div>
  );
}
