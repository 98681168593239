import React, { useEffect, useLayoutEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import AuthService from "../../Components/auth/AuthService";
import { useNavigate } from "react-router-dom";
import { hideLoading, showLoading, useLoadingDispatch } from "../../context/loadingContext";
import { Button, Dropdown, List, message, Select, Table, Upload } from 'antd';
import { InboxOutlined, MoreOutlined, UploadOutlined } from "@ant-design/icons";
import Papa from "papaparse";
import * as XLSX from 'xlsx';
import { reportTypes } from "../../Components/common/constants";
import ImportModal from "../../Components/Modals/ImportModal";
import moment from "moment";
import { domain, env, tms_uploads_bucket, tms_uploads_cdn } from "../../Components/config";
import qs from "query-string";
import FileDetailsModal from "../../Components/Modals/FileDetailsModal";
import { Pagination } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  antTable: {
    // "& .ant-table-tbody > tr > td": {
    //   borderBottom: "1px solid #b7b9bd",
    // },
  },
}));

const Auth = new AuthService();

export default function FileImport() {
    const classes = useStyles();
  const navigate = useNavigate();
  const { Dragger } = Upload;
  const { Option } = Select;
  var layoutDispatch = useLoadingDispatch();
  const [file, setFile] = useState(null);
  const [reportType, setReportType] = useState(null);
  const [isValidFile, setIsValidFile] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [allVehicle, setAllVehicle] = React.useState([]);
  const [allErrors, setAllErrors] = React.useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenDetails, setIsModalOpenDetails] = useState(false);
  const [newFile, setNewFile] = useState('');
  const [allCurrentFiles, setAllCurrentFiles] = useState([]);
  const [fileDetails, setFileDetails] = useState([]);

  const [dataToSend, setDataToSend] = useState('');

  const [page, setPage] = React.useState(1);
  const [pageChange, setPageChange] = React.useState(false);
  const [limit] = React.useState(50);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(null);
  

  const handleSelect = () => {
    setIsModalOpen(true);
  }

  const handleChange = (event, value) => {
    setPage(value);
    setPageChange(true)
  };

  const fileCol = [
    {
      title: 'File Name',
      dataIndex: 'file_name',
      key: 'file_name',
      render: (text) => {
        const encodedFileName = text.replace(/\+/g, '%2B');
        return (
          <>
            <a
              href={`${tms_uploads_cdn}/${env}/imports/${reportType === 1 ? "fuels" : "service"}/${encodedFileName}`}
              target="_blank"
            >
              {extractOriginalFileName(text)}
            </a>
          </>
        );
      }
    },    
    {
      title: 'Uploaded At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => <>
      {moment(text).format('YYYY-MM-DD HH:mm')}
      </> 
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: '',
      align: 'center',
      render(record) {
        return (
          < >
            <Dropdown
              menu={{
                items: [
                  {
                    key: '1',
                    label: 'View Details',
                    onClick: () => getFileDetails(record),
                  },
                  {
                    key: '2',
                    label: 'Delete',
                    onClick: (e) => handleDeleteRecord(record),
                  },
                ],
              }}
            >
              <MoreOutlined />
            </Dropdown>
          </>
        );
      },
    },
  ]

  const fuelTemplate = [
    "vehicle_name",
    "date",
    "volume",
    "fuel_type",
    "price_per_fuel_unit",
    // "total_price",
    "odometer",
    "vendor",
    'fuel_tank'
    
];

  const serviceTemplate = [
    "vehicle_name",
    "completed_at",
    "vendor",
    "service_type"
  ];

  const validateDate = (dateString) => {    
    const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
    if (!dateString.match(datePattern)) {
      return false;
    }
  
    const [day, month, year] = dateString.split('/').map(Number);
    const date = new Date(year, month - 1, day);
  
    return date.getFullYear() === year && date.getMonth() === (month - 1) && date.getDate() === day;
  };

  // const validateISODate = (dateString) => {
  //   const isoDateRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2} [+-]\d{4}$/;
  
  //   if (!isoDateRegex.test(dateString)) {
  //     return false;
  //   }
  
  //   const datePart = dateString.substring(0, 19).replace(" ", "T");
  //   const date = new Date(datePart);
  
  //   return !isNaN(date.getTime());
  // };


const validateISODate = (dateTimeString) => {
  // DD/MM/YYYY HH:mm format
  const dateTimeRegex = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/;

  if (!dateTimeRegex.test(dateTimeString)) {
    return false;
  }

  // Extract date and time parts
  const [datePart, timePart] = dateTimeString.split(' ');
  const [day, month, year] = datePart.split('/').map(Number);
  const [hours, minutes] = timePart.split(':').map(Number);

  // Check for valid date and time ranges
  const isValidDate = (day >= 1 && day <= 31) && (month >= 1 && month <= 12) && (year > 0);
  const isValidTime = (hours >= 0 && hours <= 23) && (minutes >= 0 && minutes <= 59);

  return isValidDate && isValidTime;
};

  const validateInteger = (value) => {
    return !isNaN(value) && Number(value) > 0;
  };

  const validateNumericValues = (value) => {
    return !isNaN(value) && parseFloat(value) > 0;
  };

  const validateNumber = (value) => {
    return Number.isInteger(Number(value));
  };

  const validatePrice = (value) => {
    return !isNaN(parseFloat(value.replace(/[^\d.-]/g, '')));
  };

  // const props = {
  //   name: 'file',
  //   multiple: false,
  //   accept: '.csv',
  //   onRemove: (file) => {
  //     setFileList([]);
  //     setFile(null);
  //     setIsValidFile(false);
  //     setAllErrors([])
  //   },

  //   beforeUpload: (file) => {
  //       Papa.parse(file, {
  //         header: true,
  //         skipEmptyLines: true,
  //         complete: (result) => {
  //           const headers = result.meta.fields;
  //           console.log('headers', headers);
  //           const template = reportType == 1 ? fuelTemplate : serviceTemplate;
  //             if (!headers.every((header) => template.includes(header))) {
  //             message.error('File is not valid. Please use the correct template.');
  //             setFile(null);
  //             setFileList([]);
  //             setIsValidFile(false);
  //             setAllErrors([])
  //           } else {
  //             const allVehiclesExist = result.data.every((row) => {
  //               const vehicleName = row['vehicle_name'];
  //               return allVehicle.some((vehicle) => vehicle.registration_number === vehicleName);
  //             });
  //             console.log('allVehiclesExist', allVehiclesExist);
  //             if (!allVehiclesExist) {
  //               message.error('One or more vehicles in the file do not exist. ');
  //               setFile(null);
  //               setFileList([]);
  //               setIsValidFile(false);
  //             } else {
  //               const dataTypeErrors = [];
  //               result.data.forEach((row, index) => {
  //                   const rowNumber = index + 1
  //                   if(reportType == 1) {
  //                       const vehicleName = row['vehicle_name'];
  //                       const date = row['date'];
  //                       const volume = row['volume'];
  //                       const odometer = row['odometer'];
  //                       // const fuelPrice = row['total_price'];
  //                       const unit_price = row['price_per_fuel_unit']

  //                       console.log(`Row ${rowNumber} - unit_price:`, unit_price, result.data);

  //                       if (date && !validateDate(date)) {
  //                           dataTypeErrors.push(`Invalid date format in row ${rowNumber}.`);
  //                         }
  //                         if (volume && !validateInteger(volume)) {
  //                           dataTypeErrors.push(`Invalid volume format in row ${rowNumber}.`);
  //                         }
  //                         if (odometer && !validateNumber(odometer)) {
  //                           dataTypeErrors.push(`Invalid odometer format in row ${rowNumber}.`);
  //                         }
  //                         // if (fuelPrice && !validatePrice(fuelPrice)) {
  //                         //   dataTypeErrors.push(`Invalid total price format in row ${rowNumber}.`);
  //                         // }
  //                         if (!unit_price || !validateNumericValues(unit_price)) {
  //                           dataTypeErrors.push(`Invalid unit price in row ${rowNumber}.`);
  //                         }
  //                        }
  //                 if (reportType === 2) { 
  //                   const vehicleName = row['vehicle_name'];
  //                   const completedAt = row['completed_at'];
  //                   if (completedAt && !validateISODate(completedAt)) {
  //                       dataTypeErrors.push(`Invalid completed at date format in row ${rowNumber}.`);
  //                     }
  //                 }
  //               });
  
  //               if (dataTypeErrors.length > 0) {
  //                 // message.error(dataTypeErrors.join(' '));
  //                 console.log('dataTypeErrors', dataTypeErrors);
  //                 setAllErrors([...dataTypeErrors])
  //                 setFile(null);
  //                 setFileList([]);
  //                 setIsValidFile(false);
  //               } else {
  //                 setFile(file);
  //                 setIsValidFile(true);
  //                 setFileList([file]);
  //                 setAllErrors([])
  //               }
  //             }
  //           }
  //         }
  //       });
  //       return false;
  //     },
    
  //   onDrop(e) {
  //     console.log('Dropped files', e.dataTransfer.files);
  //     const file = e.dataTransfer.files
  //     const isCsv = file[0].type === 'text/csv';
      
  //       if (!isCsv) {
  //         message.error('You can only upload CSV file!');
  //         return
  //       } 
  //   },
  // };

  const convertExcelDateToJSDate = (serial) => {
    // Excel uses 1900 as a base date, and day 1 is January 1, 1900
    const date = new Date(Math.round((serial - 25569)*86400*1000));
    return date;
  };

const props = {
  name: 'file',
  multiple: false,
  accept: '.csv, .xlsx',
  onRemove: (file) => {
    setFileList([]);
    setFile(null);
    setIsValidFile(false);
    setAllErrors([]);
  },

  beforeUpload: (file) => {
    const isCSV = file.type === 'text/csv';
    const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    if (!isCSV && !isXLSX) {
      message.error('You can only upload CSV or XLSX files!');
      setFile(null);
      setFileList([]);
      setIsValidFile(false);
      return false;
    }

    if (isCSV) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          setDataToSend(result.data)
          console.log('FileCheck', file);

          handleData(result.data, result.meta.fields, file);
        },
        error: (error) => {
          message.error('Error parsing CSV file.');
          setFile(null);
          setFileList([]);
          setIsValidFile(false);
        }
      });
    } else if (isXLSX) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const result = XLSX.utils.sheet_to_json(sheet, { header: 1 });        

        // Convert the array of arrays to a more structured format
        const headers = result[0];
        const rows = result.slice(1).map(row => {
          const obj = {};
          headers.forEach((header, index) => {
            let value = row[index];
            if (header === 'date' && typeof value === 'number') {
              
              // Excel date to a JavaScript date
              value = convertExcelDateToJSDate(value);
              // date as MM/DD/YYYY
              value = value.toLocaleDateString('en-GB');
              console.log('here1', value);
            }
            obj[header] = value;
          });
          return obj;
        });
        console.log('rows, headers, file', rows, headers, file);
        
        setDataToSend(rows)
        console.log('FileCheck', file);
        
        handleData(rows, headers, file);

      };
      reader.readAsArrayBuffer(file);
    }

    return false;
  },

  onDrop(e) {
    console.log('Dropped files', e.dataTransfer.files);
    const file = e.dataTransfer.files[0];
    const isCSV = file.type === 'text/csv';
    const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    if (!isCSV && !isXLSX) {
      message.error('You can only upload CSV or XLSX files!');
      return;
    }
  },
};

// Separate function to handle data processing
const handleData = (data, headers, file) => {
  console.log('headers', headers, data);
  const filteredData = data.filter(row => row['vehicle_name'] && row['vehicle_name'].trim() !== '');
  const template = reportType === 1 ? fuelTemplate : serviceTemplate;
  const isValidTemplate = template.every((field) => headers.includes(field)) && headers.every((header) => template.includes(header));
  // if (!headers.every((header) => template.includes(header))) {
    if (!isValidTemplate) {
    message.error('File is not valid. Please use the correct template.');
    setFile(null);
    setFileList([]);
    setIsValidFile(false);
    setAllErrors([]);
  } else {
    const allVehiclesExist = filteredData.every((row) => {
      const vehicleName = row['vehicle_name']?.trim().toLowerCase();
      return allVehicle.some((vehicle) => vehicle.registration_number?.trim().toLowerCase() === vehicleName);
    });
    console.log('allVehiclesExist', allVehiclesExist);
    if (!allVehiclesExist) {
      message.error('One or more vehicles in the file do not exist.');
      setFile(null);
      setFileList([]);
      setIsValidFile(false);
    } else {
      const dataTypeErrors = [];
      filteredData.forEach((row, index) => {
        const rowNumber = index + 1;
        if (reportType === 1) {
          const vehicleName = row['vehicle_name'];
          const date = row['date'];
          const volume = row['volume'];
          const odometer = row['odometer'];
          const unit_price = row['price_per_fuel_unit'];

          console.log(`Row ${rowNumber} - unit_price:`, unit_price, filteredData);

          if (date && !validateDate(date)) {
            dataTypeErrors.push(`Invalid date format in row ${rowNumber}.`);
          }
          if (volume && !validateInteger(volume)) {
            dataTypeErrors.push(`Invalid volume format in row ${rowNumber}.`);
          }
          if (odometer && !validateNumber(odometer)) {
            dataTypeErrors.push(`Invalid odometer format in row ${rowNumber}.`);
          }
          if (!unit_price || !validateNumericValues(unit_price)) {
            dataTypeErrors.push(`Invalid unit price in row ${rowNumber}.`);
          }
        }
        if (reportType === 2) {
          const completedAt = row['completed_at'];
          if (completedAt && !validateISODate(completedAt)) {
            dataTypeErrors.push(`Invalid completed at date format in row ${rowNumber}.`);
          }
        }
      });

      if (dataTypeErrors.length > 0) {
        console.log('dataTypeErrors', dataTypeErrors);
        setAllErrors([...dataTypeErrors]);
        setFile(null);
        setFileList([]);
        setIsValidFile(false);
      } else {
        setFile(file);
        setIsValidFile(true);
        setFileList([file]);
        setAllErrors([]);
      }
    }
  }
};


  const handleDeleteRecord = async (record) => {

    const dataToSend = {
      batch_id: record.batch_id,
      type: reportType
    };
    showLoading(layoutDispatch);
    try {
      const response = await Auth.fetch("/import/delete-imports", {
        method: "DELETE",
        body: JSON.stringify(dataToSend),
      });
      if(response?.ack) {
        message.success(response?.message)
        getAllCurrentFiles(reportType)
      }

    } catch (error) {
      console.log(error);
    } finally {
    hideLoading(layoutDispatch);
    }
  };

  const getFileDetails = async (record) => {
    showLoading(layoutDispatch);
    try {
      const response = await Auth.fetch(`/import/view-imports?type=${reportType}&batch_id=${record?.batch_id}`);
      if (response?.ack) {
        console.log('response1111', response);
        
        if(reportType === 1) {
          setFileDetails(response?.fuel_arr || [])
          setIsModalOpenDetails(true);

        }
        if(reportType === 2) {
          setFileDetails(response?.service_arr || [])
          setIsModalOpenDetails(true);
        }
      }
    } catch (err) {
      console.log(err);
    } finally{
      hideLoading(layoutDispatch);
    }
  }

  const getAllVehicles = async () => {
    showLoading(layoutDispatch);
    try {
      const response = await Auth.fetch(`/vehicle/vehicles`);
      if (response) {
        hideLoading(layoutDispatch);
        if (response?.ack) {
          setAllVehicle(response.vehicles);
        }
      }
    } catch (err) {
      console.log(err);
    } finally{
      hideLoading(layoutDispatch);
    }
  }

  const getAllCurrentFiles = async (type) => {
    showLoading(layoutDispatch);
    try {
      const response = await Auth.fetch(`/import/imports?type=${type}`);
      if (response?.ack) {
        if(type === 1) {
          setAllCurrentFiles(response?.fuel_arr || [])
          setTotalPages(Math.ceil(response.total_count / limit));
          setTotalCount(response.total_count);

        }
        if(type === 2) {
          setAllCurrentFiles(response?.service_arr || [])
          setTotalPages(Math.ceil(response.total_count / limit));
          setTotalCount(response.total_count);

        }
      }
    } catch (err) {
      console.log(err);
    } finally{
      hideLoading(layoutDispatch);
    }
  }


function getNewFileName(oldFileName) {
  const extension = oldFileName?.slice(oldFileName?.lastIndexOf('.'));
  const baseName = oldFileName?.slice(0, oldFileName?.lastIndexOf('.'));
  const randomString = Math.random().toString(36).substring(2, 12);
  return `${baseName}-${randomString}${extension}`;
}

function extractOriginalFileName(modifiedFileName) {
  const extensionIndex = modifiedFileName?.lastIndexOf('.');
  const extension = modifiedFileName?.slice(extensionIndex);
  const randomStringPattern = /-\w{10}(?=\.\w+$)/;
  const baseName = modifiedFileName?.replace(randomStringPattern, '');
  return baseName;
}

const getUploadUrl = async (newFileName, type) => {
  const params = {
    bucket: tms_uploads_bucket,
    ContentType: file.type,
    Key: env + `/imports/${type}/` + newFileName,
  };
  const response = await fetch(
    `${domain}/generate-put-url?${qs.stringify(params)}`,
    {
      headers: {
        "Content-Type": params.ContentType,
      },
    }
  );
  return response.json();
};

const uploadFileToS3 = async (uploadUrl, contentType) => {
  const response = await fetch(uploadUrl.putURL, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": contentType,
    },
  });
  if (response.status !== 200) {
    throw new Error("File upload failed.");
  }
};

// const handleSendFile = async () => {
//     Papa.parse(file, {
//       header: true,
//       skipEmptyLines: true,
//       complete: async (result) =>  {
//         const dataArray = result.data.filter(item => item.vehicle_name != '')
//         const updatedDataArray = dataArray.map(item => {
//           const vehicle = allVehicle.find(vehicle => vehicle.registration_number === item.vehicle_name);
//           return {
//             ...item,
//             vehicle_id: vehicle ? vehicle.id : null, 
//           };
//         });
//         if(reportType == 1) {
//         //   updatedDataArray.forEach(item => {
//         //     item.total_price = item.total_price.replace(/[^0-9.-]+/g, ''); 
//         // })
//         showLoading(layoutDispatch);
//         const newFileName = getNewFileName(file?.name);
//         setNewFile(newFileName)
//         const dataToSend = {
//           file_name: newFileName,
//           fuel_arr: updatedDataArray.map(item => ({
//             fuel_date: item?.date 
//           ? moment(item.date, 'DD/MM/YYYY').format('YYYY-MM-DD') 
//           : null,
//             vehicle_id: item?.vehicle_id,
//             fuel_type: item?.fuel_type == 'Diesel' ? 1 : 2,
//             volume: item?.volume,
//             odometer: item?.odometer,
//             vendor: item?.vendor, 
//             unit_price: item?.price_per_fuel_unit
//           }))
//         }
//         console.log('updated parsed Array', dataToSend);
//         try {
//           const res = await Auth.fetch("/import/fuel", {
//             method: "POST",
//             body: JSON.stringify(dataToSend),
//           })

//           if(res?.ack) {
//           const uploadPromises = []
//           uploadPromises.push(
//             getUploadUrl(newFileName, 'fuels')
//               .then(uploadUrl => uploadFileToS3(uploadUrl, file.type))
//               .catch(error => console.error("Error uploading file:", error))
//           );
//           await Promise.all(uploadPromises);
//           message.success('Upload successful');
//           getAllCurrentFiles(reportType)
//           } else {
//           message.error(res?.message);
//           }
//         } catch (error) {
//           console.log(error);
//         } finally {
//           hideLoading(layoutDispatch);
//         } 
//         } 
//         if(reportType == 2) {
//         showLoading(layoutDispatch);
//         const newFileName = getNewFileName(file?.name);
//         setNewFile(newFileName)
//         const dataToSend = {
//           // service_date: updatedDataArray[0]?.completed_at 
//           // ? moment(updatedDataArray[0].completed_at, 'YYYY-MM-DD HH:mm:ss Z').format('YYYY-MM-DD')
//           // : null,
//           file_name: newFileName,
//           service_arr: updatedDataArray.map(item => ({
//             vehicle_id: item?.vehicle_id,
//             vendor: item?.vendor, 
//             service_type: item?.service_type,
//             service_date: item?.completed_at 
//           ? moment(item.completed_at, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD')
//           : null,
//           }))
//         }
//         console.log('updated parsed Array', dataToSend);
//         try {
//           const res = await Auth.fetch("/import/service", {
//             method: "POST",
//             body: JSON.stringify(dataToSend),
//           })

//           if(res?.ack) {
//           const uploadPromises = []
//           uploadPromises.push(
//             getUploadUrl(newFileName, 'service')
//               .then(uploadUrl => uploadFileToS3(uploadUrl, file.type))
//               .catch(error => console.error("Error uploading file:", error))
//           );
//           await Promise.all(uploadPromises);
//           message.success('Upload successful');
//           getAllCurrentFiles(reportType)
//           } else {
//           message.error(res?.message);
//           }
//         } catch (error) {
//           console.log(error);
//         } finally {
//           hideLoading(layoutDispatch);
//         } 
//         } 
        
//       }
//     })
//   }

console.log(dataToSend, 'dataTosend');


const handleSendFile = async () => {
    const dataArray = dataToSend?.filter(item => item.vehicle_name != '')
    const updatedDataArray = dataArray.map(item => {
      const vehicle = allVehicle.find(vehicle => vehicle.registration_number === item.vehicle_name);
      return {
        ...item,
        vehicle_id: vehicle ? vehicle.id : null, 
      };
    });
    if(reportType == 1) {
    //   updatedDataArray.forEach(item => {
    //     item.total_price = item.total_price.replace(/[^0-9.-]+/g, ''); 
    // })
    showLoading(layoutDispatch);
    const newFileName = getNewFileName(file?.name);
    setNewFile(newFileName)
    const dataToSend = {
      file_name: newFileName,
      fuel_arr: updatedDataArray.map(item => ({
        fuel_date: item?.date 
      ? moment(item.date, 'DD/MM/YYYY').format('YYYY-MM-DD') 
      : null,
        vehicle_id: item?.vehicle_id,
        fuel_type: item?.fuel_type == 'Diesel' ? 1 : 2,
        volume: item?.volume,
        odometer: item?.odometer,
        fuel_tank: item?.fuel_tank,
        vendor: item?.vendor, 
        unit_price: item?.price_per_fuel_unit
      }))
    }
    console.log('updated parsed Array', dataToSend);
    try {
      const res = await Auth.fetch("/import/fuel", {
        method: "POST",
        body: JSON.stringify(dataToSend),
      })

      if(res?.ack) {
      const uploadPromises = []
      uploadPromises.push(
        getUploadUrl(newFileName, 'fuels')
          .then(uploadUrl => uploadFileToS3(uploadUrl, file.type))
          .catch(error => console.error("Error uploading file:", error))
      );
      await Promise.all(uploadPromises);
      message.success('Upload successful');
      getAllCurrentFiles(reportType)
      } else {
      message.error(res?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoading(layoutDispatch);
    } 
    } 
    if(reportType == 2) {
    showLoading(layoutDispatch);
    const newFileName = getNewFileName(file?.name);
    setNewFile(newFileName)
    const dataToSend = {
      // service_date: updatedDataArray[0]?.completed_at 
      // ? moment(updatedDataArray[0].completed_at, 'YYYY-MM-DD HH:mm:ss Z').format('YYYY-MM-DD')
      // : null,
      file_name: newFileName,
      service_arr: updatedDataArray.map(item => ({
        vehicle_id: item?.vehicle_id,
        vendor: item?.vendor, 
        service_type: item?.service_type,
        service_date: item?.completed_at 
      ? moment(item.completed_at, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD')
      : null,
      }))
    }
    console.log('updated parsed Array', dataToSend);
    try {
      const res = await Auth.fetch("/import/service", {
        method: "POST",
        body: JSON.stringify(dataToSend),
      })

      if(res?.ack) {
      const uploadPromises = []
      uploadPromises.push(
        getUploadUrl(newFileName, 'service')
          .then(uploadUrl => uploadFileToS3(uploadUrl, file.type))
          .catch(error => console.error("Error uploading file:", error))
      );
      await Promise.all(uploadPromises);
      message.success('Upload successful');
      getAllCurrentFiles(reportType)
      } else {
      message.error(res?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoading(layoutDispatch);
    } 
    } 
  
}

  
  useLayoutEffect(()=> {
    getAllVehicles()
  },[])

  useEffect(() => {
    if (reportType) {
      setFile(null);
      setFileList([]);
      setIsValidFile(false);
      setAllErrors([]);
    }
  }, [reportType]);

  useEffect(() => {
    const timeOutId = setTimeout(() => getAllCurrentFiles(reportType), 100);
    return () => clearTimeout(timeOutId);
  }, [page]);

  useEffect(() => {
    message.config({
      top: window.innerHeight - 100, 
      duration: 3, 
    });
  }, [])

  return (
    <div>
        <div className="accident-box" style={{ marginTop: '15px' }}>
        <div style={{display: 'flex', justifyContent: "space-between", alignItems: "center"}}>
            <h3>Imports</h3>
            <Button type="link" onClick={()=> handleSelect()}>
              Browse Templates
           </Button>           
        </div>
        <span>Import Type<span style={{ color: 'red'}}>*</span></span>
        <Select
        style={{
          width: "100%",
        }}
        value={reportType}
        placeholder="Select File Type"
        onChange={(value) => {
          setReportType(value)
          getAllCurrentFiles(value)
        }}
      >
        {reportTypes.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.title}
          </Option>
        ))}
      </Select>
      {
        reportType && (
            <>
            <Dragger {...props} style={{ marginBottom: '5px', marginTop: '5px'}} fileList={isValidFile ? [file] : []}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Strictly prohibited from uploading company data or other banned files.
        </p>
      </Dragger>
      {
  allErrors.length > 0 && (
    <List
      size="small"
      header={<span style={{ fontWeight: 'bold'}}>Errors</span>}
      footer={null}
      bordered
      dataSource={allErrors}
      renderItem={(item) => <List.Item style={{ color: 'red'}}>{item}</List.Item>}
    />
  )
}
      {isValidFile && (
        <div style={{ display: "flex", justifyContent: "center"}}>
        <Button 
        icon={<UploadOutlined/>}
        onClick={() => {
          console.log('File is valid and can be uploaded:', file, fileList);
          handleSendFile()
        }}>
          Upload File
        </Button>
        </div>
      )}
        </>
        )
      }
        </div>
        {
          reportType && (
            <>
            <h3 style={{ marginTop: '20px' }}>File Records</h3>
            <div className={classes.antTable}>
          <Table
          columns={fileCol}
          style={{ overflowX: "auto", marginTop: '10px' }}
          pagination={false}
          bordered
          dataSource={allCurrentFiles}
          // footer={() => `List of Files Record - Total count: ${allCurrentFiles?.length || 0} `}
          footer={() => {
            const startRecord = (page - 1) * limit + 1;
            const endRecord = Math.min(page * limit, allCurrentFiles.length ? totalCount : 0); 
            return `Showing ${startRecord}-${endRecord} of ${allCurrentFiles.length ? totalCount : "0"} Records`;
          }}
        />
      </div>
      <div style={{ float: "right", margin: "10px" }}>
        <Pagination
          page={page}
          count={totalPages}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </div>
            </>
            
          )
        }
        
      
        <ImportModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}  />
        <FileDetailsModal isModalOpen={isModalOpenDetails} setIsModalOpen={setIsModalOpenDetails} fileDetails={fileDetails} allVehicle={allVehicle} reportType={reportType} extractOriginalFileName={extractOriginalFileName} classes={classes} />
    </div>
  );
}