import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Image,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  message,
  Tooltip,
  Alert,
  Tag,
  Table,
} from "antd";
import CloseIcon from '@mui/icons-material/Close';
import AuthService from "../auth/AuthService";
import config, { tms_uploads_cdn } from "../config";
import { vehicleServiceTasks } from "../common/constants";
import dayjs from "dayjs";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Button as UploadButton } from "@mui/material";
import { CheckCircleOutlined, CloseCircleOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import qs from "query-string";
import { hideLoading, showLoading, useLoadingDispatch } from "../../context/loadingContext";
import moment from "moment";
import { Modal as MUIModal, Typography, IconButton, Button as MUIButton} from "@mui/material";



const Auth = new AuthService();

const TaskHistoryModal = ({ isModalOpen, setIsModalOpen, allVehicle, history, setHistory }) => {
  const { Option } = Select;
  const { TextArea } = Input;
  var layoutDispatch = useLoadingDispatch();
  const [filesOpen, setFilesOpen] = React.useState(false);
  const [selectedRecordId, setSelectedRecordId] = React.useState(0);
  const [selectedFiles, setSelectedFiles] = React.useState([]);

  const [screenWidth, setScreenWidth] = React.useState("");
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  React.useEffect(() => {
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const handleFilesOpen = (row) => {
    if (row?.files) {
      setSelectedRecordId(row.id);
      setSelectedFiles(JSON.parse(row?.files));
    }
    setFilesOpen(true);
  };

  const handleFilesClose = () => setFilesOpen(false);

  const historyCol = [
    
    {
      title: 'Due Date',
      render: (record )=> {
        return (
          <>
          <span>{moment(record.due_date, "YYYY-MM-DD").format("YYYY-MM-DD")}</span>
        </>
        )
       
      },
      width: 100
    },
    {
      align: "center",
      title: "Status",
      render: ({ due_date }) => {
        return (
          <Tag color={'#339900'} icon={<CheckCircleOutlined />} style={{ marginLeft: '4px' }}>
            Completed
          </Tag>
        );
      },
    },
    {
      align: "center",
      title: "Service Task",
      width: 100,
      render: (record) => {
          const vehicle = vehicleServiceTasks.find((obj) => obj.id === record?.type);
          // const title = vehicle?.title || "";
        return (
          <>
          <span>{vehicle?.title || ""}</span><br/>
          <span style={{ fontSize: '10px', color: 'darkgrey'}}>{vehicle?.message}</span>
          </>
        );
      },
    },
    {
      align: "center",
      title: "Completed at",
      width: 120,
      render: (record) => {
        return (
          <>
          <span>{record.completion_date ? moment(record.completion_date, "YYYY-MM-DD").format("YYYY-MM-DD") : null}</span>
          </>
          
        );
      },
    },
    {
        title: "Notes",
        dataIndex: "notes",
        key: "notes",
    },
    {
        title: 'Files',
        width: 100,
        render: (record) => {
          return record?.files != null &&  record?.files != ""  && JSON.parse(record?.files)?.length > 0 ? (
            <MUIButton
              onClick={() => {
                handleFilesOpen(record);
              }}
            >
              {JSON.parse(record?.files)?.length + "-Files"}
            </MUIButton>
          ) : (
            <MUIButton
            disabled
            >
              { "0-Files"}
            </MUIButton>
          )
        },
      },
  ];
  

  const handleCancel = () => {
    setIsModalOpen(false);
    resetState();
  };

  const resetState = () => {
    setHistory([])
  };

  return (
    <>
      <Modal
        width={'80%'}
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        title={"Service History "}
        footer={[
          <>
            <Button onClick={handleCancel}>{"Close"}</Button>
            
          </>,
        ]}
      >
        <>
          <Divider />
          <Table dataSource={history} columns={historyCol} style={{ marginTop: "4px"}} pagination={false} />

        </>
        {/* ==================popup for Showing uploaded Image==================== */}
      <MUIModal
          open={filesOpen}
          onClose={handleFilesClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              overflowY: "auto",
              maxHeight: "90vh",
              transform: "translate(-50%, -50%)",
              width: screenWidth < 610 ? "90%" : 600,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <IconButton
            edge="end"
            color="inherit"
            onClick={handleFilesClose}
            aria-label="close"
            sx={{ position: 'absolute', top: 0, right: 0, marginRight: '5px'}}
        >
          <CloseIcon />
        </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Download the files below
            </Typography>
            {selectedFiles.length > 0 && (
              <p style={{ padding: "0" }}>Files</p>
            )}
            {selectedFiles.length > 0 &&
              selectedFiles.map((file, idx) => {
              const lastHyphenIndex = file.lastIndexOf('-')
              const dotBeforeExtensionIndex = file.lastIndexOf('.');
              const newFileName = file.slice(0, lastHyphenIndex) + file.slice(dotBeforeExtensionIndex);
                return (
                  <li key={idx}>
                    <a
                      href={`${config.tms_uploads_cdn}/${config.env}/reminders/${selectedRecordId}/${file}`}
                      target="_blank"
                    >
                      {newFileName}
                    </a>
                  </li>
                );
              })}
          </Box>
        </MUIModal>
      </Modal>
    </>
  );
};

export default TaskHistoryModal;
