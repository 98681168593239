import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Grid, IconButton, Modal, Pagination, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AuthService from "../../Components/auth/AuthService";
import { useNavigate } from "react-router-dom";
import {
  hideLoading,
  showLoading,
  useLoadingDispatch,
} from "../../context/loadingContext";
import { Table, DatePicker as AntDate } from "antd";
import { DeleteFilled, EditFilled, PlusCircleFilled } from "@ant-design/icons";
import DatePicker from "react-date-picker";
import moment from "moment";
import { PaidBy, accidentFaults, accidentStatus, yesNo } from "../../Components/common/constants";
import Message from "../../Components/common/Message";
import { ActionDialog } from "../../Components/actionDialog/ActionDialog";
import CloseIcon from '@mui/icons-material/Close';
import { FormButton } from "../../Components/UiElements/UiElements";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  tblTopDiv: {},
  tblsearch: {
    display: "flex",
    float: "left",
  },
  addIcon: {
    float: "right",
  },
  dateField: {
    width: "100%",
    marginRight: "10px",
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "thin solid #b6babf",
      borderRadius: '5px',
    }
  },
  antTable: {
    "& .ant-table-tbody > tr > td": {
      borderBottom: "1px solid #b7b9bd",
    },
  },
  antAddIcon: {
    "& .anticon-plus-circle:hover": {
      color: "#4096ff !important"
    }
  },
}));

const Auth = new AuthService();

export default function AccidentList() {

  const classes = useStyles();
  const navigate = useNavigate();
  var layoutDispatch = useLoadingDispatch();
  const currentDate = new Date();
  const lastDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const [page, setPage] = React.useState(1);
  const [pageChange, setPageChange] = React.useState(false);
  const [limit] = React.useState(50);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(null);
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [allAccidents, setAllAccidents] = React.useState([]);
  const [drivers, setDrivers] = React.useState([]);
  const [allVehicle, setAllVehicle] = React.useState([]);
  const [allRoutes, setAllRoutes] = React.useState([]);
  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(lastDateOfMonth);
  const [filesOpen, setFilesOpen] = React.useState(false);
  const [selectedDriver, setSelectedDriver] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [selectedRoute, setSelectedRoute] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedPaidBy, setSelectedPaidBy] = useState("");
  const [actionDialogProps, setActionDialogProps] = useState({
    message: "",
    type: "",
    isOpen: false,
  });

  const handleFilesClose = () => setFilesOpen(false);

  const handleRowClick = (id) => {
    navigate(`/accident/${id}`)
  };

  const ShowActionDialog = (
    message,
    type,
    isOpen,
    OnOkCallback,
    OnCancellCallback
  ) => {
    setActionDialogProps((prevState) => ({
      ...prevState,
      message: message,
      type: type,
      isOpen: isOpen,
      onOk: OnOkCallback,
      onCancel: OnCancellCallback,
    }));
  };

  const handleDelete = (id) => {
    ShowActionDialog(
      "Are you sure to want to delete this record?",
      "confirm",
      true,
      function () {
        const params = {
          method: "DELETE",
          body: JSON.stringify({
            id: id,
          }),
        };
        Auth.fetch(`/accident/accident`, params)
          .then((res) => {
            if (res?.ack) {
              setType("info");
              setLoadMessage(res.message);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              setLoadMessage("error");
              setLoadMessage(res.status);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };

  const accidentColumns = [
    {
      title: "Code",
      dataIndex: 'code',
      key: 'code',
      // render(record) {
      //   return record.id
      //     ? "MA " + String(record.id).padStart(4, '0')
      //     : ""
      // }
    },
    {
      title: "Status",
      render(record) {
        const statusType = accidentStatus.find((obj) => obj.id == record?.status);
        const background = statusType?.id == '1' ? "green" : 'red';
        return {
          props: {
            style: { background: background, color: 'white' }
          },
          children: <span>
            {statusType ? statusType.title : ""}
            {/* { record?.status
              ? accidentStatus.filter((obj) => obj.id == record.status)[0]?.title
              : ""} */}
          </span>
        };
      },
      // width: 50
    },
    {
      title: 'Accident Date',
      render: (record) => {
        return record.accident_date
          ? moment(record.accident_date).format("YYYY-MM-DD")
          : ""
      },
    },
    {
      title: 'Case Open Date',
      render: (record) => {
        return record.case_open_date
          ? moment(record.case_open_date).format("YYYY-MM-DD")
          : ""
      },
    },
    {
      title: 'Case Close Date',
      render: (record) => {
        return record.case_close_date
          ? moment(record.case_close_date).format("YYYY-MM-DD")
          : ""
      },
    },
    {
      title: "Vehicle Reg.",
      dataIndex: 'registration_number',
      key: 'registration_number',

    },
    {
      title: 'Route',
      dataIndex: "route",
      key: "route",

    },
    {
      title: 'Driver',
      dataIndex: "driver_name",
      key: "driver_name",
    },
    // {
    //   title: 'Claim Amount',
    //   dataIndex: "amount",
    //   render: (text, record) => {
    //     const formattedAmount = Number(record.amount).toLocaleString(undefined, {
    //       minimumFractionDigits: 2,
    //       maximumFractionDigits: 2
    //     });
    //     return `£${formattedAmount}` || '£0.00';
    //   }
    // },
    {
      title: 'Fault',
      render: (record) => {
        return record?.fault
          ? accidentFaults.find(obj => obj.id.toString() === record?.fault.toString())?.title
          : '';
      },
    },
    {
      title: 'Paid By',
      render: (record) => {
        return record.paid_by
          ? PaidBy.find(obj => obj.id.toString() === record.paid_by.toString())?.title
          : '';
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      dataIndex: '',
      render(record) {
        console.log('record', record)
        return (
          <div  onClick={(e) => e.stopPropagation()}>
            <EditFilled onClick={() => handleRowClick(record.id)} style={{ fontSize: '25px', cursor: "pointer", color: "#006cb8" }} />
            {
              record.status !== 0 && <DeleteFilled onClick={() => handleDelete(record.id)} style={{ fontSize: '25px', cursor: "pointer", marginRight: "5px", color: "#cd1437" }} />
            }
            {/* <DeleteFilled onClick={() => handleDelete(record.id)} style={{ fontSize: '25px', cursor: "pointer", marginRight: "5px", color: "#cd1437" }} /> */}
          </div>
        );
      },
    }
  ];

  const search = (download) => {
    let search = {};
    if (startDate) search.start_date = moment(startDate).format("YYYY-MM-DD");
    if (endDate) search.end_date = moment(endDate).format("YYYY-MM-DD");
    if (selectedDriver) search.driver_id = selectedDriver.id;
    if (selectedVehicle) search.vehicle_id = selectedVehicle.id;
    if (selectedRoute) search.route_id = selectedRoute.id;
    if (selectedStatus) search.status = selectedStatus.id;
    if (selectedPaidBy) search.paid_by = selectedPaidBy.id;
    if (page && pageChange) search.page = page;

    if (download) search.download = 1;

    showLoading(layoutDispatch);
    Auth.fetch("/accident/accidents", {
      method: "POST",
      body: JSON.stringify(search),
    }).then((res) => {
      hideLoading(layoutDispatch);
      if (res?.ack) {
        if (download) {
          window.open(res.file_url, "_blank", "noreferrer");
        }
        else {
          setAllAccidents(res?.accidents);
          setTotalPages(Math.ceil(res.total_count / limit));
          setTotalCount(res.total_count)
        }
      } else {
        setAllAccidents([]);
      }
    });
  };

  const getAllDrivers = async () => {
    showLoading(layoutDispatch);
    try {
      const response = await Auth.fetch(`/driver/drivers`);
      if (response) {
        hideLoading(layoutDispatch);
        if (response?.ack) {
          setDrivers(response.drivers);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const getAllVehicles = async () => {
    showLoading(layoutDispatch);
    try {
      const response = await Auth.fetch(`/vehicle/vehicles`);
      if (response) {
        hideLoading(layoutDispatch);
        if (response?.ack) {
          setAllVehicle(response.vehicles);
        }
      }
    } catch (err) {
      console.log(err);
    } finally{
      hideLoading(layoutDispatch);
    }
  }

  const getAllRoutes = async () => {
    showLoading(layoutDispatch);
    try {
      const response = await Auth.fetch("/route/all-routes", {
        method: "POST",
        // body: JSON.stringify(datasend),
      });
      if (response) {
        hideLoading(layoutDispatch);
        if (response?.ack) {
          setAllRoutes(response.routes);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
    setPageChange(true)
  };

  React.useEffect(() => {
    getAllDrivers();
    getAllVehicles();
    getAllRoutes()

  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => search(), 1000);
    return () => clearTimeout(timeOutId);
  }, [
    startDate,
    endDate,
    selectedDriver,
    selectedVehicle,
    selectedStatus,
    selectedRoute,
    selectedPaidBy,
    page
  ]);

  const [screenWidth, setScreenWidth] = React.useState("");

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  React.useEffect(() => {
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const updateFilters = (newValues) => {
    const existingFiltersString = localStorage.getItem('AccidentFilters');
    const existingFilters = existingFiltersString ? JSON.parse(existingFiltersString) : {};
    Object.assign(existingFilters, newValues);
    existingFilters.timestamp = new Date().getTime();
    const updatedFiltersString = JSON.stringify(existingFilters);
    localStorage.setItem('AccidentFilters', updatedFiltersString);
  };

  const getFiltersOrDefault = () => {
    const existingFiltersString = localStorage.getItem('AccidentFilters');
    const existingFilters = existingFiltersString ? JSON.parse(existingFiltersString) : {};
    const currentTime = new Date().getTime();
    const expirationTime = 30 * 60 * 1000;
    if (existingFilters.timestamp && currentTime - existingFilters.timestamp < expirationTime) {
      if (!existingFilters.route) {
        existingFilters.route = '';
      }
      if (!existingFilters.driver) {
        existingFilters.driver = '';
      }

      if (!existingFilters.vehicle) {
        existingFilters.vehicle = '';
      }

      if (!existingFilters.status) {
        existingFilters.status = '';
      }

      if (!existingFilters.paidBy) {
        existingFilters.paidBy = '';
      }
      return existingFilters;
    } else {
      localStorage.removeItem('AccidentFilters');
      return {};
    }
  };

  useEffect(() => {
    const initialState = getFiltersOrDefault();
    setSelectedRoute(initialState?.route);
    setSelectedDriver(initialState?.driver);
    setSelectedVehicle(initialState?.vehicle);
    setSelectedStatus(initialState?.status);
    setSelectedPaidBy(initialState?.paidBy);
    if (!initialState?.startDate) {
      const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      setStartDate(firstDayOfMonth);
      initialState.startDate = firstDayOfMonth

    } else {
      setStartDate(initialState.startDate);
    }

    if (!initialState?.endDate) {
      const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
      setEndDate(lastDayOfMonth);
      initialState.endDate = lastDateOfMonth

    } else {
      setEndDate(initialState.endDate);
    }
    initialState.timestamp = new Date().getTime();
    updateFilters(initialState)
  }, []);

  return (
    <div>
            <h2 style={{marginBottom: '10px', marginTop: 0}}>Accidents</h2>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={8}>
        </Grid>
        <Grid item xs={4}>
          <FormButton
            sx={{ float: "right" }}
            type="print"
            onClick={() => { search(1) }}
          >
            Download
          </FormButton>
        </Grid>
      </Grid>
      <Grid container rowSpacing={2} columnSpacing={1.5}>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="pcnDate">Start Date:</label>
          {/* <DatePicker
            format={"dd-MM-yyyy"}
            className={classes.dateField}
            onChange={(date) => {
              setStartDate(date)
              updateFilters({ startDate: date })
              setPageChange(false)
              setPage(1)
            }}
            value={startDate}
          /> */}
          <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={startDate ? dayjs(startDate) : null}
               onChange={(_, dateStr) => {
                setStartDate(dateStr);
                updateFilters({ startDate: dateStr })
                setPageChange(false)
                setPage(1)
              }}
              placeholder="Select Start Date" 
               />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="pcnDate">End Date:</label>
          {/* <DatePicker
            format={"dd-MM-yyyy"}
            className={classes.dateField}
            onChange={(date) => {
              setEndDate(date)
              updateFilters({ endDate: date })
              setPageChange(false)
              setPage(1)
            }}
            value={endDate}
          /> */}
          <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: '100%'}}
               size="large"
               value={endDate ? dayjs(endDate) : null}
               onChange={(_, dateStr) => {
                setEndDate(dateStr);
                updateFilters({ endDate: dateStr })
                setPageChange(false)
                setPage(1)
              }}
              placeholder="Select End Date" 
               />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="route">Routes</label>
          <Autocomplete
            id="routes"
            size="small"
            options={allRoutes}
            getOptionLabel={(option) => option.title ? `${option.title} (${option.code})` : ""}
            value={selectedRoute}
            onChange={(e, values) => {
              setSelectedRoute(values);
              updateFilters({ route: values })
              setPageChange(false)
              setPage(1)

            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Routes"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="route">Vehicles</label>
          <Autocomplete
            id="vehicles"
            size="small"
            options={allVehicle}
            getOptionLabel={(option) => option.registration_number ? option.registration_number : ''}
            value={selectedVehicle}
            onChange={(e, values) => {
              setSelectedVehicle(values);
              updateFilters({ vehicle: values })
              setPageChange(false)
              setPage(1)

            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Vehicle"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="Driver">Driver</label>
          <Autocomplete
            id="Driver-type"
            size="small"
            options={drivers}
            getOptionLabel={(option) => option.driver_name ? option.driver_name : ''}
            value={selectedDriver}
            onChange={(e, values) => {
              setSelectedDriver(values);
              updateFilters({ driver: values })
              setPageChange(false)
              setPage(1)

            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Driver"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="Status">Status</label>
          <Autocomplete
            size="small"
            id="Status-type"
            options={accidentStatus}
            getOptionLabel={(option) => option.title ? option.title : ''}
            value={selectedStatus}
            onChange={(e, values) => {
              setSelectedStatus(values);
              updateFilters({ status: values })
              setPageChange(false)
              setPage(1)

            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Status"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={1.5}>
          <label htmlFor="Status">Paid By</label>
          <Autocomplete
            size="small"
            id="paidBy"
            options={PaidBy}
            getOptionLabel={(option) => option.title ? option.title : ''}
            value={selectedPaidBy}
            onChange={(e, values) => {
              setSelectedPaidBy(values);
              updateFilters({ paidBy: values })
              setPageChange(false)
              setPage(1)

            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Paid By"
              />
            )}
          />
        </Grid>
      </Grid>

      <Modal
        open={filesOpen}
        onClose={handleFilesClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          overflowY: "auto",
          maxHeight: "90vh",
          transform: "translate(-50%, -50%)",
          width: screenWidth < 610 ? "90%" : 600,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleFilesClose}
            aria-label="close"
            sx={{ position: 'absolute', top: 0, right: 0, marginRight: '5px' }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Download the files below
          </Typography>
        </Box>
      </Modal>
      <br />
      <Grid item xs={4}>
        <div className={classes.antAddIcon}>
          <PlusCircleFilled style={{ fontSize: "30px", color: "#1677FF", float: "right", marginBottom: "10px", }} onClick={() => navigate("/accident/new")} />
        </div>
      </Grid>
      <div className={classes.antTable}>
        <Table
          columns={accidentColumns}
          style={{ overflowX: "auto" }}
          pagination={false}
          dataSource={allAccidents}
          // footer={() => `List of Accidents - Total Records: ${allAccidents.length === 0 ? "0" : allAccidents.length}`}
          footer={() => {
            const startRecord = (page - 1) * limit + 1;
            const endRecord = Math.min(page * limit, allAccidents.length ? totalCount : 0); 
            return `Showing ${startRecord}-${endRecord} of ${allAccidents.length ? totalCount : "0"} Records`;
          }}
        />
      </div>

      <Message type={type} msg={loadMessage} />
      <div style={{ float: "right", margin: "10px" }}>
        <Pagination
          page={page}
          count={totalPages}
          variant="outlined"
          color="primary"
          onChange={handleChange}
        />
      </div>
      <ActionDialog
        message={actionDialogProps.message}
        type={actionDialogProps.type}
        isOpen={actionDialogProps.isOpen}
        onClose={actionDialogProps.onClose}
        onSubmit={() => {
          actionDialogProps.onOk();
        }}
        onCancel={() =>
          setActionDialogProps((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
      />
    </div>
  );
}
