import React, { useEffect, useState } from "react";
import AuthService from "../../Components/auth/AuthService";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { Box, Grid } from "@mui/material";
import Message from "../../Components/common/Message";
import {
  useLoadingDispatch,
  showLoading,
  hideLoading,
} from "../../context/loadingContext";
import { Table, Select, Button as AntButton, Result, DatePicker as AntDate, Tooltip, Tag, Row, Col, Collapse, Statistic, Card, Input, Alert, Image, Divider,  } from "antd";
import dayjs from "dayjs";
import { CheckOutlined, CloseOutlined, EyeFilled, EyeTwoTone, SelectOutlined } from "@ant-design/icons";
import ViewReportsDrawer from "./ViewReportsDrawer";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import qs from "query-string";
import { Button as UploadButton } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { netsuite_url, tms_uploads_cdn, env, tms_uploads_bucket, domain } from '../../Components/config';
import { useSelector } from "react-redux";
import { userRolesTypes } from "../../Components/common/constants";
import FinancialOverviewDrawer from "./FinancialOverviewDrawer";
import AmountDiscrepancyDrawer from "./AmountDiscrepancyDrawer";
import InventoryOverviewDrawer from "./InventoryOverviewDrawer";


const useStyles = makeStyles((theme) => ({
  dateField: {
    marginRight: "10px",
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "thin solid #b6babf",
      borderRadius: '5px',
    },
    "& .react-date-picker__button svg ": {
      stroke: "#b6babf",
    }
  },
  selectStyle: {
    "& .ant-select .ant-select-selector": {
      borderBottom: '1px solid #d9d9d9',
      borderTop: '0px',
      borderLeft: '0px',
      borderRight: '0px',
      borderRadius: '0px'
    }
  },
  dataSheet: {
    padding: '5px',
  "& .ant-card .ant-card-body": {
    padding: "4px",
    // backgroundColor: 'rgb(240, 240, 240)',
  },
  
  "& .ant-statistic .ant-statistic-content": {
    fontSize: "12px",
    fontWeight: "bold",
  },
  "& .ant-statistic .ant-statistic-title": {
    fontSize: "12px",
  },
  "& .ant-input[disabled]": {
    color: 'black !important',
    fontWeight: 500
  },
  
},
}));


export default function ViewReports() {
  const { Option } = Select;
  const { Panel } = Collapse;
  const { TextArea } = Input;
  const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  
  const { roles } = useSelector((state) => state.AppState?.userTypes);
  const UserType = useSelector((state) => state.AppState?.userTypes?.type);

  const [deliveryDate, setDeliveryDate] = React.useState(new Date());
  const [routes, setRoutes] = React.useState([]);
  const [routePallets, setRoutePallets] = React.useState([]);
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [drawerOpenFinancial, setDrawerOpenFinancial] = React.useState(false);
  const [drawerOpenAmount, setDrawerOpenAmount] = React.useState(false);
  const [drawerOpenInventory, setDrawerOpenInventory] = React.useState(false);
  const [financialOverview, setFinancialOverview] = React.useState([]);
  const [selectedRoute, setSelectedRoute] = React.useState("");

  const matchedRoles = roles
  .map((role) => {
    return userRolesTypes.find((userRole) => userRole.id === role.role_id);
  })
  .filter(Boolean);
const includesFinance = matchedRoles.some(item => item.title === "Finance")
const includesRouting = matchedRoles.some(item => item.title === "Routing")
const includesWareHouse = matchedRoles.some(item => item.title === "Warehouse User")
const includesWareHouseManager = matchedRoles.some(item => item.title === "Warehouse Manager")
const includesSalesManager = matchedRoles.some(item => item.title === "Sales Manager")


  const Auth = new AuthService();

  const loadRoutes = async () => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
    setType("success");
    setRoutes([]);
    localStorage.removeItem("disabledRoutes")
    localStorage.removeItem("disabledCashupRoutes")
    localStorage.removeItem("disabledStockRoutes")
    localStorage.removeItem("disabledGoodsRoutes")

    setLoadMessage("Loading routes. Please wait ....");
    showLoading(layoutDispatch);
    try {
      const res = await Auth.post(`/route/load-locked-routes`, {
        delivery_date: moment(deliveryDate).format("YYYY-MM-DD"),
      })
      if(res?.ack) {
        setType('success')
        setLoadMessage(res?.message + str);
        setRoutes(res.routes);
        fetchData()
      } else {
        setType('error')
        setLoadMessage(res?.message + str);
      }
    } catch (error) {
      console.log(error);
      setType('error')
      setLoadMessage('Something went wrong' + str)
    } finally {
      hideLoading(layoutDispatch);
    }
  };

  const loadFinancialOverview = async () => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }

    showLoading(layoutDispatch);
    try {
      const res = await Auth.fetch(`/route/financial-summary-overview?delivery_date=${deliveryDate}`)
      if(res?.ack) {
        setType('success')
        setLoadMessage(res?.message + str);
        console.log('overview', res?.financial_summary);
        if(res?.financial_summary?.length) {
          const mergedArray = res?.financial_summary.map((item) => {
            const match = routes.find((other) => other.id == item.route_status_id);
            if (match) {
              return {
                route_status_id: item.route_status_id,
                amount_received_cash: item.amount_received_cash,
                amount_received_card: item.amount_received_card,
                driver_name: match.driver_name,
                registration_number: match.registration_number,
                title: match.title,
                card_received: match.card_received,
                cash_received: match.cash_received,
  
              };
            }
            return null;
          }).filter(Boolean); 
          
          console.log(mergedArray,'mergedArray');
          setFinancialOverview(mergedArray)
          setDrawerOpenFinancial(true)
        }
        
      } else {
        setType('error')
        setLoadMessage(res?.message + str);
      }
    } catch (error) {
      console.log(error);
      setType('error')
      setLoadMessage('Something went wrong' + str)
    } finally {
      hideLoading(layoutDispatch);
    }
  };

  const loadAmountDiscrepancy = async () => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }

    showLoading(layoutDispatch);
    try {
      const res = await Auth.fetch(`/route/financial-summary-overview?delivery_date=${deliveryDate}`)
      if(res?.ack) {
        setType('success')
        setLoadMessage('Amount discrepancies loaded!' + str);
        console.log('overview', res?.financial_summary);
        if(res?.financial_summary?.length) {
          const mergedArray = res?.financial_summary.map((item) => {
            const match = routes.find((other) => other.id == item.route_status_id);
            if (match) {
              return {
                route_status_id: item.route_status_id,
                amount_received_cash: item.amount_received_cash,
                amount_received_card: item.amount_received_card,
                driver_name: match.driver_name,
                registration_number: match.registration_number,
                title: match.title,
                card_received: match.card_received,
                cash_received: match.cash_received,
  
              };
            }
            return null;
          }).filter(Boolean); 
          
          console.log(mergedArray,'mergedArray');
          setFinancialOverview(mergedArray)
          setDrawerOpenAmount(true)
        }
        
      } else {
        setType('error')
        setLoadMessage(res?.message + str);
      }
    } catch (error) {
      console.log(error);
      setType('error')
      setLoadMessage('Something went wrong' + str)
    } finally {
      hideLoading(layoutDispatch);
    }
  };

  const handleOpenDrawer = (row) => {
    if (row) {
      setSelectedRoute(row);
    }
    setDrawerOpen(true);
  };
  const handleCloseDrawer = () => {
    setDrawerOpen(false)
    setSelectedRoute("");
    loadRoutes();
  };

  const dataCol = [
    {
      title: "Route",
      width: 140,
      render(record) {
        return (
          <span>{`${record.title} (${record.code})`}</span>
        );
      },
    },
    {
      title: "Vehicle",
      dataIndex: "registration_number",
      key: "registration_number",
      width: 80,
    },
    {
      title: "Driver Name",
      dataIndex: "driver_name",
      key: "driver_name",
      width: 180,
    },
    {
      title: 'Status',
      key: 'staus',
      align: 'center',
      render(record) {
      const payments_posted = record?.payments_posted_by > 0 ? true : false;
      const completed = record?.start_status === 2 ? true : false;
      const warehouse_cashup = record?.cashup_updated_by > 0 ? true : false;
      const goods_return = record?.goods_return_updated_by > 0 ? true : false;
      const discrepancies_updated = record?.stock_discrepancies_updated_by > 0 ? true : false;

        return (
          <div style={{ display: 'flex'}} >
       
          <Tag color={completed ? 'green' : 'volcano'} >Route Completed</Tag>
          <Tag color={record?.cashup_signature ? 'green' : 'volcano'} >Driver Cashup</Tag>
          <Tag color={goods_return ? 'green' : 'volcano'}>Goods Return</Tag>
          <Tag color={discrepancies_updated ? 'green' : 'volcano'} >Stock Discrepancies</Tag>
          <Tag color={warehouse_cashup ? 'green' : 'volcano'} >Warehouse Cashup</Tag>
          <Tag color={payments_posted ? 'green' : 'volcano'} >Payment Posted</Tag>
          
    </div>
        );
      },
      width: 200
    },
    
    {
      title: 'Details',
      key: 'details',
      align: "right",
      dataIndex: '',
      width: 80,
      render(record) {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Tooltip title="View reports">
            <OpenInNewIcon
            sx={{ cursor: "pointer", fontSize: "25px" }}
            onClick={() => {
              handleOpenDrawer(record);
            }}
          />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  // CASH UP SECTION ------------------------------------------------------------------------

    const [fiftyPoundBillCount, setFiftyPoundBillCount] = useState("");
    const [twentyPoundBillCount, setTwentyPoundBillCount] = useState("");
    const [tenPoundBillCount, setTenPoundBillCount] = useState("");
    const [fivePoundBillCount, setFivePoundBillCount] = useState("");
    const [coinCount, setCoinCount] = useState({
    oneP: '',
    twoP: '',
    fiveP: '',
    tenP: '',
    TwentyP: '',
    fiftyP: '',
    onePound: '',
    twoPound: ''
  });
  const [frontdeskCashupID, setFrontdeskCashupID] = React.useState(null);
  const [notes, setNotes] = useState("");
  const [files, setFiles] = useState([]);
  const [currentItemFiles, setCurrentItemFiles] = useState([]);
  const [totalCoinAmount, setTotalCoinAmount] = useState(0);
  const [totalNotesAmount, setTotalNotesAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [deletedFiles, setDeletedFiles] = React.useState([]);

  const genExtraFrontCashUp = () => {
    
    const completed = frontdeskCashupID > 0 ? true : false;
    return (
      <div style={{ display: 'flex', alignItems: 'center'}}>
        <AntButton
              style={{
                marginRight: '5px',
                color: completed  ? 'green' : 'red',
                cursor: 'alias',
                pointerEvents: "none",
                padding: '4px 4px'
              }}
              icon={completed ? <CheckOutlined/> : <CloseOutlined />}
                >
                 Front Desk Cashup
            </AntButton>
          </div>
    )
  };

    const fetchData = async () => {
      
      showLoading(layoutDispatch);
      try {
        const res = await Auth.fetch(`/route/frontdesk-cashup?delivery_date=${deliveryDate}`);
        if (res?.ack) {
          setFiftyPoundBillCount(res?.frontdesk_cashup?.fifty_notes || "")
          setTwentyPoundBillCount(res?.frontdesk_cashup?.twenty_notes || "")
          setTenPoundBillCount(res?.frontdesk_cashup?.ten_notes || "")
          setFivePoundBillCount(res?.frontdesk_cashup?.five_notes || "")
          setNotes(res?.frontdesk_cashup?.notes || "")
          setCurrentItemFiles(res?.frontdesk_cashup?.files ? JSON.parse(res?.frontdesk_cashup?.files) : [])
          setCoinCount((prevCoins) => ({
            ...prevCoins,
            oneP: res?.frontdesk_cashup?.one_pennies_coins,
            twoP: res?.frontdesk_cashup?.two_pennies_coins,
            fiveP: res?.frontdesk_cashup?.five_pennies_coins,
            tenP: res?.frontdesk_cashup?.ten_pennies_coins,
            TwentyP: res?.frontdesk_cashup?.twenty_pennies_coins,
            fiftyP: res?.frontdesk_cashup?.fifty_pennies_coins,
            onePound: res?.frontdesk_cashup?.one_pound_coins,
            twoPound: res?.frontdesk_cashup?.two_pound_coins,
          }));
        }
        setFrontdeskCashupID(res?.frontdesk_cashup?.id > 0 ? res?.frontdesk_cashup?.id : null)
      } catch (err) {
        console.log(err)
      } finally {
        hideLoading(layoutDispatch);
      }
  }
  
    // useEffect(() => {
    //   fetchData()
    // }, [])

    const calculateTotalCoinAmount = () => {
      const totalCoin =
        (coinCount.oneP ? parseInt(coinCount.oneP) : 0) * 0.01 +
        (coinCount.twoP ? parseInt(coinCount.twoP) : 0) * 0.02 +
        (coinCount.fiveP ? parseInt(coinCount.fiveP) : 0) * 0.05 +
        (coinCount.tenP ? parseInt(coinCount.tenP) : 0) * 0.10 +
        (coinCount.TwentyP ? parseInt(coinCount.TwentyP) : 0) * 0.20 +
        (coinCount.fiftyP ? parseInt(coinCount.fiftyP) : 0) * 0.50 +
        (coinCount.onePound ? parseInt(coinCount.onePound) : 0) * 1.00 +
        (coinCount.twoPound ? parseInt(coinCount.twoPound) : 0) * 2.00;
  
      setTotalCoinAmount(totalCoin.toFixed(2));
  
    }
  
    useEffect(() => {
      calculateTotalCoinAmount();
    }, [coinCount]);
  
    const calculateTotalNoteAmount = () => {
      const totalNote =
        (fiftyPoundBillCount ? parseInt(fiftyPoundBillCount) : 0) * 50.00 +
        (twentyPoundBillCount ? parseInt(twentyPoundBillCount) : 0) * 20.00 +
        (tenPoundBillCount ? parseInt(tenPoundBillCount) : 0) * 10.00 +
        (fivePoundBillCount ? parseInt(fivePoundBillCount) : 0) * 5.00;
  
      setTotalNotesAmount(totalNote.toFixed(2));
    }
  
    useEffect(() => {
      calculateTotalNoteAmount();
    }, [fiftyPoundBillCount, twentyPoundBillCount, tenPoundBillCount, fivePoundBillCount]);
  
    const calculateTotalAmount = () => {
      const total =
        (totalCoinAmount ? parseFloat(totalCoinAmount) : 0) + (totalNotesAmount ? parseFloat(totalNotesAmount) : 0)
  
  
      setTotalAmount(total);
    }
  
    useEffect(() => {
      calculateTotalAmount();
    }, [totalCoinAmount, totalNotesAmount]);
  
    const handleSaveCashUpFrontdesk = async () => {
      let rand = Math.random() * 50;
      let str = " ";
      for (let i = 0; i < rand; i++) {
        str += " ";
      }
     
      if(currentItemFiles?.length < 1) {
        setType('error')
        setLoadMessage('At least add one file to continue' + str)
        return
      }
      const cashUpData = {
        fifty_notes: fiftyPoundBillCount,
        twenty_notes: twentyPoundBillCount,
        ten_notes: tenPoundBillCount,
        five_notes: fivePoundBillCount,
        notes: notes,
        files: currentItemFiles,
        fifty_pennies_coins: coinCount.fiftyP,
        twenty_pennies_coins: coinCount.TwentyP,
        ten_pennies_coins: coinCount.tenP,
        five_pennies_coins: coinCount.fiveP,
        two_pennies_coins: coinCount.twoP,
        one_pennies_coins: coinCount.oneP,
        one_pound_coins: coinCount.onePound,
        two_pound_coins: coinCount.twoPound,
        delivery_date: deliveryDate,
      };
      if(frontdeskCashupID) {
        cashUpData.id = frontdeskCashupID
      }
      showLoading(layoutDispatch);
      try {
        const res = await Auth.fetch("/route/frontdesk-cashup", {
          method: "POST",
          body: JSON.stringify(cashUpData),
        });
        if (res.ack) {
          const uploadPromises = files?.map(async (f, idx) => {
            const recordID = frontdeskCashupID ? frontdeskCashupID : res?.record_id
            try {
              const uploadUrl = await getUploadUrl(f, recordID);
              await uploadFileToS3(uploadUrl, f.file, f.file.type);
            } catch (error) {
              console.error("Error uploading file:", error);
            }
          });
  
          Promise.all(uploadPromises);
          setLoadMessage(res.message)
          setType('success')
          fetchData()
        } else {
          setLoadMessage(res.message)
          setType('error')
        }
      } catch (err) {
        console.log(err)
      } finally {
        hideLoading(layoutDispatch);
      }
  
    };
  
    
    const handleSubmitImage = (e) => {
      e.preventDefault();
      let acceptedFiles = e.target.files;
  
      for (let file of acceptedFiles) {
        const randomString = Math.random().toString(36).substring(2, 10);
        const newFileName = `${file.name.split(".")[0]}-${randomString}.${file.name.split(".")[1]
          }`;
        setLoadMessage("");
        setFiles((prevFiles) => [
          ...prevFiles,
          {
            name: newFileName,
            display_name: newFileName,
            file: file,
            ContentType: file.type,
          },
        ]);
        setCurrentItemFiles((prevFiles) => [...prevFiles, newFileName]);
      }
    };
  
    const getUploadUrl = async (file, recordID) => {
      const params = {
        bucket: tms_uploads_bucket,
        ContentType: file.file.type,
        Key: env + "/frontdesk_cashup/" + recordID + "/" + file.name,
      };
      const response = await fetch(
        `${domain}/generate-put-url?${qs.stringify(params)}`,
        {
          headers: {
            "Content-Type": params.ContentType,
          },
        }
      );
      return response.json();
    };
  
    const uploadFileToS3 = async (uploadUrl, file, contentType) => {
      const response = await fetch(uploadUrl.putURL, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": contentType,
        },
      });
      if (response.status !== 200) {
        throw new Error("File upload failed.");
      }
    };
  
    const handleDeleteFile = (fileName) => {
      const updatedFiles = files?.filter((file) => file.name !== fileName);
      setFiles(updatedFiles);
      setCurrentItemFiles(currentItemFiles?.filter((item) => item !== fileName));
    };
  
    const handleDeleteCurrentFile = (itemToDelete) => {
      setCurrentItemFiles(
        currentItemFiles.filter((item) => item !== itemToDelete)
      );
      if (!deletedFiles.includes(itemToDelete)) {
        setDeletedFiles((prevDeletedFiles) => [
          ...prevDeletedFiles,
          itemToDelete,
        ]);
      }
    };

    //Inventory Data 
  const [inventoryData, setInventoryData] = useState(null)
  const [inventoryAdjustmentInternalID, setInventoryAdjustmentInternalID] = useState(null)
  const [inventoryAdjustmentCode, setInventoryAdjustmentCode] = useState(null)
  const [inventoryPostDate, setInventoryPostDate] = useState(null)

  const getInventoryData = async () => {
    setInventoryData(null)
    let rand = Math.random() * 50;
        let str = " ";
        for (let i = 0; i < rand; i++) {
            str += " ";
        }
    showLoading(layoutDispatch)
    const dataToSend = {
      delivery_date: moment(deliveryDate).format("YYYY-MM-DD")
    }
    try {
      const response = await Auth.fetch("/route/load-inventory-adjustments", {
        method: "POST",
        body: JSON.stringify(dataToSend),
      });
      if(response?.ack) {
        console.log('response', response);
        const updatedInventoryAdjustments = response?.inventory_adjustments.map(item => {
          // Only add the key if it doesn't already exist
          if (item?.returned_quantity_to_damages_posted === undefined) {
            return {
              ...item,
              returned_quantity_to_damages_posted: item?.returned_quantity_to_damages || 0
            };
          }
          return item;
        });
      
        const data = {
         posted: response?.posted,
         inventory_adjustments: updatedInventoryAdjustments,
        }
        setInventoryPostDate(response?.inventory_adjustments[0]?.netsuite_posted_date ? moment(response?.inventory_adjustments[0]?.netsuite_posted_date).format('YYYY-MM-DD') : null)
        setInventoryAdjustmentInternalID(response?.inventory_adjustments[0]?.netsuite_invetory_adjustment_internal_id)
        setInventoryAdjustmentCode(response?.inventory_adjustments[0]?.netsuite_invetory_adjustment_code)
        setInventoryData(data);
        setDrawerOpenInventory(true)
        setType('success')
        setLoadMessage(response.message + str)
      }
    } catch (err) {
      console.log(err);
      setType('error')
      setLoadMessage('Something went wrong')
    } finally {
      hideLoading(layoutDispatch)
    }
  }


    

  return (
    <>
    <h2 style={{marginBottom: '10px', marginTop: 0}}>View Reports</h2>
      <div>
        <Grid
          container
          spacing={2}
          sx={{ alignItems: "flex-end", display: "flex" }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <AntDate
               format={'YYYY-MM-DD'}
               style={{ width: 160}}
               size="large"
               value={deliveryDate ? dayjs(deliveryDate) : null}
               onChange={(_, dateStr) => {
                setDeliveryDate(dateStr);
                  setRoutes([]);
              }}
              placeholder="Select Date"
              />
              <AntButton type="primary" size="large" onClick={loadRoutes} >Load Routes</AntButton>

            </Box>
          </Grid>
        </Grid>
        <br />
        <hr/>
        {
          routes?.length > 0 && (
            <>
            <div className={classes.dataSheet}>
            {
              (!includesRouting && !includesWareHouse && !includesWareHouseManager && !includesSalesManager) && (
                <AntButton
                  type="primary"
                  size="large"
                  onClick={(e) => {
                    loadFinancialOverview()
                  }}

                >
                  {" "}
                  Financial Summary
                </AntButton>
              )
            }
            {
              (!includesRouting && !includesWareHouse && !includesWareHouseManager && !includesSalesManager) && (
                <AntButton
                  type="primary"
                  size="large"
                  style={{marginLeft: '5px'}}
                  onClick={(e) => {
                    getInventoryData()
                  }}
                >
                  {" "}
                  Inventory Adjustments 
                </AntButton>
              )
            } 
            {
              UserType == 1 && (
                <AntButton
                  size="large"
                  type="primary"
                  style={{marginLeft: '5px'}}
                  onClick={(e) => {
                    loadAmountDiscrepancy();
                  }}

                >
                  {" "}
                  Amount Discrepancies
                </AntButton>
              )
            }
            <Collapse style={{ margin: "10px 0px" }} >
      <Panel
          header={
            <>
              <span style={{ fontWeight: "bold" }}>
                Front Desk Cash Up
              </span>
            </>
          }
       extra={genExtraFrontCashUp()}>
                      
      <div style={{ display: "flex", justifyContent: "space-between" }}>

<h2 ></h2>
<div>
  <Row gutter={[10, 16]}>
    <Card style={{ backgroundColor: "#f0f0f0", width: '150px', marginRight: '10px' }}>
      <Statistic
        title="Total Notes Amount"
        value={"£" + totalNotesAmount}
        precision={2}
        valueStyle={{
          color: "#3f8600",
        }}
      // prefix="£"
      />
    </Card>
    <Card style={{ backgroundColor: "#f0f0f0", width: '150px', marginRight: '10px' }}>
      <Statistic
        title="Total Coin Amount"
        value={"£" + totalCoinAmount}
        precision={2}
        valueStyle={{
          color: "#3f8600",
        }}
      // prefix="£"
      />
    </Card>

    <Card style={{ backgroundColor: "#f0f0f0", width: '150px' }}>
      <Statistic
        style={{ "&.ant-statistic .ant-statistic-title": { fontSize: '16px !important' } }}
        title="Total Amount"
        value={"£" + totalAmount.toFixed(2)}
        precision={2}
        valueStyle={{
          color: "#3f8600",
        }}
      // prefix="£"
      />
    </Card>

  </Row>
  </div>
  </div>

  <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            £50 Notes
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter £50 Count"
              type="number"
              onChange={(e) => setFiftyPoundBillCount(e.target.value)}
              value={fiftyPoundBillCount}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total £50 Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="£50 Amount"
              disabled
              // type="number"
              // value={(fiftyPoundBillCount ? parseInt(fiftyPoundBillCount) * 50 : 0).toFixed(2)}
              value={"£" + (fiftyPoundBillCount ? parseInt(fiftyPoundBillCount) * 50.00 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            £20 Notes
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter £20 Count"
              type="number"
              onChange={(e) => setTwentyPoundBillCount(e.target.value)}
              value={twentyPoundBillCount}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total £20 Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="£20 Amount"
              disabled
              // type="text"
              value={"£" + (twentyPoundBillCount ? parseInt(twentyPoundBillCount) * 20.00 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            £10 Notes
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter £10 Count"
              type="number"
              onChange={(e) => setTenPoundBillCount(e.target.value)}
              value={tenPoundBillCount}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total £10 Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="£10 Amount"
              disabled
              // type="number"
              value={"£" + (tenPoundBillCount ? parseInt(tenPoundBillCount) * 10.00 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            £5 Notes
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter £5 Count"
              type="number"
              onChange={(e) => setFivePoundBillCount(e.target.value)}
              value={fivePoundBillCount}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total £5 Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="£5 Amount"
              disabled
              // type="number"
              value={"£" + (fivePoundBillCount ? parseInt(fivePoundBillCount) * 5.00 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            £2 Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter £2 Count"
              type="number"
              value={coinCount.twoPound}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  twoPound: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total £2 Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="£2 Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.twoPound ? parseInt(coinCount.twoPound) * 2.00 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            £1 Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter £1 Count"
              type="number"
              value={coinCount.onePound}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  onePound: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total £1 Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="£1 Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.onePound ? parseInt(coinCount.onePound) * 1.00 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            50p Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter 50p Count"
              type="number"
              value={coinCount.fiftyP}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  fiftyP: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total 50p Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="50p Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.fiftyP ? parseInt(coinCount.fiftyP) * 0.50 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            20p Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter 20p Count"
              type="number"
              value={coinCount.TwentyP}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  TwentyP: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total 20p Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="20p Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.TwentyP ? parseInt(coinCount.TwentyP) * 0.20 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            10p Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter 10p Count"
              type="number"
              value={coinCount.tenP}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  tenP: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total 10p Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="10p Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.tenP ? parseInt(coinCount.tenP) * 0.10 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            5p Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter 5p Count"
              type="number"
              value={coinCount.fiveP}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  fiveP: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total 5p Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="5p Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.fiveP ? parseInt(coinCount.fiveP) * 0.05 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            2p Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter 2p Count"
              type="number"
              value={coinCount.twoP}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  twoP: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total 2p Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="2p Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.twoP ? parseInt(coinCount.twoP) * 0.02 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            1p Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter 1p Count"
              type="number"
              value={coinCount.oneP}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  oneP: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total 1p Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="1p Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.oneP ? parseInt(coinCount.oneP) * 0.01 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>

      <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>Notes</h4>
      <div style={{ marginBottom: "10px" }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <TextArea
              autoSize={{ minRows: 6, maxRows: 12 }}
              label="Notes"
              placeholder="Enter Any Specific Notes"
              onChange={(e) => setNotes(e.target.value)}
              value={notes}
            />
          </Col>
        </Row>
      </div>
      <Divider />
     
      
      <Divider />
      <div style={{ display: 'flex'}}>
        <UploadButton
          component="label"
          variant="contained"
          sx={{ marginTop: "10px" }}
          startIcon={<CloudUploadIcon />}
        >
          Add files
          <input
            type="file"
            hidden
            multiple
            capture
            accept="image/*, video/*"
            onChange={(e) => {
              handleSubmitImage(e);
            }}
          />
        </UploadButton>
        <Alert style={{ width: '180px', marginTop: "10px", marginLeft: "5px"}} message={'Images are required'} type="info" showIcon/>
      </div>
      {currentItemFiles?.length > 0 && <h4>Current Files:</h4>}
      <Image.PreviewGroup>
        {currentItemFiles?.length > 0 &&
          currentItemFiles
            .filter((item) => !files?.some((file) => file.name === item))
            .map((item) => {
              const imageUrl = `${tms_uploads_cdn}/${env}/frontdesk_cashup/${frontdeskCashupID}/${item}`;
              return (
                <div
                  style={{
                    position: "relative",
                    marginRight: "10px",
                    display: "inline-block",
                  }}
                >
                  <Image
                    width={200}
                    height={150}
                    style={{ padding: "5px", border: "1px solid lightgrey" }}
                    src={imageUrl}
                  />
                  <Tooltip title="Delete">
                    <CloseOutlined
                      style={{
                        fontSize: "30px",
                        position: "absolute",
                        right: 5,
                        top: 5,
                        color: "#c12828",
                      }}
                      onClick={() => handleDeleteCurrentFile(item)}
                    />
                  </Tooltip>
                </div>
              );
            })}
      </Image.PreviewGroup>
      <Divider />
      {files?.length > 0 && <h4>New Files:</h4>}
      <Image.PreviewGroup>
        {files?.length > 0 &&
          files?.map((files) => {
            return (
              <div
                style={{
                  position: "relative",
                  marginRight: "10px",
                  display: "inline-block",
                }}
              >
                <Image
                  width={200}
                  height={150}
                  style={{ padding: "5px", border: "1px solid lightgrey" }}
                  src={URL.createObjectURL(files.file)}
                />
                <Tooltip title="Delete">
                  <CloseOutlined
                    style={{
                      fontSize: "30px",
                      position: "absolute",
                      right: 5,
                      top: 5,
                      color: "#c12828",
                    }}
                    onClick={() => handleDeleteFile(files.name)}
                  />
                </Tooltip>
              </div>
            );
          })}
      </Image.PreviewGroup>
      {files?.length > 0 && <Divider />}

      <div
        style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}
      >
        
        <AntButton
          type="primary"
          size="large"
          onClick={handleSaveCashUpFrontdesk}
        >
          Save
        </AntButton>
      </div>


                      
          </Panel>
        </Collapse>
        </div>
            <Table
              style={{ overflowX: "auto", marginTop: "10px" }}
              columns={dataCol}
              dataSource={routes?.sort((a, b) => a.title.localeCompare(b.title))}
              pagination={false}
              />
              </>
          ) 
        }
         <ViewReportsDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} selectedRoute={selectedRoute} setSelectedRoute={setSelectedRoute} deliveryDate={deliveryDate} routes={routes} handleCloseDrawer={handleCloseDrawer}/>
         <FinancialOverviewDrawer drawerOpen={drawerOpenFinancial} setDrawerOpen={setDrawerOpenFinancial} financialOverview={financialOverview} deliveryDate={deliveryDate} />
         <AmountDiscrepancyDrawer drawerOpen={drawerOpenAmount} setDrawerOpen={setDrawerOpenAmount} AmountDiscrepancyOverview={financialOverview} deliveryDate={deliveryDate} />
         <InventoryOverviewDrawer
          drawerOpen={drawerOpenInventory}
          setDrawerOpen={setDrawerOpenInventory}
          inventoryData={inventoryData}
          setInventoryData={setInventoryData}
          deliveryDate={deliveryDate}
          getInventoryData={getInventoryData}
          setInventoryAdjustmentInternalID={setInventoryAdjustmentInternalID}
          inventoryAdjustmentInternalID={inventoryAdjustmentInternalID}
          setInventoryAdjustmentCode={setInventoryAdjustmentCode}
          inventoryAdjustmentCode={inventoryAdjustmentCode}
          setInventoryPostDate={setInventoryPostDate}
          inventoryPostDate={inventoryPostDate}
          />
          
        <Message type={type} msg={loadMessage} />
      </div>
    </>
  );
}
