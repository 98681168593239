import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Divider,
  Row,
  Statistic,
  Table,
  Form,
  Button,
  InputNumber,
  Input,
  Typography,
  Collapse,
  Image,
  Descriptions,
  Tooltip,
  Alert,
} from "antd";
import "./reports.css";
import { makeStyles } from "@mui/styles";
import Message from "../../Components/common/Message";
import AuthService from "../../Components/auth/AuthService";
import {
  hideLoading,
  showLoading,
  useLoadingDispatch,
} from "../../context/loadingContext";
import { cardTerminals } from "../../Components/common/constants";
import { CheckOutlined, CloseCircleOutlined, CloseOutlined, CloudDownloadOutlined, DownloadOutlined, ExpandAltOutlined } from "@ant-design/icons";
// import { config.netsuite_url,  } from "../../Components/config";
import config  from "../../Components/config";
import TextArea from "antd/es/input/TextArea";

import { Button as UploadButton } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import qs from "query-string";


const Auth = new AuthService();

const useStyles = makeStyles((theme) => ({
  dataSheet: {
    "& .ant-card .ant-card-body": {
      padding: "4px",
      // backgroundColor: 'rgb(240, 240, 240)',
    },
    
    "& .ant-statistic .ant-statistic-content": {
      fontSize: "12px",
      fontWeight: "bold",
    },
    "& .ant-statistic .ant-statistic-title": {
      fontSize: "12px",
    },
    "& .ant-input[disabled]": {
      color: 'black !important',
      fontWeight: 500
    },
    
  },
  antInput: {
    "& .ant-input": {
      height: "40px",
    },
    "& .ant-input-outlined[disabled]": {
      color: "black"
    }
  },
}));

const { Text } = Typography;

const CashUpReport = ({ cashUpData, setCashUpData, delivery_date }) => {
  const classes = useStyles();
  var layoutDispatch = useLoadingDispatch();
  const [loadMessage, setLoadMessage] = React.useState("");
  const [type, setType] = React.useState("");
  //   const [cash, setCash] = React.useState();
  //   const [card, setCard] = React.useState();
  // const [inputValues, setInputValues] = React.useState({});

  const generateColor = (index) => {
    const colors = ["#f0f0f0"];
    return colors[0];
  };
  const { Panel } = Collapse;


  const dataCol = [
    {
      title: "S.No.",
      dataIndex: "rank",
      key: "rank",
      width: 55
    }, 
    // {
    //   title: "Shop Code",
    //   dataIndex: "customerName",
    //   key: "customerName",
    //   width: 100
    // },
    {
      title: "Shop Code",
      // dataIndex: "customerName",
      // key: "customerName",
      render: (text,record) => {
        return <span><a href={`${config.netsuite_url}/app/common/entity/custjob.nl?id=${record.customerInternalId}`} target="_blank">{record.customerName}</a></span>;
      },
      width: 100
    },
    {
      title: "Shop Name",
      dataIndex: "customerProjectId",
      key: "customerProjectId",
      width: 200
    },
    // {
    //   title: "Invoice",
    //   dataIndex: "invoice_no",
    //   key: "invoice_no",
    //   width: 100
    // },
    {
      title: 'Order No.',
      render: (text, record) => {
          return (
              <span>
                  {record.invoice_no != null && record?.invoice_no != "" ? (
                      <a 
                          href={`${config.netsuite_url}/app/accounting/transactions/custinvc.nl?id=${record.invoiceInternalId}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                      >
                          {record.invoice_no}
                      </a>
                  ) : (
                      <a 
                          href={`${config.netsuite_url}/app/accounting/transactions/salesord.nl?id=${record.internalId}`} 
                          target="_blank" 
                          rel="noopener noreferrer"
                      >
                          {record.saleOrderNo}
                      </a>
                  )}
              </span>
          );
      },
      width: 100
    },
    {
      title: "Total (£)",
      dataIndex: "orderAmount",
      key: "orderAmount",
      // render: (text, record) => {
      //   const orderAmount = Number(record.orderAmount).toLocaleString("en-GB");
      //   return `${orderAmount}`;
      // },
      render: (text, record) => Number(record.orderAmount).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) || '0.00',
      width: 100
    },
    {
      title: "Cash",
      dataIndex: "amountReceivedCash",
      key: "amountReceivedCash",
      render: (text, record) => Number(record.amountReceivedCash).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) || '0.00',
      width: 100
    },
    {
      title: "Card",
      dataIndex: "amountReceivedCard",
      key: "amountReceivedCard",
      render: (text, record) => Number(record.amountReceivedCard).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) || '0.00',
      width: 100
    },
    // {
    //   title: "Bank",
    //   dataIndex: "amountReceivedAccount",
    //   key: "amountReceivedAccount",
    //   render: (text, record) => Number(record.amountReceivedAccount).toLocaleString(undefined, {
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2
    //   }) || '0.00',
    //   width: 100
    // },
    {
      title: "Status",
      render(record) {
        let statusText;
        let backgroundColor;
    
        switch (record?.delivered) {
          case 1:
            statusText = "Delivered";
            backgroundColor = "#80cb80";
            break;
          case 2:
            statusText = "Postponed";
            backgroundColor = "#f0d787";
            break;
            case -1:
              statusText = "Submitted";
              backgroundColor = "#1890FF";
              break;
          default:
            statusText = "Not Delivered";
            backgroundColor = "#edb1a7";
            break;
        }
    
        return {
          props: {
            style: {
              background: backgroundColor,
            },
          },
          children: <>{statusText}</>,
        };
      },
      width: 100
    },   
  ];


  const [disabledRoutes, setDisabledRoutes] = React.useState([]);


useEffect(() => {
  localStorage.setItem("disabledCashupRoutes", JSON.stringify(disabledRoutes));
}, [disabledRoutes]);

useEffect(() => {
  // Retrieve previously stored disabledRoutes from localStorage
  const storedDisabledRoutes = JSON.parse(localStorage.getItem("disabledCashupRoutes")) || [];

  const newDisabledRoutes = cashUpData
    .filter(stock => stock.credit_notes_posted_by > 0)
    .map(stock => stock.routeStatusID);

  // Merge stored disabledRoutes with new ones, avoiding duplicates
  const updatedDisabledRoutes = [...new Set([...storedDisabledRoutes, ...newDisabledRoutes])];

  // Update the state and localStorage
  setDisabledRoutes(updatedDisabledRoutes);
  localStorage.setItem("disabledCashupRoutes", JSON.stringify(updatedDisabledRoutes));
}, [cashUpData]);

  const handleInputChange = (index, fieldName, value) => {
    const updatedCashUpData = [...cashUpData];
    updatedCashUpData[index][fieldName] = value;
    setCashUpData(updatedCashUpData);
  };

  const onFinish = async (dataIndex) => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
    showLoading(layoutDispatch);
    const routeStatusID = cashUpData[dataIndex].routeStatusID;
    const cash = cashUpData[dataIndex].cashReceived;
    const card = cashUpData[dataIndex].cardReceived;
    const dataToSend = {
      route_status_id: routeStatusID,
      cash_received: cash,
      card_received: card,
    };
    try {
      const response = await Auth.fetch("/route/update-amount-received-route", {
        method: "POST",
        body: JSON.stringify(dataToSend),
      });
      if (response.ack) {
        setType('success')
        setLoadMessage(response?.message +str)
        const updatedDisabledRoutes = [...disabledRoutes, routeStatusID];
            setDisabledRoutes(updatedDisabledRoutes);
            localStorage.setItem(
              "disabledCashupRoutes",
              JSON.stringify(updatedDisabledRoutes)
            );
      } else {
        setType('error')
        setLoadMessage(response?.message + str)
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoading(layoutDispatch);
    }
  };

  const [expandAll, setExpandAll] = useState(false);

  const onClickExpand = () => {
    let cashUpData1 = [...cashUpData];

    if (expandAll) {
      cashUpData1.map((route, rIdx) => {
            route.expanded = false;
        })
    }
    else {
      cashUpData1.map((route, rIdx) => {
            route.expanded = true;
        });
    }
    setCashUpData(cashUpData1);
    setExpandAll(!expandAll);
};

const handleRouteExpand = (route_idx) => {
  const updatedData = [...cashUpData];
  updatedData[route_idx].expanded = !updatedData[route_idx].expanded;
  setCashUpData(updatedData);
}

useEffect(() => {
  if (cashUpData.length === 1) {
    setCashUpData(prevData => {
      return prevData.map(item => ({
        ...item,
        expanded: true
      }));
    });
  }
}, []);

// const downloadCSVFile = (id) => {
//   const dataObject = cashUpData.find((item, idx) => idx === id);
//   console.log('dataObject', dataObject)

//   // Extract formatted_date and driver fields
//   const formattedDate = dataObject.formatted_date.replace(/ /g, '-'); // Replace spaces with dashes
//   const driverName = dataObject.driver.replace(/\s+/g, ''); // Remove spaces

//   // Define the filename
//   const filename = `${formattedDate}_${dataObject.driverRoute}_cashUp.csv`;

//   // Extract all fields from the dataObject, excluding 'expanded' and 'formatted_date'
//   const fields = Object.keys(dataObject).filter(field => field !== 'expanded' && field !== 'formatted_date' && field !== 'routeStatusID');
  
//   // Define a mapping object to rename tableData headers
//   const tableDataHeaderMapping = {
//       rank: 'S No.',
//       customerName: 'Shop Code',
//       customerProjectId: 'Shop Name',
//       // palletOrderID: 'Pallet Order ID',
//       // customerInternalId: 'Customer Internal Id',
//       invoice_no: 'Invoice No',
//       orderAmount: 'Total',
//       amountReceivedCash: 'Cash',
//       amountReceivedCard: 'Card',
//       amountReceivedAccount: 'Account'
//   };

//   // Define fields to exclude from tableData
//   const excludedTableDataFields = ['invoiceInternalId', 'palletOrderID', 'customerInternalId'];

//   // Format data into CSV string
//   let csvData = '';
  
//   // Loop through each field
//   fields.forEach(field => {
//       // Rename 'route' field to 'Route No'
//       let fieldName; 
//       // = field === 'route' ? 'Route No' : field;

//       if (field === 'route') {
//         fieldName = 'Route No';
//       }

//       if (field === 'driver') {
//         fieldName = 'Driver';
//       }

//     if (field === 'truck') {
//         fieldName = 'Truck Reg.';
//       }
//       if (field === 'driverRoute') {
//         fieldName = 'Route';
//       }
//       if (field === 'terminal') {
//         fieldName = 'CC Terminal No.';
//       }
//       if (field === 'totalCash') {
//         fieldName = 'Total Cash';
//       }
//       if (field === 'totalCard') {
//         fieldName = 'Total Card';
//       }
//       if (field === 'totalAccount') {
//         fieldName = 'Total Account';
//       }
//       if (field === 'cardReceived') {
//         fieldName = 'Total Card Received';
//       }
//       if (field === 'cashReceived') {
//         fieldName = 'Total Cash Received';
//       }
      
//       // Check if the field is tableData
//       if (field === 'tableData') {
//           // Extract tableData headers
//           const tableHeaders = Object.keys(dataObject[field][0]);
//           // Map headers to their renamed counterparts and exclude unwanted fields
//           const renamedHeaders = tableHeaders
//               .filter(header => !excludedTableDataFields.includes(header))
//               .map(header => tableDataHeaderMapping[header] || header);
//           // Add tableData headers to CSV
//           csvData += `${renamedHeaders.join(',')}\n`;

//           // Loop through each row in tableData and add to CSV
//           dataObject[field].forEach(row => {
//               // Exclude unwanted fields from tableData
//               const rowData = Object.entries(row)
//                   .filter(([key]) => !excludedTableDataFields.includes(key))
//                   .map(([key, value]) => value);
//               csvData += `${rowData.join(',')}\n`;
//           });
//       } else {
//           // For other fields, add key and value to CSV
//           csvData += `${fieldName},${dataObject[field]}\n`;
//       }
//   });

//   // Create a Blob object from the CSV data
//   const blob = new Blob([csvData], { type: 'text/csv' });

//   // Create a temporary URL for the Blob
//   const url = window.URL.createObjectURL(blob);

//   // Create a link element to trigger the download
//   const link = document.createElement('a');
//   link.href = url;
//   link.setAttribute('download', filename);

//   // Append the link to the body and click it to trigger the download
//   document.body.appendChild(link);
//   link.click();

//   // Clean up: remove the link and revoke the URL
//   document.body.removeChild(link);
//   window.URL.revokeObjectURL(url);
// };

// const downloadCSVFile = (id) => {
//   const dataObject = cashUpData.find((item, idx) => idx === id);
//   console.log('dataObject', dataObject);

//   // Extract formatted_date and driver fields
//   const formattedDate = dataObject.formatted_date.replace(/ /g, '-'); // Replace spaces with dashes
//   const driverName = dataObject.driver.replace(/\s+/g, ''); // Remove spaces

//   // Define the filename
//   const filename = `${formattedDate}_${dataObject.driverRoute}_cashUp.csv`;

//   // Define fields to exclude
//   const excludedFields = ['expanded', 'formatted_date', 'routeStatusID'];

//   // Define a mapping object to rename tableData headers
//   const tableDataHeaderMapping = {
//       rank: 'S No.',
//       customerName: 'Shop Code',
//       customerProjectId: 'Shop Name',
//       invoice_no: 'Invoice No',
//       orderAmount: 'Total',
//       amountReceivedCash: 'Cash',
//       amountReceivedCard: 'Card',
//       amountReceivedAccount: 'Account'
//   };

//   // Define fields to exclude from tableData
//   const excludedTableDataFields = ['invoiceInternalId', 'palletOrderID', 'customerInternalId'];

//   // Separate simple fields and tableData
//   const fields = Object.keys(dataObject).filter(field => !excludedFields.includes(field));
//   const simpleFields = fields.filter(field => field !== 'tableData');
//   const tableDataField = fields.find(field => field === 'tableData');

//   // Format simple fields data into CSV string
//   let csvData = '';

//   simpleFields.forEach(field => {
//       let fieldName = field;
//       let fieldValue = dataObject[field];

//       if (field === 'route') fieldName = 'Route No';
//       if (field === 'driver') fieldName = 'Driver';
//       if (field === 'truck') fieldName = 'Truck Reg.';
//       if (field === 'driverRoute') fieldName = 'Route';
//       if (field === 'terminal'){
//         fieldName = 'CC Terminal No.';
//         fieldValue = `C${fieldValue}`
//       } 
//       if (field === 'totalCash') fieldName = 'Total Cash';
//       if (field === 'totalCard') fieldName = 'Total Card';
//       if (field === 'totalAccount') fieldName = 'Total Account';
//       if (field === 'cardReceived') fieldName = 'Total Card Received';
//       if (field === 'cashReceived') fieldName = 'Total Cash Received';

//       csvData += `${fieldName},${fieldValue}\n`;
//   });

//   // Process tableData if it exists
//   if (tableDataField) {
//       // Extract tableData headers
//       const tableHeaders = Object.keys(dataObject[tableDataField][0]);
//       // Map headers to their renamed counterparts and exclude unwanted fields
//       const renamedHeaders = tableHeaders
//           .filter(header => !excludedTableDataFields.includes(header))
//           .map(header => tableDataHeaderMapping[header] || header);
//       // Add tableData headers to CSV
//       csvData += `${renamedHeaders.join(',')}\n`;

//       // Loop through each row in tableData and add to CSV
//       dataObject[tableDataField].forEach(row => {
//           const rowData = Object.entries(row)
//               .filter(([key]) => !excludedTableDataFields.includes(key))
//               .map(([key, value]) => value);
//           csvData += `${rowData.join(',')}\n`;
//       });
//   }

//   // Create a Blob object from the CSV data
//   const blob = new Blob([csvData], { type: 'text/csv' });

//   // Create a temporary URL for the Blob
//   const url = window.URL.createObjectURL(blob);

//   // Create a link element to trigger the download
//   const link = document.createElement('a');
//   link.href = url;
//   link.setAttribute('download', filename);

//   // Append the link to the body and click it to trigger the download
//   document.body.appendChild(link);
//   link.click();

//   // Clean up: remove the link and revoke the URL
//   document.body.removeChild(link);
//   window.URL.revokeObjectURL(url);
// };

const downloadCSVFile = (id) => {
  const dataObject = cashUpData.find((item, idx) => idx === id);
  console.log('dataObject', dataObject);

  // Extract formatted_date and driver fields
  const formattedDate = dataObject.formatted_date.replace(/ /g, '-'); // Replace spaces with dashes
  const driverName = dataObject.driver.replace(/\s+/g, ''); // Remove spaces

  // Define the filename
  const filename = `${formattedDate}_${dataObject.driverRoute}_cashUp.csv`;

  // Define fields to exclude
  const excludedFields = ['expanded', 'formatted_date', 'routeStatusID'];

  // Define a mapping object to rename tableData headers
  const tableDataHeaderMapping = {
      rank: 'S No.',
      customerName: 'Shop Code',
      customerProjectId: 'Shop Name',
      invoice_no: 'Invoice No',
      orderAmount: 'Total',
      amountReceivedCash: 'Cash',
      amountReceivedCard: 'Card',
      amountReceivedAccount: 'Bank'
  };

  // Define fields to exclude from tableData
  const excludedTableDataFields = ['invoiceInternalId', 'palletOrderID', 'customerInternalId'];

  // Define the desired order for simple fields and table fields
  const simpleFieldsOrder = [
    'route', 'driver', 'truck', 'driverRoute', 'terminal', 
    'totalCash', 'totalCard', 'totalAccount', 'cardReceived', 'cashReceived'
  ];
  const tableFieldsOrder = [
    'rank', 'customerName', 'customerProjectId', 'invoice_no', 
    'orderAmount', 'amountReceivedCash', 'amountReceivedCard', 'amountReceivedAccount'
  ];

  // Filter and map the simple fields according to the desired order
  const simpleFields = simpleFieldsOrder.filter(field => dataObject.hasOwnProperty(field) && !excludedFields.includes(field));

  // Format simple fields data into CSV string
  let csvData = '';

  simpleFields.forEach(field => {
      let fieldName = field;
      let fieldValue = dataObject[field];

      if (field === 'route') fieldName = 'Route No';
      if (field === 'driver') fieldName = 'Driver';
      if (field === 'truck') fieldName = 'Truck Reg.';
      if (field === 'driverRoute') fieldName = 'Route';
      if (field === 'terminal'){
        fieldName = 'CC Terminal No.';
        // fieldValue = `C${fieldValue}`;
        fieldValue = fieldValue ? `C${fieldValue}` : ''
      } 
      if (field === 'totalCash') fieldName = 'Total Cash';
      if (field === 'totalCard') fieldName = 'Total Card';
      if (field === 'totalAccount') fieldName = 'Total Account';
      if (field === 'cardReceived') fieldName = 'Total Card Received';
      if (field === 'cashReceived') fieldName = 'Total Cash Received';

      csvData += `${fieldName},${fieldValue}\n`;
  });

  // Process tableData if it exists
  if (dataObject.tableData) {
      // Extract tableData headers
      const tableHeaders = Object.keys(dataObject.tableData[0]);
      // Map headers to their renamed counterparts and exclude unwanted fields
      const renamedHeaders = tableFieldsOrder
          .filter(header => tableHeaders.includes(header) && !excludedTableDataFields.includes(header))
          .map(header => tableDataHeaderMapping[header] || header);
      // Add tableData headers to CSV
      csvData += `${renamedHeaders.join(',')}\n`;

      // Loop through each row in tableData and add to CSV
      dataObject.tableData.forEach(row => {
          const rowData = tableFieldsOrder
              .filter(key => !excludedTableDataFields.includes(key))
              .map(key => row[key]);
          csvData += `${rowData.join(',')}\n`;
      });
  }

  // Create a Blob object from the CSV data
  const blob = new Blob([csvData], { type: 'text/csv' });

  // Create a temporary URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element to trigger the download
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);

  // Append the link to the body and click it to trigger the download
  document.body.appendChild(link);
  link.click();

  // Clean up: remove the link and revoke the URL
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

const genExtra = (idx, data) => {
    
  const posted = data?.cashup_updated_by > 0 ? true : false;
  const completed = data?.start_status === 2 ? true : false;
  return (
    <div style={{ display: 'flex', alignItems: 'center'}}>
      <Button
            style={{
              marginRight: '5px',
              color: completed  ? 'green' : 'red',
              cursor: 'alias',
              pointerEvents: "none",
              padding: '4px 4px'
            }}
            icon={completed ? <CheckOutlined/> : <CloseOutlined />}
              >
               Route Completed
          </Button>
      {
          <Button
            style={{
              marginRight: '5px',
              color: posted || disabledRoutes.includes(data.routeStatusID) ? 'green' : 'red',
              cursor: 'alias',
              pointerEvents: "none",
              padding: '4px 4px'
            }}
            icon={posted || disabledRoutes.includes(data.routeStatusID) ? <CheckOutlined/> : <CloseOutlined />}
              >
                Warehouse Cashup
          </Button>
        
      }
      {
          <Button
            style={{
              marginRight: '5px',
              color: data?.cashup_signature ? 'green' : 'red',
              cursor: 'alias',
              pointerEvents: "none",
              padding: '4px 4px'
            }}
            icon={data?.cashup_signature ? <CheckOutlined/> : <CloseOutlined />}
              >
                Driver Cashup
          </Button>
        
      }
      {/* <Button
    type="primary"
    style={{padding: '4px 4px'}}
    onClick={(e) => {
      e.stopPropagation();
      downloadCSVFile(idx);
    }}
  > */}
  <Tooltip title="Download">
    <CloudDownloadOutlined
    style={{ fontSize: '25px', color: '#1677FF'}}
    onClick={(e) => {
      e.stopPropagation();
      downloadCSVFile(idx);
    }}
     />
     </Tooltip>
  {/* </Button> */}
  </div>
  )
};


const genExtraContent = (id, data) => (
  <>
  {(data.totalCash > 0 && data.dc_grand_total == 0) && (
    <div style={{ display: 'flex', alignItems: 'center'}}>
    <CloseCircleOutlined style={{ color: 'red', fontSize: '18px', marginRight: '4px'}} /><span style={{ color: 'red', fontSize: '15px'}}>Cash Up Not Found </span>
    </div>
  )
  }
  </>
);



// Define the values for coins and notes
const coinValues = {
  dc_one_pennies_coins: 0.01,
  dc_two_pennies_coins: 0.02,
  dc_five_pennies_coins: 0.05,
  dc_ten_pennies_coins: 0.10,
  dc_twenty_pennies_coins: 0.20,
  dc_fifty_pennies_coins: 0.50,
  dc_one_pound_coins: 1.00,
  dc_two_pound_coins: 2.00,
};

const noteValues = {
  dc_five_notes: 5,
  dc_ten_notes: 10,
  dc_twenty_notes: 20,
  dc_fifty_notes: 50,
};

// Function to calculate total coins and notes
const calculateTotals = (data) => {
  let totalCoins = 0;
  let totalNotes = 0;

  Object.keys(coinValues).forEach((key) => {
    totalCoins += (data[key] || 0) * coinValues[key];
  });

  Object.keys(noteValues).forEach((key) => {
    totalNotes += (data[key] || 0) * noteValues[key];
  });

  return {
    ...data,
    dc_change_total: totalCoins,
    dc_notes_total: totalNotes,
    dc_grand_total: totalCoins + totalNotes,
  };
};

// Apply the calculation to all data entries
const updatedCashUpData = cashUpData.map(calculateTotals)
console.log('updatedCashUpData', updatedCashUpData);

  const [fiftyPoundBillCount, setFiftyPoundBillCount] = useState("");
  const [twentyPoundBillCount, setTwentyPoundBillCount] = useState("");
  const [tenPoundBillCount, setTenPoundBillCount] = useState("");
  const [fivePoundBillCount, setFivePoundBillCount] = useState("");
  const [coinCount, setCoinCount] = useState({
    oneP: '',
    twoP: '',
    fiveP: '',
    tenP: '',
    TwentyP: '',
    fiftyP: '',
    onePound: '',
    twoPound: ''
  });

  const [totalCoinAmount, setTotalCoinAmount] = useState(0);
  const [totalNotesAmount, setTotalNotesAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [notes, setNotes] = useState("");
  const [files, setFiles] = useState([]);
  const [currentItemFiles, setCurrentItemFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = React.useState([]);
  const [frontdeskCashupID, setFrontdeskCashupID] = React.useState(null);

  const calculateTotalCoinAmount = () => {
    const totalCoin =
      (coinCount.oneP ? parseInt(coinCount.oneP) : 0) * 0.01 +
      (coinCount.twoP ? parseInt(coinCount.twoP) : 0) * 0.02 +
      (coinCount.fiveP ? parseInt(coinCount.fiveP) : 0) * 0.05 +
      (coinCount.tenP ? parseInt(coinCount.tenP) : 0) * 0.10 +
      (coinCount.TwentyP ? parseInt(coinCount.TwentyP) : 0) * 0.20 +
      (coinCount.fiftyP ? parseInt(coinCount.fiftyP) : 0) * 0.50 +
      (coinCount.onePound ? parseInt(coinCount.onePound) : 0) * 1.00 +
      (coinCount.twoPound ? parseInt(coinCount.twoPound) : 0) * 2.00;

    setTotalCoinAmount(totalCoin.toFixed(2));

  }

  useEffect(() => {
    calculateTotalCoinAmount();
  }, [coinCount]);

  const calculateTotalNoteAmount = () => {
    const totalNote =
      (fiftyPoundBillCount ? parseInt(fiftyPoundBillCount) : 0) * 50.00 +
      (twentyPoundBillCount ? parseInt(twentyPoundBillCount) : 0) * 20.00 +
      (tenPoundBillCount ? parseInt(tenPoundBillCount) : 0) * 10.00 +
      (fivePoundBillCount ? parseInt(fivePoundBillCount) : 0) * 5.00;

    setTotalNotesAmount(totalNote.toFixed(2));
  }

  useEffect(() => {
    calculateTotalNoteAmount();
  }, [fiftyPoundBillCount, twentyPoundBillCount, tenPoundBillCount, fivePoundBillCount]);

  const calculateTotalAmount = () => {
    const total =
      (totalCoinAmount ? parseFloat(totalCoinAmount) : 0) + (totalNotesAmount ? parseFloat(totalNotesAmount) : 0)


    setTotalAmount(total);
  }

  useEffect(() => {
    calculateTotalAmount();
  }, [totalCoinAmount, totalNotesAmount]);

  const handleSaveCashUpFrontdesk = async () => {
    let rand = Math.random() * 50;
    let str = " ";
    for (let i = 0; i < rand; i++) {
      str += " ";
    }
   
    if(currentItemFiles?.length < 1) {
      setType('error')
      setLoadMessage('At least add one file to continue' + str)
      return
    }
    const cashUpData = {
      fifty_notes: fiftyPoundBillCount,
      twenty_notes: twentyPoundBillCount,
      ten_notes: tenPoundBillCount,
      five_notes: fivePoundBillCount,
      notes: notes,
      files: currentItemFiles,
      fifty_pennies_coins: coinCount.fiftyP,
      twenty_pennies_coins: coinCount.TwentyP,
      ten_pennies_coins: coinCount.tenP,
      five_pennies_coins: coinCount.fiveP,
      two_pennies_coins: coinCount.twoP,
      one_pennies_coins: coinCount.oneP,
      one_pound_coins: coinCount.onePound,
      two_pound_coins: coinCount.twoPound,
      delivery_date: delivery_date,
    };
    if(frontdeskCashupID) {
      cashUpData.id = frontdeskCashupID
    }
    showLoading(layoutDispatch);
    try {
      const res = await Auth.fetch("/route/frontdesk-cashup", {
        method: "POST",
        body: JSON.stringify(cashUpData),
      });
      if (res.ack) {
        const uploadPromises = files?.map(async (f, idx) => {
          const recordID = frontdeskCashupID ? frontdeskCashupID : res?.record_id
          try {
            const uploadUrl = await getUploadUrl(f, recordID);
            await uploadFileToS3(uploadUrl, f.file, f.file.type);
          } catch (error) {
            console.error("Error uploading file:", error);
          }
        });

        Promise.all(uploadPromises);
        setLoadMessage(res.message)
        setType('success')
        fetchData()
      } else {
        setLoadMessage(res.message)
        setType('error')
      }
    } catch (err) {
      console.log(err)
    } finally {
      hideLoading(layoutDispatch);
    }

  };

  
  const handleSubmitImage = (e) => {
    e.preventDefault();
    let acceptedFiles = e.target.files;

    for (let file of acceptedFiles) {
      const randomString = Math.random().toString(36).substring(2, 10);
      const newFileName = `${file.name.split(".")[0]}-${randomString}.${file.name.split(".")[1]
        }`;
      setLoadMessage("");
      setFiles((prevFiles) => [
        ...prevFiles,
        {
          name: newFileName,
          display_name: newFileName,
          file: file,
          ContentType: file.type,
        },
      ]);
      setCurrentItemFiles((prevFiles) => [...prevFiles, newFileName]);
    }
  };

  const getUploadUrl = async (file, recordID) => {
    const params = {
      bucket: config.tms_uploads_bucket,
      ContentType: file.file.type,
      Key: config.env + "/frontdesk_cashup/" + recordID + "/" + file.name,
    };
    const response = await fetch(
      `${config.domain}/generate-put-url?${qs.stringify(params)}`,
      {
        headers: {
          "Content-Type": params.ContentType,
        },
      }
    );
    return response.json();
  };

  const uploadFileToS3 = async (uploadUrl, file, contentType) => {
    const response = await fetch(uploadUrl.putURL, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": contentType,
      },
    });
    if (response.status !== 200) {
      throw new Error("File upload failed.");
    }
  };

  const handleDeleteFile = (fileName) => {
    const updatedFiles = files?.filter((file) => file.name !== fileName);
    setFiles(updatedFiles);
    setCurrentItemFiles(currentItemFiles?.filter((item) => item !== fileName));
  };

  const handleDeleteCurrentFile = (itemToDelete) => {
    setCurrentItemFiles(
      currentItemFiles.filter((item) => item !== itemToDelete)
    );
    if (!deletedFiles.includes(itemToDelete)) {
      setDeletedFiles((prevDeletedFiles) => [
        ...prevDeletedFiles,
        itemToDelete,
      ]);
    }
  };

  const fetchData = async () => {
      
    showLoading(layoutDispatch);
    try {
      const res = await Auth.fetch(`/route/frontdesk-cashup?delivery_date=${delivery_date}`);
      if (res?.ack) {
        setFiftyPoundBillCount(res?.frontdesk_cashup?.fifty_notes || "")
        setTwentyPoundBillCount(res?.frontdesk_cashup?.twenty_notes || "")
        setTenPoundBillCount(res?.frontdesk_cashup?.ten_notes || "")
        setFivePoundBillCount(res?.frontdesk_cashup?.five_notes || "")
        setNotes(res?.frontdesk_cashup?.notes || "")
        setCurrentItemFiles(res?.frontdesk_cashup?.files ? JSON.parse(res?.frontdesk_cashup?.files) : [])
        setCoinCount((prevCoins) => ({
          ...prevCoins,
          oneP: res?.frontdesk_cashup?.one_pennies_coins,
          twoP: res?.frontdesk_cashup?.two_pennies_coins,
          fiveP: res?.frontdesk_cashup?.five_pennies_coins,
          tenP: res?.frontdesk_cashup?.ten_pennies_coins,
          TwentyP: res?.frontdesk_cashup?.twenty_pennies_coins,
          fiftyP: res?.frontdesk_cashup?.fifty_pennies_coins,
          onePound: res?.frontdesk_cashup?.one_pound_coins,
          twoPound: res?.frontdesk_cashup?.two_pound_coins,
        }));
      }
      setFrontdeskCashupID(res?.frontdesk_cashup?.id > 0 ? res?.frontdesk_cashup?.id : null)
    } catch (err) {
      console.log(err)
    } finally {
      hideLoading(layoutDispatch);
    }

}

  useEffect(() => {
    fetchData()
  }, [])

  const genExtraFrontCashUp = () => {
    
    const completed = frontdeskCashupID > 0 ? true : false;
    return (
      <div style={{ display: 'flex', alignItems: 'center'}}>
        <Button
              style={{
                marginRight: '5px',
                color: completed  ? 'green' : 'red',
                cursor: 'alias',
                pointerEvents: "none",
                padding: '4px 4px'
              }}
              icon={completed ? <CheckOutlined/> : <CloseOutlined />}
                >
                 Front Desk Cashup
            </Button>
          </div>
    )
  };


  return (
    <div className={classes.dataSheet}>
      <div style={{display: "flex", alignItems: 'center', justifyContent: 'space-between'}}>
      <h3>Cash Up</h3>
      <Button 
      type="primary" 
      style={{ float: 'right'}} 
      onClick={onClickExpand}
      icon={<ExpandAltOutlined />}>
          {expandAll ? "Collapse All" : "Expand All"}
        </Button>
      </div>
      <Collapse style={{ margin: "10px 0px" }} >
      <Panel
          header={
            <>
              <span style={{ fontWeight: "bold" }}>
                Front Desk Cash Up
              </span>
            </>
          }
       extra={genExtraFrontCashUp()}>
                      
      <div style={{ display: "flex", justifyContent: "space-between" }}>

<h2 ></h2>
<div>
  <Row gutter={[10, 16]}>
    <Card style={{ backgroundColor: "#f0f0f0", width: '150px', marginRight: '10px' }}>
      <Statistic
        title="Total Notes Amount"
        value={"£" + totalNotesAmount}
        precision={2}
        valueStyle={{
          color: "#3f8600",
        }}
      // prefix="£"
      />
    </Card>
    <Card style={{ backgroundColor: "#f0f0f0", width: '150px', marginRight: '10px' }}>
      <Statistic
        title="Total Coin Amount"
        value={"£" + totalCoinAmount}
        precision={2}
        valueStyle={{
          color: "#3f8600",
        }}
      // prefix="£"
      />
    </Card>

    <Card style={{ backgroundColor: "#f0f0f0", width: '150px' }}>
      <Statistic
        style={{ "&.ant-statistic .ant-statistic-title": { fontSize: '16px !important' } }}
        title="Total Amount"
        value={"£" + totalAmount.toFixed(2)}
        precision={2}
        valueStyle={{
          color: "#3f8600",
        }}
      // prefix="£"
      />
    </Card>

  </Row>
  </div>
  </div>

  <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            £50 Notes
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter £50 Count"
              type="number"
              onChange={(e) => setFiftyPoundBillCount(e.target.value)}
              value={fiftyPoundBillCount}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total £50 Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="£50 Amount"
              disabled
              // type="number"
              // value={(fiftyPoundBillCount ? parseInt(fiftyPoundBillCount) * 50 : 0).toFixed(2)}
              value={"£" + (fiftyPoundBillCount ? parseInt(fiftyPoundBillCount) * 50.00 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            £20 Notes
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter £20 Count"
              type="number"
              onChange={(e) => setTwentyPoundBillCount(e.target.value)}
              value={twentyPoundBillCount}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total £20 Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="£20 Amount"
              disabled
              // type="text"
              value={"£" + (twentyPoundBillCount ? parseInt(twentyPoundBillCount) * 20.00 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            £10 Notes
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter £10 Count"
              type="number"
              onChange={(e) => setTenPoundBillCount(e.target.value)}
              value={tenPoundBillCount}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total £10 Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="£10 Amount"
              disabled
              // type="number"
              value={"£" + (tenPoundBillCount ? parseInt(tenPoundBillCount) * 10.00 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            £5 Notes
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter £5 Count"
              type="number"
              onChange={(e) => setFivePoundBillCount(e.target.value)}
              value={fivePoundBillCount}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total £5 Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="£5 Amount"
              disabled
              // type="number"
              value={"£" + (fivePoundBillCount ? parseInt(fivePoundBillCount) * 5.00 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            £2 Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter £2 Count"
              type="number"
              value={coinCount.twoPound}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  twoPound: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total £2 Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="£2 Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.twoPound ? parseInt(coinCount.twoPound) * 2.00 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            £1 Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter £1 Count"
              type="number"
              value={coinCount.onePound}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  onePound: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total £1 Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="£1 Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.onePound ? parseInt(coinCount.onePound) * 1.00 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            50p Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter 50p Count"
              type="number"
              value={coinCount.fiftyP}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  fiftyP: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total 50p Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="50p Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.fiftyP ? parseInt(coinCount.fiftyP) * 0.50 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            20p Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter 20p Count"
              type="number"
              value={coinCount.TwentyP}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  TwentyP: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total 20p Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="20p Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.TwentyP ? parseInt(coinCount.TwentyP) * 0.20 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            10p Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter 10p Count"
              type="number"
              value={coinCount.tenP}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  tenP: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total 10p Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="10p Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.tenP ? parseInt(coinCount.tenP) * 0.10 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            5p Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter 5p Count"
              type="number"
              value={coinCount.fiveP}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  fiveP: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total 5p Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="5p Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.fiveP ? parseInt(coinCount.fiveP) * 0.05 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            2p Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter 2p Count"
              type="number"
              value={coinCount.twoP}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  twoP: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total 2p Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="2p Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.twoP ? parseInt(coinCount.twoP) * 0.02 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[6, 16]}>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            1p Coins
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="Enter 1p Count"
              type="number"
              value={coinCount.oneP}
              onChange={(e) => {
                setCoinCount((prevCoins) => ({
                  ...prevCoins,
                  oneP: e.target.value
                }));
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
          <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
            Total 1p Amount
          </h4>
          <div className={classes.antInput}>
            <Input
              placeholder="1p Amount"
              // type="number"
              disabled
              value={"£" + (coinCount.oneP ? parseInt(coinCount.oneP) * 0.01 : 0).toFixed(2)}
            />
          </div>
        </Col>
      </Row>

      <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>Notes</h4>
      <div style={{ marginBottom: "10px" }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <TextArea
              autoSize={{ minRows: 6, maxRows: 12 }}
              label="Notes"
              placeholder="Enter Any Specific Notes"
              onChange={(e) => setNotes(e.target.value)}
              value={notes}
            />
          </Col>
        </Row>
      </div>
      <Divider />
     
      
      <Divider />
      <div style={{ display: 'flex'}}>
        <UploadButton
          component="label"
          variant="contained"
          sx={{ marginTop: "10px" }}
          startIcon={<CloudUploadIcon />}
        >
          Add files
          <input
            type="file"
            hidden
            multiple
            capture
            accept="image/*, video/*"
            onChange={(e) => {
              handleSubmitImage(e);
            }}
          />
        </UploadButton>
        <Alert style={{ width: '180px', marginTop: "10px", marginLeft: "5px"}} message={'Images are required'} type="info" showIcon/>
      </div>
      {currentItemFiles?.length > 0 && <h4>Current Files:</h4>}
      <Image.PreviewGroup>
        {currentItemFiles?.length > 0 &&
          currentItemFiles
            .filter((item) => !files.some((file) => file.name === item))
            .map((item) => {
              const imageUrl = `${config.tms_uploads_cdn}/${config.env}/frontdesk_cashup/${frontdeskCashupID}/${item}`;
              return (
                <div
                  style={{
                    position: "relative",
                    marginRight: "10px",
                    display: "inline-block",
                  }}
                >
                  <Image
                    width={200}
                    height={150}
                    style={{ padding: "5px", border: "1px solid lightgrey" }}
                    src={imageUrl}
                  />
                  <Tooltip title="Delete">
                    <CloseOutlined
                      style={{
                        fontSize: "30px",
                        position: "absolute",
                        right: 5,
                        top: 5,
                        color: "#c12828",
                      }}
                      onClick={() => handleDeleteCurrentFile(item)}
                    />
                  </Tooltip>
                </div>
              );
            })}
      </Image.PreviewGroup>
      <Divider />
      {files?.length > 0 && <h4>New Files:</h4>}
      <Image.PreviewGroup>
        {files?.length > 0 &&
          files?.map((files) => {
            return (
              <div
                style={{
                  position: "relative",
                  marginRight: "10px",
                  display: "inline-block",
                }}
              >
                <Image
                  width={200}
                  height={150}
                  style={{ padding: "5px", border: "1px solid lightgrey" }}
                  src={URL.createObjectURL(files.file)}
                />
                <Tooltip title="Delete">
                  <CloseOutlined
                    style={{
                      fontSize: "30px",
                      position: "absolute",
                      right: 5,
                      top: 5,
                      color: "#c12828",
                    }}
                    onClick={() => handleDeleteFile(files.name)}
                  />
                </Tooltip>
              </div>
            );
          })}
      </Image.PreviewGroup>
      {files?.length > 0 && <Divider />}

      <div
        style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}
      >
        
        <Button
          type="primary"
          size="large"
          onClick={handleSaveCashUpFrontdesk}
        >
          Save
        </Button>
      </div>


                      
                    </Panel>
                  </Collapse>
      {updatedCashUpData.length > 0 &&
        updatedCashUpData.map((data, index) => (
          <>
            <div className="view-page">
            <Collapse style={{margin: '10px 0px'}} activeKey={data.expanded ? index.toString() : ''} onChange={() => { handleRouteExpand(index)}}>
              <Panel header={
              <>
              <span style={{ fontWeight: 'bold'}}>Route: </span>
              <span style={{ marginRight: '10px'}}>{data.driverRoute}</span>
              <span style={{ fontWeight: 'bold'}}>Driver: </span>
              <span style={{ marginRight: '10px'}}>{data.driver}</span>
              <span style={{ fontWeight: 'bold'}}>Vehicle Reg: </span>
              <span >{data.truck}</span>
              </>
              } extra={genExtra(index, data)} key={index}>
              <Row gutter={[6, 16]}>
                <Col span={3}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="Route No"
                        value={data.route}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={3}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="Truck"
                        value={data.truck}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={6}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="Route"
                        value={data.driverRoute}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={6}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="Driver"
                        value={data.driver}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={4}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="CC Terminal No."
                        value={
                          data.terminal
                            ? cardTerminals.filter((obj) => obj.id == data.terminal)[0].title
                            : "N/A"
                        }
                        valueStyle={{
                          color: "#3f8600",
                        }}
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row gutter={[6, 16]} style={{ marginTop: "10px" }}>
                <Col span={4}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="Total Cash Expected"
                        value={data.totalCash}
                        precision={2}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                        prefix="£"
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={4}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="Total Card Expected"
                        value={data.totalCard}
                        precision={2}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                        prefix="£"
                      />
                    </div>
                  </Card>
                </Col>
                {/* <Col span={3}>
                  <Card style={{ backgroundColor: generateColor(index) }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Statistic
                        title="Total Bank"
                        value={data.totalAccount}
                        precision={2}
                        valueStyle={{
                          color: "#3f8600",
                        }}
                        prefix="£"
                      />
                    </div>
                  </Card>
                </Col> */}
              </Row>
              <div style={{ marginTop: "15px", marginBottom: "40px" }}>
                <Row gutter={16}>
                  <Col>
                    <Text>Cash Received</Text>
                    <Input
                      type="number"
                      name="cash"
                      style={{
                        width: "100%",
                      }}
                      placeholder="Cash received"
                      value={data.cashReceived ? data.cashReceived : null}
                      onChange={(e) =>
                        handleInputChange(index, "cashReceived", e.target.value)
                      }
                    />
                  </Col>
                  <Col>
                    <Text>Card Received</Text>
                    <Input
                      name="card"
                      type="number"
                      style={{
                        width: "100%",
                      }}
                      placeholder="Card received"
                      value={data.cardReceived ? data.cardReceived : null}
                      onChange={(e) =>
                        handleInputChange(index, "cardReceived", e.target.value)
                      }
                    />
                  </Col>
                  <Col>
                    <Button style={{ marginTop: '20px' }} type="primary" onClick={() => onFinish(index)}>
                      Save
                    </Button>
                  </Col>
                </Row>
              </div>
              

              <Collapse style={{ margin: "10px 0px" }} >
                    <Panel
                      header={
                        <>
                          <span style={{ fontWeight: "bold" }}>
                            Cash Up Details
                          </span>
                        </>
                      }
                      key={index} extra={genExtraContent(index, data)}
                    >
                      <Descriptions bordered size={'small'} >
                      <Descriptions.Item label={`£50`}> Count: {data.dc_fifty_notes || 0} 
                      <br/>
                      Total: {"£" +(data.dc_fifty_notes ? parseInt(data.dc_fifty_notes) * 50.00 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£20`}> Count: {data.dc_twenty_notes || 0} 
                      <br/>
                      Total: {"£" +(data.dc_twenty_notes ? parseInt(data.dc_twenty_notes) * 20.00 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£10`}> Count: {data.dc_ten_notes || 0} 
                      <br/>
                      Total: {"£" +(data.dc_ten_notes ? parseInt(data.dc_ten_notes) * 10.00 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£5`}> Count: {data.dc_five_notes || 0} 
                      <br/>
                      Total: {"£" +(data.dc_five_notes ? parseInt(data.dc_five_notes) * 5.00 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£2`}> Count: {data.dc_two_pound_coins || 0} 
                      <br/>
                      Total: {"£" +(data.dc_two_pound_coins ? parseInt(data.dc_two_pound_coins) * 2.00 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£1`}> Count: {data.dc_one_pound_coins || 0} 
                      <br/>
                      Total: {"£" +(data.dc_one_pound_coins ? parseInt(data.dc_one_pound_coins) * 1.00 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£50p`}> Count: {data.dc_fifty_pennies_coins || 0} 
                      <br/>
                      Total: {"£" +(data.dc_fifty_pennies_coins ? parseInt(data.dc_fifty_pennies_coins) * 0.50 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£20p`}> Count: {data.dc_twenty_pennies_coins || 0} 
                      <br/>
                      Total: {"£" +(data.dc_twenty_pennies_coins ? parseInt(data.dc_twenty_pennies_coins) * 0.20 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£10p`}> Count: {data.dc_ten_pennies_coins || 0} 
                      <br/>
                      Total: {"£" +(data.dc_ten_pennies_coins ? parseInt(data.dc_ten_pennies_coins) * 0.10 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£5p`}> Count: {data.dc_five_pennies_coins || 0} 
                      <br/>
                      Total: {"£" +(data.dc_five_pennies_coins ? parseInt(data.dc_five_pennies_coins) * 0.05 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£2p`}> Count: {data.dc_two_pennies_coins || 0} 
                      <br/>
                      Total: {"£" +(data.dc_two_pennies_coins ? parseInt(data.dc_two_pennies_coins) * 0.02 : 0).toFixed(2)}</Descriptions.Item>
                      <Descriptions.Item label={`£1p`}> Count: {data.dc_one_pennies_coins || 0} 
                      <br/>
                      Total: {"£" +(data.dc_one_pennies_coins ? parseInt(data.dc_one_pennies_coins) * 0.01 : 0).toFixed(2)}</Descriptions.Item>

                      </Descriptions>
                      {/* <Row gutter={[10, 16]}>
                      <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                      <span style={{  marginBottom: "3px" }}>
                      £50 Count
                        </span>
                       <Input
                        placeholder="£50 Count"
                        disabled
                        // type="number"
                        value={data.dc_fifty_notes}
                        />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                        <span style={{  marginBottom: "3px" }}>£50 Amount</span>
                          <Input
                            placeholder="£50 Amount"
                            disabled
                            value={"£" +(data.dc_fifty_notes ? parseInt(data.dc_fifty_notes) * 50.00 : 0).toFixed(2)}
                          />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                      <span style={{  marginBottom: "3px" }}>
                      £20 Count
                        </span>
                       <Input
                        placeholder="£20 Count"
                        disabled
                        // type="number"
                        value={data.dc_twenty_notes}
                        />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                        <span style={{  marginBottom: "3px" }}> £20 Amount</span>
                          <Input
                            placeholder="£20 Amount"
                            disabled
                            value={"£" +(data.dc_twenty_notes ? parseInt(data.dc_twenty_notes) * 20.00 : 0).toFixed(2)}
                          />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                      <span style={{  marginBottom: "3px" }}>
                      £10 Count
                        </span>
                       <Input
                        placeholder="£10 Count"
                        disabled
                        // type="number"
                        value={data.dc_ten_notes}
                        />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                        <span style={{  marginBottom: "3px" }}> £10 Amount</span>
                          <Input
                            placeholder="£10 Amount"
                            disabled
                            value={"£" +(data.dc_ten_notes ? parseInt(data.dc_ten_notes) * 10.00 : 0).toFixed(2)}
                          />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                      <span style={{  marginBottom: "3px" }}>
                      £5 Count
                        </span>
                       <Input
                        placeholder="£5 Count"
                        disabled
                        type="number"
                        value={data.dc_five_notes}
                        />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                        <span style={{  marginBottom: "3px" }}> £5 Amount</span>
                          <Input
                            placeholder="£5 Amount"
                            disabled
                            value={"£" +(data.dc_five_notes ? parseInt(data.dc_five_notes) * 5.00 : 0).toFixed(2)}
                          />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                      <span style={{  marginBottom: "3px" }}>
                      £2 Coin Count
                        </span>
                       <Input
                        placeholder="£2 Count"
                        disabled
                        // type="number"
                        value={data.dc_two_pound_coins}
                        />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                        <span style={{  marginBottom: "3px" }}> £2 Amount</span>
                          <Input
                            placeholder="£2 Amount"
                            disabled
                            value={"£" +(data.dc_two_pound_coins ? parseInt(data.dc_two_pound_coins) * 2.00 : 0).toFixed(2)}
                          />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                      <span style={{  marginBottom: "3px" }}>
                      £1 Coin Count
                        </span>
                       <Input
                        placeholder="£1 Count"
                        disabled
                        // type="number"
                        value={data.dc_one_pound_coins}
                        />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                        <span style={{  marginBottom: "3px" }}> £1 Amount</span>
                          <Input
                            placeholder="£1 Amount"
                            disabled
                            value={"£" +(data.dc_one_pound_coins ? parseInt(data.dc_one_pound_coins) * 1.00 : 0).toFixed(2)}
                          />
                       </Col>
                      </Row>

                      <Row style={{ marginTop: '10px'}} gutter={[10, 16]}>
                      <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                      <span style={{  marginBottom: "3px" }}>
                      50p Count
                        </span>
                       <Input
                        placeholder="50p Count"
                        disabled
                        // type="number"
                        value={data.dc_fifty_pennies_coins}
                        />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                        <span style={{  marginBottom: "3px" }}> £50p Amount</span>
                          <Input
                            placeholder="£50p Amount"
                            disabled
                            value={"£" +(data.dc_fifty_pennies_coins ? parseInt(data.dc_fifty_pennies_coins) * 0.50 : 0).toFixed(2)}
                          />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                      <span style={{  marginBottom: "3px" }}>
                      20p Count
                        </span>
                       <Input
                        placeholder="20p Count"
                        disabled
                        // type="number"
                        value={data.dc_twenty_pennies_coins}
                        />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                        <span style={{  marginBottom: "3px" }}> £20p Amount</span>
                          <Input
                            placeholder="£20p Amount"
                            disabled
                            value={"£" +(data.dc_twenty_pennies_coins ? parseInt(data.dc_twenty_pennies_coins) * 0.20 : 0).toFixed(2)}
                          />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                      <span style={{  marginBottom: "3px" }}>
                      10p Count
                        </span>
                       <Input
                        placeholder="10p Count"
                        disabled
                        // type="number"
                        value={data.dc_ten_pennies_coins}
                        />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                        <span style={{  marginBottom: "3px" }}> £10p Amount</span>
                          <Input
                            placeholder="£10p Amount"
                            disabled
                            value={"£" +(data.dc_ten_pennies_coins ? parseInt(data.dc_ten_pennies_coins) * 0.10 : 0).toFixed(2)}
                          />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                      <span style={{  marginBottom: "3px" }}>
                      5p Count
                        </span>
                       <Input
                        placeholder="5p Count"
                        disabled
                        // type="number"
                        value={data.dc_five_pennies_coins}
                        />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                        <span style={{  marginBottom: "3px" }}> £5p Amount</span>
                          <Input
                            placeholder="£5p Amount"
                            disabled
                            value={"£" +(data.dc_five_pennies_coins ? parseInt(data.dc_five_pennies_coins) * 0.05 : 0).toFixed(2)}
                          />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                      <span style={{  marginBottom: "3px" }}>
                      2p Count
                        </span>
                       <Input
                        placeholder="2p Count"
                        disabled
                        // type="number"
                        value={data.dc_two_pennies_coins}
                        />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                        <span style={{  marginBottom: "3px" }}> £2p Amount</span>
                          <Input
                            placeholder="£2p Amount"
                            disabled
                            value={"£" +(data.dc_two_pennies_coins ? parseInt(data.dc_two_pennies_coins) * 0.02 : 0).toFixed(2)}
                          />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                      <span style={{  marginBottom: "3px" }}>
                      1p Count
                        </span>
                       <Input
                        placeholder="20p Count"
                        disabled
                        // type="number"
                        value={data.dc_one_pennies_coins}
                        />
                       </Col>
                       <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                        <span style={{  marginBottom: "3px" }}> £1p Amount</span>
                          <Input
                            placeholder="£1p Amount"
                            disabled
                            value={"£" +(data.dc_one_pennies_coins ? parseInt(data.dc_one_pennies_coins) * 0.01 : 0).toFixed(2)}
                          />
                       </Col>
                      </Row> */}

                      <Row gutter={[6, 16]}>
                       <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
                       Total Coin
                        </h4>
                       <Input
                        placeholder=" Total Coins Amount"
                        disabled
                        type="number"
                        value={data.dc_change_total.toFixed(2)}
                        />
                       </Col>
                       <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
                       Total Notes
                        </h4>
                       <Input
                        placeholder=" Total Notes Amount"
                        disabled
                        type="number"
                        value={data.dc_notes_total.toFixed(2)}
                        />
                       </Col>
                       <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
                       Grand Total
                        </h4>
                       <Input
                        placeholder=" Total Amount"
                        disabled
                        type="number"
                        value={data.dc_grand_total.toFixed(2)}
                        />
                       </Col>
                      </Row>
                      <Row>
                      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <h4 style={{ fontWeight: "bold", marginBottom: "3px" }}>
                       Notes
                        </h4>
                       <TextArea
                       autoSize
                        placeholder="Notes"
                        disabled
                        // type="number"
                        value={data.dc_notes}
                        />
                       </Col>
                      </Row>
                      {data.dc_files?.length > 0 && <h4>Current Files:</h4>}
                      <br />
                      <Image.PreviewGroup>
                        {data?.dc_files?.length > 0 &&
                          data?.dc_files
                            .map((item) => {
                              const imageUrl = `${config?.tms_uploads_cdn}/${
                                config.env
                              }/cashup/${data.routeStatusID}/${item}`;
                              return (
                                <div
                                  style={{
                                    position: "relative",
                                    marginRight: "10px",
                                    display: "inline-block",
                                  }}
                                >
                                  <Image
                                    width={200}
                                    height={150}
                                    style={{
                                      padding: "5px",
                                      border: "1px solid lightgrey",
                                    }}
                                    src={imageUrl}
                                  />
                                </div>
                              );
                            })}
                      </Image.PreviewGroup>
                      <Divider />
                      
                    </Panel>
                  </Collapse>

              <>
                <Table
                  style={{ overflowX: "auto", marginTop: "10px" }}
                  columns={dataCol}
                  bordered
                  dataSource={data.tableData}
                  pagination={false}
                  scroll={{
                    y: 400,
                  }}
                />
              </>
              {/* <Divider style={{ height: "8px", background: "#006cb8" }} /> */}

              </Panel>
              </Collapse>
              
            </div>
          </>
        ))}
      <Message type={type} msg={loadMessage} />
    </div>
  );
};

export default CashUpReport;
